import { createSlice } from "@reduxjs/toolkit";
import { emptyState as initialState, IPriceExtended, IPriceGood, IPriceGoodAccountPrice, IPriceGoodTradeOffer } from "./types";


const priceSlice = createSlice({
  name: "priceSlice",
  initialState,
  reducers: {
    setPrices(state, action) {
      const goods: IPriceGood[] = action.payload
      state.items = goods.map((g: IPriceGood) => (
        {
          ...g,
          offers: g.offers.map((o: IPriceGoodTradeOffer) => (
            {
              ...o,
              accounts: o.accounts.map((a: IPriceGoodAccountPrice) => (
                {
                  ...a,
                  selected: false,
                  new: false,
                  exist: true,
                  changed: false,
                })),
              selected: "unchecked"
            })),
          selected: "unchecked"
        }
      ))
    },
    updatePrices(state, action) {
      const { main, combined } = action.payload
      const prices: IPriceExtended[] = main

      // тут приходит стуктура, а я жду линейный массив. поправить в dataUtils
      const accountsStorageChanged: IPriceGood[] = combined

      let itms: IPriceGood[] = accountsStorageChanged.map((g: IPriceGood) => (
        {
          ...g,
          offers: g.offers.map((o: IPriceGoodTradeOffer) => (
            {
              ...o,
              accounts: o.accounts.map((a: IPriceGoodAccountPrice) => {
                const newPrice = prices.find(p => (p.account !== null && p.account !== undefined && p.sku !== null && p.sku !== undefined)
                  && (p.account == a.account)
                  && (p.sku == o.id))
                return newPrice ? {
                  ...a,
                  prices: {
                    id: newPrice.id,
                    price: Number(newPrice.price),
                    price_mod: Number(newPrice.params?.price_settings?.old_price ?? a.prices.price_mod),
                    base_price: a.prices.base_price,
                  },
                  selected: false,
                  new: false,
                  exist: true,
                  changed: false,
                }
                  : {
                    ...a
                  }
              }),
              selected: "unchecked"
            })),
          selected: "unchecked"
        }
      ))

      state.items = itms.map(g => ({
        ...g,
        offers: g.offers.map(o => ({
          ...o,
          accounts: o.accounts.filter(a => a.changed || a.exist || a.new)
        }))
      }))

    },
    setRequestParams(state, action) {
      state.filter = action.payload
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload
    },
    setCount(state, action) {
      state.count = action.payload
    },
  }
})


export const priceReducer = priceSlice.reducer
export const { setPrices, setIsLoading, setRequestParams, setCount, updatePrices,
} = priceSlice.actions