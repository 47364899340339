type TBindingInfo = {
  tg_code?: string
  bot_url?: string
}

export interface ITgConnectModalState {
  isOpen: boolean
  bindingInfo: TBindingInfo | null
  tgId: number
  isLoading: boolean
}

export const initialState: ITgConnectModalState = {
  isOpen: false,
  bindingInfo: null,
  isLoading: false,
  tgId: -1,
}

export const tgModalActionTypes = {
  ConnectUserToTelegram: '[ConnectUserToTelegram] Action',
  GetInfoAboutTgId: '[GetInfoAboutTgId] Action',
  IntervalGetInfoAboutTgIdTerminated: '[IntervalGetInfoAboutTgIdTerminated] Action',
}
