import {useEffect, useState} from 'react'
import {useNavigate, useSearchParams} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'

import {goodActionTypes, IGoodsGetParams} from '../../store/goods/types'
import {StorageType} from '../../../setup'
import {GoodsTable1} from './list/GoodsTable1'
import {setRequestParams} from '../../store/goods'
import {useIntl} from 'react-intl'
import {useAppLayout} from '../../../_metronic/layout/core/AppLayoutProvider'
import GoodsQuickMenu from './GoodsQuickMenu'
import {GoodsQuickMenuItem} from './types'

export const DEFAULT_LIMIT = 10
export const DEFAULT_PAGE = 0

const GoodsList = () => {
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()
  const [load, setLoad] = useState(true)
  const intl = useIntl()
  const useUrlEncoding = true // use URL encoding to set search parameters like + and &
  const appLayout = useAppLayout()

  const page = +(searchParams.get('page') ?? DEFAULT_PAGE)
  const perPage = +(searchParams.get('ipp') ?? DEFAULT_LIMIT)
  const filter = searchParams.get('search') ?? ''
  const order = searchParams.get('order') ?? 'id'

  const goods = useSelector((state: StorageType) => state.goods.items)
  const count = useSelector((state: StorageType) => state.goods.count)
  const goodsState = useSelector((state: StorageType) => state.goods)

  const params: IGoodsGetParams = {
    items: perPage,
    page: page,
    order: order,
    // search: filter,
    search: useUrlEncoding ? decodeURIComponent(filter) : filter,
  }

  useEffect(() => {
    dispatch(setRequestParams(params))
  }, [searchParams])

  useEffect(() => {
    dispatch({type: goodActionTypes.GetGoods})
  }, [goodsState.itemsPerPage, goodsState.currentPage, goodsState.ordering, goodsState.search])

  useEffect(() => {
    setLoad(goodsState.isLoading)
  }, [goodsState])

  useEffect(() => {
    appLayout.setToolbars(<GoodsQuickMenu activeItem={GoodsQuickMenuItem.List} />)

    return () => {
      appLayout.setToolbars(<></>)
    }
  }, [])
  return (
    <GoodsTable1
      isLoading={goodsState.isLoading}
      title={intl.formatMessage({id: 'COMPONENTS.TABLES.GOODS.LIST.TITLE'})}
      count={count}
      className='card-xxl-stretch mb-5 mb-xl-8'
      items={goods}
      childrenContainerClassName='d-flex justify-content-end align-items-center'
      filterParams={{
        pageNumber: goodsState.currentPage,
        filter: goodsState.search,
        itemsPerPage: goodsState.itemsPerPage,
        ordering: goodsState.ordering,
        url: '/goods/list/',
        useURLEncoding: useUrlEncoding,
        onSearchButtonClick: () => dispatch({type: goodActionTypes.GetGoods}),
      }}
    ></GoodsTable1>
  )
}

export default GoodsList
