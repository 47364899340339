import { createAction } from "@reduxjs/toolkit";
import { IGoodTradeOfferCreateCardGetParams, IGoodTradeOfferMarketplaceGetParams } from "./api";
import { IGoodAndCardsSaveRequest, IGoodCardsSaveRequest, IGoodGetParams, IGoodItemForm, IGoodsWithFilterRequestParams, IOzonCard, IWBCard, goodActionTypes as actionTypes } from "./types";

export const SET_GOODS = actionTypes.SetGoods;
export const ASYNC_GET_GOODS = actionTypes.GetGoods;
//export const ASYNC_GET_GOOD_TRADEOFFERS = actionTypes.GetGoodTrageOffers;
export const ASYNC_GET_GOOD = actionTypes.GetGood;
export const ASYNC_ADD_GOOD = actionTypes.AddGood;
export const ASYNC_UPDATE_GOOD_CARDS = actionTypes.UpdateGoodCards;
export const ASYNC_UPDATE_GOOD = actionTypes.UpdateGood;
export const ASYNC_UPDATE_GOOD_AND_CARDS = actionTypes.UpdateGoodAndCards;
export const ASYNC_UPDATE_GOOD_NOT_CARDS = actionTypes.UpdateGoodNotCards;
export const ASYNC_GET_GOOD_TRADEOFFER = actionTypes.GetGoodTradeOffer;
export const ASYNC_GET_GOOD_TRADEOFFER_FILTERED = actionTypes.GetGoodTradeOfferFiltered;
export const ASYNC_ADD_GOOD_TRADEOFFER = actionTypes.AddGoodTradeOffer;
export const ASYNC_UPDATE_GOOD_TRADEOFFER = actionTypes.UpdateGoodTradeOffer;
export const ASYNC_DELETE_GOOD_TRADEOFFER = actionTypes.DeleteGoodTradeOffer;
export const SET_GOODS_REQUEST_PARAMS = actionTypes.SetGoodsRequestParams
export const SET_GOODS_IS_LOADING = actionTypes.SetIsLoading
export const SET_EMPTY_GOOD = actionTypes.ClearSelectedGood
export const COPY_GOOD_CARD = actionTypes.CopyGoodCard
export const ASYNC_GET_GOODS_WITH_FILTER = actionTypes.GetGoodsWithFilter;

export const SET_OZON_CARD = actionTypes.SetOzonCard
export const SET_WB_CARD = actionTypes.SetWbCard

export const setGoodsCreator = createAction<string>(SET_GOODS);
export const clearSelectedGoodCreator = createAction(SET_EMPTY_GOOD);
export const copyGoodTradeOfferCardCreator = createAction<IGoodTradeOfferCreateCardGetParams>(COPY_GOOD_CARD);

// action creator. params we can add when call it like "getGoodCreator(params:IGoodParams)"
// in saga worker we must add input parameter. in this paramenter in field payload we can read this parameter
export const getGoodCreator =  createAction<IGoodGetParams>(ASYNC_GET_GOOD); 
export const addGoodCreator =  createAction<IGoodItemForm>(ASYNC_ADD_GOOD); 
export const updateGoodCreator =  createAction<IGoodItemForm>(ASYNC_UPDATE_GOOD); 
export const updateGoodCardsCreator =  createAction<IGoodCardsSaveRequest>(ASYNC_UPDATE_GOOD_CARDS); 
export const updateGoodAndCardsCreator =  createAction<IGoodAndCardsSaveRequest>(ASYNC_UPDATE_GOOD_AND_CARDS); 
export const updateGoodNotCardsCreator =  createAction<IGoodItemForm>(ASYNC_UPDATE_GOOD_NOT_CARDS); 

//export const getGoodTradeOffersCreator =  createAction<IGoodTradeOffersGetParams>(ASYNC_GET_GOOD_TRADEOFFERS); 
export const getGoodTradeOfferCreator =  createAction<number>(ASYNC_GET_GOOD_TRADEOFFER);
export const getGoodTradeOfferWithFilterCreator =  createAction<{goodId: number, filter: string}>(ASYNC_GET_GOOD_TRADEOFFER_FILTERED);
export const addGoodTradeOfferCreator =  createAction<IGoodTradeOfferMarketplaceGetParams>(ASYNC_ADD_GOOD_TRADEOFFER);
export const updateGoodTradeOfferCreator =  createAction<IGoodTradeOfferMarketplaceGetParams>(ASYNC_UPDATE_GOOD_TRADEOFFER);
export const deleteGoodTradeOfferCreator =  createAction<IGoodTradeOfferMarketplaceGetParams>(ASYNC_DELETE_GOOD_TRADEOFFER);

export const getAsyncGoodsCreator = createAction(ASYNC_GET_GOODS);
export const setIsLoadingGoodsCreator = createAction<boolean>(SET_GOODS_IS_LOADING);
export const getAsyncGoodsWithFilterCreator = createAction<IGoodsWithFilterRequestParams>(ASYNC_GET_GOODS_WITH_FILTER);

export const setOzonCardCreator =  createAction<IOzonCard>(SET_OZON_CARD); 
export const setWbCardCreator =  createAction<IWBCard>(SET_WB_CARD); 
