import { createAction } from "@reduxjs/toolkit";
import {  IImportMpTaskRequest, IImportXlsTaskRequest, IUserTask, taskActionTypes as actionTypes } from "./types"

export const ASYNC_GET_TASKS = actionTypes.GetTasks;
export const ASYNC_GET_IMPORT_XLS_FORMATS = actionTypes.GetImportXlsFormat;
export const ASYNC_GET_TASK = actionTypes.GetTask;
export const ASYNC_ADD_IMPORT_MP_TASK = actionTypes.AddImportMpTask;
export const ASYNC_ADD_IMPORT_XLS_TASK = actionTypes.AddImportXlsTask;
export const SET_REQUEST_PARAMS = actionTypes.SetRequestParams;
export const SET_TIMER = actionTypes.SetTimer;
export const SET_REFRESHDATA = actionTypes.SetRefreshData;
export const CHECK_REFRESHDATA = actionTypes.CheckRefreshData;
export const SET_REFRESH_IS_WORKING = actionTypes.SetRefreshIsWorking;

export const addAsyncImportMpTaskCreator = createAction<IImportMpTaskRequest>(ASYNC_ADD_IMPORT_MP_TASK);
export const addAsyncImportXlsTaskCreator = createAction<IImportXlsTaskRequest>(ASYNC_ADD_IMPORT_XLS_TASK);
export const getAsyncImportTasksCreator = createAction(ASYNC_GET_TASKS);
export const getAsyncImportXlsFormatsCreator = createAction(ASYNC_GET_IMPORT_XLS_FORMATS);
export const getAsyncImportTaskCreator = createAction<number>(ASYNC_GET_TASK);


export const setTimerCreator = createAction<any>(SET_TIMER);
export const setRefreshDataCreator = createAction<any>(SET_REFRESHDATA);
export const checkRefreshDataCreator = createAction<IUserTask[]>(CHECK_REFRESHDATA);
export const setRefreshIsWorking = createAction<boolean>(SET_REFRESH_IS_WORKING);




