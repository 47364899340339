import { createSlice } from "@reduxjs/toolkit"
import { emptyState as initialState, IStockItem, IStockListItem, IStockUpdateParams, } from "./types"


const stockSlice = createSlice({
    name: "stockSlice",
    initialState,
    reducers: {
        setStocks(state, action){
            state.items = [...action.payload]
        },
        setStock(state, action){
            console.log('setStock',action.payload)
            const {main, combined: stockId} = action.payload

            state.items = [...state.items.map((s: IStockListItem) => {
                if (s.id == stockId){

                    if (s.stocks?.length){
                        const stItems = s.stocks.map(st => {
                            if (st.id == main.id){
                                st.quantity = main.quantity
                                st.reserved = main.reserved
                            }
                            return st
                        })
                        return {...s, stocks: stItems}
                    }
                    else {
                        return s
                    }
                }
                else
                    return s
            })]
        },
        addStock(state, action){
            console.log('addStock',action.payload)

            state.items = [...state.items.map((s: IStockListItem) => {
                if (s.id == action.payload.stockId){
                    const stock:IStockItem = {
                        id: action.payload.id,
                        quantity: action.payload.quantity,
                        reserved: action.payload.reserved,
                        sku_customer_uid: action.payload.sku_customer_uid,
                        warehouse: action.payload.warehouse,
                    }
                    return {...s, stocks: [...s.stocks ?? [], stock]}
                }
                else
                    return s
            })]
        },
        setRequestParams(state, action){
            const {limit, page, ordering, search} = action.payload
            state.itemsPerPage = limit ?? 0
            state.currentPage = page ?? 0
            state.ordering = ordering ?? ""
            state.search = search ?? ""
        },
        // setCount(state, action){
        //     state.count = action.payload
        // },
        // setRequestParams(state, action){
        //     const {items, page, order, search} = action.payload
        //     state.itemsPerPage = items
        //     state.currentPage = page
        //     state.ordering = order
        //     state.search = search
        // },
        setIsLoading(state, action){
            state.isLoading = action.payload
        },
        setIsSavingStockPriceId(state, action){
            console.log('reducers',action.payload)
            state.isSavingStockPriceId = action.payload
        },
        setCount(state, action){
            state.count = action.payload
        },
        // setSelectedGood(state, action){
        //     state.selected = action.payload
        // },
        // setOzonCard(state, action){
        //     if (state.selected?.params?.card_data?.ozon) {
        //         state.selected.params.card_data.ozon = action.payload
        //     };
        // },
        // setWbCard(state, action){
        //     if (state.selected.params?.card_data?.wildberries){
        //         state.selected.params.card_data.wildberries = action.payload
        //     }
        // },
    }
})


export const stockReducer = stockSlice.reducer
export const { setStocks, setIsLoading, setStock, setRequestParams, setCount, addStock,setIsSavingStockPriceId,
} = stockSlice.actions