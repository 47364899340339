import {useEffect, useState} from 'react'
import {Button, Col, Row} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'
import {useNavigate, useSearchParams} from 'react-router-dom'
import ReactSelect from 'react-select'
import {StorageType} from '../../../../setup'
import {ISelectOption} from '../../../components/common/select/AsyncSelectInput'
import Filter from '../../../components/lists/UrlFilter'
import {emptyUrlParameters, IUrlParameters} from '../../../components/lists/UrlFilteredList'
import DateRangePickerBootstrap from '../../../components/select/date-picker/DateRangePicker'
import ModalInfo from '../../../components/vision/info/ModalInfo'
import {IAccountState} from '../../../store/accounts/types'
import {IMarketplaceState} from '../../../store/marketplaces/types'
import {
  getAsyncMpDocsWithFilterCreator,
  setMpDocsRequestParams,
} from '../../../store/mpdocs/actions'
import {getMpDocStickerOnServer, getMpDocStickersOnServer} from '../../../store/mpdocs/api' //'../../../store/mpdocs/api'
import {DEFAULT_MPDOCS_GET_LIMIT, DEFAULT_MPDOCS_PAGE} from '../../../store/mpdocs/constants'
import {createLink, dateUtils, errorUtils} from '../../../utils/dataUtils'
import {IMpDocItem, IMpDocStorage} from '../../../store/mpdocs/types'
import MpDocsList from './MpDocsList'
import 'moment/locale/ru'
import moment from 'moment'
import FormCheckInput from 'react-bootstrap/esm/FormCheckInput'
import {intl} from '../../goods/item/validation'
import PushMessager from '../../../store/system/pushMessager'
import {IDateRange} from '../../../utils/types'
import OrdersMenu from '../../orders/OrdersMenu'
import {useAppLayout} from '../../../../_metronic/layout/core/AppLayoutProvider'
import {ReactSelectSimpleStyles} from '../../orders/list/OrdersListWrapper'
import ModalWindowExtended from '../../../components/modals/cathegory-select-modal/ModalWindowExtended'
import MpDocsItemWrapper from '../item/MpDocsItemWrapper'
import {useLocation} from 'react-router'
import * as publicRoutes from '../../../routing/publicRoutes'
import * as routes from '../../../routing/routes'

moment.locale(
  intl.formatMessage({id: 'COMPONENTS.MPDOCS.LIST.FILTERS.DATERANGE.LOCALIZATION-LANG'})
)
const defaultCreateDate = {startDate: moment().add(-2, 'days'), endDate: moment().add(-1, 'days')}
const defaultShipDate = {startDate: moment().add(-1, 'days'), endDate: moment()}

// path to scan service
export const scanService = process.env.REACT_APP_API_SCAN_URL //`http://localhost:3000/id/`

// make link and click on it
export const makeLinkAndClick = (url: string, isNotBlank?: boolean) => {
  let alink = document.createElement('a')
  alink.href = url
  alink.target = isNotBlank ? '_self' : '_blank'
  alink.click()
}

export const requestStickers = async (
  itemIds: number[] | number
): Promise<HTMLAnchorElement | null> => {
  try {
    let response = Array.isArray(itemIds)
      ? await getMpDocStickersOnServer({mpdocIds: itemIds})
      : await getMpDocStickerOnServer({mpdocId: itemIds})
    let dta: any = response.data
    // Creating new object of PDF file
    const fileURL = window.URL.createObjectURL(dta)
    // Setting various property values
    let alink = document.createElement('a')
    alink.href = fileURL
    alink.target = '_blank'

    //alink.click(); // use it to open file in another tab
    return alink
  } catch (error) {
    const err = errorUtils.getErrorFromObject(error)
    err
      ? PushMessager.addError(
          intl.formatMessage({id: 'STATUS.ERROR1'}),
          intl.formatMessage({id: 'COMPONENTS.MPDOCS.LIST.ERROR.STICKERFAILED'}),
          err
        )
      : PushMessager.addError(
          intl.formatMessage({id: 'STATUS.ERROR1'}),
          intl.formatMessage({id: 'COMPONENTS.MPDOCS.LIST.ERROR.STICKERFAILED'})
        )
    return null
  }
}

export interface IChangeUrlParameter {
  selected: ISelectOption | ISelectOption[]
  optionName: string
  optionToReset?: string
}

interface InterfaceMpDocsListWrapper {
  forWarehouse?: boolean
}

const MpDocsListWrapper = (props: InterfaceMpDocsListWrapper) => {
  // filter constants
  const marketplaceParameter = 'marketplace'
  const accountParameter = 'account'
  const created_before_Parameter = 'created_before'
  const created_after_Parameter = 'created_after'
  const back_from_item = 'back'

  const dispatch = useDispatch()
  const appLayout = useAppLayout()
  const intl = useIntl()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const location = useLocation()

  const isPublicRoute = location.pathname.startsWith('/public/')

  // get data from storage
  const mpdocState: IMpDocStorage = useSelector((s: StorageType) => s.mpdocs)
  const marketplaceState: IMarketplaceState = useSelector((s: StorageType) => s.marketplaces)
  const accountState: IAccountState = useSelector((s: StorageType) => s.accounts)

  const [mpdocStateItems, setMpDocStateItems] = useState<IMpDocItem[]>([])
  const [showModal, setShowModal] = useState<boolean>(false)
  const [quickSelectedItem, setQuickSelectedItem] = useState<string>('')

  // get main filter parameters from url
  const page = +(searchParams.get('page') ?? DEFAULT_MPDOCS_PAGE)
  const perPage = +(searchParams.get('ipp') ?? DEFAULT_MPDOCS_GET_LIMIT)
  const filter = searchParams.get('search') ?? ''
  const order = searchParams.get('order') ?? 'id'

  // get additional filter parameters from url
  const marketplaceUrlParam = searchParams.get(marketplaceParameter) ?? ''
  const accountUrlParam = searchParams.get(accountParameter) ?? ''
  const createdAfterUrlParam = searchParams.get(created_after_Parameter) ?? ''
  const createdBeforeUrlParam = searchParams.get(created_before_Parameter) ?? ''
  const backFromItem = searchParams.get(back_from_item) ?? ''

  // init filters variables
  const selectedMarketplace = marketplaceState?.items?.find(
    (mp) => mp.id.toString() == marketplaceUrlParam
  )
  const selectedAccount = accountState?.items?.find((mp) => mp.id.toString() == accountUrlParam)

  // prepare (init) variables for date range filters
  const [selectedCreateDates, setSelectedCreateDates] = useState<IDateRange>(
    dateUtils.convertStringToIDateRange(
      createdAfterUrlParam,
      createdBeforeUrlParam,
      defaultCreateDate
    )
  )
  const [selectedCreateDatesOn, setSelectedCreateDatesOn] = useState<boolean>(
    !dateUtils.dateRangeIsEqual(selectedCreateDates, defaultCreateDate) ?? false
  )

  const selectedItems: number[] =
    mpdocStateItems.filter((i) => i.selected)?.map((ii) => ii.id) ?? []

  // parameters to filters, sorting and pagination
  const params: IUrlParameters = {
    filter,
    itemsPerPage: perPage,
    ordering: order,
    pageNumber: page,
    url: '/' + (isPublicRoute ? publicRoutes.MPDOCS : routes.MPDOCS) + '/list',
    additionalParameters: [
      {label: marketplaceParameter, value: marketplaceUrlParam},
      {label: accountParameter, value: accountUrlParam},
      {label: created_before_Parameter, value: createdBeforeUrlParam},
      {label: created_after_Parameter, value: createdAfterUrlParam},
    ],
  }

  // raises when any parameter in URL changes
  useEffect(() => {
    dispatch(setMpDocsRequestParams(params))
  }, [searchParams])

  // raises when any filter parameter in storage (redux) changes
  useEffect(() => {
    if (!backFromItem.length || !mpdocState?.items?.length)
      // check it to cancel reload after back from item
      dispatch(getAsyncMpDocsWithFilterCreator()) // call get mpdocs with storage filters
  }, [
    mpdocState.filter?.itemsPerPage,
    mpdocState.filter?.pageNumber,
    mpdocState.filter?.ordering,
    mpdocState.filter?.filter,
    mpdocState.filter?.additionalParameters,
  ])

  useEffect(() => {
    setMpDocStateItems(
      mpdocState.items.map((itm) => {
        const oldItm = mpdocStateItems.length && mpdocStateItems.find((osi) => osi.id == itm.id)
        return {
          ...itm,
          selected: oldItm ? oldItm.selected ?? false : false,
        }
      })
    )
  }, [mpdocState.items])

  // raises when load
  useEffect(() => {
    if (!backFromItem.length || !mpdocState?.items?.length)
      // check it to cancel reload after back from item
      dispatch(getAsyncMpDocsWithFilterCreator()) // call get mpdocs with storage filters

    if (!isPublicRoute) {
      appLayout.setToolbars(<OrdersMenu isActive='documents' />)
    }

    return () => {
      // remove control back button
      appLayout.setToolbars(<></>)
    }
  }, [isPublicRoute])

  // universal changer of additional parameter with reseting the related filter
  const changeSelectFilter = (paramData: IChangeUrlParameter | IChangeUrlParameter[]) => {
    const navigateObject = Array.isArray(paramData)
      ? createLink.paginationLink({
          ...params,
          pageNumber: 0, // reset page on 0 when change filter
          additionalParameters: [
            ...(params.additionalParameters?.map((ap) => {
              let founded = paramData.find((p) => p.optionName == ap.label)
              if (founded) {
                return {
                  label: founded.optionName,
                  value: founded.selected
                    ? Array.isArray(founded.selected)
                      ? founded.selected.map((s) => s.value).toString()
                      : founded.selected.value
                    : '',
                }
              } else {
                let foundedToReset = paramData.find(
                  (p) => p.optionToReset && p.optionToReset == ap.label
                )
                if (foundedToReset && foundedToReset.optionToReset)
                  return {
                    label: foundedToReset.optionToReset,
                    value: '',
                  }
                else return ap
              }
            }) ?? []),
          ],
        })
      : createLink.paginationLink({
          ...params,
          pageNumber: 0, // reset page on 0 when change filter
          additionalParameters: [
            ...(params.additionalParameters?.map((ap) =>
              ap.label == paramData.optionName
                ? {
                    label: paramData.optionName,
                    value: paramData.selected
                      ? Array.isArray(paramData.selected)
                        ? paramData.selected.map((s) => s.value).toString()
                        : paramData.selected.value
                      : '',
                  }
                : paramData.optionToReset && ap.label == paramData.optionToReset
                ? {
                    label: paramData.optionToReset,
                    value: '',
                  }
                : ap
            ) ?? []),
          ],
        })
    navigate(navigateObject.url)
  }

  //call to print sticker (barcode)
  const printBarcode = async () => {
    const items: number[] = selectedItems
    setShowModal(true)
    const link = await requestStickers(items)
    setShowModal(false)
    link?.click()
  }

  // set selected rows
  const setSelectedItem = (item: IMpDocItem, selected: boolean) => {
    setMpDocStateItems(mpdocStateItems.map((osi) => (osi.id == item.id ? {...osi, selected} : osi)))
  }

  // select/unselect all rows
  const selectAll = (selected: boolean) => {
    setMpDocStateItems(mpdocStateItems.map((osi) => ({...osi, selected})))
  }

  // changes mode (enabled/disabled) of date range filters
  const changeDateFilterVisibility = (
    setter: (val: boolean) => void,
    selectedDatesOn: boolean,
    parameters?: ISelectOption[],
    resetParameters?: string[]
  ) => {
    if (selectedDatesOn) {
      // выкл из url
      resetParameters &&
        changeSelectFilter(
          resetParameters.map((parameter) => ({
            selected: {value: '', label: parameter},
            optionName: parameter,
          }))
        )
    } else {
      parameters &&
        changeSelectFilter(
          parameters.map((parameter) => ({
            selected: {value: parameter.value, label: parameter.label},
            optionName: parameter.label,
          }))
        )
    }

    setter(!selectedDatesOn)
  }

  // set date range with controling disable
  const changeDateFilter = (
    picker: IDateRange,
    setter: (dateRange: IDateRange) => void,
    parameters: ISelectOption[]
  ) => {
    // change filter
    changeSelectFilter(
      parameters.map((parameter) => ({
        selected: {value: parameter.value, label: parameter.label},
        optionName: parameter.label,
      }))
    )

    // set current selected date range
    setter({
      startDate: picker.startDate,
      endDate: picker.endDate,
    })
  }

  return (
    <MpDocsList
      count={mpdocState.count ?? 0}
      // accounts={accountState?.items ?? []}
      accounts={accountState?.items ?? []}
      marketplaces={marketplaceState?.items ?? []}
      isLoading={mpdocState.isLoading}
      items={mpdocStateItems ?? []}
      itemUrl={isPublicRoute ? publicRoutes.MPDOCS_ITEM : routes.MPDOCS_ITEM}
      filterParams={mpdocState.filter ?? emptyUrlParameters}
      title={intl.formatMessage({id: 'COMPONENTS.MPDOCS.LIST.TITLE'})}
      // description={intl.formatMessage({ id: "COMPONENTS.MPDOCS.LIST.SUBTITLE" })}
      setSelectedItem={setSelectedItem}
      setSelectedAllItems={selectAll}
      setQuickView={setQuickSelectedItem}
    >
      <>
        <div className='p-5'>
          <Row className='mb-3'>
            <Col xxl={4} xl={4} lg={4} md={12} sm={12} xs={12}>
              <ReactSelect
                isSearchable={false}
                className=' my-1'
                styles={ReactSelectSimpleStyles}
                isMulti={false}
                onChange={(selected: any) =>
                  changeSelectFilter({
                    selected,
                    optionName: marketplaceParameter,
                    optionToReset: accountParameter,
                  })
                }
                placeholder={intl.formatMessage({
                  id: 'COMPONENTS.MPDOCS.LIST.FILTERS.MARKETPLACE.PLACEHOLDER',
                })}
                loadingMessage={() =>
                  intl.formatMessage({id: 'COMPONENTS.MPDOCS.LIST.FILTERS.ALL.LOADING'})
                }
                noOptionsMessage={() =>
                  intl.formatMessage({id: 'COMPONENTS.MPDOCS.LIST.FILTERS.MARKETPLACE.NOVALUES'})
                }
                value={
                  selectedMarketplace
                    ? {label: selectedMarketplace?.name, value: selectedMarketplace?.id}
                    : null
                }
                getOptionLabel={(w: any) => w.label}
                isClearable={true}
                options={
                  marketplaceState?.items?.map((mp) => ({label: mp.name, value: mp.id})) ?? []
                }
              />
            </Col>
            <Col xxl={4} xl={4} lg={4} md={12} sm={12} xs={12}>
              <ReactSelect
                isSearchable={false}
                className=' my-1'
                isMulti={false}
                styles={ReactSelectSimpleStyles}
                onChange={(selected: any) =>
                  changeSelectFilter({selected, optionName: accountParameter})
                }
                placeholder={intl.formatMessage({
                  id: 'COMPONENTS.MPDOCS.LIST.FILTERS.ACCOUNT.PLACEHOLDER',
                })}
                loadingMessage={() =>
                  intl.formatMessage({id: 'COMPONENTS.MPDOCS.LIST.FILTERS.ALL.LOADING'})
                }
                noOptionsMessage={() =>
                  intl.formatMessage({id: 'COMPONENTS.MPDOCS.LIST.FILTERS.ACCOUNT.NOVALUES'})
                }
                value={
                  selectedAccount
                    ? {label: selectedAccount?.name, value: selectedAccount?.id}
                    : null
                }
                getOptionLabel={(w: any) => w.label}
                isClearable={true}
                options={
                  accountState?.items
                    ?.filter((itm) =>
                      selectedMarketplace ? itm.marketplace == selectedMarketplace?.id : true
                    )
                    ?.map((mp) => ({label: mp.name, value: mp.id})) ?? []
                }
              />
            </Col>
            <Col
              xxl={4}
              xl={4}
              lg={4}
              md={12}
              sm={12}
              xs={12}
              className={`d-flex flex-row align-items-center mt-1`}
            >
              <FormCheckInput
                className='me-1 my-1'
                checked={selectedCreateDatesOn}
                onChange={() =>
                  changeDateFilterVisibility(
                    setSelectedCreateDatesOn,
                    selectedCreateDatesOn,
                    [
                      {
                        label: created_after_Parameter,
                        value: selectedCreateDates.startDate.format('YYYY-MM-DD'),
                      },
                      {
                        label: created_before_Parameter,
                        value: selectedCreateDates.endDate.format('YYYY-MM-DD'),
                      },
                    ],
                    [created_after_Parameter, created_before_Parameter]
                  )
                }
              />
              <DateRangePickerBootstrap
                className={`w-100 ms-1`}
                locale={{
                  applyLabel: intl.formatMessage({id: 'ACTION.APPLY'}),
                  cancelLabel: intl.formatMessage({id: 'ACTION.CANCEL'}),
                }}
                initDateFrom={selectedCreateDates.startDate.toDate()}
                initDateTo={selectedCreateDates.endDate.toDate()}
                disabled={!selectedCreateDatesOn}
                placeholder={intl.formatMessage({
                  id: 'COMPONENTS.MPDOCS.LIST.FILTERS.CREATED.PLACEHOLDER',
                })}
                onApply={(event: any, picker: IDateRange) =>
                  changeDateFilter(picker, setSelectedCreateDates, [
                    {
                      label: created_after_Parameter,
                      value: picker.startDate.format('YYYY-MM-DD'),
                    },
                    {
                      label: created_before_Parameter,
                      value: picker.endDate.format('YYYY-MM-DD'),
                    },
                  ])
                }
              >
                <input
                  className='w-100 form-control'
                  value={`${moment(selectedCreateDates.startDate).format('LL')} - ${moment(
                    selectedCreateDates.endDate
                  ).format('LL')}`}
                  placeholder={intl.formatMessage({
                    id: 'COMPONENTS.MPDOCS.LIST.FILTERS.CREATED.PLACEHOLDER',
                  })}
                />
              </DateRangePickerBootstrap>
            </Col>
          </Row>
          <Row>
            <Col>
              <Filter urlParams={{...(mpdocState.filter ?? emptyUrlParameters)}} />
            </Col>
          </Row>
          <Row>
            <Col className='d-flex flex-row justify-content-end align-items-center'>
              <Button onClick={printBarcode} className='my-2 me-2' disabled={!selectedItems.length}>
                {intl.formatMessage({id: 'COMPONENTS.MPDOCS.LIST.BUTTONS.PRINT'})}
              </Button>
            </Col>
          </Row>
          <ModalInfo
            title={intl.formatMessage({id: 'COMPONENTS.MPDOCS.LIST.MODALS.PRINT.TITLE'})}
            showModal={showModal}
            setShowModal={setShowModal}
          />
        </div>
        <ModalWindowExtended
          applyShow={false}
          cancelShow={false}
          setShow={() => null}
          centered
          show={quickSelectedItem.length ? true : false}
          size='xl'
          headerHide
          bodyClassIsOff
          canApply
        >
          <MpDocsItemWrapper id={quickSelectedItem} closeAction={() => setQuickSelectedItem('')} />
        </ModalWindowExtended>
      </>
    </MpDocsList>
  )
}

export default MpDocsListWrapper
