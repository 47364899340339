import { createSlice } from "@reduxjs/toolkit"
import { initialWarehouses as initialState, IWarehouse, } from "./types"


const warehouseSlice = createSlice({
    name: "warehouseSlice",
    initialState,
    reducers: {
        setWarehouses(state, action) {
            state.items = [...action.payload]
        },
        addWarehouse(state, action) {
            state.items = [...state.items, action.payload]
        },
        setWarehouse(state, action) {
            const wrh: IWarehouse = action.payload
            state.items = [...state.items.map((wr: IWarehouse) => {
                if (wr.id == wrh.id)
                    wr = wrh
                return wr
            })]
        },
        deleteWarehouse(state, action) {
            const wrhId: number = action.payload
            state.items = [...state.items.filter((wr: IWarehouse) => wr.id != wrhId)]
        },
        setIsLoading(state, action) {
            state.isLoading = action.payload
        },
    }
})


export const warehouseReducer = warehouseSlice.reducer
export const { setWarehouses, addWarehouse, setWarehouse, deleteWarehouse, setIsLoading } = warehouseSlice.actions
