import {useState, ChangeEvent} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {updateData} from '../../../../../store'

import {Form} from 'react-bootstrap'

import {StorageType} from '../../../../../../setup'

const OzonStep4 = () => {
  const data = useSelector((storage: StorageType) => storage.mpWizard.data)

  const [accName, setAccName] = useState(data?.step3?.accountName || '')

  const dispatch = useDispatch()

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setAccName(e.target.value)

    dispatch(updateData({key: 'step3', field: 'accountName', value: e.target.value}))
  }

  return (
    <div className='fs-5'>
      <p className='mb-9'>
        Пожалуйста, введите название вашего кабинета. Это поможет вам легко идентифицировать и
        управлять своими аккаунтами в будущем. Название может быть любым, удобным для вас, и оно
        будет использоваться только в рамках нашего сервиса.
      </p>
      <Form className='mb-9'>
        <Form.Control
          value={accName}
          onChange={onChange}
          className='bg-secondary'
          placeholder='Название кабинета'
        />
      </Form>
    </div>
  )
}

export default OzonStep4
