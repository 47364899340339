import { createSlice } from "@reduxjs/toolkit"
import { initialImages as initialState, } from "./types"


const imageSlice = createSlice({
    name: "imageSlice",
    initialState,
    reducers: {
        setImages(state, action){
            state.items = [...action.payload]
        }
    }
})


export const imageReducer = imageSlice.reducer
export const { setImages } = imageSlice.actions







// const reducer = createReducer(initialAccount, (builder) => {
//     builder
//         .addCase(GET_ACCOUNTS, (s, a): PayloadAction<IAccount> => {
//             return {...s,
//                 accounts: a.payload
//             }
//         })
// })

//export default accountSlice.reducer