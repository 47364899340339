import { createAction } from "@reduxjs/toolkit";
import { IPushMessage, pushSystemMessageActionTypes } from "./types";

export const SET_SYSTEM_PUSHMESSAGE = pushSystemMessageActionTypes.SetMessage;
export const SET_SYSTEM_PUSHMESSAGES = pushSystemMessageActionTypes.SetMessages;
export const ADD_SYSTEM_PUSHMESSAGE = pushSystemMessageActionTypes.AddMessage;
export const ADD_SYSTEM_PUSHMESSAGE_GROUP = pushSystemMessageActionTypes.AddMessageGroup;
export const GET_SYSTEM_PUSHMESSAGE = pushSystemMessageActionTypes.GetMessage;
export const GET_SYSTEM_PUSHMESSAGES = pushSystemMessageActionTypes.GetMessages;
export const DEL_SYSTEM_PUSHMESSAGES = pushSystemMessageActionTypes.DelMessages;

export const setPushMessageCreator = createAction<IPushMessage>(SET_SYSTEM_PUSHMESSAGE);
export const setPushMessagesCreator = createAction<IPushMessage[]>(SET_SYSTEM_PUSHMESSAGES);
export const addPushMessageCreator = createAction<IPushMessage>(ADD_SYSTEM_PUSHMESSAGE);
export const addPushMessageGroupCreator = createAction<IPushMessage[]>(ADD_SYSTEM_PUSHMESSAGE_GROUP);
export const getPushMessageCreator = createAction<number>(GET_SYSTEM_PUSHMESSAGE);
export const getPushMessagesCreator = createAction(GET_SYSTEM_PUSHMESSAGES);

export const delPushMessagesCreator = createAction<number>(DEL_SYSTEM_PUSHMESSAGES);