/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {NavLink} from 'react-router-dom'
import {KTSVG} from '../../../_metronic/helpers'
import {createLink} from '../../utils/dataUtils'
import {currentScreenSize} from '../../utils/systemUtils'

import Loader1 from '../vision/loaders/Loader1'
import {Col, Row} from 'react-bootstrap'
import {DEFAULT_BUTTONS_PAGINATION, IPaginationLink} from '../../modules/goods/types'
import {UrlPagination} from './UrlPagination'
import Filter from './UrlFilter'
import {DEFAULT_LIMIT, DEFAULT_PAGE} from '../../modules/goods/GoodsList'
import {ISelectExtendedOption} from '../../modules/goods/list/FilterSelect'

const arrowUp = '/media/icons/duotune/arrows/arr003.svg'
const arrowDown = '/media/icons/duotune/arrows/arr004.svg'

export interface IUrlParameters {
  pageNumber: number
  filter: string
  itemsPerPage: number
  ordering: string
  price_type?: string
  placeholder?: string
  title?: string
  url: string
  additionalParameters?: ISelectExtendedOption[] | null
  useURLEncoding?: boolean // to code use encodeURIComponent, after read from URL use decodeURIComponent(param_value)

  onSearchButtonClick?: (value: any) => void
}

// defaultCancelValue - value, which return "" on url instead value
export const getAdditionalParametersUrl = (
  params: IUrlParameters,
  defaultCancelValue?: any
): string => {
  return params.additionalParameters != undefined && params.additionalParameters.length > 0
    ? params.additionalParameters.reduce(
        (acc, current) =>
          acc +
          '&' +
          current.label +
          '=' +
          (defaultCancelValue != current.value ? current.value : ''),
        ''
      )
    : ``
}

export const emptyUrlParameters: IUrlParameters = {
  filter: '',
  itemsPerPage: DEFAULT_LIMIT,
  ordering: '',
  pageNumber: 0,
  url: '',
}

export interface IFilterRequestParams {
  page?: number
  limit?: number
  offset?: number
  ordering?: string
  search?: string
}

const pagingVariants = [10, 50, 100]

interface ITableColumn {
  index: number
  name: string
  widthPx?: number
  orderName?: string
  orderUrl?: IPaginationLink
  className?: string
}

interface IUrlFilteredListProps {
  columnHeaders: ITableColumn[] // columns in <tr><th></th></tr> block with properties
  count: number // number of items (to show it in bottom and to worked pagination)
  filterParams?: IUrlParameters // current filter parameters (if not set, pagination, sort and filter are not working)

  title?: string
  description?: string
  className?: string
  isLoading?: boolean

  // list of items in <tr><td></td></tr> wrapper
  children?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLTableRowElement>, HTMLTableRowElement>
}

// this list uses filter through url address. when you change page number, or set filter, or sort, url is changed and properties
// must updates in wrapper and then sets through props in this component
const UrlFilteredList: React.FC<IUrlFilteredListProps> = (props) => {
  const intl = useIntl()
  const limit = props.filterParams?.itemsPerPage ?? DEFAULT_LIMIT
  const currentPage =
    props.filterParams && props.filterParams.pageNumber > 0
      ? props.filterParams.pageNumber
      : DEFAULT_PAGE
  let maxPaginationButtons = DEFAULT_BUTTONS_PAGINATION[currentScreenSize()]
  const numberOfPages =
    Math.ceil(props.count / (limit ?? DEFAULT_LIMIT)) < maxPaginationButtons
      ? Math.ceil(props.count / (limit ?? DEFAULT_LIMIT))
      : maxPaginationButtons

  const columnHeaders = props.columnHeaders?.sort((a, b) => a.index - b.index)

  return (
    <>
      <div className={`card ${props.className}`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>
              {props.title ?? intl.formatMessage({id: 'COMPONENTS.TABLES.GOODS.LIST.TITLE'})}
            </span>
            <span className='text-muted mt-1 fw-bold fs-7'>
              {props.description ??
                intl.formatMessage({id: 'COMPONENTS.TABLES.GOODS.LIST.DESCRIPTION'})}
            </span>
          </h3>
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className='card-body py-3'>
          {props.filterParams ? (
            <Row className='d-flex flex-row'>
              <Col>
                <Filter urlParams={{...props.filterParams}} />
              </Col>
            </Row>
          ) : null}

          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            {/* table-row-dashed table-row-gray-300 table-striped */}
            <table className='table  align-middle gs-0 gy-2 table-striped'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bolder text-muted'>
                  {columnHeaders?.map((col) => {
                    return col.orderName && col.orderUrl && props.filterParams ? (
                      <th
                        className={`w-${col.widthPx ?? 25}px ${col.className ? col.className : ''}`}
                      >
                        <NavLink to={col.orderUrl.url}>{col.name}</NavLink>
                        <OrderingArrow
                          currentOrderName={
                            `${orderingSign(props.filterParams.ordering)}${col.orderName}` ?? ''
                          }
                          orderName={col.orderName}
                        />
                      </th>
                    ) : (
                      <th className={`w-${col.widthPx ?? 25}px`}>{col.name}</th>
                    )
                  })}
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>{props.isLoading ? <Loader1 /> : props.children}</tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
        {props.filterParams ? (
          <UrlPagination
            isLoading={props.isLoading ?? false}
            itemsNumber={props.count}
            itemsPerPageVariants={pagingVariants}
            makeLink={createLink.paginationLink}
            pageButtonNumber={numberOfPages}
            filterParams={{
              pageNumber: currentPage,
              itemsPerPage: props.filterParams.itemsPerPage ?? DEFAULT_LIMIT,
              filter: props.filterParams.filter,
              ordering: props.filterParams.ordering,
              url: props.filterParams.url,
            }}
          />
        ) : null}
      </div>
    </>
  )
}

const orderingSign = (currentOrder: string) => {
  return currentOrder.includes('-') ? '' : '-'
}

const OrderingArrow = (props: IOrderingArrowProps) => {
  if (props.currentOrderName.includes(props.orderName))
    return (
      <span>
        {orderingSign(props.currentOrderName) ? (
          <KTSVG path={arrowDown} className='svg-icon-2 svg-icon-primary' />
        ) : (
          <KTSVG path={arrowUp} className='svg-icon-2 svg-icon-primary' />
        )}
      </span>
    )
  else return null
}

interface IOrderingArrowProps {
  currentOrderName: string
  orderName: string
}

export default UrlFilteredList
