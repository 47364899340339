import {FC} from 'react'
import {useDispatch} from 'react-redux'
import {actions} from '../../../../store/auth/AuthRedux'
import useCountdown from '../../../../hooks/useCountdown'
import {Button} from 'react-bootstrap'

interface ICountdownTimerProps {
  targetDate: number
  userId: number
  clearOTP: () => void
  setTargetDate: (targetDate: number) => void
}

const TWO_MINUTES_IN_MS = 2 * 60 * 1000

const CountdownTimer: FC<ICountdownTimerProps> = ({
  targetDate,
  setTargetDate,
  userId,
  clearOTP,
}) => {
  const [, , minutes, seconds] = useCountdown(targetDate)

  const dispatch = useDispatch()

  const onClick = (userId: number) => () => {
    setTargetDate(TWO_MINUTES_IN_MS)
    clearOTP()
    dispatch(actions.setConfirmationStatus(''))
    dispatch(actions.getCall(userId))
  }

  return (
    <>
      {minutes <= 0 && seconds <= 0 ? (
        <Button className='w-100 mb-6' onClick={onClick(userId)}>
          Запросить повторный звонок
        </Button>
      ) : (
        <p className='mb-6 text-center fs-6'>
          Запросить повторный звонок через {minutes < 10 ? `0${minutes}` : minutes}:
          {seconds < 10 ? `0${seconds}` : seconds}
        </p>
      )}
    </>
  )
}

export default CountdownTimer
