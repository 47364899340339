import { NavLink } from "react-router-dom"
import { createLink } from "../../utils/dataUtils"
import { IUrlParameters } from "../lists/UrlFilteredList"
import OrderingArrow, { orderingSign } from "./OrderingArrow"


interface IOrderingHeaderProps {
    filterParams: IUrlParameters
    orderFieldName: string
}

// control to render ready link to sorting through url parameters changing (we can use it in table headers for example)
const OrderingHeader = (props: IOrderingHeaderProps) => {
    const orderObject = (title: string, sortParameter: string) => createLink.paginationLink({
        ...props.filterParams,
        ordering: orderingSign(props.filterParams.ordering) + sortParameter
      })

    return <>
        <NavLink 
            to={orderObject(props.filterParams.title ?? "", props.orderFieldName).url}
            className={`text-gray-500`}
        >
            {props.filterParams.title ?? ""}
        </NavLink>
        <OrderingArrow currentOrderName={props.filterParams.ordering ?? ""} orderName={props.orderFieldName} />
    </>
    }



export default OrderingHeader