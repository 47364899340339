import {createSlice} from '@reduxjs/toolkit'
import {initialPvzCdekState as initialState} from './types'

const cdekPvzSlice = createSlice({
  name: 'cdekPvzSlice',
  initialState,
  reducers: {
    setCdekPvz(state, action) {
      state.items = [...action.payload]
    },
  },
})

export const cdekPvzReducer = cdekPvzSlice.reducer
export const {setCdekPvz} = cdekPvzSlice.actions
