import {createSlice} from '@reduxjs/toolkit'
import {IUserModel} from '../../modules/auth/models/UserModel'
import {initialState} from './types'

const userSlice = createSlice({
  name: 'userSlice',
  initialState,
  reducers: {
    setUsers(state, action) {
      state.items = [...action.payload]
    },
    addUser(state, action) {
      state.items = [action.payload, ...state.items]
    },
    setUser(state, action) {
      try {
        const usr: IUserModel = action.payload
        state.items = [
          ...state.items.map((cc: IUserModel) => {
            if (cc.id == usr.id) {
              cc = usr
            }
            return cc
          }),
        ]

        state.isUpdatingSuccess = true
      } catch (error) {
        console.log(error)
      }
    },
    unlinkUser(state, action) {
      const userEmail: string = action.payload
      state.items = [...state.items.filter((u) => u.email != userEmail)]
    },

    setIsLoading(state, action) {
      state.isLoading = action.payload
    },
    setCount(state, action) {
      state.count = action.payload
    },
  },
})

export const userReducer = userSlice.reducer
export const {setUsers, setIsLoading, setCount, setUser, unlinkUser, addUser} = userSlice.actions
