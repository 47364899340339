import {select, takeEvery, takeLatest} from 'redux-saga/effects'
import {intl} from '../../modules/goods/item/validation'
import {sagaUtils} from '../../utils/dataUtils'
import {
  setAnswer,
  setFeedbacks,
  setFeedbackSendResult,
  setFeedbacksLoading,
  setGeneratedFeedback,
  setIsGenerated,
  setIsSelectLoading,
  setIsSendLoading,
  setLoading,
  setSelectedFeedbacks,
} from './reducer'
import {
  ASYNC_GENERATE_ANSWER,
  ASYNC_GENERATE_FEEDBACK,
  ASYNC_SEND_FEEDBACK,
  FETCH_FEEDBACKS,
  FETCH_FETCH_FEEDBACKS_BY_ID,
} from './actions'
import {
  fetchFeedbackById,
  fetchFeedbacks,
  generateAnswer,
  generateFeedback,
  postFeedback,
} from './api'
import {StorageType} from '../../../setup'
import {IFeedbackState} from './types'

export function* feedbackSaga() {
  yield takeEvery(FETCH_FEEDBACKS, workerFetchFeedbacks)
  yield takeLatest(FETCH_FETCH_FEEDBACKS_BY_ID, workerFetchFeedbackById)
  yield takeEvery(ASYNC_GENERATE_ANSWER, workerGenerateAnswer)
  yield takeEvery(ASYNC_GENERATE_FEEDBACK, workerGenerateFeedback)
  yield takeLatest(ASYNC_SEND_FEEDBACK, workerSendFeedback)
}

export function* workerFetchFeedbacks() {
  const {filters}: IFeedbackState = yield select((storage: StorageType) => storage.feedback)
  yield sagaUtils.workerWrapper(
    setFeedbacksLoading,
    fetchFeedbacks,
    filters,
    setFeedbacks,
    null,
    intl.formatMessage({id: 'SAGAS.FEEDBACK.GET'}),
    'GET',
    'data',
    false,
    '',
    undefined,
    undefined
  )
}

export function* workerFetchFeedbackById(action: any): any {
  yield sagaUtils.workerWrapper(
    setIsSelectLoading,
    fetchFeedbackById,
    action.payload,
    setSelectedFeedbacks,
    null,
    intl.formatMessage({id: 'SAGAS.FEEDBACK.GET'}),
    'GET',
    'data',
    false
  )
}

export function* workerGenerateAnswer(action: any) {
  yield sagaUtils.workerWrapper(
    setLoading,
    generateAnswer,
    action.payload,
    setAnswer,
    null,
    intl.formatMessage({id: 'SAGAS.ANSWER.GENERATE'}),
    'CREATE',
    'data.result',
    false
  )
}

export function* workerGenerateFeedback(action: any) {
  yield sagaUtils.workerWrapper(
    setIsGenerated,
    generateFeedback,
    action.payload,
    setGeneratedFeedback,
    null,
    intl.formatMessage({id: 'SAGAS.ANSWER.GENERATE'}),
    'CREATE',
    'data.result',
    false
  )
}

export function* workerSendFeedback(action: any) {
  yield sagaUtils.workerWrapper(
    setIsSendLoading,
    postFeedback,
    action.payload,
    setFeedbackSendResult,
    null,
    intl.formatMessage({id: 'SAGAS.ANSWER.GENERATE'}),
    'CREATE',
    'data',
    false
  )
}
