import { createSlice } from "@reduxjs/toolkit"
import { initialDeliveryServices as initialState, } from "./types"


const deliveryServiceSlice = createSlice({
    name: "deliveryServiceSlice",
    initialState,
    reducers: {
        setDeliveryServices(state, action){
            state.items = [...action.payload]
        }
    }
})


export const deliveryServiceReducer = deliveryServiceSlice.reducer
export const { setDeliveryServices } = deliveryServiceSlice.actions







// const reducer = createReducer(initialAccount, (builder) => {
//     builder
//         .addCase(GET_ACCOUNTS, (s, a): PayloadAction<IAccount> => {
//             return {...s,
//                 accounts: a.payload
//             }
//         })
// })

//export default accountSlice.reducer