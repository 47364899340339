import {AbilityBuilder, createMongoAbility, MongoQuery, PureAbility} from '@casl/ability'
import {IAccessPath} from '../app/modules/auth/models/UserModel'

export type Action = 'create' | 'watch' | 'update' | 'delete'
export type Subject =
  | 'acceptance-rates'
  | 'user-feedback'
  | 'promotion'
  | 'developing-sections'
  | 'supply'

export type AppAbility = PureAbility<[Action, Subject], MongoQuery>

const {can, cannot, build} = new AbilityBuilder<AppAbility>(createMongoAbility)

export const updateAbility = (accessPaths: IAccessPath[], curAbility: AppAbility) => {
  const {can, rules} = new AbilityBuilder<AppAbility>(createMongoAbility)

  accessPaths.forEach((ap) => {
    const rule = ap.code.split('_')

    can(rule[0] as Action, rule[1] as Subject)
  })

  curAbility.update(rules)
}

export default build()
