import { useEffect, useState } from 'react';
import {  Col, Row, Toast, ToastContainer } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { StorageType } from '../../../../../../setup';
import { delPushMessagesCreator } from '../../../../../store/system/actions';
import { IPushMessage } from '../../../../../store/system/types';
import PushMessageNotify from './PushMessageNotify';

interface PushMessageControllerProps {

}



const PushMessageController = (props: PushMessageControllerProps) => {

    const [currentMessage, setCurrentMessage] = useState<IPushMessage | null>(null)
    const messagesQueue: IPushMessage[] = useSelector((state: StorageType) => state.system.pushMessages) ?? []
    const dispatch = useDispatch();

    const closeNotify = (mQueue: IPushMessage | null) => {
        console.log('closeNotify',mQueue)
        dispatch(delPushMessagesCreator((mQueue != null) && (mQueue?.id != undefined) && (mQueue.id >= 0) ? mQueue.id : -1))
    }
    
    useEffect(() => {
        if (messagesQueue.length){
            setCurrentMessage(messagesQueue[0])
            console.log('messagesQueue',messagesQueue)
            if (messagesQueue?.length && messagesQueue[0] && messagesQueue[0].status == 'success'){
                setTimeout(() => {
                    console.log('closeNotify',currentMessage)
                    closeNotify(currentMessage)
                // }, (messagesQueue[0]?.showSeconds ?? 3) * 1000)
                }, 1500)
            }
        }
        else
        {
            setCurrentMessage(null)
        }
    }, [messagesQueue])

        return <PushMessageNotify 
                show={currentMessage ? true : false}
                numberOf={messagesQueue.length}
                close={() => closeNotify(currentMessage)}
                message={currentMessage}
                key="mnotify"
                />
                




}

export default PushMessageController