import { FormCheckInputProps } from "react-bootstrap/esm/FormCheckInput"
import css from "./CheckBoxCustom.module.css"

const CheckBoxCustom = (props: FormCheckInputProps) => {
    return <input
        {...props}
        className={css.checkboxCustom}
        type="checkbox"
    />
}



export default CheckBoxCustom