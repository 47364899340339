import axios from 'axios'

const API_URL = `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENDPOINT_PREFIX}`
export const USERS_URL = `${API_URL}v2/users/`

// #region
//https://api-dev.shop-delivery.ru/int_api/v2/users/1/get_link_tg_bot/

export const connectUserToTelegram = (id: number) => {
  return axios.get(`${USERS_URL}${id}/get_link_tg_bot/`)
}

// #endregion
