import {useDispatch} from 'react-redux'
import {addCustomerCompanyAction} from '../../../../../store/mpWizard/actions'
import {updateData} from '../../../../../store'

import LinkingToLegalEntity from '../../LinkingToLegalEntity'
import {TCompany} from '../../../../../store/mpWizard/types'

const OzonStep3 = () => {
  const dispatch = useDispatch()

  const onSelectCustomerCompany = (cc: TCompany | null) => {
    dispatch(updateData({key: 'step2', field: 'customerCompanyId', value: cc?.id}))
  }

  const onAddCustomerCompany = (cc: TCompany) => {
    dispatch(addCustomerCompanyAction({company: cc}))
  }

  return (
    <div>
      <p className='mb-8 fs-5 text-gray-700'>
        Для правильного функционирования сервиса нам необходимо привязать личный кабинет к
        юридическому лицу, которое им владеет. Вы можете выбрать его из имеющихся или создать новое.
      </p>

      <LinkingToLegalEntity
        onSelectCustomerCompany={onSelectCustomerCompany}
        addCustomerCompanyHandler={onAddCustomerCompany}
      />
    </div>
  )
}

export default OzonStep3
