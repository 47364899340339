import { createAction } from "@reduxjs/toolkit";
import { ICustomer, ICustomerAdd } from "../../modules/auth/models/UserModel";
import {  customerActionTypes as actionTypes } from "./types"

export const ASYNC_GET_CUSTOMERS = actionTypes.GetCustomers;
export const ASYNC_UPDATE_CUSTOMER = actionTypes.UpdateCustomer;
export const ASYNC_ADD_CUSTOMER = actionTypes.AddCustomer;


export const getAsyncCustomersCreator = createAction(ASYNC_GET_CUSTOMERS);
export const updateAsyncCustomerCreator = createAction<ICustomer>(ASYNC_UPDATE_CUSTOMER);
export const addAsyncCustomerCreator = createAction<ICustomerAdd>(ASYNC_ADD_CUSTOMER);


