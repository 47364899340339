import {IUserModel} from '../../modules/auth/models/UserModel'

export const initialState: IUserState = {
  count: 0,
  selected: null,
  items: [],
  isLoading: false,
  isUpdatingSuccess: false,
}

export interface IUserModelResponse {
  success: boolean
  result?: IUserModel
}

export interface IUserState {
  count: number
  selected: null
  items: IUserModel[]
  isLoading: boolean
  isUpdatingSuccess: boolean
}

export const userActionTypes = {
  GetUsers: '[GetUsers] Action',
  UpdateUserProfile: '[UpdateUserProfile] Action',
  LinkUserProfile: '[LinkUserProfile] Action',
  UnlinkUserProfile: '[UnlinkUserProfile] Action',
}
