import { createAction } from "@reduxjs/toolkit";
import { accountActionTypes, IAccount } from "./types"

export const SET_ACCOUNTS = accountActionTypes.SetAccounts;
export const ASYNC_GET_ACCOUNTS = accountActionTypes.GetAccounts;
export const ASYNC_ADD_ACCOUNT = accountActionTypes.AddAccount;
export const ASYNC_UPDATE_ACCOUNT = accountActionTypes.UpdateAccount;
export const ASYNC_DELETE_ACCOUNT = accountActionTypes.DeleteAccount;
export const SET_IS_LOADING_ACCOUNTS = accountActionTypes.SetIsLoadingAccounts;
export const ADD_ACCOUNT = accountActionTypes.AddAccountNoApi;
export const SET_ACCOUNT = accountActionTypes.SetAccountNoApi;

export const setAccountsCreator = createAction<string>(SET_ACCOUNTS);
export const setIsLoadingAccountsCreator = createAction<boolean>(SET_IS_LOADING_ACCOUNTS);
export const getAsyncAccountsCreator = createAction(ASYNC_GET_ACCOUNTS);
export const deleteAsyncAccountCreator = createAction<number>(ASYNC_DELETE_ACCOUNT);

export const addAsyncAccountCreator = createAction<IAccount>(ASYNC_ADD_ACCOUNT);
export const addAccountCreator = createAction<IAccount>(ADD_ACCOUNT);
export const setAccountCreator = createAction<IAccount>(SET_ACCOUNT);
export const updateAsyncAccountCreator = createAction<IAccount>(ASYNC_UPDATE_ACCOUNT);