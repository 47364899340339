import {takeLatest, takeEvery, select} from 'redux-saga/effects'
import {setIsLoading, setTransactionData} from './reducer'
import {FETCH_TRANSACTIONS} from './actions'
import {sagaUtils} from '../../utils/dataUtils'
import {intl} from '../../modules/goods/item/validation'
import {StorageType} from '../../../setup'
import {ItransactionsStorage} from './types'
import {fetchTransactions} from './api'

export function* transactionsSaga() {
  yield takeLatest(FETCH_TRANSACTIONS, workerFetchTransactions)
}

export function* workerFetchTransactions() {
  const {filters}: ItransactionsStorage = yield select(
    (storage: StorageType) => storage.transactions
  )

  yield sagaUtils.workerWrapper(
    setIsLoading,
    fetchTransactions,
    filters,
    setTransactionData,
    null,
    intl.formatMessage({id: 'SAGAS.GOODS.GROUPS.GET'}),
    'GET',
    'data',
    false,
    '',
    undefined,
    undefined
  )
}
