import { call, put, select, takeEvery } from "redux-saga/effects"
import { addPushMessageCreator } from '../system/actions';
import { StorageType } from '../../../setup';
import { IMpCardsStorage, IGoodMpCardUniversal, IMpCardItemPublishParams } from './types';
import {
    ASYNC_GET_MPCARD, ASYNC_GET_MPCARDS, ASYNC_GET_UNIVERSAL_MPCARD, SET_ACCOUNT_MPCARD_PARAMETER,
    SET_MARKETPLACE_MPCARD_PARAMETER, SET_MPCARD_PARAMETERS, SET_MPCARD_PRICE, RESET_UNIVERSAL_SELECTED_MPCARD,
    RESET_MPCARD_PARAMETERS, ASYNC_SAVE_UNIVERSAL_MPCARD, SET_MPCARD_PRICE_FROM_GOODITEM, ASYNC_NEW_UNIVERSAL_MPCARD,
    ASYNC_GET_MPCARD_TEMPLATES, ASYNC_CREATE_MPCARD_TEMPLATE, ASYNC_DELETE_MPCARD_TEMPLATE, ASYNC_UPDATE_MPCARD_TEMPLATE,
    ASYNC_COPY_MPCARD_TEMPLATE, SET_MPCARD_TEMPLATES_ISLOADING, ASYNC_CREATE_UNIVERSAL_MPCARD, SET_UNIVERSAL_SELECTED_MPCARD, ASYNC_PUBLISH_MPCARD, ASYNC_PUBLISH_MPCARD2, SET_SELECTED_UNIVERSAL_MPCARD, SET_MPCARD_IS_SAVING,
} from "./actions";
import {
    getOfferMpCardFromServer, getOfferUniversalMpCardFromServer, setOfferUniversalMpCardToServer, getMpCardsFromServer2, getUniversalMpCardUniversalAttributeValues,
    getUniversalMpCardTemplatesFromServer,
    createUniversalMpCardTemplateInServer,
    deleteUniversalMpCardTemplateInServer,
    updateUniversalMpCardTemplateInServer,
    saveUniversalMpCardToServer,
    publishMpCardOnMarketplace,
} from "./api";
import {
    addMpCardTemplate, deleteMpCardTemplate, resetRequestParams, setAccountParameter, setGoodTradeOfferMpCardIsPublished2, setGoodTradeOfferMpCardPublish2, setIsLoading, setIsUniversalMpCardLoading, setLastOperationIsSuccess, setMarketplaceParam,
    setMpCardPrices,
    setMpCardTemplates,
    setMpCards, setMpCardsCount, setMpCardsIsLoading, setRequestParams, setSelectedMpCard, setSelectedUniversalMpCard, setSelectedUniversalMpCardNewSkuData, setTemplatesIsLoading, setisUniversalMpCardSaving, updateMpCardTemplate,
} from "./reducers";
import { getGoodTradeOffers, saveTradeOfferPrice } from "../goods/api";
import { imgUtils, sagaUtils } from "../../utils/dataUtils";
import { intl } from "../../modules/goods/item/validation";
import { setGoodTradeOfferMpCardIsPublished, setGoodTradeOfferMpCardPublish, setGoodTradeOffers } from "../goods";
import { getGoodTradeOfferCreator } from "../goods/actions";





export function* mpcardSaga() {
    yield takeEvery(ASYNC_GET_MPCARDS, workerGetMpCards)
    yield takeEvery(ASYNC_GET_MPCARD, workerGetMpCard)
    yield takeEvery(ASYNC_GET_MPCARD_TEMPLATES, workerGetMpCardTemplates)
    yield takeEvery(SET_MPCARD_TEMPLATES_ISLOADING, workerSetMpCardTemplatesIsLoading)
    yield takeEvery(ASYNC_CREATE_MPCARD_TEMPLATE, workerCreateMpCardTemplate)
    yield takeEvery(ASYNC_UPDATE_MPCARD_TEMPLATE, workerUpdateMpCardTemplate)
    yield takeEvery(ASYNC_COPY_MPCARD_TEMPLATE, workerCopyMpCardTemplate)
    yield takeEvery(ASYNC_DELETE_MPCARD_TEMPLATE, workerDeleteMpCardTemplate)
    yield takeEvery(SET_MPCARD_PARAMETERS, workerSetMpCardParameters)
    yield takeEvery(RESET_MPCARD_PARAMETERS, workerResetMpCardParameters)
    yield takeEvery(SET_MPCARD_IS_SAVING, workerSaveMpCardIsSaving)
    yield takeEvery(SET_MARKETPLACE_MPCARD_PARAMETER, workerSetMpCardMarketplaceParameter)
    yield takeEvery(SET_ACCOUNT_MPCARD_PARAMETER, workerSetMpCardAccountParameter)
    yield takeEvery(SET_MPCARD_PRICE, workerSaveMpCardPrice)
    yield takeEvery(SET_MPCARD_PRICE_FROM_GOODITEM, workerSaveMpCardPriceFromGoodItem)
    yield takeEvery(ASYNC_GET_UNIVERSAL_MPCARD, workerGetUniversalMpCard)
    yield takeEvery(SET_UNIVERSAL_SELECTED_MPCARD, workerSetMpCardSelectedUniversal)
    yield takeEvery(SET_SELECTED_UNIVERSAL_MPCARD, workerSelectedUniversalSetMpCard)
    yield takeEvery(RESET_UNIVERSAL_SELECTED_MPCARD, workerResetMpCardSelectedUniversal)
    yield takeEvery(ASYNC_SAVE_UNIVERSAL_MPCARD, workerSaveUniversalMpCard)
    yield takeEvery(ASYNC_CREATE_UNIVERSAL_MPCARD, workerCreateUniversalMpCard)
    yield takeEvery(ASYNC_NEW_UNIVERSAL_MPCARD, workerNewUniversalMpCard)
    yield takeEvery(ASYNC_PUBLISH_MPCARD, workerPublishMpCard)
    yield takeEvery(ASYNC_PUBLISH_MPCARD2, workerPublishMpCard2)
}

// gets all cards an set it to storage
export function* workerGetMpCards(): any {
    yield put(setMpCardsIsLoading(true))
    const params: IMpCardsStorage = yield select((storage: StorageType) => storage.mpcards)
    try {
        const mpcardItemsPromise = yield call(() => getMpCardsFromServer2(params.filter))
        mpcardItemsPromise?.data?.results && (yield put(setMpCards(mpcardItemsPromise.data.results)))
        mpcardItemsPromise?.data?.count && (yield put(setMpCardsCount(mpcardItemsPromise.data.count)))

    }
    catch (error) {
        yield put(addPushMessageCreator(
            {
                name: "Не удалось загрузить карточки товаров",
                status: "error",
                details: ["Сервер недоступен"],
                message: "Ошибка",
            }))
    }
    finally {
        yield put(setMpCardsIsLoading(false))

    }

}

// gets card and set it to storage
export function* workerGetMpCard(action: any): any {
    yield sagaUtils.workerWrapper(setMpCardsIsLoading,
        getOfferMpCardFromServer, { id: action.payload },
        setSelectedMpCard, null,
        intl.formatMessage({ id: "SAGAS.MPCARDS.GET" }), "GET", "data", false, "")
}

// publish mpcard on server (marketplace)
export function* workerPublishMpCard(action: any): any {
    const params: IMpCardItemPublishParams = action.payload
    //setGoodTradeOfferMpCardPublish
    yield sagaUtils.workerWrapper(null,
        publishMpCardOnMarketplace, action.payload,
        null, null,
        intl.formatMessage({ id: params.action == "publish" ? "SAGAS.MPCARDS.PUBLISH" : "SAGAS.MPCARDS.UNPUBLISH" }), params.action == "publish" ? "PUBLISH" : "UNPUBLISH", "data.result", true, "",
        { action: setGoodTradeOfferMpCardPublish, params: { id: params.id, isPublishing: true } },
        { action: setGoodTradeOfferMpCardIsPublished, params: { id: params.id, is_published: params.action == 'publish' ? true : false } },
        { action: setGoodTradeOfferMpCardPublish, params: { id: params.id, isPublishing: false } },
        { action: setGoodTradeOfferMpCardPublish, params: { id: params.id, isPublishing: false } },
        { action: setGoodTradeOfferMpCardPublish, params: { id: params.id, isPublishing: false } }
    )
}

// publish mpcard on server (marketplace)
export function* workerPublishMpCard2(action: any): any {
    const params: IMpCardItemPublishParams = action.payload
    //setGoodTradeOfferMpCardPublish
    yield sagaUtils.workerWrapper(null,
        publishMpCardOnMarketplace, action.payload,
        null, null,
        intl.formatMessage({ id: params.action == "publish" ? "SAGAS.MPCARDS.PUBLISH" : "SAGAS.MPCARDS.UNPUBLISH" }), params.action == "publish" ? "PUBLISH" : "UNPUBLISH", "data.result", true, "",
        { action: setGoodTradeOfferMpCardPublish2, params: { id: params.id, isPublishing: true } },
        { action: setGoodTradeOfferMpCardIsPublished2, params: { id: params.id, is_published: params.action == 'publish' ? true : false } },
        { action: setGoodTradeOfferMpCardPublish2, params: { id: params.id, isPublishing: false } },
        { action: setGoodTradeOfferMpCardPublish2, params: { id: params.id, isPublishing: false } },
        { action: setGoodTradeOfferMpCardPublish2, params: { id: params.id, isPublishing: false } }
    )
}

// sets mpcard templates isloading flag
export function* workerSetMpCardTemplatesIsLoading(action: any): any {
    yield put(setTemplatesIsLoading(action.payload))
}

// gets mpcard templates and set it to storage
export function* workerGetMpCardTemplates(action: any): any {
    yield sagaUtils.workerWrapper(setTemplatesIsLoading,
        getUniversalMpCardTemplatesFromServer, action.payload,
        setMpCardTemplates, null,
        intl.formatMessage({ id: "SAGAS.MPCARDS.GETTEMPLATES" }), "GET", "data.results", false)
}

// create mpcard template on server
export function* workerCreateMpCardTemplate(action: any): any {
    yield sagaUtils.workerWrapper(setTemplatesIsLoading,
        createUniversalMpCardTemplateInServer, action.payload,
        addMpCardTemplate, null,
        intl.formatMessage({ id: "SAGAS.MPCARDS.CREATETEMPLATE" }), "CREATE", "data.result", true,)
}

// create mpcard template on server
export function* workerCopyMpCardTemplate(action: any): any {
    yield sagaUtils.workerWrapper(setTemplatesIsLoading,
        createUniversalMpCardTemplateInServer, action.payload,
        addMpCardTemplate, null,
        intl.formatMessage({ id: "SAGAS.MPCARDS.CREATETEMPLATE" }), "CREATE", "data.result", true,)
}

// update mpcard template on server
export function* workerUpdateMpCardTemplate(action: any): any {
    yield sagaUtils.workerWrapper(setTemplatesIsLoading,
        updateUniversalMpCardTemplateInServer, action.payload,
        null, null,
        intl.formatMessage({ id: "SAGAS.MPCARDS.UPDATETEMPLATE" }), "SAVE", "direct", true, "", undefined,
        { action: updateMpCardTemplate, params: action.payload }, undefined, undefined, undefined
    )
}

// delete mpcard template on server
export function* workerDeleteMpCardTemplate(action: any): any {
    yield sagaUtils.workerWrapper(setTemplatesIsLoading,
        deleteUniversalMpCardTemplateInServer, action.payload,
        null, null,
        intl.formatMessage({ id: "SAGAS.MPCARDS.DELETETEMPLATE" }), "DELETE", "direct", true, "", undefined,
        { action: deleteMpCardTemplate, params: action.payload }, undefined, undefined, undefined
    )
}

// gets universal card and set it to storage field 'selectedUniversal'
export function* workerGetUniversalMpCard(action: any): any {
    console.log('useeffect skuId workerGetUniversalMpCard', action.payload)

    // get saved sku_id
    // const sku_id = yield select((store: StorageType) => store.mpcards.selectedUniversal_sku_id)

    yield sagaUtils.workerWrapper(setIsUniversalMpCardLoading,
        getOfferUniversalMpCardFromServer, action.payload,
        setSelectedUniversalMpCard, null,
        intl.formatMessage({ id: "SAGAS.MPCARDS.GET" }), "GET", "data", false, "",)
    // undefined, {action: setSelectedUniversalMpCardNewSkuId, params: sku_id}, undefined, undefined, undefined)
}

// creates universal card and set it to storage field 'selectedUniversal'
export function* workerCreateUniversalMpCard(action: any): any {
    yield sagaUtils.workerWrapper(setisUniversalMpCardSaving,
    // yield sagaUtils.workerWrapper(setIsUniversalMpCardLoading,
        saveUniversalMpCardToServer, action.payload,
        setSelectedUniversalMpCard, null,
        intl.formatMessage({ id: "SAGAS.MPCARDS.CREATE" }), "CREATE", "data", false, "",
        undefined, [{ action: getGoodTradeOfferCreator, params: action.payload.good_id }, {action: setLastOperationIsSuccess, params: true}], {action: setLastOperationIsSuccess, params: false}, {action: setLastOperationIsSuccess, params: false})
}

// gets empty universal card and set it to storage field 'selectedUniversal'
export function* workerNewUniversalMpCard(action: any): any {
    yield sagaUtils.workerWrapper(setIsUniversalMpCardLoading,
        getUniversalMpCardUniversalAttributeValues, action.payload,
        setSelectedUniversalMpCard, null,
        intl.formatMessage({ id: "SAGAS.MPCARDS.GET" }), "GET", "data", false, "")
}

// save universal card to server
export function* workerSaveUniversalMpCard(action: any): any {

    yield sagaUtils.workerWrapper(setisUniversalMpCardSaving,
    // yield sagaUtils.workerWrapper(setIsUniversalMpCardLoading,
        setOfferUniversalMpCardToServer, action.payload,
        setSelectedUniversalMpCard, null,
        intl.formatMessage({ id: "SAGAS.MPCARDS.UPDATE" }), "SAVE", "data", true, "",
        undefined, {action: setLastOperationIsSuccess, params: true}, {action: setLastOperationIsSuccess, params: false}, {action: setLastOperationIsSuccess, params: false}, undefined, (postedCard: any) => {
            return {
                ...postedCard,
                variants: postedCard.variants
                    ? postedCard.variants.map((v: any) => {
                        v.images = v.images ? imgUtils.setUncachableImages(v.images ?? []) : []
                        return v;
                    })
                    : []
            }
        }
    )
}

// resets selected universal card in storage
export function* workerSetMpCardSelectedUniversal(action: any): any {
    yield put(setSelectedUniversalMpCardNewSkuData(action.payload
        ? {
            sku_id: (action.payload as IGoodMpCardUniversal).new_sku_id,
            good_id: (action.payload as IGoodMpCardUniversal).new_good_id,
            marketplace_name: (action.payload as IGoodMpCardUniversal).marketplace_name,
            account_name: (action.payload as IGoodMpCardUniversal).account_name,
        }
        : undefined))
    yield put(setSelectedUniversalMpCard(action.payload))
}

// sets selected universal card in storage
export function* workerSelectedUniversalSetMpCard(action: any): any {
    yield put(setSelectedUniversalMpCard(action.payload))
}

// resets selected universal card in storage
export function* workerResetMpCardSelectedUniversal(): any {
    yield put(setSelectedUniversalMpCardNewSkuData(undefined))
    yield put(setSelectedUniversalMpCard(null))
}

// set marketplace request parameter
export function* workerSetMpCardMarketplaceParameter(action: any): any {

    yield put(setMarketplaceParam(action.payload))

}

// set account request parameter
export function* workerSetMpCardAccountParameter(action: any): any {
    console.log('saga account change', action.payload)
    yield put(setAccountParameter(action.payload))
    const params: IMpCardsStorage = yield select((storage: StorageType) => storage.mpcards)
    console.log('saga params after account', params)

}

// set all request parameters
export function* workerSetMpCardParameters(action: any): any {
    yield put(setRequestParams(action.payload))

}

// reset all request parameters
export function* workerResetMpCardParameters(action: any): any {
    yield put(resetRequestParams())

}

export function* workerSaveMpCardPrice(action: any): any {
    // const priceParams: IGoodTradeOfferPrice = action.payload

    yield sagaUtils.workerWrapper(setIsLoading,
        saveTradeOfferPrice, action.payload,
        setMpCardPrices, null,
        intl.formatMessage({ id: "SAGAS.MPCARDSPRICE.SAVE" }), "SAVE", "data", true, "")

}

// set is saving mpcard
export function* workerSaveMpCardIsSaving(action: any): any {
    yield put(setisUniversalMpCardSaving(action.payload))
}


// special function to save price from tradeoffers in good item. needed because mpcards not depends from good. russian 'kostil'
export function* workerSaveMpCardPriceFromGoodItem(action: any): any {
    // const priceParams: IGoodTradeOfferPrice = action.payload

    yield sagaUtils.workerWrapper(setIsLoading,
        saveTradeOfferPrice, action.payload,
        setMpCardPrices, null,
        intl.formatMessage({ id: "SAGAS.MPCARDSPRICE.SAVE" }), "SAVE", "data", true, "")

    yield sagaUtils.workerWrapper(null,
        getGoodTradeOffers, { good_id: action.payload.goodId, search: action.payload?.filter ?? "" },
        setGoodTradeOffers, null,
        intl.formatMessage({ id: "SAGAS.GOODS.GOOD.GET" }), "GET", "data.results", false)


}