import {useIntl} from 'react-intl'
// import loading from './../../../../_metronic/assets/media/gif/load_joke1.gif'
import {loader} from '../../../../_metronic/assets/media/svg'

interface ILoaderProps {
  className?: string
  hideMessage?: boolean
  label?: string
}

const Loader1 = (props: ILoaderProps) => {
  const intl = useIntl()

  return (
    <div
      style={{width: '100%', display: 'flex', flexDirection: 'column'}}
      className={`${props.className ?? ''}`}
    >
      <div style={{width: '100px', textAlign: 'center', margin: 'auto'}}>
        <img
          style={{height: '100px'}}
          src={loader}
          alt={intl.formatMessage({id: 'STATUS.LOADING'})}
        />
        <p>
          {!props.hideMessage ? props?.label ?? intl.formatMessage({id: 'STATUS.LOADING'}) : ''}
        </p>
      </div>
    </div>
  )
}

export default Loader1
