import { createAction } from "@reduxjs/toolkit";
import { customerActionTypes as actionTypes, ICustomerCompany } from "./types"

export const SET_CUSTOMER_COMPANYS = actionTypes.SetCustomerCompanys;
export const ASYNC_GET_CUSTOMER_COMPANYS = actionTypes.GetCustomerCompanys;
export const ASYNC_ADD_CUSTOMER_COMPANY = actionTypes.AddCustomerCompany;
export const ASYNC_UPDATE_CUSTOMER_COMPANY = actionTypes.SetCustomerCompany;
export const ASYNC_DELETE_CUSTOMER_COMPANY = actionTypes.DeleteCustomerCompany;

export const setCustomerCompanysCreator = createAction<string>(SET_CUSTOMER_COMPANYS);
export const getAsyncCustomerCompanysCreator = createAction(ASYNC_GET_CUSTOMER_COMPANYS);
export const addAsyncCustomerCompanyCreator = createAction<ICustomerCompany>(ASYNC_ADD_CUSTOMER_COMPANY);
export const updateAsyncCustomerCompanyCreator = createAction<ICustomerCompany>(ASYNC_UPDATE_CUSTOMER_COMPANY);
export const deleteAsyncCustomerCompanyCreator = createAction<number>(ASYNC_DELETE_CUSTOMER_COMPANY);