/* eslint-disable jsx-a11y/anchor-is-valid */

import { FC } from "react"
import image from './../../../_metronic/assets/media/images/17.png'
import { useIntl } from "react-intl"

const DashboardNoData: FC = (props) => {
  const intl = useIntl()
  const imgStyle = {
    maxWidth: '500px',
  }

  return <div className="d-flex w-100 flex-column justify-content-center align-items-center mt-5">
    <h3 className="fs-1 mt-5 text-center">{intl.formatMessage({ id: "DASHBOARD.ERRORS.NODATA" })}</h3>
    <img className="text-center p-5" style={imgStyle} src={image} />
  </div>
}


export default DashboardNoData
