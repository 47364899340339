/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {useSelector, shallowEqual} from 'react-redux'
import {Outlet, Route, Routes, Navigate} from 'react-router-dom'
import {Login} from './components/Login'
import NumberConfirm from './components/NumberConfirm'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {Register} from './components/Register'

import {StorageType} from '../../../setup'
import {IUserModel} from './models/UserModel'

const AuthLayout = () => {
  useEffect(() => {
    document.body.classList.add('bg-white')
    return () => {
      document.body.classList.remove('bg-white')
    }
  }, [])

  return (
    <div className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'>
      {/* begin::Content */}
      <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
        {/* begin::Logo */}
        <a href='#' className='mb-12'>
          <img alt='Logo' src={toAbsoluteUrl('/media/logos/logo-light.png')} className='h-45px' />
        </a>
        {/* end::Logo */}
        {/* begin::Wrapper */}
        <div className='w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto'>
          <Outlet />
        </div>
        {/* end::Wrapper */}
      </div>
      {/* end::Content */}
      {/* begin::Footer */}
      {/* end::Footer */}
    </div>
  )
}

const AuthPage = () => {
  const user: IUserModel = useSelector((state: StorageType) => state.auth.user, shallowEqual)

  return (
    <Routes>
      <Route element={<AuthLayout />}>
        <Route path='login' element={<Login />} />
        <Route path='register' element={<Register />} />
        <Route path='phone-confirm' element={<NumberConfirm />} />
        <Route
          index
          element={
            user && !user.params?.number_confirm ? <Navigate to='/auth/phone-confirm' /> : <Login />
          }
        />
      </Route>
    </Routes>
  )
}

export {AuthPage}
