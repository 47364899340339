import { toAbsoluteUrl } from "../../../_metronic/helpers"

export const initialSystem: ISystem = {
    pushMessages: [],
    backendUrl: ""
}
export const EMPTY_PUSH_MESSAGE: IPushMessage = {
    id: 0,
    code: '',
    name: '',
    message: '',
    details: '',
    status: 'info',
    picture: toAbsoluteUrl('/media/misc/image.png'),// we using not imgUtils.no_photo, because there is an error of not inited imgUtils
    // showed: false,
    showSeconds: 5
}

export interface ISystem {
    pushMessages: IPushMessage[] | []
    backendUrl: string
}

export interface IPushMessage {
    id?: number
    code?: string
    name: string
    message?: string
    details?: any
    picture?: string
    status: "error" | "success" | "info"
    //showed: boolean
    showSeconds?: number  // how many seconds to show
}


export const pushSystemMessageActionTypes = {
    GetMessages: '[GetMessages] Action',
    GetMessage: '[GetMessage] Action',
    SetMessage: '[SetMessage] Action',
    SetMessages: '[SetMessages] Action',
    AddMessage: '[AddMessage] Action',
    AddMessageGroup: '[AddMessageGroup] Action',
    DelMessages: '[DelMessages] Action',
}