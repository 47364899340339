import { createAction } from "@reduxjs/toolkit";
import { IUrlParameters } from "../../components/lists/UrlFilteredList";
import { IGoodTradeOfferPrice, IGoodTradeOfferPriceWithGoodIDANdFilter } from "../goods/api";
import {  IGoodMpCardUniversal, IGoodMpCardUniversalRequest, IMpCardItemPublishParams, IMpCardTemplate, 
    IMpCardTemplateCreateParams, IMpCardTemplateGetParams, 
    INewMpCardParams, 
    mpCardActionTypes as actionTypes, } from "./types"
import { IUniversalCategoriesParams } from "../categories/types";

export const ASYNC_GET_MPCARDS = actionTypes.GetMpCards;
export const ASYNC_GET_MPCARD = actionTypes.GetMpCard;
export const ASYNC_GET_UNIVERSAL_MPCARD = actionTypes.GetUniversalMpCard;
export const ASYNC_CREATE_UNIVERSAL_MPCARD = actionTypes.CreateUniversalMpCard;
export const ASYNC_GET_MPCARD_TEMPLATES = actionTypes.GetMpCardTemplates;
export const SET_MPCARD_TEMPLATES_ISLOADING = actionTypes.SetMpCardTemplateIsLoading;
export const ASYNC_NEW_UNIVERSAL_MPCARD = actionTypes.NewUniversalMpCard;
export const ASYNC_SAVE_UNIVERSAL_MPCARD = actionTypes.SaveUniversalMpCard;
export const ASYNC_CREATE_MPCARD_TEMPLATE = actionTypes.CreateMpCardTemplate;
export const ASYNC_UPDATE_MPCARD_TEMPLATE = actionTypes.UpdateMpCardTemplate;
export const ASYNC_DELETE_MPCARD_TEMPLATE = actionTypes.DeleteMpCardTemplate;
export const ASYNC_COPY_MPCARD_TEMPLATE = actionTypes.CopyMpCardTemplate;
export const SET_MPCARD_IS_SAVING = actionTypes.SetMpCardIsSaving;

export const ASYNC_UPDATE_MPCARD = actionTypes.UpdateMpCard;
export const ASYNC_PUBLISH_MPCARD = actionTypes.PublishMpCard;
export const ASYNC_PUBLISH_MPCARD2 = actionTypes.PublishMpCard2;
export const SET_MPCARD_PARAMETERS = actionTypes.SetParametersMpCard;
export const RESET_MPCARD_PARAMETERS = actionTypes.ResetParametersMpCard;
export const ADD_MPCARD_PARAMETERS = actionTypes.AddParametersMpCard;
export const SET_MARKETPLACE_MPCARD_PARAMETER = actionTypes.SetMarketplaceParameter;
export const SET_ACCOUNT_MPCARD_PARAMETER = actionTypes.SetAccountParameter;
export const SET_MPCARD_PRICE = actionTypes.SetMpCardPrice;
export const SET_MPCARD_PRICE_FROM_GOODITEM = actionTypes.SetMpCardPriceFromGoodItem;
export const RESET_UNIVERSAL_SELECTED_MPCARD = actionTypes.ResetMpCardSelectedUniversal;
export const SET_UNIVERSAL_SELECTED_MPCARD = actionTypes.SetMpCardSelectedUniversal;
export const SET_SELECTED_UNIVERSAL_MPCARD = actionTypes.SetSelectedUniversalMpCard;



export const getAsyncMpCardsCreator = createAction(ASYNC_GET_MPCARDS);
export const getAsyncMpCardCreator = createAction<number>(ASYNC_GET_MPCARD);
export const getAsyncMpCardTemplatesCreator = createAction<IMpCardTemplateGetParams | null>(ASYNC_GET_MPCARD_TEMPLATES);
export const setMpCardTemplatesIsLoadingCreator = createAction<boolean>(SET_MPCARD_TEMPLATES_ISLOADING);
export const saveAsyncMpCardCreator = createAction<IGoodMpCardUniversalRequest>(ASYNC_SAVE_UNIVERSAL_MPCARD);
export const createAsyncMpCardCreator = createAction<INewMpCardParams>(ASYNC_CREATE_UNIVERSAL_MPCARD);
export const getAsyncUniversalMpCardCreator = createAction<number>(ASYNC_GET_UNIVERSAL_MPCARD);
export const newAsyncUniversalMpCardCreator = createAction<IUniversalCategoriesParams>(ASYNC_NEW_UNIVERSAL_MPCARD);
export const createAsyncMpCardTemplateCreator = createAction<IMpCardTemplateCreateParams>(ASYNC_CREATE_MPCARD_TEMPLATE);
export const updateAsyncMpCardTemplateCreator = createAction<IMpCardTemplate>(ASYNC_UPDATE_MPCARD_TEMPLATE);
export const copyAsyncMpCardTemplateCreator = createAction<IMpCardTemplateCreateParams>(ASYNC_COPY_MPCARD_TEMPLATE);
export const deleteAsyncMpCardTemplateCreator = createAction<number>(ASYNC_DELETE_MPCARD_TEMPLATE);


// export const setMpCardsRequestParametersCreator = createAction<IMpCardListRequestParams>(SET_MPCARD_PARAMETERS);
export const setMpCardsRequestParametersCreator = createAction<IUrlParameters>(SET_MPCARD_PARAMETERS);
export const publishMpCardCreator = createAction<IMpCardItemPublishParams>(ASYNC_PUBLISH_MPCARD);
export const publishMpCard2Creator = createAction<IMpCardItemPublishParams>(ASYNC_PUBLISH_MPCARD2); // it's to publish from mpcards list (another reducers and source of data)
export const resetMpCardsRequestParametersCreator = createAction(RESET_MPCARD_PARAMETERS);
export const setMpCardsRequestMarketplaceParameterCreator = createAction<number | null>(SET_MARKETPLACE_MPCARD_PARAMETER);
export const setMpCardsRequestAccountParameterCreator = createAction<number | null>(SET_ACCOUNT_MPCARD_PARAMETER);

export const setMpCardPriceCreator = createAction<IGoodTradeOfferPrice>(SET_MPCARD_PRICE);
export const setMpCardPriceFromGoodItemCreator = createAction<IGoodTradeOfferPriceWithGoodIDANdFilter>(SET_MPCARD_PRICE_FROM_GOODITEM);
export const setGoodMpCardSelectedUniversal = createAction<IGoodMpCardUniversal | null>(SET_UNIVERSAL_SELECTED_MPCARD);
export const setSelectedUniversalMpCardCreator = createAction<IGoodMpCardUniversal | null>(SET_SELECTED_UNIVERSAL_MPCARD);
export const resetGoodMpCardSelectedUniversal = createAction(RESET_UNIVERSAL_SELECTED_MPCARD);
export const setMpCardIsSavingCreator = createAction<boolean>(SET_MPCARD_IS_SAVING);


