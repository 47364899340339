/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import React, {FC} from 'react'
import {KTSVG} from '../../../helpers'
import {useLayout} from '../../core'
import {DefaultTitle} from '../header/page-title/DefaultTitle'
import {Button} from 'react-bootstrap'

interface IToolbar1Props {
  className?: string
}

const Toolbar1: FC<IToolbar1Props> = (props) => {
  const {classes} = useLayout()

  return (
    <div className={`toolbar ${props.className ?? ''}`} id='kt_toolbar'>
      <div
        id='kt_toolbar_container'
        className={clsx(classes.toolbarContainer.join(' '), 'd-flex flex-stack')}
      >
        <div className=' d-flex flex-row ' style={{maxWidth: '100%'}}>
          {
            props.children ?? null
            // пасхалочка от бэка застряла у мужа жена в унитазе вызвал муж мчс думает чем жену прикрыть прикрыл ее мексиканской шляпой между ног приезжаю мчсники смотрят и говорят мда мужик жене то мы поможем а вот мексиканцу пизда походу
          }
        </div>
      </div>
    </div>
  )
}

export {Toolbar1}
