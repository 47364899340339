import {IRegion} from '../regions/types'
import {IPvzCdek} from '../pvzCdek/types'

export const initialDeliveryAccount: IDeliveryAccountState = {
  count: 0,
  next: '',
  previous: '',
  items: [
    {
      id: 0,
      name: '',
      company: 0,
      delivery_service: 0,
      custom_delivery_time: [],
      params: {
        weekends: [],
        client_id: '',
        region_code: 0,
        client_secret: '',
        shipment_point: '',
        non_working_hours: 0,
        extra_days_for_pvz: 0,
        custom_delivery_time: [],
        extra_days_for_courier: 0,
        extra_days_after_holidays: 0,
        non_delivery_intervals: [],
      },
      region: {id: 0, label: ''},
      shipment_point: {id: '', label: ''},
    },
  ],
  isLoading: false,
  isSuccessLastOperation: false,
}

export interface IDeliveryAccount {
  id: number
  name: string
  company?: number
  delivery_service: number
  custom_delivery_time?: IRegion[]
  params: {
    weekends?: []
    client_id: string
    region_code: number
    client_secret: string
    shipment_point: string
    non_working_hours: number
    extra_days_after_holidays: number
    extra_days_for_pvz?: number
    custom_delivery_time?: {} | []
    extra_days_for_courier?: number
    non_delivery_intervals?: Array<{date_to: string; date_from: string}>
  }
  region: IRegion
  shipment_point: IPvzCdek
}

export const emptyDeliveryAccount: IDeliveryAccount = {
  id: -1,
  name: 'Новый аккаунт службы доставки',
  company: -1,
  delivery_service: -1,
  custom_delivery_time: [],
  params: {
    weekends: [],
    client_id: '',
    region_code: 0,
    client_secret: '',
    shipment_point: '',
    non_working_hours: 0,
    extra_days_for_pvz: 0,
    custom_delivery_time: [],
    extra_days_for_courier: 0,
    extra_days_after_holidays: 0,
    non_delivery_intervals: [],
  },
  region: {
    id: 0,
    label: '',
  },
  shipment_point: {
    id: '',
    label: '',
  },
}

export interface IDeliveryAccountState {
  count: number
  next: string
  previous: string
  items: Array<IDeliveryAccount>
  isLoading: boolean
  isSuccessLastOperation: boolean
}

export const deliveryAccountActionTypes = {
  GetDeliveryAccounts: '[GetDeliveryAccounts] Action',
  SetDeliveryAccounts: '[SetDeliveryAccounts] Action',
  UpdateDeliveryAccount: '[UpdateDeliveryAccount] Action',
  AddDeliveryAccount: '[AddDeliveryAccount] Action',
  DeleteDeliveryAccount: '[DeleteDeliveryAccount] Action',
  SetIsLoadingDeliveryAccounts: '[SetIsLoadingDeliveryAccounts] Action',
  AddDeliveryAccountNoApi: '[AddDeliveryAccountNoApi] Action',
  SetDeliveryAccountNoApi: '[SetDeliveryAccountNoApi] Action',
}
