import axios from 'axios'
import {IPriceType, IPriceTypeListResponse} from './types'
import {IFilterParameters} from '../../components/lists/Filter'
import {IPriceTypeFilters} from './types'

const API_URL = `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENDPOINT_PREFIX}`
export const PRICES_TYPES_URL = `${API_URL}v2/price_types/`

export const getPriceTypesFromServer = (params: IPriceTypeFilters) => {
  const limit = params.itemsPerPage ?? 10
  const page = params.currentPage ?? 0
  const offset = page * limit

  return axios.get<IPriceTypeListResponse>(
    `${PRICES_TYPES_URL}?limit=${limit}&offset=${offset}&ordering=${params.ordering}`
  )
}

export const addPriceTypeToServer = (params: IPriceType) => {
  const {id, ...newPriceTypeProps} = params

  return axios.post<IPriceType>(`${PRICES_TYPES_URL}create_price_type/`, newPriceTypeProps)
}

export const deletePriceTypeFromServer = (params: number) => {
  return axios.delete(`${PRICES_TYPES_URL}${params}/delete_price_type/`)
}

export const updatePriceTypeOnServer = (params: IPriceType) => {
  return axios.put<IPriceType>(`${PRICES_TYPES_URL}${params.id}/update_price_type/`, params)
}
