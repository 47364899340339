import axios from 'axios'
import {MARKETING_ACCOUNT_PARAMETER} from './constants'
import {
  IAccountWithCampaign,
  IAccountWithCategories,
  ICampaign,
  ICreateCampaignRequestParams,
  ICreateCampaignsResult,
  IPauseCampaignRequestParams,
  IPauseResumeCampaignResult,
  IPrepareGoodRequestParams,
  IRequestParamsOzonPromo,
  IUrlParametersLight,
} from './types'

const API_URL = `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENDPOINT_PREFIX}`
export const COMMON_URL = `${API_URL}v2/marketing/`

export const fetchPrepareGoods = (params: IPrepareGoodRequestParams) => {
  return axios.post<IAccountWithCategories[]>(`${COMMON_URL}prepare_goods/`, params)
}

// http://api-dev.shop-delivery.ru/int_api/v2/get_keywords/?nmid=54554
export const getPhrasesFromServer = (nmId: number) => {
  return axios.get<{keywords: string[]}>(`${COMMON_URL}get_keywords/?nmid=${nmId}`)
}

// http://api-dev.shop-delivery.ru/int_api/v2/campaigns
export const getCompaignsFromServer = (params: IUrlParametersLight) => {
  return axios.get<IAccountWithCampaign[]>(
    `${COMMON_URL}campaigns/?ordering=${params.ordering?.length ? params.ordering : ''}${
      params.itemsPerPage != undefined ? '&limit=' + params.itemsPerPage : ''
    }${
      params.pageNumber != undefined && params.itemsPerPage != undefined
        ? '&offset=' + params.itemsPerPage * params.pageNumber
        : ''
    }${params.filter?.length ? '&search=' + params.filter : ''}${
      params.additionalParameters != undefined &&
      params.additionalParameters.filter((ap) => ap.label == MARKETING_ACCOUNT_PARAMETER)?.length
        ? '&account=' +
          params.additionalParameters
            .filter((ap) => ap.label == MARKETING_ACCOUNT_PARAMETER)
            .reduce((acc, cur, idx) => (acc.length ? ',' + acc : acc) + cur.value, '')
        : ''
    }`
  )
}

// http://api-dev.shop-delivery.ru/int_api/v2/campaigns/create_advert_campaign/
export const createNewCampaignOnServer = (campaignData: ICreateCampaignRequestParams) => {
  return axios.post<ICreateCampaignsResult>(`${COMMON_URL}campaigns/create_advert_campaign/`, {
    ...campaignData,
    budget: campaignData.budget_add,
    budget_add: 0,
  })
}

// http://api-dev.shop-delivery.ru/int_api/v2/marketing/campaigns/{id}/change_campaign_condition/
export const pauseResumeCampaignOnServer = (campaignData: IPauseCampaignRequestParams) => {
  return axios.post<IPauseResumeCampaignResult>(
    `${COMMON_URL}campaigns/${campaignData.id}/change_campaign_condition/`,
    {enabled: campaignData.enabled}
  )
}

// http://api-dev.shop-delivery.ru/int_api/v2/campaigns/change_campaign_settings/
export const updateCampaignOnServer = (campaignData: ICreateCampaignRequestParams) => {
  return campaignData.advert_id != undefined
    ? axios.post<ICreateCampaignsResult>(
        `${COMMON_URL}campaigns/${campaignData.advert_id}/change_campaign_settings/`,
        campaignData
      )
    : null
}

// http://api-dev.shop-delivery.ru/int_api/v2/campaigns/111/delete_campaign/
export const deleteCampaignOnServer = (campaignId: number) => {
  return axios.delete(`${COMMON_URL}campaigns/${campaignId}/delete_campaign`)
}

// http://api-dev.shop-delivery.ru/int_api/v2/
// export const saveCampaignOnServer = (campaignData: ICreateCampaignRequestParams) => {
//     return axios.post(`${COMMON_URL}campaigns/create_advert_campaign/`, campaignData)
// }

// http://api-dev.shop-delivery.ru/int_api/v2/marketing/campaigns/change_state_ozon_bids/
export const changeStateOzonBids = (data: IRequestParamsOzonPromo) => {
  return axios.post(`${COMMON_URL}campaigns/change_state_ozon_bids/`, {
    account_id: data.account_id,
    active: data.active,
  })
}

// http://api-dev.shop-delivery.ru/int_api/v2/marketing/campaigns/change_state_ozon_promo_goods/
export const changeStateOzonPromoGoods = (data: IRequestParamsOzonPromo) => {
  return axios.post(`${COMMON_URL}campaigns/change_state_ozon_promo_goods/`, {
    account_id: data.account_id,
    active: data.active,
  })
}
