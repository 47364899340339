import { actions } from "../../app/modules/auth";
import { REFRESH_TOKEN_URL } from "../../app/store/auth/AuthCRUD";

export default function setupAxios(axios: any, store: any) {
  axios.defaults.headers.Accept = 'application/json';

    
  axios.interceptors.request.use((config: any) => {
        const { auth: {access}, } = store.getState()
        if (access) {
          config.headers.Authorization = `JWT ${access}`
        }
  
        return config
      },
      (err: any) => Promise.reject(err)
    )

    
    axios.interceptors.response.use((response: any) => {
        return response;
      },
      async (error: any) => {
        if (error.response.status === 401 && !error.config?.isFired) {          
          error.config.isFired = true;
          try{
            const {auth: {refresh}} = store.getState()
            const newToken = await getToken(refresh);
            if (newToken && newToken.data?.access){
              store.dispatch(actions.setAccessToken(newToken.data))
              error.config.headers["Authorization"] = `JWT ${newToken.data.access}`;
            }
          }
          catch(er){
            return axios(error.config);
          }
          return axios(error.config);
        } 
        else 
        {
          return Promise.reject(error);
        }
      }
    )

    const getToken = async (refresh: string) => {
      let res = {data: {access: ""}};
      try
      {
        let response = await fetch(REFRESH_TOKEN_URL, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json;charset=utf-8'
          },
          body: JSON.stringify({refresh: refresh}),
        })
        const data = await response.json();
        res.data = data;
      } 
      catch(err){
        return res;  
      }
      return res;
    }

}


// этот кусок кода из старого приложения, вероятно, обрабатывает сторонние ошибки аксиоса. нужно разобраться и применить для текущего приложения
// inject(
//   "sendRequest",
//   ({
//     url,
//     params,
//     data,
//     method,
//     contentType,
//     external = false,
//     ...options
//   }) => {
//     method = method || "POST";
//     const baseURL = !external
//       ? process.env.VUE_APP_ENDPOINT_URL + process.env.VUE_APP_ENDPOINT_PREFIX
//       : "";
//     let headers = {};
//     const token = app.$cookies.get(TOKEN_COOKIE);
//     if (token) {
//       headers.Authorization = "JWT " + token;
//     }
//     if (options.headers) {
//       headers = { ...headers, ...options.headers };
//     }
//     return jwtInterceptor({
//       method,
//       url,
//       params,
//       data,
//       headers,
//       baseURL,
//       contentType,
//       ...options
//     }).catch(error => {
//       if (
//         error &&
//         error.response &&
//         (error.response.status === 500 || error.response.status === 400)
//       ) {
//         Vue.notify({
//           type: "error",
//           title: "Что то пошло не так!",
//           text: `${
//             error.response.data?.errors
//               ? error.response.data?.errors
//                   .map(e => {
//                     if (typeof e !== "string") {
//                       Object.keys(e)
//                         .map(err => e[err])
//                         .join(",");
//                     } else {
//                       return e;
//                     }
//                   })
//                   .join(",")
//               : "500"
//           }`,
//           duration: error.response?.data?.error_id ? -3000 : 3000
//         });
//       } else if (error && error.response && error.response.status === 404) {
//         app.router.push({ name: "404" });
//       }
//       throw { ...error.response.data };
//     });
//   }
// );