import axios, { AxiosResponse } from "axios"
import { IFilterRequestParams, IUrlParameters } from "../../components/lists/UrlFilteredList"
import { ITaskResponse } from "../tasks/types";
import { DEFAULT_MPDOCS_GET_LIMIT, DEFAULT_MPDOCS_OFFSET } from "./constants";
import { IMpDocBarcodeRequest, IMpDocBarcodesRequest, IMpDocItem, IMpDocListRequest, IMpDocListResponse } from "./types";



const API_URL = `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENDPOINT_PREFIX}`;
export const MPDOCS_URL = `${API_URL}v2/mpdocs/`


// ==== utils

export function readBlobAsText(blob: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsText(blob);
    });
  }


// =========


// =================   get mpdocs =================
// #region
//https://api-dev.shop-delivery.ru/int_api/v2/mpdocs/?limit=10&offset=1


// get all mpdocs from server
export const getMpDocsWithFilterFromServer = (params: IUrlParameters) => { 
    const offset = (params.pageNumber != undefined && params.itemsPerPage != undefined)
    ? params.pageNumber * params.itemsPerPage
    : DEFAULT_MPDOCS_OFFSET

    return axios.get<IMpDocListResponse>(`${MPDOCS_URL}?limit=${params.itemsPerPage ?? DEFAULT_MPDOCS_GET_LIMIT}&offset=${offset}${params.filter != undefined ? `&search=${params.filter}` : ``}${params.ordering != undefined ? `&ordering=${params.ordering}` : ``}${(params.additionalParameters != undefined && params.additionalParameters.length > 0) ? params.additionalParameters.reduce((acc, current) => acc + "&" + current.label + "=" + current.value, "") : ``}`)
}

// #endregion


// =================   get mpdoc =================
// #region
//https://api-dev.shop-delivery.ru/int_api/v2/mpdocs/11111/


// get order by id from server
export const getMpDocFromServer = (params: number) => { 
    return axios.get<IMpDocItem>(`${MPDOCS_URL}${params}/`)
}

// #endregion


// =================   get order stickers =================
// #region
// https://api-dev.shop-delivery.ru/int_api/v2/mpdocs/649/get_pdf/


// get sticker by id
export const getMpDocStickerOnServer = (params: IMpDocBarcodeRequest) => {
    return axios.get<ITaskResponse>(`${MPDOCS_URL}${params.mpdocId}/get_pdf/`, { responseType: 'blob' })
        .catch(async (response) => {

            const err = await readBlobAsText(response.response.data)
            const error: any = JSON.parse(err as string)
            throw { response: { data: error } }
        })
}
// get stickers by ids
export const getMpDocStickersOnServer = (params: IMpDocBarcodesRequest) => { 
    return axios.post<ITaskResponse>(`${MPDOCS_URL}get_pdf_list/`, {id: [...params.mpdocIds]}, {responseType: 'blob'})
        .catch(async (response) => {

            const err = await readBlobAsText(response.response.data)
            const error: any = JSON.parse(err as string)
            throw { response: { data: error } }
        })
    
}

// #endregion