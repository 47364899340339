import {put, select, takeEvery} from 'redux-saga/effects'
import {StorageType} from '../../../setup'
import {sagaUtils} from '../../utils/dataUtils'
import {ActionWithPayload} from '../auth/AuthRedux'
import {
  ASYNC_GET_COMPANIES,
  ASYNC_GET_KEYWORDS,
  ASYNC_GET_PREPARE_GOODS,
  CREATE_CAMPAIGN,
  DELETE_CAMPAIGN,
  PAUSE_RESUME_CAMPAIGN,
  SET_COMPANIES_FILTER,
  UPDATE_CAMPAIGN,
  CHANGE_OZON_BIDS,
  CHANGE_OZON_PROMO_GOODS,
} from './actions'
import {
  createNewCampaignOnServer,
  deleteCampaignOnServer,
  fetchPrepareGoods,
  getCompaignsFromServer,
  getPhrasesFromServer,
  pauseResumeCampaignOnServer,
  updateCampaignOnServer,
  changeStateOzonBids,
  changeStateOzonPromoGoods,
} from './api'
import {
  addCampaign,
  deleteCampaign,
  pauseResumeCampaign,
  setAccountsWithCampaigns,
  setFilter,
  setIsLoading,
  setItems,
  setKeywords,
  updateCampaign,
} from './reducers'
import {
  ICampaignSaveData,
  ICreateCampaignRequestParams,
  IMarketingState,
  IPauseCampaignRequestParams,
  IPrepareGoodRequestParams,
  IUrlParametersLight,
} from './types'

import {intl} from '../../modules/goods/item/validation'

export function* marketingSaga() {
  yield takeEvery(ASYNC_GET_PREPARE_GOODS, workerGetPrepareGoods)
  yield takeEvery(ASYNC_GET_KEYWORDS, workerGetKeywords)
  yield takeEvery(SET_COMPANIES_FILTER, workerSetCompaniesFilter)
  yield takeEvery(ASYNC_GET_COMPANIES, workerGetCompanies)
  // yield takeEvery(ADD_CAMPAIGN, workerAddCampaign)
  yield takeEvery(CREATE_CAMPAIGN, workerCreateCampaign)
  yield takeEvery(DELETE_CAMPAIGN, workerDeleteCampaign)
  yield takeEvery(UPDATE_CAMPAIGN, workerUpdateCampaign)
  yield takeEvery(PAUSE_RESUME_CAMPAIGN, workerPauseResumeCampaign)
  yield takeEvery(CHANGE_OZON_BIDS, workerChangeStateOzonBids)
  yield takeEvery(CHANGE_OZON_PROMO_GOODS, workerChangeStateOzonPromoGoods)
}

export function* workerGetPrepareGoods(action: ActionWithPayload<IPrepareGoodRequestParams>): any {
  yield sagaUtils.workerWrapper(
    setIsLoading,
    fetchPrepareGoods,
    action.payload,
    setItems,
    null,
    '',
    'GET',
    'data',
    false,
    ''
  )
}

export function* workerGetKeywords(action: ActionWithPayload<number>): any {
  yield sagaUtils.workerWrapperCombinedReducerRequest(
    setIsLoading,
    getPhrasesFromServer,
    action.payload,
    setKeywords,
    null,
    action.payload,
    '',
    'GET',
    'data',
    false,
    ''
  )
}

export function* workerDeleteCampaign(action: ActionWithPayload<number>): any {
  yield sagaUtils.workerWrapper(
    setIsLoading,
    deleteCampaignOnServer,
    action.payload,
    deleteCampaign,
    action.payload,
    '',
    'DELETE',
    'direct',
    true
  )
}

export function* workerUpdateCampaign(action: ActionWithPayload<ICampaignSaveData>): any {
  if (action.payload?.account_id) {
    yield sagaUtils.workerWrapper(
      setIsLoading,
      updateCampaignOnServer,
      {...action.payload?.changedCampaigns[0], account_id: action.payload?.account_id},
      updateCampaign,
      null,
      '',
      'SAVE',
      'data.result',
      true
    )
  }
}

export function* workerPauseResumeCampaign(
  action: ActionWithPayload<IPauseCampaignRequestParams>
): any {
  yield sagaUtils.workerWrapper(
    setIsLoading,
    pauseResumeCampaignOnServer,
    action.payload,
    pauseResumeCampaign,
    null,
    '',
    'SAVE',
    'data.result',
    true
  )
}

export function* workerSetCompaniesFilter(action: ActionWithPayload<IUrlParametersLight>): any {
  yield put(setFilter(action.payload))
}

export function* workerAddCampaign(action: ActionWithPayload<ICampaignSaveData>): any {
  yield put(addCampaign(action.payload))
}

export function* workerCreateCampaign(
  action: ActionWithPayload<ICreateCampaignRequestParams>
): any {
  yield sagaUtils.workerWrapper(
    setIsLoading,
    createNewCampaignOnServer,
    action.payload,
    addCampaign,
    null,
    '',
    'ADD',
    'data.result',
    true
  )
}

export function* workerGetCompanies(): any {
  const marketing: IMarketingState = yield select((store: StorageType) => store.marketing)

  yield sagaUtils.workerWrapper(
    setIsLoading,
    getCompaignsFromServer,
    marketing.filter,
    setAccountsWithCampaigns,
    null,
    '',
    'GET',
    'data.results',
    false,
    ''
  )
}

export function* workerChangeStateOzonBids(action: any) {
  yield sagaUtils.workerWrapper(
    null,
    changeStateOzonBids,
    action.payload,
    null,
    null,
    intl.formatMessage({id: 'SAGAS.MARKETING.CHANGE_STATE_OZON_BIDS'}),
    'SAVE',
    'data',
    true
  )
}

export function* workerChangeStateOzonPromoGoods(action: any) {
  yield sagaUtils.workerWrapper(
    null,
    changeStateOzonPromoGoods,
    action.payload,
    null,
    null,
    intl.formatMessage({id: 'SAGAS.MARKETING.CHANGE_STATE_OZON_PROMO_GOODS'}),
    'SAVE',
    'data',
    true
  )
}
