import { put, select, takeEvery } from "redux-saga/effects"
import { fetchCategoriesByFilter, fetchCategoriesById, fetchRootCategories, loadComparisonFromServer, saveComparisonToServer } from "./api";
import { setComparison, setDestCategories, setFilteredCategories, setIsLoading, setSubCategories } from "./reducers"
import { ASYNC_GET_ROOT_CATEGORIES, ASYNC_GET_CATEGORIES_BY_FILTER, RESET_ALL_CATEGORIES,
    ASYNC_GET_ALL_CATEGORIES, RESET_FILTERED_CATEGORIES, ASYNC_GET_COMPARISON, ASYNC_UPDATE_COMPARISON, RESET_COMPARISON } from "./actions"
import { sagaUtils } from "../../utils/dataUtils";
import { intl } from "../../modules/goods/item/validation";
import { emptyComparisonSaved } from "./types";



export function* categorySaga(){
    yield takeEvery(ASYNC_GET_ROOT_CATEGORIES, workerGetRootCategories)
    yield takeEvery(ASYNC_GET_CATEGORIES_BY_FILTER, workerGetCategoriesByFilter)
    yield takeEvery(ASYNC_GET_ALL_CATEGORIES, workerGetAllCategories)
    yield takeEvery(ASYNC_GET_COMPARISON, workerGetComparison)
    yield takeEvery(ASYNC_UPDATE_COMPARISON, workerSetComparison)
    
    
    yield takeEvery(RESET_FILTERED_CATEGORIES, workerResetFilteredCategories)
    yield takeEvery(RESET_ALL_CATEGORIES, workerResetAllCategories)
    yield takeEvery(RESET_COMPARISON, workerResetComparison)
}

// get root categorys from server
export function* workerGetRootCategories():any{
    yield sagaUtils.workerWrapper(setIsLoading, 
        fetchRootCategories, null, 
        setSubCategories, null, 
        intl.formatMessage({id: "SAGAS.CATEGORYS.GETROOT"}), "GET", "data.categories", false)
}

// get subcategorys from server
export function* workerGetCategoriesById(action: any):any{
    yield sagaUtils.workerWrapper(setIsLoading, 
        fetchCategoriesById, action.payload, 
        setSubCategories, null, 
        intl.formatMessage({id: "SAGAS.CATEGORYS.GETSUB"}), "GET", "data.categories", false)
}

// get comparison from server
export function* workerGetComparison(action: any):any{
    yield sagaUtils.workerWrapper(setIsLoading, 
        loadComparisonFromServer, action.payload, 
        setComparison, null, 
        intl.formatMessage({id: "SAGAS.CATEGORYS.GETCOMPARISON"}), "GET", "data", false)
}

// ыфму comparison to server from server
export function* workerSetComparison(action: any):any{
    yield sagaUtils.workerWrapper(setIsLoading, 
        saveComparisonToServer, action.payload,
        setComparison, null, 
        intl.formatMessage({id: "SAGAS.CATEGORYS.SETCOMPARISON"}), "SAVE", "data.result", true)
}

// reset comparison to default
export function* workerResetComparison():any{
    yield put(setComparison(emptyComparisonSaved))
}

// get destination categorys (preload subcategorus from server)
export function* workerGetAllCategories(action: any):any{
    yield workerGetCategoriesById({payload: action.payload.root})
    const state = yield select()
    const items = state?.categories?.selected?.find((cat:any) => (cat.id == action.payload.sub)) ?? []
    yield put(setDestCategories(items?.children ?? []))
}

// get categories by filter options
export function* workerGetCategoriesByFilter(action: any):any{
    yield sagaUtils.workerWrapper(setIsLoading, 
        fetchCategoriesByFilter, action.payload, 
        setFilteredCategories, null, 
        intl.formatMessage({id: "SAGAS.CATEGORYS.GETBYFILTER"}), "GET", "data.results", false)
}

// sets filtered categorys
export function* workerResetFilteredCategories():any{
    yield put(setFilteredCategories([]))
}

// clear all categorys
export function* workerResetAllCategories():any{
    yield put(setSubCategories([]))
    yield put(setDestCategories([]))
    yield put(setFilteredCategories([]))
}