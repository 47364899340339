import {IUrlParameters} from './../../components/lists/UrlFilteredList'
import {createAction} from '@reduxjs/toolkit'
import {
  IOrderBarcodeRequest,
  IOrderChangeStatusesRequest,
  IOrderChangeStatusRequest,
  IOrderListRequest,
  IOrderRegisterRequest,
  IOrdersGetParams,
  orderActionTypes as actionTypes,
} from './types'
import {IDeliveryTrack, IDeliveryTrackOrderRequest} from '../deliveryServices/types'

export const ASYNC_GET_ORDERS_MANUAL = actionTypes.GetOrdersManual
export const ASYNC_GET_ORDER = actionTypes.GetOrder
export const ASYNC_GET_STICKER = actionTypes.GetSticker
export const ASYNC_SET_ORDER_STATUS = actionTypes.SetOrderStatus
export const ASYNC_SET_ORDER_STATUSES = actionTypes.SetOrderStatuses
export const ASYNC_GET_ORDERS_WITH_FILTER = actionTypes.GetOrdersWithFilter
export const SET_ORDERS_REQUEST_PARAMETERS = actionTypes.SetRequestParameters
export const ASYNC_SET_TRACKNUMBER = actionTypes.SetTrackNumber
export const ASYNC_DELETE_TRACKNUMBER = actionTypes.DeleteTrackNumber
export const REGISTER_ORDER = actionTypes.RegisterOrder

export const getAsyncOrdersManualCreator = createAction<IOrderListRequest>(ASYNC_GET_ORDERS_MANUAL)
export const getAsyncOrdersWithFilterCreator = createAction(ASYNC_GET_ORDERS_WITH_FILTER)
export const getAsyncOrderCreator = createAction<number>(ASYNC_GET_ORDER)
export const setAsyncOrderStatusCreator =
  createAction<IOrderChangeStatusRequest>(ASYNC_SET_ORDER_STATUS)
export const setAsyncOrderStatusesCreator =
  createAction<IOrderChangeStatusesRequest>(ASYNC_SET_ORDER_STATUSES)
export const getAsyncOrderStickerCreator = createAction<IOrderBarcodeRequest>(ASYNC_GET_STICKER)
export const setOrdersRequestParams = createAction<IUrlParameters>(SET_ORDERS_REQUEST_PARAMETERS)
export const setAsyncTrackNumberCreator =
  createAction<IDeliveryTrackOrderRequest>(ASYNC_SET_TRACKNUMBER)
export const deleteAsyncTrackNumberCreator = createAction<number>(ASYNC_DELETE_TRACKNUMBER)
export const registerOrderCreator = createAction<IOrderRegisterRequest>(REGISTER_ORDER)
