import {call, take, race, put, delay, takeEvery} from 'redux-saga/effects'
import {TransactionStatus} from './types'
import {STOP_CHECKING_TRANSACTION_STATUS, CHECK_TRANSACTION_STATUS} from './actions'
import {setTransactionStatus} from './reducer'
import {checkTransactionStatus} from './api'

export function* checkTransactionStatusSaga() {
  yield takeEvery(CHECK_TRANSACTION_STATUS, workerStartOwerViewCheking)
}

function* workerCheckTransactionStatus(action: any): ReturnType<any> {
  try {
    let status = TransactionStatus.PENDING

    while (status === TransactionStatus.PENDING) {
      const response = yield call(checkTransactionStatus, action.payload)
      status = response.data.status

      yield put(setTransactionStatus(status))

      yield delay(3000)
    }
  } catch (error) {
    console.log('error', error)
    yield put(setTransactionStatus(TransactionStatus.ERROR))
  }
}

function* workerStartOwerViewCheking(action: any): ReturnType<any> {
  yield race([call(workerCheckTransactionStatus, action), take(STOP_CHECKING_TRANSACTION_STATUS)])
}
