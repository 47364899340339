/* eslint-disable jsx-a11y/anchor-is-valid */
import 'moment/locale/ru'
import moment from 'moment'
import {FC, useEffect, useState} from 'react'
import {Button, Col, Row} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'
import {NavLink} from 'react-router-dom'
import {StorageType} from '../../../setup'
import {KTSVG} from '../../../_metronic/helpers'
import {DashboardGraphicList} from '../../../_metronic/partials/widgets/mixed/DashboardGraphicList'
import {DashboardGraphicSummary} from '../../../_metronic/partials/widgets/mixed/DashboardGraphicSummary'
import DateRangePickerBootstrap from '../../components/select/date-picker/DateRangePicker'
import {intl} from '../../modules/goods/item/validation'
import {IDashboardStorage, IDateRange2} from '../../store/dashboard/types'
import {IDateRange} from '../../utils/types'
import Logo from './Dashboard/Logo'
import SquareIconedTextBlock from './Dashboard/SquareIconedTextBlock'
import css from './Dashboard/SquareIconedTextBlock.module.css'
import css2 from './DashboardPage.module.css'
import {setDateRangeCreator} from '../../store/dashboard/actions'
import {getDevidedNumberString} from '../../components/vision/info/DevidedNumber'
import {ILayout, LayoutSetup, getLayout, useLayout} from '../../../_metronic/layout/core'
import {config} from 'process'
import {createPortal} from 'react-dom'
import {Toolbar} from '../../../_metronic/layout/components/toolbar/Toolbar'
import {useAppLayout} from '../../../_metronic/layout/core/AppLayoutProvider'
import SbxButton from '../../components/buttons/SbxButtons/SbxButton'
import DashboardNoData from './DashboardNoData'

moment.locale(intl.formatMessage({id: 'SYSTEM.LANGUAGE'}))

let ControlDictionary: 'CustomerCompanys' | 'Accounts' | 'Warehouses' | 'Goods' | null
export type TControlDictionary = typeof ControlDictionary

export interface IControlValue {
  dictionary: TControlDictionary
  message?: string
  path: string
}

interface IToolbarHolderProps {
  container: any
}

const ToolbarHolder: FC<IToolbarHolderProps> = (props) => {
  return createPortal(props.children, props.container)
}

interface IDashboardPageProps {
  emptyItems: IControlValue[]
}

const DashboardPage: FC<IDashboardPageProps> = (props) => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const dashboardStore: IDashboardStorage = useSelector((state: StorageType) => state.dashboard)
  const [period, setPeriod] = useState<IDateRange2>({
    from: moment(new Date()).add('month', -1).hours(0).minutes(1).toDate(),
    to: moment(new Date()).hours(23).minutes(59).toDate(),
  })

  const appLayout = useAppLayout()

  useEffect(() => {
    dispatch(setDateRangeCreator({date_from: period.from, date_to: period.to}))
    if (appLayout) {
      appLayout.setToolbars(
        <DateRangePickerBootstrap
          locale={{
            applyLabel: intl.formatMessage({id: 'ACTION.APPLY'}),
            cancelLabel: intl.formatMessage({id: 'ACTION.CANCEL'}),
            format: 'lll',
          }}
          initDateFrom={period.from}
          initDateTo={period.to}
          withTime
          placeholder={intl.formatMessage({id: 'DASHBOARD.DATERANGE.PLACEHOLDER'})}
          onApply={(event: any, picker: IDateRange) =>
            setPeriod({from: picker.startDate.toDate(), to: picker.endDate.toDate()})
          }
          className='w-100'
        >
          <div className='d-flex flex-row justify-content-start align-items-center w-100'>
            <Button variant='primary' className='fs-7 me-2'>
              {intl.formatMessage({id: 'DASHBOARD.DATERANGE.BUTTON'})}
            </Button>
            <input
              className='w-50 me-2 fs-7 form-control min-w-lg-400px text-center'
              value={`${intl.formatMessage({id: 'DASHBOARD.DATERANGE.TITLE'})}: ${moment(
                period.from
              ).format('LLL')} - ${moment(period.to).format('LLL')}`}
              placeholder={intl.formatMessage({id: 'DASHBOARD.DATERANGE.PLACEHOLDER'})}
            />
          </div>
        </DateRangePickerBootstrap>
      )
    }

    return () => {
      appLayout.setToolbars(<></>)
    }
  }, [period])

  // useEffect(() => {
  //   dispatch(setDateRangeCreator({ date_from: period.from, date_to: period.to }))
  // }, [period])

  return (
    <>
      {dashboardStore.money.nodata && dashboardStore.products.nodata ? (
        <DashboardNoData />
      ) : (
        <>
          <Row>
            <div className='col-lg-6 col-md-12'>
              {props.emptyItems?.length ? (
                <SquareIconedTextBlock className='p-5'>
                  <Logo className='' />
                  <h2 className={`${css.welcomeTitle}`}>
                    {intl.formatMessage({id: 'DASHBOARD.WELCOME.MESSAGE'})}
                  </h2>
                  {props.emptyItems?.length ? (
                    <p className={`mt-3`}>
                      {intl.formatMessage({id: 'DASHBOARD.FOUNDEMPTYDICTIONARIES.MESSAGE'})}
                    </p>
                  ) : (
                    <></>
                  )}
                </SquareIconedTextBlock>
              ) : (
                <DashboardGraphicSummary
                  lang='ru'
                  legendName={`${intl.formatMessage({
                    id: 'DASHBOARD.STATISTICS.MONEY.LEGEND',
                  })},${intl.formatMessage({id: 'DASHBOARD.STATISTICS.MONEY.MEASURE'})}`}
                  className='card-xl-stretch mb-5 mb-xl-8'
                  chartColor='danger'
                  chartHeight='150px'
                  isLoading={dashboardStore?.money?.isLoading ?? false}
                  // title={getDevidedNumberString(dashboardStore.money?.total ?? 0, " ") + intl.formatMessage({ id: "DASHBOARD.STATISTICS.MONEY.MEASURE" })}
                  title={`${intl.formatMessage({id: 'DASHBOARD.STATISTICS.MONEY.LEGEND2'})}`}
                  from={period.from}
                  to={period.to}
                  points={
                    dashboardStore.money?.picks?.map((pick, idx) => {
                      console.log('dashboardStore.money pick, idx', pick, idx, {
                        name: (idx + 1).toString(),
                        value: pick,
                      })
                      return {name: (idx + 1).toString(), value: pick}
                    }) ?? []
                  }
                  // subTitle={`${intl.formatMessage({ id: "DASHBOARD.STATISTICS.MONEY.LEGEND" })} ${intl.formatMessage({ id: "DASHBOARD.STATISTICS.FROM" })} ${moment(period.from).format("DD.MM.YYYY")} ${intl.formatMessage({ id: "DASHBOARD.STATISTICS.UNTIL" })} ${moment(period.to).format("DD.MM.YYYY")}`}
                  subTitle={
                    getDevidedNumberString(dashboardStore.money?.total ?? 0, ' ') +
                    intl.formatMessage({id: 'DASHBOARD.STATISTICS.MONEY.MEASURE'})
                  }
                  subTitleClassNames='fs-1 text-success fw-bold'
                />
              )}
            </div>
            <div className='col-lg-6 col-md-12'>
              {props.emptyItems?.length ? (
                <>
                  <SquareIconedTextBlock className={`p-5 ${css2.danger}`}>
                    <KTSVG disableSvgIconClass={true} path='/media/custom/mecha.svg' />
                    <p className='mt-3'>
                      {intl.formatMessage({id: 'DASHBOARD.FOUNDEMPTYDICTIONARIES.MESSAGE'})}
                    </p>
                  </SquareIconedTextBlock>
                  {props.emptyItems.map((item) => {
                    return (
                      <SquareIconedTextBlock className='p-5'>
                        <NavLink to={item.path}>{item.message}</NavLink>
                      </SquareIconedTextBlock>
                    )
                  })}
                </>
              ) : (
                <DashboardGraphicSummary
                  className='card-xl-stretch mb-5 mb-xl-8'
                  chartColor='primary'
                  chartHeight='150px'
                  lang='ru'
                  legendName={`${intl.formatMessage({
                    id: 'DASHBOARD.STATISTICS.ORDERS.LEGEND',
                  })},${intl.formatMessage({id: 'DASHBOARD.STATISTICS.ORDERS.MEASURE'})}`}
                  isLoading={dashboardStore?.products?.isLoading ?? false}
                  // title={getDevidedNumberString(dashboardStore.products?.total ?? 0, " ") + intl.formatMessage({ id: "DASHBOARD.STATISTICS.ORDERS.MEASURE" })}
                  title={`${intl.formatMessage({id: 'DASHBOARD.STATISTICS.ORDERS.LEGEND2'})}`}
                  from={period.from}
                  to={period.to}
                  points={
                    dashboardStore.products?.picks?.map((pick, idx) => ({
                      name: (idx + 1).toString(),
                      value: pick,
                    })) ?? []
                  }
                  // subTitle={`${intl.formatMessage({ id: "DASHBOARD.STATISTICS.ORDERS.LEGEND" })} ${intl.formatMessage({ id: "DASHBOARD.STATISTICS.FROM" })} ${moment(period.from).format("DD.MM.YYYY")} ${intl.formatMessage({ id: "DASHBOARD.STATISTICS.UNTIL" })} ${moment(period.to).format("DD.MM.YYYY")}`}
                  subTitle={
                    getDevidedNumberString(dashboardStore.products?.total ?? 0, ' ') +
                    intl.formatMessage({id: 'DASHBOARD.STATISTICS.ORDERS.MEASURE'})
                  }
                  subTitleClassNames='fs-1 text-success fw-bold'
                />
              )}
            </div>
          </Row>
          {!props.emptyItems?.length ? (
            <>
              <Row>
                <div className='col-lg-6 col-md-12'>
                  <DashboardGraphicList
                    className='card-xl-stretch mb-5 mb-xl-8'
                    chartColor='info'
                    chartHeight='200px'
                    isLoading={dashboardStore?.moneyStatistics?.marketplace.isLoading ?? false}
                    from={period.from}
                    to={period.to}
                    lang={intl.formatMessage({id: 'SYSTEM.LANGUAGE'})}
                    legendName={`${intl.formatMessage({
                      id: 'DASHBOARD.STATISTICS.MONEY.LEGEND',
                    })},${intl.formatMessage({id: 'DASHBOARD.STATISTICS.MONEY.MEASURE'})}`}
                    title={`${intl.formatMessage({
                      id: 'DASHBOARD.STATISTICS.MONEY.LEGEND',
                    })} ${intl.formatMessage({id: 'DASHBOARD.STATISTICS.BYMARKETPLACE'})}`}
                    // subTitle={`${intl.formatMessage({ id: "DASHBOARD.STATISTICS.FROM" })} ${moment(period.from).format("DD.MM.YYYY")} ${intl.formatMessage({ id: "DASHBOARD.STATISTICS.UNTIL" })} ${moment(period.to).format("DD.MM.YYYY")}`}
                    items={dashboardStore?.moneyStatistics?.marketplace.data ?? []}
                  />
                </div>
                <div className='col-lg-6 col-md-12'>
                  <DashboardGraphicList
                    className='card-xl-stretch mb-5 mb-xl-8'
                    chartColor='info'
                    chartHeight='200px'
                    isLoading={dashboardStore?.orderStatistics?.marketplace.isLoading ?? false}
                    from={period.from}
                    to={period.to}
                    lang={intl.formatMessage({id: 'SYSTEM.LANGUAGE'})}
                    legendName={`${intl.formatMessage({
                      id: 'DASHBOARD.STATISTICS.ORDERS.LEGEND',
                    })},${intl.formatMessage({id: 'DASHBOARD.STATISTICS.ORDERS.MEASURE'})}`}
                    title={`${intl.formatMessage({
                      id: 'DASHBOARD.STATISTICS.ORDERS.LEGEND',
                    })} ${intl.formatMessage({id: 'DASHBOARD.STATISTICS.BYMARKETPLACE'})}`}
                    // subTitle={`${intl.formatMessage({ id: "DASHBOARD.STATISTICS.FROM" })} ${moment(period.from).format("DD.MM.YYYY")} ${intl.formatMessage({ id: "DASHBOARD.STATISTICS.UNTIL" })} ${moment(period.to).format("DD.MM.YYYY")}`}
                    items={dashboardStore?.orderStatistics?.marketplace.data ?? []}
                  />
                </div>
              </Row>
              <Row>
                <div className='col-lg-6 col-md-12'>
                  <DashboardGraphicList
                    className='card-xl-stretch mb-5 mb-xl-8'
                    chartColor='info'
                    chartHeight='600px'
                    isLoading={dashboardStore?.moneyStatistics?.account.isLoading ?? false}
                    from={period.from}
                    to={period.to}
                    lang={intl.formatMessage({id: 'SYSTEM.LANGUAGE'})}
                    legendName={`${intl.formatMessage({
                      id: 'DASHBOARD.STATISTICS.MONEY.LEGEND',
                    })},${intl.formatMessage({id: 'DASHBOARD.STATISTICS.MONEY.MEASURE'})}`}
                    title={`${intl.formatMessage({
                      id: 'DASHBOARD.STATISTICS.MONEY.LEGEND',
                    })} ${intl.formatMessage({id: 'DASHBOARD.STATISTICS.BYACCOUNT'})}`}
                    // subTitle={`${intl.formatMessage({ id: "DASHBOARD.STATISTICS.FROM" })} ${moment(period.from).format("DD.MM.YYYY")} ${intl.formatMessage({ id: "DASHBOARD.STATISTICS.UNTIL" })} ${moment(period.to).format("DD.MM.YYYY")}`}
                    items={dashboardStore?.moneyStatistics?.account.data ?? []}
                  />
                </div>
                <div className='col-lg-6 col-md-12'>
                  <DashboardGraphicList
                    className='card-xl-stretch mb-5 mb-xl-8'
                    chartColor='info'
                    chartHeight='600px'
                    isLoading={dashboardStore?.orderStatistics?.account.isLoading ?? false}
                    from={period.from}
                    to={period.to}
                    lang={intl.formatMessage({id: 'SYSTEM.LANGUAGE'})}
                    legendName={`${intl.formatMessage({
                      id: 'DASHBOARD.STATISTICS.ORDERS.LEGEND',
                    })},${intl.formatMessage({id: 'DASHBOARD.STATISTICS.ORDERS.MEASURE'})}`}
                    title={`${intl.formatMessage({
                      id: 'DASHBOARD.STATISTICS.ORDERS.LEGEND',
                    })} ${intl.formatMessage({id: 'DASHBOARD.STATISTICS.BYACCOUNT'})}`}
                    // subTitle={`${intl.formatMessage({ id: "DASHBOARD.STATISTICS.FROM" })} ${moment(period.from).format("DD.MM.YYYY")} ${intl.formatMessage({ id: "DASHBOARD.STATISTICS.UNTIL" })} ${moment(period.to).format("DD.MM.YYYY")}`}
                    items={dashboardStore?.orderStatistics?.account.data ?? []}
                  />
                </div>
              </Row>
              <Row>
                <div className='col-lg-6 col-md-12'>
                  <DashboardGraphicList
                    className='card-xl-stretch mb-5 mb-xl-8'
                    chartColor='info'
                    chartHeight='200px'
                    isLoading={dashboardStore?.moneyStatistics?.company.isLoading ?? false}
                    from={period.from}
                    to={period.to}
                    lang={intl.formatMessage({id: 'SYSTEM.LANGUAGE'})}
                    legendName={`${intl.formatMessage({
                      id: 'DASHBOARD.STATISTICS.MONEY.LEGEND',
                    })},${intl.formatMessage({id: 'DASHBOARD.STATISTICS.MONEY.MEASURE'})}`}
                    title={`${intl.formatMessage({
                      id: 'DASHBOARD.STATISTICS.MONEY.LEGEND',
                    })} ${intl.formatMessage({id: 'DASHBOARD.STATISTICS.BYCOMPANY'})}`}
                    // subTitle={`${intl.formatMessage({ id: "DASHBOARD.STATISTICS.FROM" })} ${moment(period.from).format("DD.MM.YYYY")} ${intl.formatMessage({ id: "DASHBOARD.STATISTICS.UNTIL" })} ${moment(period.to).format("DD.MM.YYYY")}`}
                    items={dashboardStore?.moneyStatistics?.company.data ?? []}
                  />
                </div>
                <div className='col-lg-6 col-md-12'>
                  <DashboardGraphicList
                    className='card-xl-stretch mb-5 mb-xl-8'
                    chartColor='info'
                    chartHeight='200px'
                    isLoading={dashboardStore?.orderStatistics?.company.isLoading ?? false}
                    from={period.from}
                    to={period.to}
                    lang={intl.formatMessage({id: 'SYSTEM.LANGUAGE'})}
                    legendName={`${intl.formatMessage({
                      id: 'DASHBOARD.STATISTICS.ORDERS.LEGEND',
                    })},${intl.formatMessage({id: 'DASHBOARD.STATISTICS.ORDERS.MEASURE'})}`}
                    title={`${intl.formatMessage({
                      id: 'DASHBOARD.STATISTICS.ORDERS.LEGEND',
                    })} ${intl.formatMessage({id: 'DASHBOARD.STATISTICS.BYCOMPANY'})}`}
                    // subTitle={`${intl.formatMessage({ id: "DASHBOARD.STATISTICS.FROM" })} ${moment(period.from).format("DD.MM.YYYY")} ${intl.formatMessage({ id: "DASHBOARD.STATISTICS.UNTIL" })} ${moment(period.to).format("DD.MM.YYYY")}`}
                    items={dashboardStore?.orderStatistics?.company.data ?? []}
                  />
                </div>
              </Row>
            </>
          ) : null}
        </>
      )}
    </>
  )
}

export default DashboardPage
