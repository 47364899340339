import axios from 'axios'
import {IAccount} from './types'
import {ITestFieldsToSend} from '../marketplaces/types'

const API_URL = `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENDPOINT_PREFIX}`
export const ACCOUNTS_URL = `${API_URL}v2/accounts/`

export const fetchAccounts = () => {
  return axios.get(ACCOUNTS_URL)
}

export const updateAccountOnServer = (params: IAccount) => {
  return axios.put<IAccount>(`${ACCOUNTS_URL}${params.id}/`, params)
}

export const deleteAccountFromServer = (params: number) => {
  return axios.delete(`${ACCOUNTS_URL}${params}/`)
}

export const addAccountToServer = (params: IAccount[]) => {
  let pars: any = params.map((p) => {
    const {id, ...otherProps} = p

    return otherProps
  })

  return axios.post<IAccount>(ACCOUNTS_URL, pars)
}

export const testAccountConnection = async (params: ITestFieldsToSend) => {
  // return axios.post(`${ACCOUNTS_URL}test_account_api/${connectionParams.length ? `?${connectionParams.reduce((acc, cur) => acc.length ? acc + '&' + cur.parameterName + '=' + cur.value : cur.parameterName + '=' + cur.value,'')}` : ''}`)
  // Object.assign({}, connectionParams.map(cp => ({[cp.parameterName]: cp.value})))
  return await axios.post<{success: boolean}>(`${ACCOUNTS_URL}test_account_api/`, {
    marketplace_id: params.marketplaceId,
    ...params.connectionParams.reduce(
      (resObj, curItem) => ({...resObj, [curItem.parameterName]: curItem.value}),
      {}
    ),
  })
  // .then(data => {
  //     return data.data.success
  // })
  // .catch(data => {
  //     return false
  // })
}
