import { call, put, select, takeEvery } from "redux-saga/effects"
import { copyGoodTradeOfferCard, deleteGoodTradeOffers, fetchGood, fetchGoods, getGoodTradeOffers, IGoodTradeOfferCreateCardGetParams, IGoodTradeOfferCreateCardResponse, IGoodTradeOfferMarketplaceGetParams, IGoodTradeOffersGetParams, saveGood, saveGoodCards, saveGoodTradeOffer } from "./api";
import { addGood, addGoodTradeOffer, deleteGoodTradeOffer, setCount, setGoods, setGoodTradeOffer, setGoodTradeOffers, setIsLoading, setIsTradeOffersLoading, setOzonCard, setSelectedGood, setWbCard } from "./reducers"
import {
    ASYNC_GET_GOODS, ASYNC_GET_GOOD, ASYNC_GET_GOOD_TRADEOFFER, SET_GOODS_IS_LOADING, SET_OZON_CARD, SET_WB_CARD, ASYNC_ADD_GOOD,
    SET_EMPTY_GOOD, ASYNC_UPDATE_GOOD, ASYNC_ADD_GOOD_TRADEOFFER, ASYNC_UPDATE_GOOD_TRADEOFFER, COPY_GOOD_CARD, ASYNC_UPDATE_GOOD_CARDS, ASYNC_DELETE_GOOD_TRADEOFFER, ASYNC_UPDATE_GOOD_AND_CARDS, ASYNC_GET_GOOD_TRADEOFFER_FILTERED, ASYNC_UPDATE_GOOD_NOT_CARDS, ASYNC_GET_GOODS_WITH_FILTER,
    //    ASYNC_GET_GOOD_TRADEOFFERS 
} from "./actions"
import { CURRENT_PAGE_DEFAULT, EMPTY_GOOD, IGoodsGetParams, IGoodsWithFilterRequestParams, ITEMS_PER_PAGE_DEFAULT, ORDERING_DEFAULT, SEARCH_DEFAULT } from "./types";
import { PayloadAction } from "@reduxjs/toolkit";
import { addTradeOfferMpCard } from "../mpcards";
import { sagaUtils } from "../../utils/dataUtils";
import { intl } from "../../modules/goods/item/validation";
import { ActionWithPayload } from "../auth/AuthRedux";



export function* goodSaga() {
    yield takeEvery(ASYNC_GET_GOODS, workerGetGoods)
    yield takeEvery(ASYNC_GET_GOODS_WITH_FILTER, workerGetGoodsWithFilter)
    yield takeEvery(ASYNC_GET_GOOD, workerGetGood)
    yield takeEvery(ASYNC_ADD_GOOD, workerAddGood)
    yield takeEvery(ASYNC_UPDATE_GOOD, workerUpdateGood)
    yield takeEvery(ASYNC_UPDATE_GOOD_CARDS, workerUpdateGoodCards)
    yield takeEvery(ASYNC_UPDATE_GOOD_AND_CARDS, workerUpdateGoodAndCards)
    yield takeEvery(ASYNC_UPDATE_GOOD_NOT_CARDS, workerUpdateGoodNotCards)

    //yield takeEvery(ASYNC_GET_GOOD_TRADEOFFERS, workerGetGoodTradeOffers)
    yield takeEvery(ASYNC_GET_GOOD_TRADEOFFER, workerGetGoodTradeOffer)
    yield takeEvery(ASYNC_GET_GOOD_TRADEOFFER_FILTERED, workerGetGoodTradeOfferFiltered)
    yield takeEvery(ASYNC_ADD_GOOD_TRADEOFFER, workerAddGoodTradeOffer)
    yield takeEvery(ASYNC_UPDATE_GOOD_TRADEOFFER, workerUpdateGoodTradeOffer)
    yield takeEvery(ASYNC_DELETE_GOOD_TRADEOFFER, workerDeleteGoodTradeOffer)

    yield takeEvery(SET_GOODS_IS_LOADING, workerSetLoading)
    yield takeEvery(SET_EMPTY_GOOD, workerSetEmptyGood)

    yield takeEvery(SET_OZON_CARD, workerSetOzonCard)
    yield takeEvery(SET_WB_CARD, workerSetWbCard)

    yield takeEvery(COPY_GOOD_CARD, workerCopyGoodOfferCard)
}

// get goods like in filter
export function* workerGetGoods(): any {
    const state = yield select()
    const request: IGoodsGetParams = {
        items: state.goods.itemsPerPage ?? ITEMS_PER_PAGE_DEFAULT,
        page: state.goods.currentPage ?? CURRENT_PAGE_DEFAULT,
        order: state.goods.ordering ?? ORDERING_DEFAULT,
        search: state.goods.search ?? SEARCH_DEFAULT,
    }

    yield sagaUtils.workerWrapper(setIsLoading,
        fetchGoods, request,
        setGoods, null,
        intl.formatMessage({ id: "SAGAS.GOODS.GET" }), "GET", "data.results", false, "",
        undefined, { action: setCount, resultType: "data", resultTypePropertyName: "count", params: null })
}

// get goods like in filter
export function* workerGetGoodsWithFilter(action: ActionWithPayload<IGoodsWithFilterRequestParams>): any {
    // const state = yield select()
    const request: IGoodsGetParams = {
        items: ITEMS_PER_PAGE_DEFAULT,
        page: CURRENT_PAGE_DEFAULT,
        order: ORDERING_DEFAULT,
        search: action.payload?.filter ?? SEARCH_DEFAULT,
    }

    yield sagaUtils.workerWrapper(setIsLoading,
        fetchGoods, request,
        setGoods, null,
        intl.formatMessage({ id: "SAGAS.GOODS.GET" }), "GET", "data.results", false, "",
        undefined, { action: setCount, resultType: "data", resultTypePropertyName: "count", params: null })
}

// copy good offer
export function* workerCopyGoodOfferCard(action: any): any {

    yield sagaUtils.workerWrapperCombinedReducerRequest(setIsLoading,
        copyGoodTradeOfferCard, action.payload,
        addTradeOfferMpCard, null, action.payload.offerid,
        intl.formatMessage({ id: "SAGAS.GOODS.CARD.COPY" }), "COPY", "data.result", true, intl.formatMessage({ id: "SAGAS.COPY.SUCCESS" }))


}

// get good and tradeoffers by good id
export function* workerGetGood(action: any): any {

    try {
        yield put(setIsLoading(true))

        const serverData = yield sagaUtils.workerWrapper(null,
            fetchGood, action.payload,
            setSelectedGood, null,
            intl.formatMessage({ id: "SAGAS.GOODS.GOOD.GET" }), "GET", "data", false)

        yield sagaUtils.workerWrapper(null,
            getGoodTradeOffers, { good_id: serverData.id },
            setGoodTradeOffers, null,
            intl.formatMessage({ id: "SAGAS.GOODS.GOOD.GET" }), "GET", "data.results", false)
    }
    finally {
        yield put(setIsLoading(false))
    }

}

// get tradeoffers by good id
export function* workerGetGoodTradeOffer(action: any): any {
    yield sagaUtils.workerWrapper(setIsTradeOffersLoading,
        getGoodTradeOffers, { good_id: action.payload },
        setGoodTradeOffers, null,
        intl.formatMessage({ id: "SAGAS.GOODS.GOOD.GET" }), "GET", "data.results", false)

}

// get tradeoffers by good id
export function* workerGetGoodTradeOfferFiltered(action: any): any {
    yield sagaUtils.workerWrapper(setIsTradeOffersLoading,
        getGoodTradeOffers, { good_id: action.payload.goodId, search: action.payload?.filter ?? "" },
        setGoodTradeOffers, null,
        intl.formatMessage({ id: "SAGAS.GOODS.GOOD.GET" }), "GET", "data.results", false)

}

// add good
export function* workerAddGood(action: any): any {
    yield sagaUtils.workerWrapper(setIsLoading,
        saveGood, { params: action.payload, add: true },
        addGood, null,
        intl.formatMessage({ id: "SAGAS.GOODS.GOOD.ADD" }), "ADD", "data.result", true)
}

export function* workerUpdateGood(action: any): any {

    try {
        yield put(setIsLoading(true))

        const serverData = yield sagaUtils.workerWrapper(null,
            saveGood, action.payload,
            setSelectedGood, null,
            intl.formatMessage({ id: "SAGAS.GOODS.GOOD.UPDATE" }), "SAVE", "data", true, "")

        yield sagaUtils.workerWrapper(null,
            getGoodTradeOffers, { good_id: serverData.id },
            setGoodTradeOffers, null,
            intl.formatMessage({ id: "SAGAS.GOODS.GOOD.GET" }), "GET", "data.results", false)
    }
    finally {
        yield put(setIsLoading(false))
    }
}

// update good, not update mp cards
export function* workerUpdateGoodNotCards(action: any): any {

    yield call(() => workerUpdateGood({
        payload: {
            params: action.payload, add: false
        }
    }))
}

// update universal cards of good
export function* workerUpdateGoodCards(action: any): any {

    try {
        yield put(setIsLoading(true))

        const serverData = yield sagaUtils.workerWrapper(null,
            saveGoodCards, action.payload,
            setSelectedGood, null,
            intl.formatMessage({ id: "SAGAS.GOODS.TRADEOFFERS.UPDATE" }), "SAVE", "data.result", true, "")

        yield sagaUtils.workerWrapper(null,
            getGoodTradeOffers, { good_id: serverData.id },
            setGoodTradeOffers, null,
            intl.formatMessage({ id: "SAGAS.GOODS.TRADEOFFERS.GET" }), "GET", "data.results", false)
    }
    finally {
        yield put(setIsLoading(false))
    }

}

// update universal cards of good
export function* workerUpdateGoodAndCards(action: any): any {  // {payload: IGoodAndCardsSaveRequest}

    yield call(() => workerUpdateGood({
        payload: {
            params: action.payload.good, add: false
        }
    }))
    yield call(() => workerUpdateGoodCards({
        payload: {
            cards: action.payload.cards,
            goodId: action.payload.good.id
        }
    }))

}


/// disabled. make it in get good
export function* workerGetGoodTradeOffers(action: PayloadAction<IGoodTradeOffersGetParams>): any {

    yield sagaUtils.workerWrapper(setIsTradeOffersLoading,
        getGoodTradeOffers, action.payload,
        setGoodTradeOffers, null,
        intl.formatMessage({ id: "SAGAS.GOODS.TRADEOFFERS.GET" }), "GET", "data.results", false, "")


}

export function* workerAddGoodTradeOffer(action: PayloadAction<IGoodTradeOfferMarketplaceGetParams>): any {
    try {
        yield put(setIsTradeOffersLoading(true))

        yield sagaUtils.workerWrapper(null,
            saveGoodTradeOffer, action.payload,
            addGoodTradeOffer, null,
            intl.formatMessage({ id: "SAGAS.GOODS.GOOD.UPDATE" }), "SAVE", "data.result", true, "")

        yield sagaUtils.workerWrapper(null,
            getGoodTradeOffers, { good_id: action.payload.offerData.good },
            setGoodTradeOffers, null,
            intl.formatMessage({ id: "SAGAS.GOODS.GOOD.GET" }), "GET", "data.results", false)
    }
    finally {
        yield put(setIsTradeOffersLoading(false))
    }
}


export function* workerUpdateGoodTradeOffer(action: any): any {
    try {
        yield put(setIsTradeOffersLoading(true))
        yield sagaUtils.workerWrapper(null,
            saveGoodTradeOffer, action.payload,
            setGoodTradeOffer, null,
            intl.formatMessage({ id: "SAGAS.GOODS.GOOD.UPDATE" }), "SAVE", "data", true, "")

        yield sagaUtils.workerWrapper(null,
            getGoodTradeOffers, { good_id: action.payload.offerData.good },
            setGoodTradeOffers, null,
            intl.formatMessage({ id: "SAGAS.GOODS.GOOD.GET" }), "GET", "data.results", false)

    }
    finally {
        yield put(setIsTradeOffersLoading(false))
    }


}

export function* workerDeleteGoodTradeOffer(action: any): any {
    yield sagaUtils.workerWrapper(setIsLoading,
        deleteGoodTradeOffers, action.payload,
        deleteGoodTradeOffer, action.payload,
        intl.formatMessage({ id: "SAGAS.GOODS.GOOD.DELETE" }), "DELETE", "direct", true, "")

}

export function* workerSetLoading(action: any): any {
    yield put(setIsLoading(action.payload))
}

export function* workerSetEmptyGood(): any {
    //console.log('saga clearSelectedGood set', EMPTY_GOOD)
    yield put(setSelectedGood(EMPTY_GOOD))
}

export function* workerSetOzonCard(action: any): any {
    yield put(setOzonCard(action.payload))
}

export function* workerSetWbCard(action: any): any {
    yield put(setWbCard(action.payload))
}