import {put, takeEvery} from 'redux-saga/effects'
import {
  addAccountToServer,
  deleteAccountFromServer,
  fetchAccounts,
  updateAccountOnServer,
} from './api'
import {
  addAccount,
  deleteAccount,
  setAccount,
  setAccounts,
  setIsLoading,
  setIsSuccessLastOperation,
} from './reducers'
import {
  ASYNC_GET_ACCOUNTS,
  ASYNC_ADD_ACCOUNT,
  ASYNC_UPDATE_ACCOUNT,
  ASYNC_DELETE_ACCOUNT,
  SET_IS_LOADING_ACCOUNTS,
  ADD_ACCOUNT,
  SET_ACCOUNT,
} from './actions'
import {intl} from '../../modules/goods/item/validation'
import {sagaUtils} from '../../utils/dataUtils'

export function* accountSaga() {
  yield takeEvery(ASYNC_GET_ACCOUNTS, workerGetAccount)
  yield takeEvery(ASYNC_ADD_ACCOUNT, workerAddAccount)
  yield takeEvery(ADD_ACCOUNT, workerAddAccountNoApi)
  yield takeEvery(ASYNC_UPDATE_ACCOUNT, workerUpdateAccount)
  yield takeEvery(SET_ACCOUNT, workerSetAccount)
  yield takeEvery(ASYNC_DELETE_ACCOUNT, workerDeleteAccount)
  yield takeEvery(SET_IS_LOADING_ACCOUNTS, workerSetIsLoading)
}

// get all accounts from server
export function* workerGetAccount(): any {
  yield sagaUtils.workerWrapper(
    setIsLoading,
    fetchAccounts,
    null,
    setAccounts,
    null,
    intl.formatMessage({id: 'SAGAS.ACCOUNTS.GET'}),
    'GET',
    'data.results',
    false
  )
}

// set is loading flag
export function* workerSetIsLoading(action: any): any {
  yield put(setIsLoading(action.payload))
}

// update account on server
export function* workerUpdateAccount(action: any): any {
  yield sagaUtils.workerWrapper(
    setIsLoading,
    updateAccountOnServer,
    action.payload,
    setAccount,
    null,
    intl.formatMessage({id: 'SAGAS.ACCOUNTS.UPDATE.ACCOUNT'}),
    'SAVE',
    'data',
    true,
    '',
    {action: setIsSuccessLastOperation, params: false},
    {action: setIsSuccessLastOperation, params: true},
    {action: setIsSuccessLastOperation, params: false},
    {action: setIsSuccessLastOperation, params: false}
  )
}

// update account in redux
export function* workerSetAccount(action: any): any {
  yield put(setAccount(action.payload))
  yield sagaUtils.workerWrapper(
    setIsLoading,
    updateAccountOnServer,
    action.payload,
    setAccount,
    null,
    intl.formatMessage({id: 'SAGAS.ACCOUNTS.UPDATE.ACCOUNT'}),
    'SAVE',
    'data',
    true,
    '',
    {action: setIsSuccessLastOperation, params: false},
    {action: setIsSuccessLastOperation, params: true},
    {action: setIsSuccessLastOperation, params: false},
    {action: setIsSuccessLastOperation, params: false}
  )
}

// delete account by account id
export function* workerDeleteAccount(action: any): any {
  yield sagaUtils.workerWrapper(
    setIsLoading,
    deleteAccountFromServer,
    action.payload,
    deleteAccount,
    action.payload,
    intl.formatMessage({id: 'SAGAS.ACCOUNTS.DELETE.ACCOUNT'}),
    'DELETE',
    'direct',
    true,
    '',
    {action: setIsSuccessLastOperation, params: false},
    {action: setIsSuccessLastOperation, params: true},
    {action: setIsSuccessLastOperation, params: false},
    {action: setIsSuccessLastOperation, params: false}
  )
}

// add new account with saving it on server
export function* workerAddAccount(action: any): any {
  yield sagaUtils.workerWrapper(
    setIsLoading,
    addAccountToServer,
    action.payload,
    addAccount,
    null,
    intl.formatMessage({id: 'SAGAS.ACCOUNTS.ADD.ACCOUNT'}),
    'ADD',
    'data',
    true,
    `${intl.formatMessage({id: 'SAGAS.ACCOUNTS.ACCOUNT'})} ${
      action.payload.name
    } ${intl.formatMessage({id: 'SAGAS.ADD.SUCCESS'})}`,
    {action: setIsSuccessLastOperation, params: false},
    {action: setIsSuccessLastOperation, params: true},
    {action: setIsSuccessLastOperation, params: false},
    {action: setIsSuccessLastOperation, params: false}
  )
}

// add new account to storage
export function* workerAddAccountNoApi(action: any): any {
  yield put(addAccount(action.payload))
  // yield sagaUtils.workerWrapper(setIsLoading,
  //     null, null,
  //     addAccount, action.payload,
  //     intl.formatMessage({ id: "SAGAS.ACCOUNTS.ADD.ACCOUNT" }), "ADD", "data", true,
  //     `${intl.formatMessage({ id: "SAGAS.ACCOUNTS.ACCOUNT" })} ${action.payload.name} ${intl.formatMessage({ id: "SAGAS.ADD.SUCCESS" })}`,
  //     { action: setIsSuccessLastOperation, params: false },
  //     { action: setIsSuccessLastOperation, params: true },
  //     { action: setIsSuccessLastOperation, params: false },
  //     { action: setIsSuccessLastOperation, params: false }
  // )
}
