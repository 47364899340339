import { put, takeEvery } from "redux-saga/effects"
import { addCompetitorGoodToServer, checkUrlCompetitorGoodFromServer, delCompetitorGoodFromServer, editCompetitorGoodToServer, fetchCompetitorGoods, fetchCompetitors, forceUpdateCompetitorGoodFromServer } from "./api";
import { setCompetitorGoods, setCompetitors, addCompetitorGood, delCompetitorGood, setCompetitorGood, setCompetitorGoodIsLoading, setIsLoading } from "./reducers"
import { ASYNC_ADD_COMPETITOR_GOOD, ASYNC_CHECK_COMPETITOR_GOOD_URL, ASYNC_DEL_COMPETITOR_GOOD, ASYNC_EDIT_COMPETITOR_GOOD, ASYNC_FORCE_UPDATE_COMPETITOR_GOOD, ASYNC_GET_COMPETITORS, ASYNC_GET_COMPETITOR_GOODS } from "./actions"
import { intl } from "../../modules/goods/item/validation";
import { sagaUtils } from "../../utils/dataUtils";
import { ActionWithPayload } from "../auth/AuthRedux";



export function* competitorSaga(){
    yield takeEvery(ASYNC_GET_COMPETITORS, workerGetCompetitors)
    yield takeEvery(ASYNC_GET_COMPETITOR_GOODS, workerGetCompetitorGoods)
    yield takeEvery(ASYNC_ADD_COMPETITOR_GOOD, workerAddCompetitorGood)
    yield takeEvery(ASYNC_DEL_COMPETITOR_GOOD, workerDelCompetitorGood)
    yield takeEvery(ASYNC_EDIT_COMPETITOR_GOOD, workerEditCompetitorGood)
    yield takeEvery(ASYNC_FORCE_UPDATE_COMPETITOR_GOOD, workerForceUpdateCompetitorGood)
    yield takeEvery(ASYNC_CHECK_COMPETITOR_GOOD_URL, workerCheckCompetitorGoodUrl)
}

// get competitors list and set it to redux
export function* workerGetCompetitors():any{
    yield sagaUtils.workerWrapper(setIsLoading, fetchCompetitors, null, setCompetitors, null, 
        intl.formatMessage({id: "SAGAS.COMPETITORS.GET"}), "GET", "data.results", false)
}

// gets goods of competitor and sets it to redux
export function* workerGetCompetitorGoods(action: any):any{
    yield sagaUtils.workerWrapper(setIsLoading, fetchCompetitorGoods, action.payload, setCompetitorGoods, null, 
        intl.formatMessage({id: "SAGAS.COMPETITORGOODS.GET"}), "GET", "data.results", false)
}

// delete competitor's good from server and from redux
export function* workerDelCompetitorGood(action: any):any{
    yield sagaUtils.workerWrapper(setIsLoading, delCompetitorGoodFromServer, action.payload, delCompetitorGood, action.payload, 
        intl.formatMessage({id: "SAGAS.COMPETITORGOODS.DELETE"}), "DELETE", "direct", true)
}

// add competitor's good to server and to redux
export function* workerAddCompetitorGood(action: any): any {
    yield sagaUtils.workerWrapper(setIsLoading, 
        addCompetitorGoodToServer, action.payload, 
        addCompetitorGood, null, 
        intl.formatMessage({id: "SAGAS.COMPETITORGOODS.ADD"}), "ADD", "data", true)
}

// update competitors good
export function* workerEditCompetitorGood(action: any):any{
    yield sagaUtils.workerWrapper(setIsLoading, 
        editCompetitorGoodToServer, action.payload, 
        setCompetitorGood, null, 
        intl.formatMessage({id: "SAGAS.COMPETITORGOODS.UPDATE"}), "SAVE", "data", true)
}

// check url of competitors good
export function* workerCheckCompetitorGoodUrl(action: ActionWithPayload<string>):any{
    yield sagaUtils.workerWrapper(setIsLoading, 
        checkUrlCompetitorGoodFromServer, action.payload, 
        null, null, 
        intl.formatMessage({id: "SAGAS.COMPETITORGOODS.UPDATE"}), "CHECK", "data.result", false)
}

// get price of competitor's good from server
export function* workerForceUpdateCompetitorGood(action: any):any{
    // set good is loading on
    yield put(setCompetitorGoodIsLoading({id: action.payload, isLoading: true}))

    yield sagaUtils.workerWrapper(setIsLoading, 
        forceUpdateCompetitorGoodFromServer, action.payload, 
        setCompetitorGood, null, 
        intl.formatMessage({id: "SAGAS.COMPETITORGOODS.PRICEUPDATE"}), "REFRESH", "data.result", true)

    // set good is loading off
    yield put(setCompetitorGoodIsLoading({id: action.payload, isLoading: false}))
}