import { call, put, takeEvery } from "redux-saga/effects"
import { fetchImages } from "./api";
import { setImages } from "./reducers"
import { ASYNC_GET_IMAGES } from "./actions"



export function* imageSaga(){
    yield takeEvery(ASYNC_GET_IMAGES, workerGetImages)
}


export function* workerGetImages():any{
    const imagesPromise = yield call(fetchImages)
    yield put(setImages(imagesPromise.data.results))
}