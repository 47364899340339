import {useState, ChangeEvent} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {
  getCaptchaAction,
  postCaptchaAction,
  postCodePhoneAction,
} from '../../../../../store/mpWizard/actions'

import PhoneInput from 'react-phone-input-2'
import {Button, Form} from 'react-bootstrap'

import {StorageType} from '../../../../../../setup'
import {IWizardState, CaptchaStatus} from '../../../../../store/mpWizard/types'

type TStepData = {
  phone: string
  captchaCode: string
  phoneCode: string
}

enum Action {
  getCaptcha = 'getCaptcha',
  postCaptcha = 'postCaptcha',
  postCodePhone = 'postCodePhone',
}

// name(pin):"Радаев Александр Сергеевич"
// inn(pin):"440119111674"
// kpp(pin):""
// bic(pin):"044525411"
// ogrn(pin):""

// TODO: вынести в отдельные компоненты вводы кода и каптчи
const WBStep2 = () => {
  const [stepData, setStepData] = useState<TStepData>({
    phone: '',
    captchaCode: '',
    phoneCode: '',
  })

  const {isLoading, data}: IWizardState = useSelector((storage: StorageType) => storage.mpWizard)

  const captcha = data['step1']?.captcha
  const captchaStatus = data['step1']?.captchaStatus

  const dispatch = useDispatch()

  const onChangeInput = (e: ChangeEvent<HTMLInputElement>, inputValue: string = '') => {
    const {value, name} = e.target

    if (name === 'phone') {
      setStepData((prev) => ({...prev, [name]: inputValue}))
      return
    }

    setStepData((prev) => ({...prev, [name]: value}))
  }

  const onClick = (action: Action) => () => {
    if (action === Action.getCaptcha) {
      dispatch(getCaptchaAction(stepData.phone))
    } else if (action === Action.postCaptcha) {
      dispatch(postCaptchaAction(stepData.captchaCode))
    } else if (action === Action.postCodePhone) {
      dispatch(postCodePhoneAction(stepData.phoneCode))
    }
  }

  const getAction = () => {
    if (
      captcha &&
      (captchaStatus === CaptchaStatus.ERROR || captchaStatus === CaptchaStatus.WAITING)
    ) {
      return Action.postCaptcha
    } else if (captchaStatus === 'success') {
      return Action.postCodePhone
    } else {
      return Action.getCaptcha
    }
  }

  return (
    <div>
      <p className='fs-5 mb-9'>
        Для продолжения авторизации, пожалуйста, введите номер телефона, привязанный к вашему
        личному кабинету <span className='fw-bold'>Wildberries</span>. Это необходимо для
        подтверждения вашей личности и безопасного доступа к вашему аккаунту через наш сервис.
      </p>
      <PhoneInput
        inputProps={{
          name: 'phone',
        }}
        disabled={captcha || captchaStatus}
        containerClass='mb-8'
        country='ru'
        inputClass='form-control form-control-lg form-control-solid'
        onChange={(value, data, event) => onChangeInput(event, value)}
        regions={['ex-ussr']}
        isValid={(value) => {
          if (value.length < 11) {
            return 'Минимум 11 символов (включая знак +)'
          } else {
            return true
          }
        }}
      />

      {captcha &&
        (captchaStatus === CaptchaStatus.ERROR || captchaStatus === CaptchaStatus.WAITING) && (
          <div>
            <p className='fs-5'>Введите код с картинки</p>
            <img src={captcha} alt='captcha' className='mb-5' />
            <Form>
              <Form.Control
                value={stepData.captchaCode}
                name='captchaCode'
                className='form-control form-control-lg form-control-solid w-25 mb-5'
                onChange={onChangeInput}
              />
            </Form>
            {captchaStatus === CaptchaStatus.ERROR && (
              <p className='text-danger'>Каптча не прошла проверку</p>
            )}
          </div>
        )}

      {captchaStatus === CaptchaStatus.SUCCESS && (
        <div>
          <p className='fs-5'>
            Пожалуйста, введите код из SMS, который был отправлен на ваш номер телефона.
          </p>
          <Form>
            <Form.Control
              value={stepData.phoneCode}
              name='phoneCode'
              placeholder='Код из SMS'
              className='form-control form-control-lg form-control-solid w-25 mb-5'
              onChange={onChangeInput}
            />
          </Form>
        </div>
      )}

      <Button
        className='fs-6'
        onClick={onClick(getAction())}
        disabled={stepData.phone.length < 11 || isLoading}
      >
        Далее
      </Button>
    </div>
  )
}

export default WBStep2
