export const initialOrderStatus: IOrderStatusState = {
    count: 0,
    next: "",
    previous: "",
    items: [],
}

export interface IOrderStatus {
    id: number
    name: string
    params?: any
    code: string
    manual_change: boolean
}

export const emptyOrderStatus: IOrderStatus = {
    id: -1,
    name: "",
    code: "",
    manual_change: false,
}


export interface IOrderStatusState{
    count: number
    next: string
    previous: string
    items: IOrderStatus[]
}


export const orderStatusActionTypes = {
    GetOrderStatuses: '[GetOrderStatuses] Action',
  }