import {call, put, takeLatest, takeEvery} from 'redux-saga/effects'
import {IPriceTypeIF} from './types'
import {AxiosResponse} from 'axios'
import {
  setPriceTypes,
  setIsLoading,
  addPriceType,
  deletePriceType,
  updatePriceType,
} from './reducer'
import {
  GET_ASYNC_PRICE_TYPES,
  ADD_ASYNC_PRICE_TYPE,
  DELETE_ASYNC_PRICE_TYPE,
  UPDATE_ASYNC_PRICE_TYPE,
} from './actions'
import {
  getPriceTypesFromServer,
  addPriceTypeToServer,
  deletePriceTypeFromServer,
  updatePriceTypeOnServer,
} from './api'
import {sagaUtils} from '../../utils/dataUtils'
import {intl} from '../../modules/goods/item/validation'

export function* priceTypesSaga() {
  yield takeEvery(GET_ASYNC_PRICE_TYPES, workerGetPriceTypes)
  yield takeEvery(ADD_ASYNC_PRICE_TYPE, workerAddPriceType)
  yield takeEvery(DELETE_ASYNC_PRICE_TYPE, workerDeleteWarehouse)
  yield takeEvery(UPDATE_ASYNC_PRICE_TYPE, workerUpdateWarehouse)
}

export function* workerGetPriceTypes(action: any): any {
  yield sagaUtils.workerWrapper(
    setIsLoading,
    getPriceTypesFromServer,
    action.payload,
    setPriceTypes,
    null,
    intl.formatMessage({id: 'SAGAS.PRICETYPES.GET'}),
    'GET',
    'data',
    false,
    ''
  )
}

export function* workerAddPriceType(action: any): any {
  yield sagaUtils.workerWrapper(
    setIsLoading,
    addPriceTypeToServer,
    action.payload,
    addPriceType,
    null,
    intl.formatMessage({id: 'SAGAS.PRICETYPES.ADD'}),
    'ADD',
    'data',
    true,
    ''
  )
}

export function* workerDeleteWarehouse(action: any): any {
  yield sagaUtils.workerWrapper(
    setIsLoading,
    deletePriceTypeFromServer,
    action.payload,
    deletePriceType,
    action.payload,
    intl.formatMessage({id: 'SAGAS.PRICETYPES.DELETE'}),
    'DELETE',
    'direct',
    true,
    ''
  )
}

export function* workerUpdateWarehouse(action: any): any {
  yield sagaUtils.workerWrapper(
    setIsLoading,
    updatePriceTypeOnServer,
    action.payload,
    updatePriceType,
    action.payload,
    intl.formatMessage({id: 'SAGAS.PRICETYPES.SAVE'}),
    'SAVE',
    'data',
    true,
    ''
  )
}
