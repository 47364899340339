/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { lazy, useEffect, useRef, useState } from 'react'
import ApexCharts, { ApexOptions } from 'apexcharts'
import { KTSVG } from '../../../helpers'
import { getCSSVariableValue } from '../../../assets/ts/_utils'
import DevidedNumber from '../../../../app/components/vision/info/DevidedNumber'
import Loader2 from '../../../../app/components/vision/loaders/Loader2'
import { Col, Row } from 'react-bootstrap'
import WidgetDropdown from '../../../../app/pages/dashboard/components/Menu/Dropdown/WidgetDropdown'
import 'moment/locale/ru'
import moment from 'moment'
import fr from 'apexcharts/dist/locales/fr.json'
const defaultLang = 'fr'

interface IDashboardGraphicSummaryItem {
  title?: string
  num?: number
  preText?: string // text before num
  postText?: string // text after num
  arrow?: 'up' | 'down'
}

interface IGraphicPoint{
  name: string,
  value: number
}

type IDashboardGraphicSummaryProps = {
  chartHeight: string
  chartColor: string
  className?: string
  isLoading?: boolean

  from: Date
  to: Date
  widgetControls?: any
  widgetTitle?: string
  title?: string
  subTitle?: string
  subTitleClassNames?: string
  items?: IDashboardGraphicSummaryItem[] // list of additional data between title and graphic
  points: IGraphicPoint[] // graphic points (x,y)

  lang: string
  legendName: string
}

const DashboardGraphicSummary: React.FC<IDashboardGraphicSummaryProps> = (props) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const [locales, setLocales] = useState<{locales: any, defaultLocale: string} | null>(null)

  
  useEffect(() => {
    if (!chartRef.current) {
      return
    }
    
    const importLocals = async (lang: string) => {
      const imports = await import(`apexcharts/dist/locales/${lang}.json`)
      setLocales({locales: [imports], defaultLocale: lang})
    } 

    if (!locales)
      importLocals(props.lang)
    
    let cOptions = chartOptions(
      props.chartHeight, 
      props.chartColor, 
      props.points,
      props.from, 
      props.to, 
      props.legendName, 
      locales?.locales ? locales.locales : undefined , 
      locales?.locales ? props.lang : undefined
      )
      // cOptions.xaxis = {
      //   type: 'datetime',
      //   range: 86400000*2, // 1 день в миллисекундах
      //   labels: {
      //     datetimeFormatter: {
      //       year: 'yyyy',
      //       month: 'MMM \'yy',
      //       day: 'dd MMM',
      //       hour: 'HH:mm',
      //     }
      //   },
        // min: 0,
        // max: 23,
      // }
    const chart = new ApexCharts(chartRef.current, cOptions)
    
    if (chart) {
      chart.render()
    }
    

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [chartRef, props, locales])
    }, [props.items, props.isLoading, locales]) //chartRef

  return (
    <div className={`card ${props.className ? props.className : ''}`}>
      <div className='card-header border-0 py-5'>
        <Row className='w-100'>
          <Col xxl={props.widgetControls ? 11 : 12} 
            xl={props.widgetControls ? 11 : 12} 
            lg={props.widgetControls ? 10 : 12} 
            md={props.widgetControls ? 9 : 12} 
            sm={props.widgetControls ? 9 : 12} 
            xs={props.widgetControls ? 8 : 12}>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bolder fs-3 mb-1'>{
                props.isLoading
                ? <Loader2 widthPX='30px' label=''/>
                : props.title ?? ""
              }</span>

              <span className={`${props.subTitleClassNames}`}>{
                props.isLoading
                ? <Loader2 widthPX='30px' label=''/>
                : props.subTitle ?? ""
              }</span>
            </h3>
          </Col>
          {
            props.widgetControls
            ? <Col xxl={1} xl={1} lg={2} md={3} sm={3} xs={4} className="d-flex flex-row justify-content-end align-items-center">
              <div className='card-toolbar w-100'>
                <button
                  type='button'
                  className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                  data-kt-menu-trigger='click'
                  data-kt-menu-placement='bottom-end'
                  data-kt-menu-flip='top-end'
                >
                  <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
                </button>
                {
                  props.widgetControls
                  ?
                  <WidgetDropdown title={props.widgetTitle ?? ''}>
                    {props.widgetControls}
                  </WidgetDropdown>
                  : null
                }
              </div>
            </Col>
            : null
          }
        </Row>

      </div>
      <div className='card-body p-1 d-flex flex-column'>
        <Row>
          <Col>
            {
              props.items?.length
                ? <div className='row'>{

                props.items.map(item => {
                  return (
                    <div className='col-6'>
                      <div className='card-p pt-5 bg-white flex-grow-1'>
                        <div className='row g-0'>
                          <div className='col mr-8'>
                            <div className='fs-7 text-muted fw-bold'>{item.title ?? ""}</div>
                            <div className='d-flex align-items-center'>
                              <div className='fs-4 fw-bolder'>
                                {item.preText ?? ""}<DevidedNumber devider='.'>{item.num ?? 0}</DevidedNumber>{item.postText ?? ""}
                              </div>

                              {
                                item.arrow
                                  ? <KTSVG
                                    path={`/media/icons/duotune/arrows/arr${item.arrow == 'up' ? '066' : '065'}.svg`}
                                    className={`svg-icon-5 svg-icon-${item.arrow == 'up' ? 'success' : 'danger'} ms-1`}
                                  />
                                  : null
                              }
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  )
                })
                }
                </div>
                : null
            }
            {
              props.isLoading
              ? <Loader2 widthPX='30px' label=''/>
              : <div ref={chartRef} className='mixed-widget-3-chart card-rounded-bottom'></div>}
          </Col>
        </Row>
      </div>
    </div>
  )
}

const chartOptions = (chartHeight: string, chartColor: string, points: IGraphicPoint[], from: Date, to: Date, legendName: string, locales?: ApexLocale[], defaultLocale?: string): ApexOptions => {
  // const labelColor = getCSSVariableValue('--bs-gray-800')
  // const strokeColor = getCSSVariableValue('--bs-gray-300')
  const baseColor = getCSSVariableValue('--bs-' + chartColor)
  const lightColor = getCSSVariableValue('--bs-light-' + chartColor)

  const dta = points?.map(p => {
    let dt = moment(from)
    // make dt hours =0

    return {x: dt.add(Number(p.name), "days").toDate(), y: p.value}
    }) ?? []

  //  const minTime = new Date(dta[0].x.setHours(3, 0, 0, 0)).getTime()
   

  return {
    chart:{
      type: 'area',
      redrawOnParentResize: false,
      redrawOnWindowResize: false,
      height: chartHeight,
      locales: locales ?? [fr],
      defaultLocale: defaultLocale ?? defaultLang,

    },
    series: [{
      name: legendName,
      data: dta
    }],
    dataLabels: {
      enabled: false,

    },
    xaxis: {
      type: 'datetime',
      // min: dta.length < 3 ? minTime : dta[0].x.getTime(), // Установите начало диапазона
      // max: new Date(dta[dta.length-1].x).getTime(),
      // labels: {
        // format: 'dd MMM yyyy, HH:mm'
        // format: dta.length < 3 ? 'dd MMM, HH:mm' : 'dd MMM'
        // format: dta.length < 3 ? 'HH:mm' :'dd MMM'
        // format: 'dd MMM'
      // },

    },
    stroke: {
      curve: 'smooth',
      show: true,
      width: 3,
      colors: [baseColor],

    },
    colors: [baseColor],
    markers: {
      colors: [lightColor],
      strokeColors: [baseColor],
      strokeWidth: 3,

    },
  }
}

export { DashboardGraphicSummary }
