
export const initialCompetitors: ICompetitorState = {
    count: 0,
    next: "",
    previous: "",
    competitors: [],
    competitorGoods: [],
    isLoading: false
}

export interface ICompetitor {
    id: number
    name: String
    params: any,
    code: string,
    url: string,
}

export interface ICompetitorCheckUrlResponse {
    result?: ICheckUrl
    success: boolean | null
    errors?: any[]
}

export interface ICheckUrl {
    name: string,
    url: string,
    competitor_name: string,
    competitor_id: number,
    last_price: {
        price: number,
        updated: string
    }
}

export interface ICompetitorGoodResponse {
    result?: ICompetitorGood
    success: boolean | null
    errors?: any[]
}

export interface ICompetitorGood {
    id: number
    name: string | null
    good_id: number
    url: string
    good_customer_uid: string
    sku_id?: number | null
    sku_customer_uid?: string | null
    competitor_name: string
    competitor_id: number
    last_price: ICompetitionGoodPrice | null
    isLoading: boolean
    error: boolean | null | undefined
}

interface ICompetitionGoodPrice {
    price?: number | null
    updated?: string | Date | null
}

export interface ICompetitorState {
    count: number
    next: string
    previous: string
    competitors: ICompetitor[]
    competitorGoods: ICompetitorGood[]
    isLoading?: boolean
}


export const competitorActionTypes = {
    GetCompetitors: '[GetCompetitors] Action',
    GetCompetitorGoods: '[GetCompetitorGoods] Action',
    AddCompetitorGood: '[AddCompetitorGood] Action',
    DelCompetitorGood: '[DelCompetitorGood] Action',
    EditCompetitorGood: '[EditCompetitorGood] Action',
    ForceUpdateCompetitorGood: '[ForceUpdateCompetitorGood] Action',
    CheckCompetitorGoodsUrl: '[CheckCompetitorGoodsUrl] Action',
}