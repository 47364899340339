import {createSlice} from '@reduxjs/toolkit'
import {IAccount, initialAccount as initialState} from './types'
import deepmerge from 'deepmerge'
import {uniqBy, prop} from 'ramda'

const accountSlice = createSlice({
  name: 'accountSlice',
  initialState,
  reducers: {
    setAccounts(state, action) {
      state.items = [...action.payload]
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload
    },
    setIsSuccessLastOperation(state, action) {
      state.isSuccessLastOperation = action.payload
    },
    addAccount(state, action) {
      state.items = [...state.items, action.payload]
    },
    setAccount(state, action) {
      const uniqByCode = uniqBy(prop('code'))
      const acc: IAccount = action.payload
      const newAcc = [
        ...state.items.map((ac: IAccount) =>
          ac.id == acc.id ? (deepmerge.all([ac, acc]) as any) : ac
        ),
      ]
      state.items = newAcc.map((x) => ({...x, settings_v2: uniqByCode(x.settings_v2 ?? [])}))
    },
    deleteAccount(state, action) {
      const accNum: number = action.payload
      state.items = [...state.items.filter((a) => a.id != accNum)]
    },
  },
})

export const accountReducer = accountSlice.reducer
export const {
  setAccounts,
  setAccount,
  addAccount,
  deleteAccount,
  setIsSuccessLastOperation,
  setIsLoading,
} = accountSlice.actions
