import {takeLatest, takeEvery, select} from 'redux-saga/effects'
import {setInvoiceData, setIsLoading} from './reducer'
import {FETCH_INVOICES} from './actions'
import {sagaUtils} from '../../utils/dataUtils'
import {intl} from '../../modules/goods/item/validation'
import {StorageType} from '../../../setup'
import {IInvoicesStorage} from './types'
import {fetchInvoices} from './api'

export function* invoicesSaga() {
  yield takeLatest(FETCH_INVOICES, workerFetchInvoices)
}

export function* workerFetchInvoices() {
  const {filters}: IInvoicesStorage = yield select((storage: StorageType) => storage.invoices)

  yield sagaUtils.workerWrapper(
    setIsLoading,
    fetchInvoices,
    filters,
    setInvoiceData,
    null,
    intl.formatMessage({id: 'SAGAS.GOODS.GROUPS.GET'}),
    'GET',
    'data',
    false,
    '',
    undefined,
    undefined
  )
}
