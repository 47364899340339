import { IImage } from './types';
import axios from "axios"

const API_URL = `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENDPOINT_PREFIX}`;
export const IMAGES_URL = `${API_URL}v2/images/`
const LIMIT_DEFAULT = 10
const OFFSET_DEFAULT = 10

export const fetchImages = (limit:number = LIMIT_DEFAULT, offset:number = OFFSET_DEFAULT) => 
{ 
    return axios.get(`${IMAGES_URL}?offset=${offset}&limit=${limit}`)
}

// send image to server like form data
export const postImage = (formData: any) => 
{ 
    const options:any = {
        method: 'POST',
        url: IMAGES_URL,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: formData
      };

      
    return axios.request(options).then(data => {
      const nd = new Date().valueOf();
      if (Object.keys(data.data)?.find(x => x == "id"))
        return data
      else
        return { data: {id: nd, image: {full_size: data.data}}}
    })
}


// delete image from server by id
export const deleteImage = (imageId: number) => 
{ 
    return axios.delete(`${IMAGES_URL}${imageId}`)
}


