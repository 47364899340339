import {FC, useState} from 'react'
import {useIntl} from 'react-intl'
import {NavLink} from 'react-router-dom'
import PreviewButton from '../../../components/buttons/PreviewButton'
import {IAccount} from '../../../store/accounts/types'
import {IMarketplace} from '../../../store/marketplaces/types'
import {IMpDocItem} from '../../../store/mpdocs/types'
import {dateUtils, imgUtils} from '../../../utils/dataUtils'
import {makeLinkAndClick, scanService} from '../list/MpDocsListWrapper'
import CheckBoxCustom from '../../../components/inputs/CheckBoxCustom'
import {paddingLeft40Px} from '../../orders/item/OrderData'
import ViewButton from '../../../components/buttons/ViewButton'

interface IMpDocsListItem {
  index: number
  item: IMpDocItem
  itemUrl?: string // url to navigate when click on item

  marketplaces?: IMarketplace[]
  accounts?: IAccount[]

  selected?: boolean
  setSelected?: (selected: boolean) => void

  setQuickView?: (itemId: string) => void
}

// call to get sticker from server
const gotoScanService = (id: string) => {
  makeLinkAndClick(`${scanService}${id}`)
}

const MpDocsListItem: FC<IMpDocsListItem> = (props) => {
  const intl = useIntl()

  const [collapsed, setCollapsed] = useState<boolean>(false)
  const createdDate = new Date(props.item.created)
  const currentItemMarketplace =
    props.marketplaces?.find(
      (mp) => props.item?.account?.marketplace && mp.id === props.item.account.marketplace
    ) ?? undefined

  const select = () => {
    props.setSelected && props.setSelected(!props.selected)
  }
  return (
    <tr className={`bg-secondary`}>
      <td className='cursor-pointer' onClick={select} style={paddingLeft40Px}>
        <div className='d-flex flex-column justify-content-center align-items-center'>
          <CheckBoxCustom
            checked={props.selected != undefined ? props.selected : false}
            onChange={() => props.setSelected && props.setSelected(!props.selected)}
          />
        </div>
      </td>

      <td className='cursor-pointer' onClick={select} style={{paddingLeft: '0px'}}>
        <div
          className='d-flex  justify-content-start align-items-center g-30'
          style={{paddingLeft: '0px'}}
        >
          <img
            className='w-30px h-30px m-3'
            src={
              props.marketplaces?.filter((x: any) => x.id == props.item.account?.marketplace)[0]
                ?.params?.pic ?? ''
            }
            alt={currentItemMarketplace?.name}
          />
          <div className='fs-9 text-left'>
            {props.item.account
              ? props.accounts?.find((a) => a.id == props.item.account.id)?.name ??
                props.item.account
              : intl.formatMessage({id: 'COMPONENTS.MPDOCS.LIST.FILTERS.ACCOUNT.NOTSELECTED'})}
          </div>
        </div>
      </td>
      <td className='cursor-pointer' onClick={select}>
        <div className='d-flex flex-column justify-content-center align-items-start'>
          {props.itemUrl ? (
            <NavLink className='text-dark' to={`/${props.itemUrl}/${props.item.id}`}>
              {props.item.number}
            </NavLink>
          ) : (
            props.item.number
          )}
        </div>
      </td>
      <td className='text-left'>
        <div className='d-flex flex-row justify-content-between align-items-center'>
          <ViewButton
            onClick={() => props.setQuickView && props.setQuickView(props.item.id.toString())}
          />
        </div>
      </td>
      <td className=''>
        <div className='d-flex flex-column justify-content-center align-items-start fs-9'>
          {props.item?.orders ? (
            <>
              {collapsed ? (
                <div className='d-flex flex-column align-items-start'>
                  <div className='fs-9 text-left me-2'>
                    {props.item.orders.reduce(
                      (acc, cur) => `${acc}${acc == '' ? '' : ','} ${cur.number}`,
                      ''
                    )}
                  </div>
                  {props.item.orders.length > 8 ? (
                    <div
                      className='fs-9 text-left text-primary cursor-pointer'
                      onClick={() => setCollapsed(false)}
                    >
                      {intl.formatMessage({id: 'ACTION.HIDE'})}
                    </div>
                  ) : null}
                </div>
              ) : (
                <div className='d-flex flex-row justify-content-start'>
                  <span className='fs-9 me-2'>
                    {props.item.orders.reduce(
                      (acc, cur, idx) =>
                        acc.length + cur.number.length < 80
                          ? `${acc}${acc == '' ? '' : ','} ${cur.number}`
                          : acc,
                      ''
                    )}
                    {props.item.orders.length > 8 ? (
                      <span
                        className='ms-2 fs-9 text-primary cursor-pointer'
                        onClick={() => setCollapsed(true)}
                      >
                        {intl.formatMessage({id: 'ACTION.SHOWALL'})}
                      </span>
                    ) : null}
                  </span>
                </div>
              )}
            </>
          ) : null}
        </div>
      </td>
      <td className=''>
        <div className='d-flex flex-column justify-content-center align-items-start'>
          <div>{dateUtils.standartDateString(createdDate, '.')}</div>
          <div>{dateUtils.standartTimeString(createdDate, ':')}</div>
        </div>
      </td>
      <td className='pe-5'>
        <PreviewButton onClick={() => gotoScanService(props.item.number)} />
      </td>
    </tr>
  )
}

export default MpDocsListItem
