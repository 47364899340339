import axios from "axios"
import { ICustomer } from "../../modules/auth/models/UserModel";
import { ICustomersGetResponse } from "./types";


const API_URL = `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENDPOINT_PREFIX}`;
export const CUSTOMERS_URL = `${API_URL}v2/customers/`


// =================   get customers info =================
// #region
//https://api-dev.shop-delivery.ru/int_api/v2/customers/?limit=10&offset=1


// get customers info from server
export const getCustomersFromServer = () => { 
    return axios.get<ICustomersGetResponse>(`${CUSTOMERS_URL}`)
}

// #endregion

// =================   update customer info =================
// #region
//https://api-dev.shop-delivery.ru/int_api/v2/customers/?limit=10&offset=1


// update current customer info on server
export const updateCustomerOnServer = (params: ICustomer) => { 
    console.log('updateCustomerOnServer', params)
    return axios.put<ICustomer>(`${CUSTOMERS_URL}${params.id}/`, params)
}

// add customer to server
export const addCustomerToServer = (params: {params: ICustomer, userid: number}) => { 
    return axios.post<ICustomer>(`${CUSTOMERS_URL}`, {...params.params, user: params.userid})
}

// #endregion