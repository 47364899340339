import {createSlice} from '@reduxjs/toolkit'
import {initState} from './types'

const transactionStatusSlice = createSlice({
  name: 'transactionStatusSlice',
  initialState: initState,
  reducers: {
    setTransactionStatus(state, action) {
      state.status = action.payload
    },
  },
})

export const {setTransactionStatus} = transactionStatusSlice.actions

export const transactionStatusReducer = transactionStatusSlice.reducer
