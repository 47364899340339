import {FC} from 'react'
import BaseCustomButton, {BaseCustomButtonProps} from './BaseCustomButton'

const LeftArrowButton: FC<BaseCustomButtonProps> = (props) => {
  return (
    <BaseCustomButton {...props}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='14'
        height='14'
        fill='currentColor'
        className='bi bi-arrow-left'
        viewBox='0 0 14 14'
      >
        <path
          fillRule='evenodd'
          d='M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8'
        />
      </svg>
      <span className={props.childrenClassName ? props.childrenClassName : ''}>
        {props.children}
      </span>
    </BaseCustomButton>
  )
}

export default LeftArrowButton
