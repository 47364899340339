import React from 'react'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router'
import clsx from 'clsx'
import {checkIsActive, KTSVG} from '../../../helpers'
import './MenuItemProfile.scss'

type Props = {
  to: string
  title: string
  subtitle?: string
  icon?: string
  fontIcon?: string
  hasArrow?: boolean
  hasBullet?: boolean
  noActive?: string
}

const MenuItemProfile: React.FC<Props> = ({
  to,
  title,
  subtitle,
  icon,
  fontIcon,
  hasArrow = false,
  hasBullet = false,
  noActive,
}) => {
  const {pathname} = useLocation()

  return (
    <>
      {noActive ? (
        <div className='d-flex flex-column align-items-end justify-content-center me-2'>
          <div className='text-white'>{title}</div>
          <div className='text-white'>{subtitle}</div>
        </div>
      ) : (
        <div className='menu-item me-lg-1'>
          <Link
            className={clsx('menu-link', {
              active: checkIsActive(pathname, to),
            })}
            to={to}
          >
            {hasBullet && (
              <span className='menu-bullet'>
                <span className='bullet bullet-dot'></span>
              </span>
            )}

            <div className='menu-title d-flex flex-column align-items-end justify-content-center me-2'>
              <div
                className={`${
                  checkIsActive(pathname, to) ? 'text-sbx-muted' : 'text-sbx-muted'
                } p-0 m-0 fs-7 sbxMutedHover`}
              >
                {title}
              </div>
              {/* <div className={`${checkIsActive(pathname, to) ? "text-sbx-muted" : "text-sbx-muted"} p-0 m-0 fs-7`}>{title}</div> */}
              {subtitle ? (
                <div
                  className={`${
                    checkIsActive(pathname, to) ? 'text-sbx-main' : ''
                  } p-0 m-0 fs-8 fw-bolder sbxMutedHover`}
                >
                  {subtitle}
                </div>
              ) : null}
            </div>

            {icon && (
              <span className='menu-icon rounded bg-secondary w-30px h-30px text-sbx-main'>
                S{/* <KTSVG path={icon} className='svg-icon-2' /> */}
              </span>
            )}

            {fontIcon && (
              <span className='menu-icon'>
                <i className={clsx('bi fs-3', fontIcon)}></i>
              </span>
            )}

            {hasArrow && <span className='menu-arrow'></span>}
          </Link>
        </div>
      )}
    </>
  )
}

export {MenuItemProfile}
