import {IAccessPath} from '../modules/auth/models/UserModel'

export const Routes: IAccessPath[] = [
  {
    id: 1,
    code: '/home',
    name: 'Главная',
    params: {},
  },
  {
    id: 2,
    code: '/orders',
    name: 'Заказы',
    params: {},
  },
  {
    id: 3,
    code: '/goods',
    name: 'Товары',
    params: {},
  },
  {
    id: 4,
    code: '/prices',
    name: 'Цены',
    params: {},
  },
  {
    id: 5,
    code: '/logistics',
    name: 'Логистика',
    params: {},
  },
  {
    id: 6,
    code: '/dashboard',
    name: 'Аналитика',
    params: {},
  },
  {
    id: 7,
    code: '/feedback',
    name: 'Управление отзывами',
    params: {},
  },
  {
    id: 8,
    code: '/acceptance-rates',
    name: 'Управление поставками',
    params: {},
  },
  {
    id: 9,
    code: '/services',
    name: 'Сервисы',
    params: {},
  },
]

export const TemporaryRoutes: IAccessPath[] = [
  {
    id: 1,
    code: '/home',
    name: 'Главная',
    params: {},
  },
  {
    id: 2,
    code: '/sales-management',
    name: 'Управление продажами',
    params: {},
  },
  {
    id: 3,
    code: '/feedback',
    name: 'Управление отзывами',
    params: {},
  },
  {
    id: 4,
    code: '/acceptance-rates',
    name: 'Мониторинг поставок',
    params: {},
  },
  {
    id: 5,
    code: '/services',
    name: 'Сервисы',
    params: {},
  },
]
