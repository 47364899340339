import { IUrlParameters } from './../../components/lists/UrlFilteredList';
import { DEFAULT_MPDOCS_LIMIT } from './constants';
import { emptyAccount, IAccount } from '../accounts/types';

export const emptyMpDocUrlParameters = {
    filter: "",
    itemsPerPage: DEFAULT_MPDOCS_LIMIT,
    ordering: "",
    pageNumber: 0,
    url: "/orders/mpdocs/list/",
}

export const emptyState: IMpDocStorage = {
    items: [],
    selected: null,
    isLoading: false,
    filter: emptyMpDocUrlParameters,
    count: 0,
}


export interface IMpDocStorage {
    items: IMpDocItem[]
    selected: IMpDocItem | null
    isLoading: boolean
    filter?: IUrlParameters
    count: number | null
}

export interface IOrderSkuShort{
    name: string
    price: number
}

export interface IMpDocShortOrder{
    id: number
    number: string
    // skus: {skus: IOrderSkuShort[]}
    skus: IOrderSkuShort[]
    // skus: IOrderSkuShort[]
}

export interface IMpDocItem {
    id: number,
    number: string,
    account: IAccount,
    orders: IMpDocShortOrder[]
    type: string,
    params?: IMpDocItemPayload,
    created: Date,
    updated_at: Date,
    selected?: boolean  // my parameter to using it in list

}

export interface IMpDocItemPayload{
    status?: string
    payload?: IMpDocItemAddedToAct
    task_id?: string
}

export interface IScannedData{
    barcode: string
    order_number: string
    date: string
}

export interface IMpDocItemAddedToAct{
    added_to_act?: string[]
    scanned?: IScannedData[]
}

export const emptyMpDocItem: IMpDocItem = {
    id: 0,
    number: "",
    account: emptyAccount,
    orders: [],
    type: "",
    created: new Date(),
    updated_at: new Date(),

}



export interface IMpDocListResponse {
    count: number
    results: IMpDocItem[]
}

export interface IMpDocsGetParams{
    page?: number
    items?: number
    order?: string
    search?: string
    marketplace?: string
    account?: string
    customer?: string
}

export interface IMpDocListRequest{
    company?: number
    created_after?: string
    created_before?: string
    expand?: string // List["account"]
    limit?: number
    marketplace?: number
    number?: number
    numbers?: string
    offset?: number
    ordering?: string
    search?: string
    ship_at_after?: string
    ship_at_before?: string
    status?: number
}

export interface IMpDocBarcodeRequest{
    mpdocId: number,
}

export interface IMpDocBarcodesRequest{
    mpdocIds: number[],
}


export const mpdocActionTypes = {
    GetMpDocsWithFilter: '[GetMpDocsWithFilter] Action',
    SetRequestParameters: '[SetRequestParameters] Action',
    GetMpDoc: '[GetMpDoc] Action',
    GetSticker: '[GetSticker] Action',
    GetStickers: '[GetStickers] Action',
}