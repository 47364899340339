import {createAction} from '@reduxjs/toolkit'
import {
  ICampaignSaveData,
  ICreateCampaignRequestParams,
  IPauseCampaignRequestParams,
  IPrepareGoodRequestParams,
  IUrlParametersLight,
  marketingActionTypes,
  IRequestParamsOzonPromo,
} from './types'

export const ASYNC_GET_PREPARE_GOODS = marketingActionTypes.GetPrepareGoods
export const ASYNC_GET_KEYWORDS = marketingActionTypes.GetKeywords
export const SET_COMPANIES_FILTER = marketingActionTypes.SetCompaniesFilter
export const ASYNC_GET_COMPANIES = marketingActionTypes.GetCompanies
export const ADD_CAMPAIGN = marketingActionTypes.AddCampaign
export const CREATE_CAMPAIGN = marketingActionTypes.CreateCampaign
export const DELETE_CAMPAIGN = marketingActionTypes.DeleteCampaign
export const UPDATE_CAMPAIGN = marketingActionTypes.UpdateCampaign
export const PAUSE_RESUME_CAMPAIGN = marketingActionTypes.PauseResumeCampaign
export const CHANGE_OZON_PROMO_GOODS = marketingActionTypes.ChangeOzonPromoGoods
export const CHANGE_OZON_BIDS = marketingActionTypes.ChangeOzonBids

export const getAsyncPrepareGoodsCreator =
  createAction<IPrepareGoodRequestParams>(ASYNC_GET_PREPARE_GOODS)
export const getAsyncKeywordsCreator = createAction<number>(ASYNC_GET_KEYWORDS)
export const setCompaniesFilterCreator = createAction<IUrlParametersLight>(SET_COMPANIES_FILTER)
export const getAsyncCompaniesCreator = createAction(ASYNC_GET_COMPANIES)
// export const addCampaignCreator = createAction<ICampaignSaveData>(ADD_CAMPAIGN);
export const createCampaignCreator = createAction<ICreateCampaignRequestParams>(CREATE_CAMPAIGN)
export const deleteCampaignCreator = createAction<number>(DELETE_CAMPAIGN)
export const updateCampaignCreator = createAction<ICampaignSaveData>(UPDATE_CAMPAIGN)
export const pauseResumeCampaignCreator =
  createAction<IPauseCampaignRequestParams>(PAUSE_RESUME_CAMPAIGN)
export const changeOzonPromoGoodsCreator =
  createAction<IRequestParamsOzonPromo>(CHANGE_OZON_PROMO_GOODS)
export const changeOzonBidsCreator = createAction<IRequestParamsOzonPromo>(CHANGE_OZON_BIDS)
