import axios, { AxiosResponse } from "axios"
import { IFilterRequestParams } from "../../components/lists/UrlFilteredList";
import { IStockAddParams, IStockItem, IStockListResponse, IStockUpdateParams } from "./types";



const API_URL = `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENDPOINT_PREFIX}`;
export const STOCKS_URL = `${API_URL}v2/stocks/`
export const OFFERS_STOCKS_URL = `${API_URL}v2/offers/stocks/`


// =================   get stocks =================
// #region
//https://api-dev.shop-delivery.ru/int_api/v2/stocks/?limit=10&offset=1


// get all stocks from server
export const getStocksFromServer = (params: IFilterRequestParams) => { 
    return axios.get<IStockListResponse>(`${OFFERS_STOCKS_URL}?limit=${params.limit ?? 0}&offset=${params.offset ?? 0}&ordering=${params.ordering ?? ""}&search=${params.search ?? ""}`)
}

// #endregion

// =================   set stock =================
// #region
//https://api-dev.shop-delivery.ru/int_api/v2/stocks/{id}/


// update stock on server
export const updateStockOnServer = (params: IStockUpdateParams) => { 
    return axios.put<AxiosResponse<IStockItem>>(`${STOCKS_URL}${params.id}/`, params)
}

// #endregion

// =================   add stock =================
// #region
//https://api-dev.shop-delivery.ru/int_api/v2/stocks/{id}/


// add new stock to server
export const addStockToServer = (params: IStockAddParams) => { 
    
    return axios.post<AxiosResponse<IStockItem>>(`${STOCKS_URL}`, [{
        quantity: params.quantity, 
        reserved: params.reserved,
        sku_customer_uid: params.sku_customer_uid,
        warehouse: params.warehouse,
    }])
}

// #endregion
