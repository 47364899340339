
export const currentScreenSize = () => {
    if (window.screen.width < 576){
        return "xs"
    }
    else if ((window.screen.width >= 576) && (window.screen.width < 768)){
        return "sm"
    }
    else if ((window.screen.width >= 768) && (window.screen.width < 992)){
        return "md"
    }
    else if ((window.screen.width >= 992) && (window.screen.width < 1200)){
        return "lg"
    }
    else{
        return "xl"
    }
}