import axios, {AxiosResponse} from 'axios'
import moment from 'moment'
import {
  IGraphicWithNumber,
  IGraphicWithNumberRequestParams,
  IStatisticsItem,
  IStatisticsRequestParams,
} from './types'

const API_URL = `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENDPOINT_PREFIX}`
export const STAT_URL = `${API_URL}v2/stat/`

// =================   get money/orders total and graphic =================
// #region
//https://api-dev.shop-delivery.ru/int_api/v2/stat/?data_type=orders&date_from=01.01.2023&date_to=01.02.2023

// get money/orders statistics with graphic by date range
export const getGraphicFromServer = (params: IGraphicWithNumberRequestParams) => {
  const from = moment(params.date_from).format('DD.MM.YYYY HH.mm')
  const to = moment(params.date_to).format('DD.MM.YYYY HH.mm')
  return axios.get<IGraphicWithNumber>(
    `${STAT_URL}by_orders?data_type=${params.type.toString()}&date_from=${from}&date_to=${to}`
  )
}

// #endregion

// =================   get statistics by date range an source =================
// #region
//https://api-dev.shop-delivery.ru/int_api/v2/stat/by_objects?data_type=orders&date_from=10.02.2023&date_to=20.02.2023&source=marketplace

// get statistics by date range and source
export const getStatFromServer = (params: IStatisticsRequestParams) => {
  const from = moment(params.date_from).format('DD.MM.YYYY HH.mm')
  const to = moment(params.date_to).format('DD.MM.YYYY HH.mm')
  return axios.get<IStatisticsItem[]>(
    `${STAT_URL}by_objects?data_type=${params.type.toString()}&date_from=${from}&date_to=${to}&source=${
      params.source
    }`
  )
}

// #endregion
