import axios, { AxiosResponse } from "axios"
import { ICompetitor, ICompetitorCheckUrlResponse, ICompetitorGood, ICompetitorGoodResponse } from "./types";

const API_URL = `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENDPOINT_PREFIX}`;
export const COMPETITORS_URL = `${API_URL}v2/competitors/`
export const COMPETITORS_GOODS_URL = `${API_URL}v2/competitors_goods/`

export const fetchCompetitors = () => { return axios.get(COMPETITORS_URL)}

export const fetchCompetitorGoods = (good_id: number) => { return axios.get(`${COMPETITORS_GOODS_URL}?good=${good_id}`)}


export interface ICompetitorRequestParams{
    name: string
    url: string
    competitor_id: number
    good?: number | null
    sku?: number | null
}

export interface ICompetitorWithIdRequestParams{
    id: number,
    name: string
    url: string
    competitor_id: number
    good?: number | null
    sku?: number | null
}

export const addCompetitorGoodToServer = (competitor: ICompetitorRequestParams) => { 
    return axios.post<any, AxiosResponse<ICompetitorGood>>(`${COMPETITORS_GOODS_URL}`, competitor )
}

export const editCompetitorGoodToServer = (competitor: ICompetitorWithIdRequestParams) => { 
    return axios.put<any, AxiosResponse<ICompetitorGood>>(`${COMPETITORS_GOODS_URL}${competitor.id}/`, competitor )
}

export const delCompetitorGoodFromServer = (competitor_id: number) => { 
    return axios.delete<any, AxiosResponse<any>>(`${COMPETITORS_GOODS_URL}${competitor_id}/`)
}

export const forceUpdateCompetitorGoodFromServer = (competitor_id: number) => { 
    return axios.get<ICompetitorGoodResponse>(`${COMPETITORS_GOODS_URL}${competitor_id}/update_price/`)
}

export const checkUrlCompetitorGoodFromServer = (url: string) => { 
    return axios.post<ICompetitorCheckUrlResponse>(`${COMPETITORS_GOODS_URL}check_url/`, {url})
}