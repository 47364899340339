import {call, put, select, takeEvery, takeLatest} from 'redux-saga/effects'
import {StorageType} from '../../../setup'
import {intl} from '../../modules/goods/item/validation'
import {sagaUtils} from '../../utils/dataUtils'
import {ASYNC_GET_PRICES, ASYNC_UPDATE_PRICES, SET_PRICE_FILTER} from './actions'
import {getGroupedPricesFromServer, saveGroupedPricesToServer} from './api'
import {setCount, setIsLoading, setPrices, setRequestParams} from './reducers'
import {IPriceStorage} from './types'

export function* priceSaga() {
  yield takeLatest(ASYNC_GET_PRICES, workerGetPrices)
  yield takeLatest(ASYNC_UPDATE_PRICES, workerSavePrices)
  yield takeEvery(SET_PRICE_FILTER, workerSetPriceParameters)
}

// gets all prices an set it to storage
export function* workerGetPrices(): any {
  const params: IPriceStorage = yield select((storage: StorageType) => storage.prices)
  yield sagaUtils.workerWrapper(
    setIsLoading,
    // getPricesFromServer, params.filter,
    getGroupedPricesFromServer,
    params.filter,
    setPrices,
    null,
    intl.formatMessage({id: 'SAGAS.PRICES.GET'}),
    'GET',
    'data.results',
    false,
    '',
    undefined,
    {action: setCount, resultType: 'data', resultTypePropertyName: 'count'}
  )
}

// gets all prices an set it to storage
export function* workerSetPriceParameters(action: any): any {
  yield put(setRequestParams(action.payload))
}

// save prices
// export function* workerSavePrices(action: any): any {
//   const payload: IUpdatePriceRequest = action.payload
//
//   yield sagaUtils.workerWrapperCombinedReducerRequest(
//     setIsLoading,
//     saveGroupedPricesToServer,
//     payload.pricesToSave,
//     updatePrices,
//     null,
//     payload.accountsStorageChanged,
//     intl.formatMessage({id: 'SAGAS.PRICES.SAVE'}),
//     'SAVE',
//     'data.result',
//     true,
//     ''
//   )
// }

export function* workerSavePrices(action: any): any {
  yield call(saveGroupedPricesToServer, action.payload)
  yield call(workerGetPrices)
}

// update price in prices
