/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {useIntl} from 'react-intl'
import {NavLink} from 'react-router-dom'
import {createLink, imgUtils} from '../../../utils/dataUtils'
import {currentScreenSize} from '../../../utils/systemUtils'
import {DEFAULT_LIMIT, DEFAULT_PAGE} from '../GoodsList'
import Filter from '../../../components/lists/UrlFilter'
import {UrlPagination} from '../../../components/lists/UrlPagination'
import {DEFAULT_BUTTONS_PAGINATION, IGoodsTableProps} from '../types'
import Loader1 from '../../../components/vision/loaders/Loader1'
import {Col, Row} from 'react-bootstrap'
import OrderingArrow, {orderingSign} from '../../../components/inputs/OrderingArrow'

export const PAGINATION_VARIANTS = [10, 50, 100]

const pagingVariants = PAGINATION_VARIANTS

const GoodsTable1: React.FC<IGoodsTableProps> = (props) => {
  const intl = useIntl()
  const nodata_message = intl.formatMessage({id: 'COMPONENTS.DATA.NODATA'})
  const nocategory_message = intl.formatMessage({id: 'COMPONENTS.DATA.CATEGORYNOTSET'})
  const no_photo = imgUtils.no_photo
  const limit = props.filterParams.itemsPerPage
  const currentPage =
    props.filterParams.pageNumber > 0 ? props.filterParams.pageNumber : DEFAULT_PAGE
  let maxPaginationButtons = DEFAULT_BUTTONS_PAGINATION[currentScreenSize()]
  const numberOfPages =
    Math.ceil(props.count / (limit ?? DEFAULT_LIMIT)) < maxPaginationButtons
      ? Math.ceil(props.count / (limit ?? DEFAULT_LIMIT))
      : maxPaginationButtons

  const customerOrderObject = createLink.paginationLink({
    pageNumber: currentPage,
    itemsPerPage: limit,
    title: intl.formatMessage({id: 'COMPONENTS.TABLES.GOODS.LIST.SORT.BYCUSTOMERSUID'}),
    filter: props.filterParams.filter,
    ordering: orderingSign(props.filterParams.ordering) + 'customer_uid',
    url: props.filterParams.url,
  })
  const nameOrderObject = createLink.paginationLink({
    pageNumber: currentPage,
    itemsPerPage: limit,
    title: intl.formatMessage({id: 'COMPONENTS.TABLES.GOODS.LIST.SORT.BYNAME'}),
    filter: props.filterParams.filter,
    ordering: orderingSign(props.filterParams.ordering) + 'name',
    url: props.filterParams.url,
  })

  return (
    <div className={`card ${props.className}`}>
      <Row>
        <Col>
          <div className='card-header border-0 pt-5'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bolder fs-3 mb-1'>
                {props.title ?? intl.formatMessage({id: 'COMPONENTS.TABLES.GOODS.LIST.TITLE'})}
              </span>
              <span className='text-muted mt-1 fw-bold fs-7'>{props.description ?? ''}</span>
            </h3>
          </div>
        </Col>
        <Col
          style={{padding: '0rem 3rem'}}
          className={`${props.childrenContainerClassName ? props.childrenContainerClassName : ''}`}
        >
          {props.children}
        </Col>
      </Row>
      <div className='card-body py-3'>
        <Filter urlParams={{...props.filterParams}} />
        <div className='table-responsive'>
          <table className='table table-striped table-row-gray-300 align-middle gs-0 gy-4'>
            <thead>
              <tr className='fw-bolder text-muted'>
                <th className='min-w-50px px-2'>№</th>
                <th className='min-w-50px'>
                  {intl.formatMessage({id: 'COMPONENTS.TABLES.GOODS.LIST.PHOTO'})}
                </th>
                <th className='min-w-150px'>
                  <NavLink className='text-dark' to={nameOrderObject.url}>
                    {intl.formatMessage({id: 'COMPONENTS.TABLES.GOODS.LIST.GOOD'})}
                  </NavLink>
                  <OrderingArrow
                    currentOrderName={props.filterParams.ordering ?? ''}
                    orderName='name'
                  />
                </th>
                <th className='min-w-150px'>
                  {intl.formatMessage({id: 'COMPONENTS.TABLES.GOODS.LIST.CATHEGORY'})}
                </th>
                <th className='min-w-200px text-center'>
                  <NavLink className='text-dark' to={customerOrderObject.url}>
                    {intl.formatMessage({id: 'COMPONENTS.TABLES.GOODS.LIST.ARTICLE'})}
                  </NavLink>
                  <OrderingArrow
                    currentOrderName={props.filterParams.ordering ?? ''}
                    orderName='customer_uid'
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {props.isLoading ? (
                <Loader1 />
              ) : props.items && props.items.length ? (
                props.items.map((good, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <div className='form-check form-check-sm form-check-custom form-check-solid'>
                          <span className='text-muted ps-5 fw-bold text-muted d-block fs-7'>
                            {currentPage * limit + index + 1}
                          </span>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='symbol symbol-45px me-5'>
                            <img
                              src={
                                good?.images && good?.images[0]
                                  ? good.images[0].image?.full_size
                                  : no_photo
                              }
                              alt=''
                            />
                          </div>
                        </div>
                      </td>
                      <td>
                        <NavLink
                          className='menu-link without-sub text-dark'
                          to={createLink.toGood(good.id)}
                        >
                          {good.name ? good.name : nodata_message}
                        </NavLink>
                      </td>
                      <td>
                        <div className='menu-item'>
                          {good?.category?.length
                            ? good.category.map((cat, index) => {
                                return (
                                  <span className='menu-title' key={index}>
                                    {cat?.name ?? nocategory_message}
                                  </span>
                                )
                              })
                            : nocategory_message}
                        </div>
                      </td>
                      <td>
                        <span className='text-muted  text-center fw-bold text-muted d-block fs-7'>
                          {good.customer_uid ? good.customer_uid : nodata_message}
                        </span>
                      </td>
                    </tr>
                  )
                })
              ) : (
                <tr>
                  <td colSpan={6}>{intl.formatMessage({id: 'COMPONENTS.DATA.NODATA'})}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <UrlPagination
        isLoading={props.isLoading ?? false}
        itemsNumber={props.count}
        itemsPerPageVariants={pagingVariants}
        makeLink={createLink.paginationLink}
        pageButtonNumber={numberOfPages}
        filterParams={{
          pageNumber: currentPage,
          itemsPerPage: props.filterParams.itemsPerPage ?? DEFAULT_LIMIT,
          filter: props.filterParams.filter,
          ordering: props.filterParams.ordering,
          url: props.filterParams.url,
        }}
      />
    </div>
  )
}

export {GoodsTable1}
