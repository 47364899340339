import { createSlice } from "@reduxjs/toolkit"
import { initialOrderStatus as initialState, } from "./types"


const orderStatusSlice = createSlice({
    name: "orderStatusSlice",
    initialState,
    reducers: {
        setOrderStatuses(state, action){
            state.items = [...action.payload]
        }
    }
})


export const orderStatusReducer = orderStatusSlice.reducer
export const { setOrderStatuses } = orderStatusSlice.actions