import * as yup from "yup";
import Lazy from "yup/lib/Lazy";
import { AnyObject, Maybe, Optionals } from "yup/lib/types";

yup.addMethod(yup.array, 'hasCategory', function(max, message) {
            return this.test({name: 'category', message,exclusive:true, params:{message}, 
            test(arrayOfCat) {
                return arrayOfCat && arrayOfCat.length && arrayOfCat[0].id
            }}
            );
            // return this.test('category', message, function(arrayOfCat) {
            //     return arrayOfCat && arrayOfCat.length && arrayOfCat[0].id
            // }
            // );
            // return this.test({
            //     name: 'max',
            //     exclusive: true,
            //     message,
            //     params: {
            //       max
            //     },
          
            //     test(value:any) {
            //       return false//value.length <= this.resolve(max);
            //     }
          
            //   });
  }
  );


declare module "yup" {

  interface ArraySchema<
  T extends yup.AnySchema | Lazy<any, any>, 
  C extends AnyObject = AnyObject, 
  TIn extends Maybe<yup.TypeOf<T>[]> = yup.TypeOf<T>[] | undefined, 
  TOut extends Maybe<yup.Asserts<T>[]> = yup.Asserts<T>[] | Optionals<TIn>> 
  extends yup.BaseSchema<TIn, C, TOut> {
    hasCategory(message:string): this;
  }
}


export default yup;