export const initialNews: INews = {
    count: 0,
    next: null,
    previous: null,
    results: [],
    isLoading: false,
}

export interface INews {
    count: number;
    next: number | null;
    previous: number | null;
    results: INewsItem[];
    isLoading: boolean;
}

export interface INewsItem { 
    id: number;
    title: string;
    text: string;
    created_at: string;
    posted_date: string;
    updated_at: string;
    author?: number;
}

export const newsActionTypes = {
    GetNews: '[GetNews] Action'
}