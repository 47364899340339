import {useIntl} from 'react-intl'
// import loading from './../../../../_metronic/assets/media/gif/load1.gif'
import {loader} from '../../../../_metronic/assets/media/svg'

interface ILoaderProps {
  className?: string
  style?: object
  hideMessage?: boolean
  widthPX?: string
  label?: string
  labelClass?: string
  textPosition?: 'bottom' | 'right'
  customAlignContent?: 'start' | 'end' | 'center' | 'between'
}

const Loader2 = (props: ILoaderProps) => {
  const intl = useIntl()

  return (
    <div
      style={props.style ?? {width: '100%', textAlign: 'left'}}
      className={`${props.className ?? ''}`}
    >
      <div
        style={{
          width: props.widthPX ?? '70px',
          display: 'flex',
          flexDirection: props.textPosition && props.textPosition == 'right' ? 'row' : 'column',
          alignItems: 'center',
          alignContent: props.customAlignContent ? props.customAlignContent : 'start',
        }}
      >
        <img
          style={{width: props.widthPX ?? '70px'}}
          src={loader}
          alt={intl.formatMessage({id: 'STATUS.LOADING'})}
        />
        <p className={props.labelClass ? props.labelClass : ''} style={{margin: '0 0 0 10px'}}>
          {!props.hideMessage ? props?.label ?? intl.formatMessage({id: 'STATUS.LOADING'}) : ''}
        </p>
      </div>
    </div>
  )
}

export default Loader2
