import {FC, useEffect, useState} from 'react'
import {Button, ButtonGroup, Col, Dropdown, DropdownButton} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'
import {useNavigate, useParams} from 'react-router-dom'
import {StorageType} from '../../../../setup'
import SbxLightButton from '../../../components/buttons/SbxButtons/SbxLightButton'
import ModalInfo from '../../../components/vision/info/ModalInfo'
import Loader1 from '../../../components/vision/loaders/Loader1'
import Loader2 from '../../../components/vision/loaders/Loader2'
import {IAccountState} from '../../../store/accounts/types'
import {ICustomerCompanyState} from '../../../store/customerCompanys/types'
import {
  IDeliveryServiceState,
  IDeliveryTrackOrderRequest,
} from '../../../store/deliveryServices/types'
import {IMarketplaceState} from '../../../store/marketplaces/types'
import {IOrderStatus, IOrderStatusState} from '../../../store/orderStatuses/types'
import {
  deleteAsyncTrackNumberCreator,
  getAsyncOrderCreator,
  setAsyncOrderStatusCreator,
  setAsyncTrackNumberCreator,
  registerOrderCreator,
} from '../../../store/orders/actions'
import {
  IOrderItem,
  IOrderStorage,
  emptyOrderUrlParameters,
  IOrderRegisterRequest,
} from '../../../store/orders/types'
import {createLink, dateUtils, messageUtils} from '../../../utils/dataUtils'
import {requestSticker} from '../list/OrdersListWrapper'
import OrdersItem from './OrdersItem'

interface IOrdersItemWrapperProps {
  id?: string
  closeAction?: any
}

const whiteListCompanys = ['Ozon', 'Yandex Market']

const OrdersItemWrapper: FC<IOrdersItemWrapperProps> = (props) => {
  const urlParams = useParams()
  const {id} = props?.id?.length ? props : urlParams
  const dispatch = useDispatch()
  const navigator = useNavigate()
  const intl = useIntl()

  const marketplaceState: IMarketplaceState = useSelector((s: StorageType) => s.marketplaces)
  const accountState: IAccountState = useSelector((s: StorageType) => s.accounts)
  const companyState: ICustomerCompanyState = useSelector((s: StorageType) => s.customerCompanys)
  const deliveryCompanyState: IDeliveryServiceState = useSelector(
    (s: StorageType) => s.deliveryServices
  )

  const ordersState: IOrderStorage = useSelector((db: StorageType) => db.orders)
  const orderStatusesState: IOrderStatusState = useSelector((s: StorageType) => s.orderStatuses)
  const goods = useSelector((state: StorageType) => state.goods.items)
  const item: IOrderItem | undefined = ordersState.selected ?? undefined

  const createdDate = item?.created ? new Date(item.created) : null
  //const shippedDate = item?.shipment_date ? new Date(item.shipment_date) : null

  const [showModal, setShowModal] = useState<boolean>(false)

  // when id in url changes
  useEffect(() => {
    // get order from server by id to storage (orderState.selected)
    id != undefined && !Number.isNaN(parseInt(id)) && dispatch(getAsyncOrderCreator(parseInt(id)))
  }, [id])

  // back to orders list
  const goBack = () => {
    // go to filtered page or on standart order list route
    props.closeAction
      ? props.closeAction()
      : navigator(
          createLink.paginationLink(ordersState?.filter ?? emptyOrderUrlParameters).url +
            '&back=true'
        )
  }

  // call to get sticker from server
  const printBarcode = async () => {
    if (item) {
      setShowModal(true)
      ;(await requestSticker([item.id]))?.click()
      setShowModal(false)
    }
  }

  // change status of order
  const changeStatus = (newStatus: IOrderStatus) => {
    item &&
      messageUtils.question(
        intl.formatMessage({id: 'COMPONENTS.ORDERS.LIST.ALERT.CHANGESTATUS'}),
        dispatch,
        setAsyncOrderStatusCreator({
          id: item.id,
          status: newStatus,
        })
      )
  }

  const registerOrder = (params: IOrderRegisterRequest) => () => {
    dispatch(registerOrderCreator(params))
  }

  const saveTrackInfo = (trackInfo: IDeliveryTrackOrderRequest) => {
    dispatch(setAsyncTrackNumberCreator(trackInfo))
  }

  const resetTrackInfo = (orderId: number) => {
    dispatch(deleteAsyncTrackNumberCreator(orderId))
  }

  const registerOrderButtonContent = ordersState.isRegistering ? (
    <span className='indicator-progress' style={{display: 'block'}}>
      Создание...
      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
    </span>
  ) : (
    intl.formatMessage({id: 'COMPONENTS.ORDERS.ITEM.REGISTER'})
  )

  const registerStatus = item?.params?.cdek_delivery_data?.status
  const registerNeedToUpdate = item?.params?.cdek_delivery_data?.need_to_update

  const disablingRegisterOrderButton =
    !!item?.track_number ||
    ordersState.isRegistering ||
    !whiteListCompanys.find((c) => c === item?.delivery_company) ||
    (registerStatus === 'invalid' && !registerNeedToUpdate) ||
    ((registerStatus === 'success' || registerStatus === 'successfull') && !registerNeedToUpdate)

  return item ? (
    <OrdersItem
      isLoading={ordersState.isItemLoading}
      title={`${intl.formatMessage({id: 'COMPONENTS.ORDERS.ITEM.TITLE'})} ${item.number} ${
        createdDate ? `от ${dateUtils.standartDateTimeString(createdDate)}` : ``
      }`}
      item={item}
      accounts={accountState.items}
      marketplaces={marketplaceState.items}
      companys={companyState.items}
      saveTrackInfo={saveTrackInfo}
      resetTrackInfo={resetTrackInfo}
      deliveryCompanys={deliveryCompanyState.items}
      isOrderTrackLoading={ordersState.isTrackLoading}
    >
      <Col className='p-2'>
        <DropdownButton
          className='w-100'
          as={ButtonGroup}
          title={item.status_name}
          id='bg-nested-dropdown'
        >
          {orderStatusesState?.items
            ?.filter((i) => i.manual_change)
            ?.map((os) => (
              <Dropdown.Item
                defaultChecked={item.status_code === os.code}
                className={`${item.status_code === os.code ? 'bg-secondary' : ''}`}
                eventKey={os.code}
                onClick={() => changeStatus(os)}
              >
                {os.name}
              </Dropdown.Item>
            ))}
        </DropdownButton>
        <ModalInfo
          title={intl.formatMessage({id: 'COMPONENTS.ORDERS.LIST.MODALS.PRINT.TITLE'})}
          showModal={showModal}
          setShowModal={setShowModal}
        />
      </Col>
      <Col className='p-2'>
        <Button className='w-150px fs-7' onClick={printBarcode} disabled={!item.printable}>
          {intl.formatMessage({id: 'COMPONENTS.ORDERS.LIST.BUTTONS.PRINT'})}
        </Button>
      </Col>
      <Col className='p-2'>
        <Button
          className='fs-7'
          style={{width: '155px'}}
          onClick={registerOrder({account_id: item.account, order_id: item.id})}
          disabled={disablingRegisterOrderButton}
        >
          {registerOrderButtonContent}
        </Button>
      </Col>
      <Col className='p-2'>
        <SbxLightButton onClick={goBack}>
          {intl.formatMessage({id: props.id ? 'ACTION.CLOSE' : 'ACTION.BACK'})}
        </SbxLightButton>
      </Col>
    </OrdersItem>
  ) : (
    <Loader1 />
  )
}

export default OrdersItemWrapper
