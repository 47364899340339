import {IUrlParameters} from '../../components/lists/UrlFilteredList'
import {IMPCardExtended} from '../mpcards/types'
import {MARKETING_DEFAULT_LIMIT} from './constants'

export interface IMarketingKeyPhrase {
  name: string
  key: string
  isNew: boolean
}

export interface IMarketingGoodShort {
  nmid: number
  name: string

  // no keys from server
  keyPhrases?: IMarketingKeyPhrase[]
}

export interface ICategoryWithGoods {
  subject_id: number
  subject_name: string
  goods: IMarketingGoodShort[]
}

export interface IAccountWithCategories {
  account_id: number
  objects: ICategoryWithGoods[]
}

export interface IMarketingState {
  count: number
  isLoading: boolean
  items: IAccountWithCategories[]
  accountsWithCampaigns: IAccountWithCampaign[]
  filter: IUrlParametersLight
}

export interface IPrepareGoodRequestParams {
  customer_uids: string[]
  accounts: number[] | null
}

export interface IAccountWithCampaign {
  id: number
  name: string
  campaigns: ICampaign[]
}

export interface IMarketingCampaignMpCard {
  id?: number
  good_id?: number
  name?: string
  nmid: number
}

// Type TStrategyType
let strategyTypes: 'bid' | 'place' | 'place_bid'
export type TStrategyType = typeof strategyTypes

let procStatus: 'ready' | 'error' | 'processing' | 'pause' | 'LOCALEDITING'
export type TCampaignProcessingStatus = typeof procStatus

interface ICampaignStatus {
  code: TCampaignProcessingStatus
  text: string
}

export interface ICampaign {
  advert_id: number
  budget: number // total on deposit now
  place: number
  rate: number
  strategy: TStrategyType
  advert_type: 0 | 1 // 0 - automatic, 1 - manual
  name?: string
  // mpcards: IMPCardExtended[]
  mpcards: IMarketingCampaignMpCard[]
  keywords: string[]
  status?: ICampaignStatus | null

  // custom local
  budget_add?: number // how much to add on budget
  isLoading?: boolean
  account_id?: number
  subject_id?: number
  subject_name?: string
  tmpKey?: number // need when create new campaign and edit before save to ident it inside array
  isValid: boolean
}

export interface IPauseCampaignRequestParams {
  id: number
  enabled: boolean
}

export interface ICreateCampaignRequestParams {
  advert_id?: number // id of campaign to update
  account_id: number
  name: string
  nms: string[]
  keywords: string[]
  // budget: number
  budget_add?: number // how much to add on budget
  advert_type: number
  place: number
  rate: number
  strategy: string
  subject_id: number
}

export interface ICreateCampaignsResult {
  object_id?: number
  task_id?: string //"d66559d6-be50-4863-8dd0-17e028dceb0a"
  success: boolean
  error?: any

  // error?: string[]
  // success: true
}

export interface IPauseResumeCampaignResult {
  advert_id: number
  status: ICampaignStatus
}

export interface ICampaignSaveData {
  changedCampaigns: ICampaign[]
  account_id: number
}

export interface IRequestParamsOzonPromo {
  account_id: number
  active: boolean
}

export const emptyCampaign: ICampaign = {
  advert_id: 0,
  budget: 0,
  place: 1,
  rate: 0,
  advert_type: 0, // 0 - automatic, 1 - manual
  name: '',
  strategy: 'bid',
  // mpcards: IMPCardExtended[]
  mpcards: [],
  keywords: [],
  isValid: true,
}

export interface IUrlParametersLight
  extends Omit<IUrlParameters, 'pageNumber' | 'filter' | 'itemsPerPage' | 'ordering'> {
  pageNumber?: number
  filter?: string
  itemsPerPage?: number
  ordering?: string
}

export const defaultMarketingUrlParametersLight: IUrlParametersLight = {
  url: '/marketing/companies',
  // url: "/marketing/campaigns",
  additionalParameters: [],
  filter: '',
  itemsPerPage: MARKETING_DEFAULT_LIMIT,
  ordering: '',
  pageNumber: 0,
}

export const initialMarketing: IMarketingState = {
  count: 0,
  isLoading: false,
  items: [],
  accountsWithCampaigns: [],
  filter: defaultMarketingUrlParametersLight,
}

export const marketingActionTypes = {
  AddCampaign: '[AddCampaign] Action',
  CreateCampaign: '[CreateCampaign] Action',
  UpdateCampaign: '[UpdateCampaign] Action',
  DeleteCampaign: '[DeleteCampaign] Action',
  PauseResumeCampaign: '[PauseResumeCampaign] Action',
  SetCompaniesFilter: '[SetCompaniesFilter] Action',
  GetCompanies: '[GetCompanies] Action',
  GetPrepareGoods: '[GetPrepareGoods] Action',
  GetKeywords: '[GetKeywords] Action',
  ChangeOzonPromoGoods: '[ChangeOzonPromoGoods] Action',
  ChangeOzonBids: '[ChangeOzonBids] Action',
}
