import {takeLatest, takeEvery, select} from 'redux-saga/effects'
import {
  setIsLoading,
  setGoodsGroups,
  setSelectedGoodsGroup,
  updateGoodsGroups,
  deleteGoodsGroups,
} from './reducer'
import {
  DELETE_ASYNC_GOODS_GROUP_BY_ID,
  FETCH_GOODS_GROUP_BY_ID,
  FETCH_GOODS_GROUPS,
  UPDATE_ASYNC_GOODS_GROUP_BY_ID,
} from './actions'
import {
  deleteGoodsGroupOnServer,
  fetchGoodsGroupById,
  fetchGoodsGroups,
  updateGoodsGroupOnServer,
} from './api'
import {sagaUtils} from '../../utils/dataUtils'
import {intl} from '../../modules/goods/item/validation'
import {StorageType} from '../../../setup'
import {IGoodsGroupsStorage} from './types'

export function* goodsGroupsSaga() {
  yield takeLatest(FETCH_GOODS_GROUPS, workerFetchGoodsGroups)
  yield takeEvery(FETCH_GOODS_GROUP_BY_ID, workerFetchGroupById)
  yield takeEvery(UPDATE_ASYNC_GOODS_GROUP_BY_ID, workerUpdateGroupById)
  yield takeEvery(DELETE_ASYNC_GOODS_GROUP_BY_ID, workerDeleteGroupById)
}

export function* workerFetchGoodsGroups() {
  const {filters}: IGoodsGroupsStorage = yield select((storage: StorageType) => storage.goodsGroups)

  yield sagaUtils.workerWrapper(
    setIsLoading,
    fetchGoodsGroups,
    filters,
    setGoodsGroups,
    null,
    intl.formatMessage({id: 'SAGAS.GOODS.GROUPS.GET'}),
    'GET',
    'data',
    false,
    '',
    undefined,
    undefined
  )
}

export function* workerFetchGroupById(action: any): any {
  yield sagaUtils.workerWrapper(
    setIsLoading,
    fetchGoodsGroupById,
    action.payload,
    setSelectedGoodsGroup,
    null,
    intl.formatMessage({id: 'SAGAS.GOODS.GROUPS.GET'}),
    'GET',
    'data',
    false
  )
}

export function* workerUpdateGroupById(action: any): any {
  yield sagaUtils.workerWrapper(
    setIsLoading,
    updateGoodsGroupOnServer,
    action.payload,
    updateGoodsGroups,
    action.payload,
    intl.formatMessage({id: 'SAGAS.GOODS.GROUPS.SAVE'}),
    'SAVE',
    'data',
    true,
    ''
  )
}

export function* workerDeleteGroupById(action: any): any {
  yield sagaUtils.workerWrapper(
    setIsLoading,
    deleteGoodsGroupOnServer,
    action.payload,
    deleteGoodsGroups,
    action.payload,
    intl.formatMessage({id: 'SAGAS.GOODS.GROUPS.DELETE'}),
    'DELETE',
    'direct',
    true,
    ''
  )
}
