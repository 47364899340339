import {createContext, FC} from 'react'
import {createContextualCan} from '@casl/react'
import {PureAbility} from '@casl/ability'
import {AppAbility, Action, Subject} from '../../setup/ability'

const AbilityContext = createContext<PureAbility<[Action, Subject], any>>(
  {} as PureAbility<[Action, Subject], any>
)
const Can = createContextualCan(AbilityContext.Consumer)

interface IAbilityProviderProps {
  ability: AppAbility
}

const AbilityProvider: FC<IAbilityProviderProps> = ({children, ability}) => {
  return <AbilityContext.Provider value={ability}>{children}</AbilityContext.Provider>
}

export default AbilityContext
export {AbilityProvider, Can}
