import axios, {AxiosResponse} from 'axios'
import {
  getAdditionalParametersUrl,
  IFilterRequestParams,
  IUrlParameters,
} from '../../components/lists/UrlFilteredList'
import {ITaskResponse} from '../tasks/types'
import {DEFAULT_ORDERS_GET_LIMIT, DEFAULT_ORDERS_OFFSET} from './constants'
import {
  IOrderBarcodeRequest,
  IOrderChangeStatusesRequest,
  IOrderChangeStatusRequest,
  IOrderItem,
  IOrderListRequest,
  IOrderListResponse,
  IOrderRegisterRequest,
} from './types'
import {IDeliveryTrackOrderRequest} from '../deliveryServices/types'
import {readBlobAsText} from '../mpdocs/api'

const API_URL = `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENDPOINT_PREFIX}`
export const ORDERS_URL = `${API_URL}v2/orders/`

// =================   get orders =================
// #region
//https://api-dev.shop-delivery.ru/int_api/v2/orders/?limit=10&offset=1

// get all orders from server
export const getOrdersFromServer = (params: IOrderListRequest) => {
  return axios.get<IOrderListResponse>(
    `${ORDERS_URL}?limit=${params.limit ?? DEFAULT_ORDERS_GET_LIMIT}${
      params.company != undefined ? `&company=${params.company}` : ``
    }${params.created_after != undefined ? `&created_after=${params.created_after}` : ``}${
      params.created_before != undefined ? `&created_before=${params.created_before}` : ``
    }${params.expand != undefined ? `&expand=${params.expand}` : ``}${
      params.marketplace != undefined ? `&marketplace=${params.marketplace}` : ``
    }${params.number != undefined ? `&number=${params.number}` : ``}${
      params.numbers != undefined ? `&numbers=${params.numbers}` : ``
    }${params.offset != undefined ? `&offset=${params.offset}` : ``}${
      params.ordering != undefined ? `&ordering=${params.ordering}` : ``
    }${params.search != undefined ? `&search=${params.search}` : ``}${
      params.ship_at_after != undefined ? `&ship_at_after=${params.ship_at_after}` : ``
    }${params.ship_at_before != undefined ? `&ship_at_before=${params.ship_at_before}` : ``}${
      params.status != undefined ? `&status=${params.status}` : ``
    }`
  )
}

// get all orders from server
export const getOrdersWithFilterFromServer = (params: IUrlParameters) => {
  const offset =
    params.pageNumber != undefined && params.itemsPerPage != undefined
      ? params.pageNumber * params.itemsPerPage
      : DEFAULT_ORDERS_OFFSET

  return axios.get<IOrderListResponse>(
    `${ORDERS_URL}?limit=${params.itemsPerPage ?? DEFAULT_ORDERS_GET_LIMIT}&offset=${offset}${
      params.filter != undefined ? `&search=${params.filter}` : ``
    }${
      params.ordering != undefined ? `&ordering=${params.ordering}` : ``
    }${getAdditionalParametersUrl(params)}`
  )
}

// #endregion

// =================   get order =================
// #region
//https://api-dev.shop-delivery.ru/int_api/v2/orders/11111/

// get order by id from server
export const getOrderFromServer = (params: number) => {
  return axios.get<IOrderItem>(`${ORDERS_URL}${params}/`)
}

// #endregion

// =================   set order status =================
// #region
//https://api-dev.shop-delivery.ru/int_api/v2/orders/11111/set_order_status/

// set order status by id
export const setOrderStatusOnServer = (params: IOrderChangeStatusRequest) => {
  return axios.post<ITaskResponse>(`${ORDERS_URL}${params.id}/set_order_status/`, {
    status: params.status.code,
  })
}

// #endregion

// =================   set order statuses =================
// #region
//https://api-dev.shop-delivery.ru/int_api/v2/orders/11111/bulk_status_change/

// set orders statuses by ids
export const setOrderStatusesOnServer = (params: IOrderChangeStatusesRequest) => {
  return axios.post<IOrderListResponse>(`${ORDERS_URL}bulk_status_change/`, {
    status: params.status.code,
    orders: params.orders,
  })
}

// #endregion

// =================   get order stickers =================
// #region
//https://api-dev.shop-delivery.ru/int_api/v2/orders/11111/get_sticker/

// set order status by id
export const getOrderStickersOnServer = (params: IOrderBarcodeRequest) => {
  return axios
    .post<ITaskResponse>(`${ORDERS_URL}get_sticker/`, params, {responseType: 'blob'})
    .catch(async (response) => {
      const err = await readBlobAsText(response.response.data)
      const error: any = JSON.parse(err as string)
      throw {response: {data: error}}
    })
}

// #endregion

// =================   set order track number and delivery company =================
// #region
//https://api-dev.shop-delivery.ru/int_api/v2/orders/11111/set_track_number/

// set track number and delivery company
export const setDeliveryTrackNumberOnServer = (params: IDeliveryTrackOrderRequest) => {
  return params.company && params.company.id >= 0
    ? axios.post<any>(`${ORDERS_URL}${params.orderId}/set_track_number/`, {
        track_number: params.track,
        delivery_service_id: params.company?.id,
      })
    : null
}

// #endregion

export const loadTrueSigns = (params: any) => {
  return axios.post<any>(`https://e24f3207fc211f27.mokky.dev/create_true_signs`, params)
  //   return axios.post<any>(`${ORDERS_URL}load_true_signs/`, params)
}

// =================   clear order track number and delivery company =================
// #region
//https://api-dev.shop-delivery.ru/int_api/v2/orders/11111/delete_track_number/

// reset track number and delivery company
export const resetDeliveryTrackNumberOnServer = (orderId: number) => {
  return axios.delete<any>(`${ORDERS_URL}${orderId}/delete_track_number/`)
}

// #endregion

// =================   register order =================
// #region
//https://api-dev.shop-delivery.ru/int_api/v2/orders/register_orders/

// register order
export const registerOrderOnServer = (params: IOrderRegisterRequest) => {
  return axios.post<any>(`${ORDERS_URL}register_orders/`, params)
}

// #endregion
