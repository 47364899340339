import {call, put, takeEvery} from 'redux-saga/effects'
import {fetchCdekPVZ} from './api'
import {setCdekPvz} from './reducer'
import {ASYNC_GET_PVZ_CDEK} from './actions'
import {sagaUtils} from '../../utils/dataUtils'

export function* cdekPvzSaga() {
  yield takeEvery(ASYNC_GET_PVZ_CDEK, workerGetCdekPvz)
}

export function* workerGetCdekPvz(): any {
  const cdekPvzPromise = yield call(fetchCdekPVZ, {query: ''})
  yield put(setCdekPvz(cdekPvzPromise.data))
}

// export function* workerGetCdekPvz(): any {
//   yield sagaUtils.workerWrapper(
//     setPvzLoading,
//     fetchCdekPVZ,
//     null,
//     setCdekPvz,
//     null,
//     'Получаю точки выдачи',
//     'GET',
//     'data.results',
//     false,
//     ''
//   )
// }
