

export interface BaseCustomButtonProps{
    onClick?: (data? :any) => void
    className?: string
    childrenClassName?: string
    style?: any
    colorName?: "1" | "2" | "3" | "4" | "5" | "6" | "7" | "8" | "9" | "10" | "primary" | "danger" | "info" | "warning" | "white" | "success" | "custom"  // variable name in /src/_metronic/assets/sass/components/_variables.custom.scss
    onBlur?: any
    onFocus?: any
    children?: any
    title?: string
    disabled?: boolean
    caption?: string
    captionClass?: string
    captionInline?:boolean
    offStyle?: boolean // off standart btn styles
    offBtnStyle?: boolean // off btn styles
}

const BaseCustomButton = (props: BaseCustomButtonProps) => {
    return (
        <div 
            title={props.title} 
            onClick={props.onClick}
            onBlur={props.onBlur} 
            onFocus={props.onFocus} 
            style={props.disabled ? {...props.style, opacity: '0.5', pointerEvents: 'none'} : props.style} 
            className={`${props.offStyle ? '' : `btn btn-sm btn-icon d-flex ${props.captionInline ? 'flex-row' : 'flex-column'} ${props.offBtnStyle ? `` : `p-1 m-1 btn-active-color-primary`}`} ${props.className ? props.className : ''}`} 
            >
            <div className={`svg-icon svg-icon-${props.colorName ? props.colorName : 1}`}>
            {/* <span className="svg-icon-1"> */}
                {props.children}
            </div>
            {
                props.caption 
                    ? <div className={` ${props.captionClass ? props.captionClass : ''}`}>{props.caption}</div>
                    : null
            }
        </div>)
}

export default BaseCustomButton