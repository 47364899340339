import {MarketPlaceCode} from '../../../store/marketplaces/types'
import {TMarketplace, TStep} from '../../../store/mpWizard/types'
import {
  ozonLogo,
  wbLogo,
  ymLogo,
  avitoLogo,
  sberLogo,
} from '../../../../_metronic/assets/media/images/marketplaces'

import {WBStep1, WBStep2, WBStep3, WBStep4, WBStep5} from '../components/WBSteps'
import {YMStep1, YMStep2, YMStep3, YMStep4} from '../components/YMSteps'
import {OzonStep1, OzonStep2, OzonStep3, OzonStep4} from '../components/OzonSteps'

export const marketplaces: TMarketplace[] = [
  {
    id: 1,
    code: MarketPlaceCode.WB,
    img: wbLogo,
  },
  {
    id: 2,
    code: MarketPlaceCode.OZON,
    img: ozonLogo,
  },
  {
    id: 5,
    code: MarketPlaceCode.YM,
    img: ymLogo,
  },
  {
    id: 4,
    code: MarketPlaceCode.SBER,
    img: sberLogo,
  },
  {
    id: 6,
    code: MarketPlaceCode.AVITO,
    img: avitoLogo,
  },
]

export const steps: Record<MarketPlaceCode, TStep[]> = {
  [MarketPlaceCode.WB]: [
    {title: 'Шаг 2. Токен авторизации личного кабинета', component: WBStep1},
    {
      title: 'Шаг 3. Авторизация в личный кабинет',
      component: WBStep2,
      canSkip: true,
      skipCount: 2,
    },
    {title: 'Шаг 4. Выберите кабинет', component: WBStep3},
    {title: 'Шаг 5. Привязка к юридическому лицу', component: WBStep4},
    {title: 'Шаг 6. Название кабинета', component: WBStep5},
  ],
  [MarketPlaceCode.YM]: [
    {title: 'Шаг 2. Авторизация в личный кабинетр', component: YMStep1},
    {title: 'Шаг 3. Импорт кабинетов', component: YMStep2},
    {title: 'Шаг 4. Привязка к юридическому лицу', component: YMStep3},
    {title: 'Шаг 5. Название кабинета', component: YMStep4},
  ],
  [MarketPlaceCode.OZON]: [
    {title: 'Шаг 2. Токен авторизации личного кабинета', component: OzonStep1},
    {title: 'Шаг 3. Токен рекламного кабинета', component: OzonStep2},
    {title: 'Шаг 4. Привязка к юридическому лицу', component: OzonStep3},
    {title: 'Шаг 5. Название кабинета', component: OzonStep4},
  ],
  [MarketPlaceCode.SBER]: [],
  [MarketPlaceCode.AVITO]: [],
  [MarketPlaceCode.ALIEXPRESS]: [],
  [MarketPlaceCode.SDEK]: [],
  [MarketPlaceCode.DEFAULT]: [],
}
