import { call, put, takeEvery } from "redux-saga/effects"
import { fetchDeliveryServices } from "./api";
import { setDeliveryServices } from "./reducers"
import { ASYNC_GET_DELIVERY_SERVICES } from "./actions"



export function* deliveryServiceSaga(){
    yield takeEvery(ASYNC_GET_DELIVERY_SERVICES, workerGetDeliveryServices)
}


export function* workerGetDeliveryServices():any{
    //console.log('saga deliveryServices workerGetDeliveryServices')
    const deliveryServicesPromise = yield call(fetchDeliveryServices)
    yield put(setDeliveryServices(deliveryServicesPromise.data.results))
}