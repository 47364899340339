export const DEFAULT_STOCKS_LIMIT = 10


export const emptyState: IStockStorage = {
    items: [],
    selected: null,
    isLoading: false,
    isSavingStockPriceId: null,
    itemsPerPage: 0,
    currentPage: 0,
    ordering: "",
    search: "",
    count: 0,
}


export const emptyStockItem: IStockItem = {
    id: 0,
    warehouse: 0,
    sku_customer_uid: "",
    quantity: 0,
    reserved: 0,
}

export interface ISavingPriceItem {warehouseId: number, stockId: number}

export interface IStockStorage{
    items: IStockListItem[]
    selected: IStockItem | null
    isLoading: boolean
    isSavingStockPriceId: ISavingPriceItem | null,
    itemsPerPage: number | null
    currentPage:  number | null
    ordering:  string | null
    search:  string | null
    count: number | null
}

export interface IStockRequestParameters{
    isLoading?: boolean
    itemsPerPage?: number | null
    currentPage?:  number | null
    ordering?:  string | null
    search?:  string | null
}

export interface IStockListItem{
    id: number
    name?: string | null
    customer_uid?: string | null
    good?: {
        id: number
        name: string
    } | null
    stocks?: IStockItem[] | null
}

export interface IStockItem {
    id: number
    warehouse?: number | null
    sku_customer_uid?: string | null
    quantity?: number | null
    reserved?: number | null
}

export interface IStockListResponse{
    count: number
    results: IStockListItem[]
}

export interface IStockUpdateParams{
    quantity?: number
    reserved?: number
    id: number
    sku_customer_uid?: string
    warehouse?: number
    stockId?: number
}

export interface IStockAddParams{
    quantity?: number
    reserved?: number
    sku_customer_uid?: string
    warehouse?: number
    stockId?: number
}


export const stockActionTypes = {
    GetStocks: '[GetStocks] Action',
    UpdateStock: '[UpdateStock] Action',
    SetParametersStock: '[SetParametersStock] Action',
    AddParametersStock: '[AddParametersStock] Action',
}