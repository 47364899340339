export const initialWarehouses: IWarehouseState = {
  count: 0,
  items: [],
  isLoading: false,
  selected: null,
}

export const emptyWarehouse: IWarehouse = {
  id: -1,
  name: '',
  params: null,
  is_virtual: false,
  owner: null,
  is_fulfilment: false,
}

export interface IWarehouse {
  id: number
  name: string
  params: any
  is_virtual: boolean | null
  owner?: number | null
  is_fulfilment?: boolean | null
}

export interface IWarehouseState {
  count: number
  items: IWarehouse[]
  selected: IWarehouse | null
  isLoading: boolean
}

export interface IWarehouseListRequestParams {
  limit?: number
  offset?: number
}

export interface IWarehouseResponse {
  id: string
  label: string
}

export interface IWarehouseListResponse {
  count: number
  next: string
  previous: string
  results: IWarehouse[]
  //marketplaces: Array<IAccount> | null
}

export const warehouseActionTypes = {
  GetWarehouses: '[GetWarehouses] Action',
  AddWarehouse: '[AddWarehouse] Action',
  UpdateWarehouse: '[UpdateWarehouse] Action',
  DeleteWarehouse: '[DeleteWarehouse] Action',
}
