import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {put, takeLatest, select, call} from 'redux-saga/effects'
import {getAsyncAccountsCreator} from '../accounts/actions'
import {getAsyncCompetitorsCreator} from '../competitors/actions'
import {getAsyncCustomerCompanysCreator} from '../customerCompanys/actions'
import {getAsyncCustomersCreator} from '../customers/actions'
import {getAsyncGoodsCreator} from '../goods/actions'
import {getAsyncMarketplacesCreator} from '../marketplaces/actions'
import {getAsyncOrderStatusesCreator} from '../orderStatuses/actions'
import {getAsyncWarehousesCreator} from '../warehouses/actions'
import {updateAbility} from '../../../setup/ability'
import {getUserByToken, getCall, senAuthCodeToServer} from './AuthCRUD'
import {getAsyncDeliveryServicesCreator} from '../deliveryServices/actions'

import {emptyCustomer, emptyUser, ICustomer, IUserModel} from '../../modules/auth/models/UserModel'
import {AppAbility} from '../../../setup/ability'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  Login: '[Login] Action',
  Logout: '[Logout] Action',
  UserRequested: '[Request User] Action',
  UserLoaded: '[Load User] Auth API',
  SetUser: '[Set User] Action',
  UpdateUser: '[UpdateUser] Action',
  UpdateUserInfo: '[UpdateUserInfo] Action',
  SetCustomerInfo: '[SetCustomerInfo] Action',
  GetRefreshToken: '[GetRefreshToken] Action',
  SetRefreshToken: '[SetRefreshToken] Action',
  SetTokens: '[SetTokens] Action',
  UpdateToken: '[UpdateToken] Action',
  SetAccessToken: '[SetAccessToken] Action',
  LoadDictionaries: '[LoadDictionaries] Action',
  GetCall: '[GetCall] Action',
  SendAuthCode: '[SendAuthCode] Action',
  SetConfirmationStatus: '[SetConfirmationStatus] Action',
  SetConfirmationLoading: '[SetConfirmationLoading] Action',
}

const initialAuthState: IAuthState = {
  user: undefined,
  access: undefined,
  refresh: undefined,
  confirmationLoading: false,
  confirmationStatus: '',
}

export interface IAuthState {
  user?: IUserModel
  access?: string
  refresh?: string
  confirmationLoading?: boolean
  confirmationStatus?: string
}

export interface IAuthResponseState {
  user: {
    results: IUserModel[]
  }
  access?: string
  refresh?: string
}

export const reducer = persistReducer(
  {
    storage,
    key:
      process.env.REACT_APP_BASE_LAYOUT_CONFIG_KEY ??
      'shopdelivery-ru' /*, whitelist: ['user', 'access', 'refresh']*/,
  },
  (state: IAuthState = initialAuthState, action: any): IAuthState => {
    switch (action.type) {
      case actionTypes.Login: {
        const access = action.payload?.access
        const refresh = action.payload?.refresh
        return {access, refresh, user: undefined}
      }

      case actionTypes.Logout: {
        return initialAuthState
      }

      case actionTypes.UserLoaded: {
        const user = action.payload?.user?.result ? action.payload.user.result : emptyUser
        return {...state, user}
      }

      case actionTypes.SetUser: {
        const user = action.payload?.user ? action.payload.user : emptyUser
        return {...state, user}
      }

      case actionTypes.UpdateUser: {
        const user = action.payload ? action.payload.user : emptyUser
        return {...state, user}
      }

      case actionTypes.UpdateUserInfo: {
        const user = action.payload?.user ? action.payload.user : emptyUser
        return {...state, user}
      }

      case actionTypes.SetCustomerInfo: {
        console.log('actionTypes.SetCustomerInfo action.payload', action.payload)
        const customer: ICustomer = action.payload ? action.payload : emptyCustomer
        return {...state, user: {...(state.user ?? emptyUser), customer}}
      }

      case actionTypes.GetRefreshToken: {
        return {...state}
      }

      case actionTypes.SetRefreshToken: {
        state.refresh = action.payload?.refresh
        return {...state}
      }

      case actionTypes.SetTokens: {
        state.refresh = action.payload?.refresh
        state.access = action.payload?.access
        return {...state}
      }

      case actionTypes.SetAccessToken: {
        state.access = action.payload?.access
        return {...state}
      }

      case actionTypes.SetConfirmationLoading: {
        state.confirmationLoading = action.payload
        return {...state}
      }

      case actionTypes.SetConfirmationStatus: {
        state.confirmationStatus = action.payload
        return {...state}
      }

      default:
        return state
    }
  }
)

export const actions = {
  login: (data: IAuthState, ability: AppAbility) => ({
    type: actionTypes.Login,
    payload: {access: data.access, refresh: data.refresh, curAbility: ability},
  }),
  logout: () => ({type: actionTypes.Logout}),
  requestUser: (ability: AppAbility) => ({
    type: actionTypes.UserRequested,
    payload: ability,
  }),
  fulfillUser: (user: IUserModel) => ({type: actionTypes.UserLoaded, payload: {user}}),
  loadDictionaries: () => ({type: actionTypes.LoadDictionaries}),
  setUser: (user: IUserModel) => ({type: actionTypes.SetUser, payload: {user}}),
  updateUser: (user: IUserModel) => ({type: actionTypes.UpdateUser, payload: {user}}),
  updateUserInfo: (user: IUserModel) => ({type: actionTypes.UpdateUserInfo, payload: {user}}),
  setCustomerInfo: (customerInfo: ICustomer) => ({
    type: actionTypes.SetCustomerInfo,
    payload: {...customerInfo},
  }),
  updateTokens: (refreshToken: string) => ({
    type: actionTypes.UpdateToken,
    payload: {refresh: refreshToken},
  }),
  setAccessToken: (data: IAuthState) => ({
    type: actionTypes.SetAccessToken,
    payload: {access: data.access},
  }),
  getCall: (userId: number) => ({
    type: actionTypes.GetCall,
    payload: {userId},
  }),
  sendAuthCode: (userId: number, code: string) => ({
    type: actionTypes.SendAuthCode,
    payload: {userId, code},
  }),
  setConfirmationLoading: (confirmationLoading: boolean) => ({
    type: actionTypes.SetConfirmationLoading,
    payload: confirmationLoading,
  }),
  setConfirmationStatus: (confirmationStatus: string) => ({
    type: actionTypes.SetConfirmationStatus,
    payload: confirmationStatus,
  }),
}

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga(action: {type: string; payload: any}) {
    yield put(actions.requestUser(action.payload.curAbility))
  })

  yield takeLatest(actionTypes.LoadDictionaries, function* loginSaga() {
    yield put(getAsyncCustomerCompanysCreator())
    yield put(getAsyncMarketplacesCreator())
    yield put(getAsyncCompetitorsCreator())
    yield put(getAsyncCustomersCreator())
    yield put(getAsyncDeliveryServicesCreator())
    yield put(getAsyncAccountsCreator())
    yield put(getAsyncGoodsCreator())
    yield put(getAsyncOrderStatusesCreator())
    yield put(getAsyncWarehousesCreator({}))
  })

  yield takeLatest(
    actionTypes.UserRequested,
    function* userRequested(action: {type: string; payload: AppAbility}) {
      // @ts-ignore
      const getToken = (state) => state.auth.accessToken
      // @ts-ignore
      let token = yield select(getToken)
      const {data: user} = yield getUserByToken(token)
      yield call(updateAbility, user.result.access_group, action.payload)
      yield put(actions.fulfillUser(user))

      if (user.result.params?.number_confirm) {
        yield put(actions.loadDictionaries())
      }
    }
  )

  yield takeLatest(
    actionTypes.GetCall,
    function* getCallWorker(action: {type: string; payload: {userId: number}}) {
      yield put(actions.setConfirmationLoading(true))
      yield put(actions.setConfirmationStatus(''))

      console.log('getCallWorker', action.payload)

      try {
        yield call(getCall, action.payload.userId)
      } catch (error) {
        yield put(actions.setConfirmationStatus('Произошла ошибка при инициализации звонка'))
      } finally {
        yield put(actions.setConfirmationLoading(false))
      }
    }
  )

  yield takeLatest(
    actionTypes.SendAuthCode,
    function* sendCodeWorker(action: {type: string; payload: {userId: number; code: string}}) {
      yield put(actions.setConfirmationLoading(true))

      try {
        yield call(senAuthCodeToServer, action.payload.userId, action.payload.code)

        yield put(actions.setConfirmationLoading(false))

        const getUser = (state: any): IUserModel => state.auth.user

        // @ts-ignore
        const user = yield select(getUser)

        yield put(actions.updateUser({...user, params: {...user.params, number_confirm: true}}))

        yield put(actions.loadDictionaries())
      } catch (error) {
        yield put(actions.setConfirmationLoading(false))
        yield put(actions.setConfirmationStatus('Введен неправильный код'))
      }
    }
  )
}
