/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {Link} from 'react-router-dom'
import {useLayout} from '../../core'
import {toAbsoluteUrl} from '../../../helpers'
import Version from './Version'

interface AsideLogoProps {
  to?: string
}
const AsideLogo = (props: AsideLogoProps) => {
  const {config, classes} = useLayout()
  const {aside} = config

  return (
    <div
      className='flex-column-auto px-5 d-flex justify-content-center align-items-center'
      id='kt_aside_logo'
    >
      {/* begin::Logo */}
      {aside.theme === 'dark' && (
        <Link
          to={props.to ? props.to : '/home'}
          className='position-relative'
          style={props.to ? {cursor: 'auto'} : {}}
        >
          <img
            alt='Logo'
            className='h-25px logo mb-1'
            src={toAbsoluteUrl('/media/logos/ShopDelivery.svg')}
          />
          <Version
            className='text-white position-absolute fs-8'
            style={{right: '20px', bottom: '-10px'}}
          />
        </Link>
      )}
      {aside.theme === 'light' && (
        <Link
          to={props.to ? props.to : '/home'}
          className='position-relative'
          style={props.to ? {cursor: 'auto'} : {}}
        >
          <img
            alt='Logo'
            className='h-25px logo'
            src={toAbsoluteUrl('/media/logos/ShopDelivery_black.svg')}
          />
          <Version
            className='text-white position-absolute fs-8'
            style={{right: '20px', bottom: '-10px'}}
          />
        </Link>
      )}
      {/* end::Version */}
    </div>
  )
}

export {AsideLogo}
