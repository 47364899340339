import { ForwardRefRenderFunction, ReactElement, forwardRef, } from "react"


interface IMixedArrayFieldProps {
  title?: React.ReactNode | string
  subTitle?: React.ReactNode | string
  className?: string
  bodyClassName?: string
  headerClassName?: string
  rightTopElement?: React.ReactNode
  children?: React.ReactNode
  style?: object
  cardHearedClassIsOff?: boolean
  cardBodyClassIsOff?: boolean
  footerChildren?: any
  footerClassNames?: string
  titleIsRow?: boolean

  ref?: React.MutableRefObject<HTMLDivElement>
  customTitle?: ReactElement
}

const CardSimple: ForwardRefRenderFunction<HTMLDivElement, IMixedArrayFieldProps> = (props, ref) => {

  return (
    <div className={`card ${props.className ?? ""}`}
      style={props.style}
      ref={ref}
    >
      {
        props.title || props.subTitle
          ? <div className={`card-header ${props.headerClassName ? props.headerClassName : " border-bottom"}`}>
            {
              props.titleIsRow
                ? <h3 className='card-title align-items-center justify-content-start flex-row'>
                  {
                    props.customTitle
                    ? props.customTitle
                    : <span className='card-label fw-bolder fs-3 me-5'>{props.title}</span>
                  }
                  <span className='text-muted fw-bold fs-7'>{props.subTitle}</span>
                </h3>
                : <h3 className='card-title align-items-start flex-column'>
                  <span className='card-label fw-bolder fs-3 mb-1'>{props.title}</span>

                  <span className='text-muted fw-bold fs-7'>{props.subTitle}</span>
                </h3>
            }


            <div className='card-toolbar'>
              {props.rightTopElement}
            </div>
          </div>
          : null
      }
      <div className={`${props.cardBodyClassIsOff ? '' : 'card-body'} w-100 d-flex flex-column ${props.bodyClassName ? props.bodyClassName : ""}`}>
        {props.children}
      </div>
      {
        props.footerChildren
          ? <div className={`card-footer ${props.footerClassNames ?? ""}`}>
            {props.footerChildren ?? null}
          </div>
          : null
      }
    </div>
  )
}

export default forwardRef(CardSimple)