export const initialAccount: IAccountState = {
  count: 0,
  next: '',
  previous: '',
  items: [
    {
      id: 0,
      name: '',
      company: 0,
      marketplace: 0,
    },
  ],
  isLoading: false,
  isSuccessLastOperation: false,
}

type wbParamsForAcc = {
  authorization: string
  wb_lk_token: string
  wb_lk_supplier_id: string
  wbx_validation_key: string
}

type wbMarketplace = 1
export interface IWBAccount {
  name: string
  company: number
  marketplace: wbMarketplace
  params?: wbParamsForAcc
}

export interface IAccount {
  id: number
  name: string
  company: number
  marketplace: number
  params?: {
    ozon_bids_active?: boolean
    promo_goods_active?: boolean
    authorization?: string
    api_id?: string
    api_key?: string
    perfomance_api_key?: string
    perfomance_api_secret?: string
  }
  settings_v2?: any
}

export const emptyAccount: IAccount = {
  id: -1,
  name: '',
  company: -1,
  marketplace: -1,
  params: {
    ozon_bids_active: false,
    promo_goods_active: false,
  },
}

export interface IAccountState {
  count: number
  next: string
  previous: string
  items: Array<IAccount>
  isLoading: boolean
  isSuccessLastOperation: boolean
}

export const accountActionTypes = {
  GetAccounts: '[GetAccounts] Action',
  SetAccounts: '[SetAccounts] Action',
  UpdateAccount: '[UpdateAccount] Action',
  AddAccount: '[AddAccount] Action',
  DeleteAccount: '[DeleteAccount] Action',
  SetIsLoadingAccounts: '[SetIsLoadingAccounts] Action',
  AddAccountNoApi: '[AddAccountNoApi] Action',
  SetAccountNoApi: '[SetAccountNoApi] Action',
}
