import clsx from 'clsx'
import {FC} from 'react'
import {useSelector} from 'react-redux'
import {IUserModel} from '../../../../app/modules/auth/models/UserModel'
import {StorageType} from '../../../../setup'
import {toAbsoluteUrl} from '../../../helpers'
import {HeaderUserMenu} from '../../../partials'
import {useLayout} from '../../core'
import {Button} from 'react-bootstrap'
import {MenuItem} from './MenuItem'
import {MenuItemProfile} from './MenuItemProfile'
import {intl} from '../../../../app/modules/goods/item/validation'

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px',
  toolbarButtonIconSizeClass = 'svg-icon-1'

interface ITopbar {
  to?: string
}
const Topbar = (props: ITopbar) => {
  const user: IUserModel = useSelector((state: StorageType) => state.auth.user)
  // console.log('render Topbar user',user)
  const {config} = useLayout()

  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>
      {/* Search */}
      {/* <div className={clsx('d-flex align-items-stretch', toolbarButtonMarginClass)}>
        <Search />
      </div> */}
      {/* Activities */}
      {/* <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}> */}
      {/* begin::Drawer toggle */}
      {/* <div
          className={clsx('btn btn-icon btn-active-light-primary btn-custom', toolbarButtonHeightClass)}
          id='kt_activities_toggle'
        >
          <KTSVG
            path='/media/icons/duotune/general/gen032.svg'
            className={toolbarButtonIconSizeClass}
          />
        </div> */}
      {/* end::Drawer toggle */}
      {/* </div> */}

      {/* NOTIFICATIONS */}
      {/* <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}> */}
      {/* begin::Menu- wrapper */}
      {/* <div
                className={clsx(
                    'btn btn-icon btn-active-light-primary btn-custom',
                    toolbarButtonHeightClass
                )}
                data-kt-menu-trigger='click'
                data-kt-menu-attach='parent'
                data-kt-menu-placement='bottom-end'
                data-kt-menu-flip='bottom'
            >
                <KTSVG
                    path='/media/icons/duotune/general/gen022.svg'
                    className={toolbarButtonIconSizeClass}
                />
            </div>
            <HeaderNotificationsMenu /> */}
      {/* end::Menu wrapper */}
      {/* </div> */}

      {/* CHAT */}
      {/* <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}> */}
      {/* begin::Menu wrapper */}
      {/* <div
          className={clsx(
            'btn btn-icon btn-active-light-primary btn-custom position-relative',
            toolbarButtonHeightClass
          )}
          id='kt_drawer_chat_toggle'
        >
          <KTSVG
            path='/media/icons/duotune/communication/com012.svg'
            className={toolbarButtonIconSizeClass}
          />

          <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink'></span>
        </div> */}
      {/* end::Menu wrapper */}
      {/* </div> */}

      {/* Quick links */}
      {/* <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}> */}
      {/* begin::Menu wrapper */}
      {/* <div
                className={clsx('btn btn-icon btn-active-light-primary btn-custom', toolbarButtonHeightClass)}
                data-kt-menu-trigger='click'
                data-kt-menu-attach='parent'
                data-kt-menu-placement='bottom-end'
                data-kt-menu-flip='bottom'
            >
                <KTSVG
                    path='/media/icons/duotune/general/gen025.svg'
                    className={toolbarButtonIconSizeClass}
                />
            </div>
            <QuickLinks /> */}
      {/* end::Menu wrapper */}
      {/* </div> */}
      {/* <div
        className={clsx('d-flex align-items-center me-3', toolbarButtonMarginClass)}
        id='kt_header_user_name'
      >{user?.firstname} {user?.lastname}</div> */}

      {/* begin::User */}
      <div
        // className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
        className={clsx('d-flex', toolbarButtonMarginClass)}
        id='kt_header_user_menu_toggle'
      >
        {/* begin::Toggle */}
        {/* <div
          className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          <img src={toAbsoluteUrl('/media/svg/avatars/blank.svg')} alt='metronic' />
        </div> */}
        {/* <HeaderUserMenu /> */}

        <div
          className='menu menu-lg-rounded menu-column menu-lg-row menu-state-bg menu-title-white menu-state-title-sbx-muted menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 fw-bold  align-items-stretch justify-content-end'
          id='#kt_header_menu_profile'
          data-kt-menu='true'
        >
          <MenuItemProfile
            icon={toAbsoluteUrl('/media/svg/avatars/blank.svg')}
            title={
              user?.firstname?.length || user?.lastname?.length
                ? `${user?.firstname} ${user?.lastname}`
                : user.email
            }
            subtitle={`Баланс: ${user?.customer?.balance ?? 0} `}
            to={props.to ? props.to : 'account'}
            noActive={props.to}
          />
        </div>
      </div>
      {/* <Button >Кнопка</Button> */}
      {/* end::Toggle */}

      {/* end::User */}

      {/* begin::Aside Toggler */}
      {/* {config.header.left === 'menu' && (
        <div className='d-flex align-items-center d-lg-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'
            id='kt_header_menu_mobile_toggle'
          >
            <KTSVG path='/media/icons/duotune/text/txt001.svg' className='svg-icon-1' />
          </div>
        </div>
      )} */}
    </div>
  )
}

export {Topbar}
