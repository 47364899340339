import {createSlice} from '@reduxjs/toolkit'
import {emptyState as initialState, IGoodsGroup} from './types'
import {IPriceType} from '../priceTypes/types'

const goodsGroupsSlice = createSlice({
  name: 'goodsGroupsSlice',
  initialState,
  reducers: {
    setGoodsGroups(state, action) {
      state.items = action.payload.results
      state.count = action.payload.count
      state.next = action.payload.next
      state.previous = action.payload.previous
      state.isLoading = false
    },

    setIsLoading(state, action) {
      state.isLoading = action.payload
    },

    setSelectedGoodsGroup(state, action) {
      state.selected = action.payload
    },

    setGoodsGroupsFilters(state, action) {
      state.filters = action.payload
    },
    updateGoodsGroups(state, action) {
      const updatedGoodsGroups: IGoodsGroup = action.payload

      state.items = state.items.map((gg: IGoodsGroup) =>
        gg.pk == updatedGoodsGroups.pk ? action.payload : gg
      )
    },
    deleteGoodsGroups(state, action) {
      const GGId: number = action.payload

      state.items = state.items.filter((gg: IGoodsGroup) => gg.pk !== GGId)
    },
  },
})

export const {
  setGoodsGroups,
  setIsLoading,
  setSelectedGoodsGroup,
  setGoodsGroupsFilters,
  updateGoodsGroups,
  deleteGoodsGroups,
} = goodsGroupsSlice.actions

export const goodsGroupsReducer = goodsGroupsSlice.reducer
