import { call, delay, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { intl } from '../../modules/goods/item/validation';
import { sagaUtils } from '../../utils/dataUtils';
import { CURRENT_PAGE_DEFAULT, ITEMS_PER_PAGE_DEFAULT } from '../goods/types';
import { addPushMessageGroupCreator } from '../system/actions';
import { IPushMessage } from '../system/types';
import { ASYNC_ADD_IMPORT_MP_TASK, ASYNC_ADD_IMPORT_XLS_TASK, ASYNC_GET_IMPORT_XLS_FORMATS, ASYNC_GET_TASK, ASYNC_GET_TASKS, CHECK_REFRESHDATA, SET_REFRESHDATA, SET_REFRESH_IS_WORKING, checkRefreshDataCreator, setRefreshIsWorking } from "./actions";
import { addTaskImportMpToServer, addTaskImportXlsToServer, getTaskFromServer, getTaskImportXlsFormatsFromServer, getTasksFromServer } from './api';
import { resetRefreshTasks, setCount, setFormats, setIsLoading, setRefreshTasks, setRefresherIsWorking, setTask, setTasks } from './reducers';
import { ITaskState, IUserTask } from './types';




export function* taskSaga() {
    yield takeEvery(ASYNC_GET_TASKS, workerGetTasks)
    yield takeEvery(ASYNC_GET_IMPORT_XLS_FORMATS, workerGetFormats)
    yield takeEvery(ASYNC_ADD_IMPORT_MP_TASK, workerAddImportMpTask)
    yield takeEvery(ASYNC_ADD_IMPORT_XLS_TASK, workerAddImportXlsTask)
    yield takeEvery(ASYNC_GET_TASK, workerGetTask)

    yield takeLatest(CHECK_REFRESHDATA, checkRefreshData)
    yield takeEvery(SET_REFRESHDATA, workerSetRefreshData)
    yield takeEvery(SET_REFRESH_IS_WORKING, function* (action: any) { yield (put(setRefresherIsWorking(action.payload))) })
}


// gets all tasks and set it to storage
export function* workerGetTasks(): any {

    const { tasks } = yield select()
    const request: any = {
        items: tasks.itemsPerPage ?? ITEMS_PER_PAGE_DEFAULT,
        page: tasks.currentPage ?? CURRENT_PAGE_DEFAULT,
    }

    yield sagaUtils.workerWrapper(setIsLoading,
        getTasksFromServer, request,
        setTasks, null,
        intl.formatMessage({ id: "SAGAS.TASKS.GET" }), "GET", "data.results", false, "",
        undefined, [
        { action: setCount, resultType: "data", resultTypePropertyName: "count", params: null },
        {
            action: tasks.autoRefresh.enabled && !tasks.autoRefresh.isWorking ? checkRefreshDataCreator : null,
            resultType: "data", resultTypePropertyName: "results", params: null
        }
    ])
}

// gets all tasks and set it to storage
export function* workerGetFormats(): any {

    yield sagaUtils.workerWrapper(setIsLoading,
        getTaskImportXlsFormatsFromServer, null,
        setFormats, null,
        intl.formatMessage({ id: "SAGAS.TASKFORMATS.GET" }), "GET", "data", false)
}

const userTaskToNotify = (e: IUserTask): IPushMessage => {
    return {
        name: intl.formatMessage({ id: "SAGAS.TASKS.IMPORT.TITLE" }),
        status: e.state == "SUCCESS" ? "success" : "error",
        details: [`${intl.formatMessage({ id: "SAGAS.TASKS.IMPORT.TASKNUMBER" })} ` + e.uuid + ": " + (e.state == "SUCCESS" ? ` ${intl.formatMessage({ id: "SAGAS.TASKS.IMPORT.SUCCESS" })}` : ` ${intl.formatMessage({ id: "SAGAS.TASKS.IMPORT.FAIL" })}`)],
        message: e.name,
    }
}

//check if there is data to refresh
export function* checkRefreshData(action: any): any {
    const tasks: ITaskState = (yield select()).tasks

    const tasksFromAction: IUserTask[] | null | undefined = action.payload
    let waitingData: IUserTask[] | null = []
    let ended: IUserTask[] = []

    if (tasksFromAction?.length) {
        waitingData = tasksFromAction.filter((task) => task.state != "SUCCESS" && task.state != "FAILURE") ?? []

        if (tasks?.autoRefresh?.workData?.length) {
            tasks.autoRefresh.workData.map((t: IUserTask) => {
                tasksFromAction.map((tfa: IUserTask) => {
                    if ((t.id == tfa.id) && (t.state != tfa.state)) {
                        ended.push(tfa)
                    }
                })
            })
        }

        if (waitingData?.length) {
            yield put(setRefreshIsWorking(true))
            yield put(setRefreshTasks(waitingData))

            if (ended?.length) {
                yield put(addPushMessageGroupCreator(
                    ended.map((e: IUserTask) => {
                        return userTaskToNotify(e)
                    })
                ))

            }

            yield call(workerEvery)
        }
        else {
            if (ended?.length) {
                yield put(addPushMessageGroupCreator(
                    ended.map((e: IUserTask) => {
                        return userTaskToNotify(e)
                    })
                ))
            }

            yield put(setRefreshIsWorking(false))
            yield put(resetRefreshTasks())
        }
    }
    else {
        yield put(setRefreshIsWorking(false))
        yield put(resetRefreshTasks())

        if (ended?.length) {
            yield put(addPushMessageGroupCreator(
                ended.map((e: IUserTask) => {
                    return userTaskToNotify(e)
                })
            ))

        }
    };


}

export function* workerSetRefreshData(action: any): any {
    // добавляем задания в список невыполненных, чтобы потом опрашивать их в отдельной процедуре и дописывать в хранилище
    const tasks: ITaskState = (yield select()).tasks
    const waitingData = tasks.autoRefresh.workData
    let changed: any = null
    if (tasks?.items?.length) {
        // tasks.items.map((item: IUserTask) => {
        //     if (item.id == taskItemsPromise.data.id) {
        //         if (item.state != taskItemsPromise.data.state) {
        //             changed = taskItemsPromise.data
        //         }
        //     }
        // })

    }
}

function* workerEvery(): any {
    const { tasks } = yield select()

    console.log('workerEvery', tasks.autoRefresh.interval)
    yield delay(tasks.autoRefresh.interval)
    const request: any = {
        items: tasks.itemsPerPage ?? ITEMS_PER_PAGE_DEFAULT,
        page: tasks.currentPage ?? CURRENT_PAGE_DEFAULT,
    }

    const taskItemsPromise = yield call(() => getTasksFromServer(request))
    taskItemsPromise?.data?.count && (yield put(setCount(taskItemsPromise.data.count)))
    if (taskItemsPromise?.data?.results) {
        yield put(setTasks(taskItemsPromise.data.results))
        yield put(checkRefreshDataCreator(taskItemsPromise.data.results))
    }

    //yield put({type: 'workerEvery'})
}

// gets task by id and set it to storage
export function* workerGetTask(action: any): any {

    yield sagaUtils.workerWrapper(setIsLoading,
        getTaskFromServer, action.payload,
        setTask, null,
        intl.formatMessage({ id: "SAGAS.TASK.GET" }), "GET", "data", false)

    // console.log('workerGetTask', action.payload)
    // try {
    //     const taskItemsPromise = yield call(() => getTaskFromServer(action.payload))
    //     taskItemsPromise?.data 
    //         ? (yield put(setTask(taskItemsPromise.data)))
    //         : (yield put(addPushMessageCreator(
    //             {
    //                 name: "Не удалось загрузить задачу",
    //                 status: "error",
    //                 details: ["Ошибка получения задачи с сервера"],
    //                 message: "Ошибка",
    //             })))

    // }
    // catch (error) {
    //     yield put(addPushMessageCreator(
    //         {
    //             name: "Не удалось загрузить задачу",
    //             status: "error",
    //             details: ["Ошибка получения задачи с сервера"],
    //             message: "Ошибка",
    //         }))
    // }

}

// add import task
export function* workerAddImportMpTask(action: any): any {
    yield sagaUtils.workerWrapper(setIsLoading,
        addTaskImportMpToServer, action.payload,
        null, null,
        intl.formatMessage({ id: "SAGAS.TASK.ADD" }), "ADD", "direct", true, intl.formatMessage({ id: "SAGAS.TASK.ADD.SUCCESS" }))

    // yield put(setIsLoading(true))
    // try {
    //     const taskItemPromise = yield call(() => addTaskImportMpToServer(action.payload))

    //     if (taskItemPromise?.data?.success) {
    //         // taskItemPromise?.data?.result && (yield put(addTask(taskItemPromise.data.result)))
    //         yield put(addPushMessageCreator(
    //             {
    //                 name: "Новая фоновая задача",
    //                 status: "success",
    //                 details: ["Новая задача успешно добавлена в очередь"],
    //                 message: "Успешно",
    //             }))
    //     }
    //     else {
    //         yield put(addPushMessageCreator(
    //             {
    //                 name: "Не удалось добавить задачу",
    //                 status: "error",
    //                 details: [taskItemPromise?.data?.errors ?? ["Ошибка добавления задачи на сервер"]],
    //                 message: "Ошибка",
    //             }))
    //     }

    // }
    // catch (error) {
    //     yield put(addPushMessageCreator(
    //         {
    //             name: "Не удалось добавить задачу",
    //             status: "error",
    //             details: ["Ошибка добавления задачи на сервер"],
    //             message: "Ошибка",
    //         }))
    // }
    // finally {
    //     yield put(setIsLoading(false))

    // }

}

// add import task
export function* workerAddImportXlsTask(action: any): any {

    yield sagaUtils.workerWrapper(setIsLoading,
        addTaskImportXlsToServer, action.payload,
        null, null,
        intl.formatMessage({ id: "SAGAS.TASK.ADD" }), "ADD", "direct", true, intl.formatMessage({ id: "SAGAS.TASK.ADD.SUCCESS" }))


    // yield put(setIsLoading(true))
    // try {
    //     const taskItemPromise = yield call(() => addTaskImportXlsToServer(action.payload))

    //     if (taskItemPromise?.data?.success) {
    //         // taskItemPromise?.data?.result && (yield put(addTask(taskItemPromise.data.result)))
    //         yield put(addPushMessageCreator(
    //             {
    //                 name: "Новая фоновая задача",
    //                 status: "success",
    //                 details: ["Новая задача успешно добавлена в очередь"],
    //                 message: "Успешно",
    //             }))
    //     }
    //     else {
    //         yield put(addPushMessageCreator(
    //             {
    //                 name: "Не удалось добавить задачу",
    //                 status: "error",
    //                 details: [taskItemPromise?.data?.errors ?? ["Ошибка добавления задачи на сервер"]],
    //                 message: "Ошибка",
    //             }))
    //     }

    // }
    // catch (error) {
    //     yield put(addPushMessageCreator(
    //         {
    //             name: "Не удалось добавить задачу",
    //             status: "error",
    //             details: ["Ошибка добавления задачи на сервер"],
    //             message: "Ошибка",
    //         }))
    // }
    // finally {
    //     yield put(setIsLoading(false))

    // }

}