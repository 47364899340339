/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react'
import metadata from './../../../../metadata.json'

interface IVersionProps{
  className?: string
  style?: any
}

const Version: FC<IVersionProps> = (props) => {
  
  return (

    <div className={props.className ?? ''} style={props.style ? props.style : null}>
      {`v ${metadata.buildMajor}.${metadata.buildMinor}.${metadata.buildRevision} ${metadata.buildTag}`}
    </div>

  )
}

export default Version
