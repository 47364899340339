import {createSlice} from '@reduxjs/toolkit'
import {initialState} from './types'

const tgConnectModalSlice = createSlice({
  name: 'tgConnectModal',
  initialState,
  reducers: {
    setIsOpenTgModal(state, action) {
      state.isOpen = action.payload
    },
    setBindingInfo(state, action) {
      state.bindingInfo = action.payload
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload
    },
    setTgId(state, action) {
      state.tgId = action.payload
    },
  },
})

export const tgConnectModalReducer = tgConnectModalSlice.reducer
export const {setBindingInfo, setIsOpenTgModal, setIsLoading, setTgId} = tgConnectModalSlice.actions
