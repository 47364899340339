export const initialCustomerCompanys: ICustomerCompanyState = {
    count: 0,
    next: "",
    previous: "",
    items: [],
    isLoading: false
}

export const emptyCustomerCompany: ICustomerCompany = {
    id: -1,
    customer: -1,
    customer_name: "",
    name: "",
    code: "",
    inn: "",
    kpp: "",
    bank_name: "",
    bik: "",
    kor_account: "",
    account_number: "",
    address: "",
    podpisant: "",
    is_default: false,
}

export interface ICustomerCompany {
    id: number
    customer: number
    customer_name: string
    name: string
    code: string
    inn: string
    kpp: string
    bank_name: string
    bik: string
    kor_account: string
    account_number: string
    address: string
    podpisant: string
    is_default: boolean
    settings?: any
}

export interface ICustomerCompanyState{
    count: number
    isLoading?: boolean
    next: string
    previous: string
    items: ICustomerCompany[]
}


export const customerActionTypes = {
    GetCustomerCompanys: '[GetCustomerCompanys] Action',
    SetCustomerCompanys: '[SetCustomerCompanys] Action',
    SetCustomerCompany: '[SetCustomerCompany] Action',
    DeleteCustomerCompany: '[DeleteCustomerCompany] Action',
    AddCustomerCompany: '[AddCustomerCompany] Action',
  }