import {call, put, takeLatest, takeEvery} from 'redux-saga/effects'
import {setIsLoading, setNewsData} from './reducers'
import {fetchNews} from './api'
import {ASYNC_GET_NEWS} from './actions'
import {sagaUtils} from '../../utils/dataUtils'
import {intl} from '../../modules/goods/item/validation'

export function* newsSaga() {
    yield takeEvery(ASYNC_GET_NEWS, workerGetNews);
}
export function* workerGetNews(action: any): any {
  yield sagaUtils.workerWrapper(
    setIsLoading,
    fetchNews,
    action.payload,
    setNewsData,
    null,
    intl.formatMessage({id: 'SAGAS.NEWS.GETNEWS'}),
    'GET',
    'data',
    false,
    ''
  )
}
