import {FC, useState} from 'react'
import {useIntl} from 'react-intl'
import {NavLink} from 'react-router-dom'
import {IAccount} from '../../../store/accounts/types'
import {ICustomerCompany} from '../../../store/customerCompanys/types'
import {IMarketplace} from '../../../store/marketplaces/types'
import {IOrderItem} from '../../../store/orders/types'
import {dateUtils} from '../../../utils/dataUtils'
import CheckBoxCustom from '../../../components/inputs/CheckBoxCustom'
import {paddingLeft40Px} from './OrderData'
import ViewButton from '../../../components/buttons/ViewButton'

interface IOrdersListItem {
  index: number
  item: IOrderItem
  itemUrl?: string // url to navigate when click on item

  marketplaces?: IMarketplace[]
  accounts?: IAccount[]
  companys?: ICustomerCompany[]

  selected?: boolean
  setSelected?: (selected: boolean) => void

  setQuickView?: (itemId: string) => void
}

const OrdersListItem: FC<IOrdersListItem> = (props) => {
  const intl = useIntl()

  const [collapsed, setCollapsed] = useState<boolean>(false)
  const createdDate = new Date(props.item.created)
  const shippedDate = props.item.shipment_date ? new Date(props.item.shipment_date) : null
  const currentItemMarketplace =
    props.marketplaces?.find(
      (mp) => props.item?.marketplace_id && mp.id === props.item.marketplace_id
    ) ?? undefined

  const selectItem = () => {
    props.setSelected && props.setSelected(!props.selected)
  }

  return (
    <tr
      title={`${props.item.is_error ? 'Ошибка в заказе' : ''}`}
      // className={`cursor-pointer ${props.item.is_error ? 'bg-warning' : ' bg-secondary'}`}
      className={`cursor-pointer`}
    >
      <td className='' onClick={selectItem} style={paddingLeft40Px}>
        <div className='d-flex justify-content-center align-items-center'>
          <CheckBoxCustom
            checked={props.selected != undefined ? props.selected : false}
            onChange={() => props.setSelected && props.setSelected(!props.selected)}
          />
        </div>
      </td>
      <td>
        <div className='d-flex  justify-content-start align-items-center g-30'>
          <img
            className='w-30px m-3'
            src={
              props.marketplaces?.filter((x: any) => x.id == props.item.marketplace_id)[0]?.params
                ?.pic ?? ''
            }
            alt={currentItemMarketplace?.name}
          />
          <div className='fs-9 text-left'>
            {props.item.account
              ? props.accounts?.find((a) => a.id == props.item.account)?.name ?? props.item.account
              : intl.formatMessage({id: 'COMPONENTS.ORDERS.LIST.FILTERS.ACCOUNT.NOTSELECTED'})}
          </div>
        </div>
      </td>
      <td>
        {props.itemUrl ? (
          <NavLink className='text-dark' to={`${props.itemUrl}${props.item.id}`}>
            {props.item.number}
          </NavLink>
        ) : (
          props.item.number
        )}
      </td>

      <td>
        <div className='d-flex  justify-content-center align-items-start'>
          <ViewButton
            onClick={() => props.setQuickView && props.setQuickView(props.item.id.toString())}
          />
        </div>
      </td>

      <td>
        <div>
          {props.item.skus ? (
            <>
              {collapsed ? (
                <div className='d-flex flex-column align-items-start'>
                  <div className='fs-9 text-left me-2'>
                    {props.item.skus.reduce(
                      (acc, cur) => `${acc}${acc == '' ? '' : ','} ${cur.sku_name}`,
                      ''
                    )}
                  </div>
                  {props.item.skus.length > 1 ? (
                    <div
                      className='fs-9 text-left text-primary cursor-pointer'
                      onClick={() => setCollapsed(false)}
                    >
                      {intl.formatMessage({id: 'ACTION.HIDE'})}
                    </div>
                  ) : null}
                </div>
              ) : (
                <div className='d-flex flex-row justify-content-start'>
                  <span className='fs-9'>
                    {props.item.skus.reduce(
                      (acc, cur, idx) =>
                        acc.length == 0 || acc.length + cur.sku_name.length < 90
                          ? `${acc}${acc == '' ? '' : ','} ${cur.sku_name}`
                          : acc,
                      ''
                    )}
                    {props.item.skus.length > 1 ? (
                      <span
                        className='ms-2 fs-9 text-primary cursor-pointer'
                        onClick={() => setCollapsed(true)}
                      >
                        {intl.formatMessage({id: 'ACTION.SHOWALL'})}
                      </span>
                    ) : null}
                  </span>
                </div>
              )}
            </>
          ) : null}
        </div>
      </td>
      <td className='text-center'>{props.item.amount}</td>
      <td>
        <div className='d-flex flex-column justify-content-center align-items-start'>
          <div>{dateUtils.standartDateString(createdDate, '.')}</div>
          <div>{dateUtils.standartTimeString(createdDate, ':')}</div>
        </div>
      </td>
      <td className=''>
        <div className='d-flex flex-column justify-content-center align-items-start'>
          <div>
            {shippedDate
              ? dateUtils.standartDateString(shippedDate, '.')
              : intl.formatMessage({id: 'STATUS.NODATA'})}
          </div>
          <div>{shippedDate ? dateUtils.standartTimeString(shippedDate, ':') : ''}</div>
        </div>
      </td>
      <td className='text-left'>{props.item.status_name}</td>
    </tr>
  )
}

export default OrdersListItem
