import { createSlice } from "@reduxjs/toolkit"
import { emptyState as initialState, IUserTask, } from "./types"


const taskSlice = createSlice({
    name: "taskSlice",
    initialState,
    reducers: {
        setTasks(state, action){
            state.items = [...action.payload]
        },
        setRefreshTasks(state, action){
            state.autoRefresh.workData = [...action.payload]
        },
        setRefresherIsWorking(state, action){
            state.autoRefresh.isWorking = action.payload
        },
        resetRefreshTasks(state){
            state.autoRefresh.workData = []
        },
        setFormats(state, action){
            state.formats = [...action.payload]
        },
        addTask(state, action){
            state.items = [...state.items, action.payload]
        },
        setTask(state, action){
            const usrTask: IUserTask = action.payload
            state.items = [...state.items.map((ut:IUserTask) => {
                if (ut.id == usrTask.id)
                    ut = usrTask
                return ut
            })]
        },
        setRequestParams(state, action){
            const {items, page} = action.payload
            state.itemsPerPage = items
            state.currentPage = page
        },
        setIsLoading(state, action){
            state.isLoading = action.payload
        },
        setCount(state, action){
            state.count = action.payload
        },
    }
})


export const taskReducer = taskSlice.reducer
export const { setTasks, setIsLoading, setCount, addTask, setTask, setRequestParams, setFormats, 
    setRefreshTasks, resetRefreshTasks, setRefresherIsWorking,
    // setTimer, resetTimer,
} = taskSlice.actions