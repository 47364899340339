import {useEffect, useState} from 'react'

const getReturnValues = (countDown: number) => {
  // calculate time left
  const days = Math.floor(countDown / (1000 * 60 * 60 * 24))
  const hours = Math.floor((countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60))
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000)

  return [days, hours, minutes, seconds]
}

const useCountdown = (targetDate: number) => {
  const countDownDate = new Date(targetDate).getTime()

  const [countDown, setCountDown] = useState(countDownDate - new Date().getTime())

  useEffect(() => {
    const updateTime = () => {
      return countDownDate - new Date().getTime()
    }

    const interval = setInterval(() => {
      const difference = updateTime()

      if (difference < 0) {
        clearInterval(interval)
      }
      setCountDown(difference)
    }, 1000)

    setCountDown(updateTime())

    return () => clearInterval(interval)
  }, [countDownDate])

  return getReturnValues(countDown)
}

export default useCountdown
