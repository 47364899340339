import {createSlice} from '@reduxjs/toolkit'
import {emptyState as initialState} from './types'


const transactionsSlice = createSlice({
  name: 'transactionsSlice',
  initialState,
  reducers: {
    setTransactionData(state, action) {
      state.items = action.payload.results
      state.count = action.payload.count
      state.next = action.payload.next
      state.previous = action.payload.previous
      state.isLoading = false
    },

    setIsLoading(state, action) {
      state.isLoading = action.payload
    },

    setTransactionDataFilters(state, action) {
      state.filters = action.payload
    },
  },
})

export const {
  setTransactionData,
  setIsLoading,
  setTransactionDataFilters,
} = transactionsSlice.actions

export const transactionsReducer = transactionsSlice.reducer
