import {useState, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {updateData} from '../../../../../store'
import {addCustomerCompanyAction} from '../../../../../store/mpWizard/actions'

import LinkingToLegalEntity from '../../LinkingToLegalEntity'

import {TYMAccount, TCompany, TCompanyInfo} from '../../../../../store/mpWizard/types'
import {StorageType} from '../../../../../../setup'

const YMStep3 = () => {
  const data = useSelector((state: StorageType) => state.mpWizard.data)
  const selectedYMAccounts: TYMAccount[] = data?.step1?.selectedYMAccounts

  const initCompanysInfo = selectedYMAccounts.map((acc) => ({
    accId: acc.id,
    companyId: -1,
  }))

  const [companysInfo, setCompanysInfo] = useState<TCompanyInfo[]>(initCompanysInfo)

  const dispatch = useDispatch()

  useEffect(() => {
    selectedYMAccounts.forEach((acc) => {
      dispatch(updateData({key: 'step2', field: `${acc.id}`, value: null}))
    })
  }, [])

  useEffect(() => {
    if (companysInfo.every((ci) => ci.companyId > 0)) {
      companysInfo.forEach((cI) => {
        dispatch(updateData({key: 'step2', field: `${cI.accId}`, value: cI.companyId}))
      })
    }
  }, [companysInfo])

  const onSelectCustomerCompany = (accId: number) => (cc: TCompany | null) => {
    const newCompanysInfo = companysInfo.map((ci) => {
      if (ci.accId === accId) {
        return {accId, companyId: cc?.id || -1}
      }
      return ci
    })

    setCompanysInfo(newCompanysInfo)
  }

  const onAddCustomerCompany = (accId: number) => (cc: TCompany) => {
    dispatch(addCustomerCompanyAction({company: cc, accId}))
  }

  return (
    <div>
      <p className='mb-8 fs-5 text-gray-700'>
        Для правильного функционирования сервиса нам необходимо привязать личный кабинет к
        юридическому лицу, которое им владеет. Вы можете выбрать его из имеющихся или создать новое.
      </p>
      {selectedYMAccounts.map((acc) => (
        <div key={acc.id} className='mb-10'>
          <h6 className='fs-5 mb-5 text-gray-700 text-center'>
            {acc.business.name} ({acc.domain})
          </h6>
          <LinkingToLegalEntity
            onSelectCustomerCompany={onSelectCustomerCompany(acc.id)}
            addCustomerCompanyHandler={onAddCustomerCompany(acc.id)}
          />
        </div>
      ))}
    </div>
  )
}

export default YMStep3
