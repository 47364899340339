/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useRef} from 'react'
import {useDispatch} from 'react-redux'
import {NavLink, useNavigate} from 'react-router-dom'
import useAbilityContext from '../../../hooks/useAbilityContext'
import * as Yup from 'yup'
import clsx from 'clsx'
import {useFormik} from 'formik'
import * as auth from '../../../store/auth/AuthRedux'
import {login, register} from '../../../store/auth/AuthCRUD'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import {intl} from '../../goods/item/validation'
import {useIntl} from 'react-intl'

const loginSchema = Yup.object().shape({
  firstname: Yup.string()
    .matches(/^(?!-)[a-zA-Zа-яА-ЯЁё]*-?[a-zA-Zа-яА-ЯЁё]+$/, {
      message: intl.formatMessage({id: 'AUTH.LOGIN.VALIDATION.ONLYLETTERS'}),
    })
    .min(1, intl.formatMessage({id: 'AUTH.LOGIN.VALIDATION.MIN3SYMBOLS'}))
    .max(50, intl.formatMessage({id: 'AUTH.LOGIN.VALIDATION.MAX50SYMBOLS'}))
    .required(intl.formatMessage({id: 'AUTH.LOGIN.VALIDATION.NAME'})),
  lastname: Yup.string()
    .matches(/^(?!-)[a-zA-Zа-яА-ЯЁё]*-?[a-zA-Zа-яА-ЯЁё]+$/, {
      message: intl.formatMessage({id: 'AUTH.LOGIN.VALIDATION.ONLYLETTERS'}),
    })
    .min(1, intl.formatMessage({id: 'AUTH.LOGIN.VALIDATION.MIN3SYMBOLS'}))
    .max(50, intl.formatMessage({id: 'AUTH.LOGIN.VALIDATION.MAX50SYMBOLS'}))
    .required(intl.formatMessage({id: 'AUTH.LOGIN.VALIDATION.SURNAME'})),
  email: Yup.string()
    .email(intl.formatMessage({id: 'AUTH.LOGIN.VALIDATION.INVALID_EMAIL'}))
    .min(3, intl.formatMessage({id: 'AUTH.LOGIN.VALIDATION.MIN3SYMBOLS'}))
    .max(50, intl.formatMessage({id: 'AUTH.LOGIN.VALIDATION.MAX50SYMBOLS'}))
    .required(intl.formatMessage({id: 'AUTH.LOGIN.VALIDATION.EMAIL'})),
  phone: Yup.string()
    .min(11, intl.formatMessage({id: 'AUTH.LOGIN.VALIDATION.PHONE11SYMBOLS'}))
    .max(13, intl.formatMessage({id: 'AUTH.LOGIN.VALIDATION.MAX13SYMBOLS'}))
    .required(intl.formatMessage({id: 'AUTH.LOGIN.VALIDATION.PHONE11SYMBOLS'}))
    .matches(/^\+?[1-9]\d{1,14}$/, {
      message: intl.formatMessage({id: 'VALIDATION.SETTINGS.PHONE.WRONGFORMAT'}),
    }),
  password: Yup.string()
    .min(8, intl.formatMessage({id: 'AUTH.LOGIN.VALIDATION.MIN8SYMBOLS'}))
    .max(50, intl.formatMessage({id: 'AUTH.LOGIN.VALIDATION.MAX50SYMBOLS'}))
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).*$/, {
      message: intl.formatMessage({id: 'AUTH.LOGIN.VALIDATION.PASSWORDREGEX'}),
    })
    .required(intl.formatMessage({id: 'AUTH.LOGIN.VALIDATION.PASSWORD'})),
  password2: Yup.string()
    .required(intl.formatMessage({id: 'AUTH.LOGIN.VALIDATION.PASSWORD2'}))
    .oneOf(
      [Yup.ref('password'), null],
      intl.formatMessage({id: 'AUTH.LOGIN.VALIDATION.PASSWORDNOTMATCH'})
    ),
  customer_name: Yup.object({
    name: Yup.string().required(intl.formatMessage({id: 'AUTH.LOGIN.VALIDATION.COMPANYNAME'})),
  }),
})

const initialValues = {
  firstname: '',
  lastname: '',
  email: '',
  phone: '',
  password: '',
  password2: '',
  customer_name: {
    name: '',
  },
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Register() {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const ability = useAbilityContext()

  // const navigate = useNavigate()
  // const navRef = useRef(navigate)

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setTimeout(() => {
        register(values)
          .then(({data}) => {
            login(data.email, values.password)
              .then((resp: any) => {
                setLoading(false)
                // dispatch(auth.actions.register(resp.data, navRef.current, ability))
                dispatch(auth.actions.login(resp.data, ability))
              })
              .catch(() => {
                setLoading(false)
                setSubmitting(false)
                setStatus(intl.formatMessage({id: 'AUTH.LOGIN.VALIDATION.LOGINDETAILINCORRECT'}))
              })
          })
          .catch((err) => {
            setLoading(false)
            setSubmitting(false)
            setStatus(intl.formatMessage({id: 'AUTH.LOGIN.VALIDATION.REGISTERDETAILINCORRECT'}))
          })
      }, 1000)
    },
  })

  function phoneChange() {
    formik.setFieldValue('phone', arguments[0])
  }

  return (
    <>
      <form
        className='form w-100'
        onSubmit={formik.handleSubmit}
        noValidate
        id='kt_login_signin_form'
      >
        {formik.status && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>{formik.status}</div>
          </div>
        )}

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <label className='form-label fs-6 fw-bolder text-dark'>
            {intl.formatMessage({id: 'AUTH.REGISTER.CAPTION.NAME'})}
          </label>
          <input
            placeholder={intl.formatMessage({id: 'AUTH.REGISTER.CAPTION.NAME'})}
            {...formik.getFieldProps('firstname')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {'is-invalid': formik.touched.firstname && formik.errors.firstname},
              {
                'is-valid': formik.touched.firstname && !formik.errors.firstname,
              }
            )}
            type='text'
            name='firstname'
            autoComplete='off'
          />
          {formik.touched.firstname && formik.errors.firstname && (
            <div className='fv-plugins-message-container'>
              <span role='alert'>{formik.errors.firstname}</span>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <label className='form-label fs-6 fw-bolder text-dark'>
            {intl.formatMessage({id: 'AUTH.REGISTER.CAPTION.SURNAME'})}
          </label>
          <input
            placeholder={intl.formatMessage({id: 'AUTH.REGISTER.CAPTION.SURNAME'})}
            {...formik.getFieldProps('lastname')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {'is-invalid': formik.touched.lastname && formik.errors.lastname},
              {
                'is-valid': formik.touched.lastname && !formik.errors.lastname,
              }
            )}
            type='text'
            name='lastname'
            autoComplete='off'
          />
          {formik.touched.lastname && formik.errors.lastname && (
            <div className='fv-plugins-message-container'>
              <span role='alert'>{formik.errors.lastname}</span>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <label className='form-label fs-6 fw-bolder text-dark'>
            {intl.formatMessage({id: 'AUTH.REGISTER.CAPTION.EMAIL'})}
          </label>
          <input
            placeholder={intl.formatMessage({id: 'AUTH.REGISTER.CAPTION.EMAIL'})}
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {'is-invalid': formik.touched.email && formik.errors.email},
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
            type='email'
            name='email'
            autoComplete='off'
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <label className='form-label fs-6 fw-bolder text-dark'>
            {intl.formatMessage({id: 'AUTH.REGISTER.CAPTION.PHONE'})}
          </label>
          <PhoneInput
            country='ru'
            onChange={phoneChange}
            onBlur={() => formik.setFieldTouched('phone', true)}
            inputClass={clsx(
              'form-control form-control-lg form-control-solid w-100',
              {'is-invalid': formik.touched.phone && formik.errors.phone},
              {
                'is-valid': formik.touched.phone && !formik.errors.phone,
              }
            )}
            regions={['ex-ussr']}
          />
          {formik.touched.phone && formik.errors.phone && (
            <div className='fv-plugins-message-container'>
              <span role='alert'>{formik.errors.phone}</span>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <label className='form-label fs-6 fw-bolder text-dark'>
            {intl.formatMessage({id: 'AUTH.REGISTER.CAPTION.COMPANYNAME'})}
          </label>
          <input
            placeholder={intl.formatMessage({id: 'AUTH.REGISTER.CAPTION.COMPANYNAME'})}
            {...formik.getFieldProps('customer_name.name')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid':
                  formik.touched.customer_name?.name && formik.errors.customer_name?.name,
              },
              {
                'is-valid':
                  formik.touched.customer_name?.name && !formik.errors.customer_name?.name,
              }
            )}
            type='text'
            name='customer_name.name'
            autoComplete='off'
          />
          {formik.touched.customer_name?.name && formik.errors.customer_name?.name && (
            <div className='fv-plugins-message-container'>
              <span role='alert'>{formik.errors.customer_name?.name}</span>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <div className='d-flex justify-content-between mt-n5'>
            <div className='d-flex flex-stack mb-2'>
              {/* begin::Label */}
              <label className='form-label fw-bolder text-dark fs-6 mb-0'>
                {intl.formatMessage({id: 'AUTH.REGISTER.CAPTION.PASSWORD'})}
              </label>
              {/* end::Label */}
            </div>
          </div>
          <input
            type='password'
            autoComplete='off'
            {...formik.getFieldProps('password')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid': formik.touched.password && formik.errors.password,
              },
              {
                'is-valid': formik.touched.password && !formik.errors.password,
              }
            )}
          />
          {formik.touched.password && formik.errors.password && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.password}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <div className='d-flex justify-content-between mt-n5'>
            <div className='d-flex flex-stack mb-2'>
              {/* begin::Label */}
              <label className='form-label fw-bolder text-dark fs-6 mb-0'>
                {intl.formatMessage({id: 'AUTH.REGISTER.CAPTION.PASSWORD2'})}
              </label>
              {/* end::Label */}
            </div>
          </div>
          <input
            type='password'
            autoComplete='off'
            {...formik.getFieldProps('password2')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid': formik.touched.password2 && formik.errors.password2,
              },
              {
                'is-valid': formik.touched.password2 && !formik.errors.password2,
              }
            )}
          />
          {formik.touched.password2 && formik.errors.password2 && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.password2}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Action */}
        <div className='text-center'>
          <button
            type='submit'
            id='kt_sign_in_submit'
            className='btn btn-lg btn-primary w-100 mb-5'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {!loading && (
              <span className='indicator-label'>
                {intl.formatMessage({id: 'AUTH.REGISTER.BUTTONS.REGISTER'})}
              </span>
            )}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                {intl.formatMessage({id: 'AUTH.REGISTER.STATUS.REGISTER'})}...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Action */}
      </form>
      <div className='fv-row mt-10'>
        {!loading && (
          <div className='d-flex justify-content-between mt-n5'>
            <div className='d-flex flex-stack'>
              {/* begin::Label */}
              <label className='form-label fw-bolder text-dark fs-6 mb-0'>
                {intl.formatMessage({id: 'AUTH.LOGIN.CAPTION.HASACCOUNT.QUESTION'})}
              </label>
              {/* end::Label */}
            </div>
            <div className='d-flex flex-stack'>
              {/* begin::Label */}
              <NavLink to={'/auth/login'}>
                {intl.formatMessage({id: 'AUTH.REGISTER.CAPTION.LOGIN'})}
              </NavLink>
              {/* end::Label */}
            </div>
          </div>
        )}
      </div>
    </>
  )
}
