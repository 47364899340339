import { sagaUtils } from './../../utils/dataUtils';
import { takeEvery } from "redux-saga/effects"
import { ASYNC_GET_CUSTOMERS, ASYNC_UPDATE_CUSTOMER, ASYNC_ADD_CUSTOMER } from "./actions";
import { setCount, setCustomers, setIsLoading } from "./reducers";
import { addCustomerToServer, getCustomersFromServer, updateCustomerOnServer } from "./api";
import { actions } from "../../modules/auth";
import { intl } from '../../modules/goods/item/validation';





export function* customerSaga(){
    yield takeEvery(ASYNC_GET_CUSTOMERS, workerGetCustomers)
    yield takeEvery(ASYNC_UPDATE_CUSTOMER, workerUpdateCustomer)
    yield takeEvery(ASYNC_ADD_CUSTOMER, workerAddCustomer)
}

// gets all customers and set it to storage
export function* workerGetCustomers():any{
    yield sagaUtils.workerWrapper(setIsLoading, 
        getCustomersFromServer, null, 
        setCustomers, null, 
        intl.formatMessage({id: "SAGAS.CUSTOMERS.GET"}), "GET", "data.results", false, "", 
        undefined, {action: setCount,resultType: "data", resultTypePropertyName: "count", params: null})
}

// update customer in storage
export function* workerUpdateCustomer(action: any):any{
    yield sagaUtils.workerWrapper(setIsLoading, 
        updateCustomerOnServer, action.payload, 
        actions.setCustomerInfo, null, 
        intl.formatMessage({id: "SAGAS.CUSTOMERS.UPDATE"}), "SAVE", "data", true)
}

// add customer in storage
export function* workerAddCustomer(action: any):any{
    yield sagaUtils.workerWrapper(setIsLoading, 
        addCustomerToServer, {params: action.payload.customer, userid: action.payload.userid}, 
        actions.setCustomerInfo, null, 
        intl.formatMessage({id: "SAGAS.CUSTOMERS.UPDATE"}), "SAVE", "data", true)
}
