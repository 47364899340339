import {IUrlParameters} from '../../components/lists/UrlFilteredList'
import {DEFAULT_PRICES_GET_LIMIT, DEFAULT_PRICES_OFFSET} from '../goodsGroups/constants'
import axios from 'axios'
import {ITransactionState} from './types'

const API_URL = `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENDPOINT_PREFIX}`

export const TRANSACTIONS_DATA_URL = `${API_URL}v2/balance_transactions/`

export const fetchTransactions = (params: IUrlParameters) => {
  const offset =
    params.pageNumber !== undefined && params.itemsPerPage !== undefined
      ? params.pageNumber * params.itemsPerPage
      : DEFAULT_PRICES_OFFSET

  return axios.get<ITransactionState>(
    `${TRANSACTIONS_DATA_URL}?limit=${
      params.itemsPerPage ?? DEFAULT_PRICES_GET_LIMIT
    }&offset=${offset}`
  )
}
