import { useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import PushMessageController from '../../app/modules/widgets/components/notifiers/PushMessageNotify/PushMessageNotifyController'
import { MenuComponent } from '../assets/ts/components'
import { Main } from '../partials'
import { Content } from './components/Content'
import { ScrollTop } from './components/ScrollTop'
import { AsideDefault } from './components/aside/AsideDefault'
import { HeaderWrapper } from './components/header/HeaderWrapper'
import { Toolbar } from './components/toolbar/Toolbar'
import { PageDataProvider, useLayout } from './core'
import { Footer } from './components/Footer'
import {HeaderWrapperForWarehouse} from "./components/header/HeaderWrapperForWarehouse";

const LayoutForWarehouse = () => {
    const location = useLocation()
    const layout = useLayout()


    useEffect(() => {
        setTimeout(() => {
            MenuComponent.reinitialization()
        }, 500)
    }, [])

    useEffect(() => {
        setTimeout(() => {
            MenuComponent.reinitialization()
        }, 500)
    }, [location.key])



    return (
        <PageDataProvider>
            <div className='page d-flex flex-row flex-column-fluid'>
                <AsideDefault/>
                <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
                    <HeaderWrapperForWarehouse/>
                    <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
                        <Toolbar/>
                        <div className='post d-flex flex-column-fluid' id='kt_post'>
                            <Content>
                                <Outlet/>
                            </Content>
                        </div>
                    </div>
                    <Footer/>
                </div>
            </div>

            {/* begin:: Drawers */}
            {/* <ActivityDrawer /> */}
            {/* <RightToolbar /> */}
            {/* <DrawerMessenger /> */}
            {/* end:: Drawers */}

            {/* begin:: Modals */}

            <Main/>
            <PushMessageController/>
            {/* <InviteUsers /> */}
            {/* <UpgradePlan /> */}
            {/* end:: Modals */}
            <ScrollTop/>
        </PageDataProvider>
    )
}

export {LayoutForWarehouse }
