import axios from 'axios'
import {IRegisterUserModel, IUserModel} from '../../modules/auth/models/UserModel'

const API_URL = `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENDPOINT_PREFIX}`

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}v2/users/me`
export const LOGIN_URL = `${API_URL}obtain_token/`
export const USERS_URL = `${API_URL}v2/users/`
export const REFRESH_TOKEN_URL = `${API_URL}refresh_token/`

// action types
export const UPDATE_PERSONAL_INFO = 'updateUserPersonalInfo'
export const UPDATE_ACCOUNT_INFO = 'updateUserAccountInfo'

// mutation types
export const SET_PERSONAL_INFO = 'setPersonalInfo'
export const SET_ACCOUNT_INFO = 'setAccountInfo'

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post(LOGIN_URL, {
    email,
    password,
  })
}

// Server should return AuthModel
export function register(registerModel: IRegisterUserModel) {
  return axios.post(USERS_URL, {
    ...registerModel,
  })
}

export function getUserByToken(token?: string) {
  return axios.get<IUserModel>(GET_USER_BY_ACCESSTOKEN_URL)
}

export function getCall(userId: number) {
  return axios.get(`${USERS_URL}${userId}/get_call/`)
}

export function senAuthCodeToServer(userId: number, code: string) {
  return axios.post(`${USERS_URL}post_authorization_code/`, {
    user_id: userId,
    authorization_code: code,
  })
}

export function updateUser(refreshToken: string) {}
