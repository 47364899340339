import React from 'react'
import SVG from 'react-inlinesvg'
import {toAbsoluteUrl} from '../AssetHelpers'
type Props = {
  className?: string
  path: string
  svgClassName?: string
  disableSvgIconClass?: boolean // disable native svg-icon class or not (true)
  onClick?: (value: any) => void
}

const KTSVG: React.FC<Props> = ({className = '', path, svgClassName = 'mh-50px', disableSvgIconClass, onClick}) => {
  return (
    <span onClick={onClick} className={`${disableSvgIconClass ? '' : 'svg-icon'} ${className}`}>
      <SVG src={toAbsoluteUrl(path)} className={svgClassName} />
    </span>
  )
}

export {KTSVG}
