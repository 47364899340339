import { createAction } from "@reduxjs/toolkit";
import {  IStockUpdateParams, stockActionTypes as actionTypes, IStockRequestParameters, IStockAddParams, } from "./types"

export const ASYNC_GET_STOCKS = actionTypes.GetStocks;
export const ASYNC_UPDATE_STOCK = actionTypes.UpdateStock;
export const SET_STOCK_PARAMETERS = actionTypes.SetParametersStock;
export const ADD_STOCK_PARAMETERS = actionTypes.AddParametersStock;
// export const SET_GOODS = actionTypes.SetGoods;
// export const ASYNC_GET_GOOD = actionTypes.GetGood;
// export const SET_GOODS_REQUEST_PARAMS = actionTypes.SetGoodsRequestParams
// export const SET_GOODS_IS_LOADING = actionTypes.SetIsLoading

// export const setStocksCreator = createAction<string>(SET_GOODS);

export const getAsyncStocksCreator = createAction(ASYNC_GET_STOCKS);
export const updateAsyncStockCreator = createAction<IStockUpdateParams>(ASYNC_UPDATE_STOCK);
export const addAsyncStockCreator = createAction<IStockAddParams>(ADD_STOCK_PARAMETERS);

export const setStocksRequestParametersCreator = createAction<IStockRequestParameters>(SET_STOCK_PARAMETERS);


