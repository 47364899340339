import { emptyMarketplace, IMarketplace } from "../marketplaces/types"


export interface IExtKeys {
    ozon_id?: string
    ozon_name?: string
}

// export interface IUniversalCategory{
//     id: number
//     label: string
//     children?: IUniversalCategory[]
// }

export interface ICategory {
    id: number
    label?: string
    name?: string
    children?: ICategory[]
    ext_keys?: IExtKeys

    path?: string // full path of category can store here
}

export const EMPTY_CATEGORY: ICategory = {
    id: -1,
    label: "",
    ext_keys: {
        ozon_id: "",
        ozon_name: "",
    }
}


export interface IFilteredCategory {
    id: IFilteredCategoryId
    selected_text: string
    text: string
}

export interface IFilteredCategoryId {
    id: number
    parent: number
    path_id: number[]
}

export interface IAllCategoriesProps {
    root: number
    sub: number
    dest: number
}

export interface IUniversalCategoriesParams {
    categoryId?: number
    marketplaceId: number
    sku_id?: number
}


export interface ICategoryState {
    // count: number   // not used
    // next: string    // not used
    // previous: string    // not used
    items: ICategory[]  // stores root cathegories
    selected: ICategory[]   // stores subcathegories array of selected item from array of root cathegories
    selected2: ICategory[]   // stores destination cathegories array of selected item from array of sub cathegories
    filtered: IFilteredCategory[] // stores array of cathegories filtered by search word
    comparison: IComparisonSaved
    isLoading: boolean
}

export interface IComparisonItemValue {
    id: number
    label: string
}
export interface IItemIdValue {
    id: number
    value: string
    parentId: number
}
export interface IComparisonItem {
    id: number
    // label: string
    name: string
    // children?: IComparisonItemValue[]
    values?: IItemIdValue[]
    collapsed?: boolean
    autocomplete?: boolean
    dictionary?: string
    parentId: number // parent's id
}

export interface IComparisonRequest {
    baseMarketplace: IMarketplace
    selectedMarketplace: IMarketplace
    mpCategoryId: number
    ozCategoryId: number
    attributes: IComparisonValue[]
    values: IComparisonValue[]
    currentAttrId: number,
    currentValId: number,
}

export interface IComparisonRequestItem{
    base_id: number
    linked: number[]
    //deleted?: number[]
}

export interface IComparisonSaveRequest{
    categories: IComparisonRequestItem[]
    attributes: IComparisonRequestItem[]
    values: IComparisonRequestItem[]
}

export const emptyComparisonRequestItem: IComparisonRequestItem = {
    base_id: -1,
    linked: [],
    //deleted: []
}

export interface IComparisonValue {
    ozId: number
    wbId: number
    aliId: number
    sbmmId: number
    ymId: number
    avitoId: number
    id: number
}

export const emptyComparisonValue: IComparisonValue = Object.freeze({
    id: -1,
    ozId: -1,
    wbId: -1,
    aliId: -1,
    sbmmId: -1,
    ymId: -1,
    avitoId: -1
})

export const emptyComparsionRequest: IComparisonRequest = {
    ozCategoryId: -1,
    mpCategoryId: -1,
    attributes: [],
    values: [],
    baseMarketplace: emptyMarketplace,
    selectedMarketplace: emptyMarketplace,
    currentAttrId: -1,
    currentValId: -1,
}

export interface IComparisonResponse {
    // name: string
    // id: number
    // values: IItemIdValue[]
    autocomplete: boolean
    dictionary: string
    id: number
    name: string
    values: IItemIdValue[]
}

export interface IComparisonSavedItem{
    id: number
    marketplace_id: number
    name: string
}

export const emptyComparisonSavedItem: IComparisonSavedItem = {
    id: -1,
    marketplace_id: -1,
    name: "",
}

export interface IComparisonSavedTuple{
    base_id: number
    base_name: string
    comparisons: IComparisonSavedItem[]
}

export const emptyComparisonSavedTuple: IComparisonSavedTuple = {
    base_id: -1,
    base_name: "",
    comparisons: []
}

export interface IComparisonSavedValuedTuple{
    base_id: number
    base_name: string
    comparisons: IComparisonSavedItem[]
    values: IComparisonSavedTuple[]
}

export const emptyComparisonSavedValuedTuple: IComparisonSavedValuedTuple = {
    base_id: -1,
    base_name: "",
    comparisons: [],
    values: []
}

export interface IComparisonSaved {
    categories: IComparisonSavedTuple
    attributes: IComparisonSavedValuedTuple[]
}

export const emptyComparisonSaved: IComparisonSaved = {
    categories: emptyComparisonSavedTuple,
    attributes: []
}


export const initialCategories: ICategoryState = {
    items: [],
    selected: [],
    selected2: [],
    filtered: [],
    comparison: emptyComparisonSaved,
    isLoading: false
}

export const categoryActionTypes = {
    GetRootCategories: '[GetRootCategories] Action',
    GetCategoriesByFilter: '[GetCategoriesByFilter] Action',
    GetAsyncAllCategories: '[GetAsyncAllCategories] Action',
    SetRootCategories: '[SetRootCategories] Action',
    ResetFilteredCategories: '[ResetFilteredCategories] Action',
    ResetAllCategories: '[ResetAllCategories] Action',
    GetComparison: '[GetComparison] Action',
    SetComparison: '[SetComparison] Action',
    ResetComparison: '[ResetComparison] Action',
}