import {FC} from 'react'
import {Button, ButtonProps} from 'react-bootstrap'
import classes from './SbxButton.module.scss'
import {ISbxButtonProps} from './SbxButton'

const SbxLightButton: FC<ISbxButtonProps> = (props) => {
  return (
    <Button
      onClick={props.onClick}
      disabled={props.disabled}
      className={`${props.className ?? ''} ${classes.SbxButtonLight}`}
    >
      {props.children ?? null}
    </Button>
  )
}

export default SbxLightButton
