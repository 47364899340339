import axios from 'axios'
import {IGoodsGroup, IGoodsGroupForSend, IGoodsGroupsState} from './types'
import {DEFAULT_PRICES_GET_LIMIT} from './constants'
import {DEFAULT_PRICES_OFFSET} from './constants'
import {IUrlParameters} from '../../components/lists/UrlFilteredList'
import {IPriceType} from '../priceTypes/types'
import {PRICES_TYPES_URL} from '../priceTypes/api'

const API_URL = `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENDPOINT_PREFIX}`
export const GOODS_GROUPS_URL = `${API_URL}listgoods/`

export const fetchGoodsGroups = (params: IUrlParameters) => {
  const offset =
    params.pageNumber !== undefined && params.itemsPerPage !== undefined
      ? params.pageNumber * params.itemsPerPage
      : DEFAULT_PRICES_OFFSET

  return axios.get<IGoodsGroupsState>(
    `${GOODS_GROUPS_URL}?limit=${params.itemsPerPage ?? DEFAULT_PRICES_GET_LIMIT}&offset=${offset}`
  )
}

export const fetchGoodsGroupById = (id: number) => {
  return axios.get<IGoodsGroup>(`${GOODS_GROUPS_URL}${id}/`)
}

export const updateGoodsGroupOnServer = (params: IGoodsGroup) => {
  return axios.put<IGoodsGroup>(`${GOODS_GROUPS_URL}${params.pk}/`, params)
}

export const deleteGoodsGroupOnServer = (id: number) => {
  return axios.delete(`${GOODS_GROUPS_URL}${id}/`)
}

export const addGoodsGroupToServer = (params: IGoodsGroupForSend) => {
  return axios.post<IPriceType>(`${GOODS_GROUPS_URL}`, params)
}
