import {put, takeEvery} from 'redux-saga/effects'
import {
  addDeliveryAccountToServer,
  deleteDeliveryAccountFromServer,
  fetchDeliveryAccounts,
  updateDeliveryAccountOnServer,
} from './api'
import {
  addDeliveryAccount,
  deleteDeliveryAccount,
  setDeliveryAccount,
  setDeliveryAccounts,
  setIsLoading,
  setIsSuccessLastOperation,
} from './reducers'
import {
  ASYNC_GET_DELIVERYACCOUNTS,
  ASYNC_ADD_DELIVERYACCOUNT,
  ASYNC_UPDATE_DELIVERYACCOUNT,
  ASYNC_DELETE_DELIVERYACCOUNT,
  SET_IS_LOADING_DELIVERYACCOUNTS,
  ADD_DELIVERYACCOUNT,
  SET_DELIVERYACCOUNT,
} from './actions'
import {intl} from '../../modules/goods/item/validation'
import {sagaUtils} from '../../utils/dataUtils'

export function* deliveryAccountSaga() {
  yield takeEvery(ASYNC_GET_DELIVERYACCOUNTS, workerGetDeliveryAccount)
  yield takeEvery(ASYNC_ADD_DELIVERYACCOUNT, workerAddDeliveryAccount)
  yield takeEvery(ADD_DELIVERYACCOUNT, workerAddDeliveryAccountNoApi)
  yield takeEvery(ASYNC_UPDATE_DELIVERYACCOUNT, workerUpdateDeliveryAccount)
  yield takeEvery(SET_DELIVERYACCOUNT, workerSetDeliveryAccount)
  yield takeEvery(ASYNC_DELETE_DELIVERYACCOUNT, workerDeleteDeliveryAccount)
  yield takeEvery(SET_IS_LOADING_DELIVERYACCOUNTS, workerSetIsLoading)
}

// get all deliveryAccounts from server
export function* workerGetDeliveryAccount(): any {
  yield sagaUtils.workerWrapper(
    setIsLoading,
    fetchDeliveryAccounts,
    null,
    setDeliveryAccounts,
    null,
    intl.formatMessage({id: 'SAGAS.DELIVERYACCOUNTS.GET'}),
    'GET',
    'data.results',
    false
  )
}

// set is loading flag
export function* workerSetIsLoading(action: any): any {
  yield put(setIsLoading(action.payload))
}

// update deliveryAccount on server
export function* workerUpdateDeliveryAccount(action: any): any {
  yield sagaUtils.workerWrapper(
    setIsLoading,
    updateDeliveryAccountOnServer,
    action.payload,
    setDeliveryAccount,
    null,
    intl.formatMessage({id: 'SAGAS.DELIVERYACCOUNTS.UPDATE.DELIVERYACCOUNT'}),
    'SAVE',
    'data.result',
    true,
    '',
    {action: setIsSuccessLastOperation, params: false},
    {action: setIsSuccessLastOperation, params: true},
    {action: setIsSuccessLastOperation, params: false},
    {action: setIsSuccessLastOperation, params: false}
  )
}

// update deliveryAccount in redux
export function* workerSetDeliveryAccount(action: any): any {
  yield put(setDeliveryAccount(action.payload))
  // yield sagaUtils.workerWrapper(setIsLoading,
  //     updateDeliveryAccountOnServer, action.payload,
  //     setDeliveryAccount, null,
  //     intl.formatMessage({ id: "SAGAS.DELIVERYACCOUNTS.UPDATE.DELIVERYACCOUNT" }), "SAVE", "data", true, "",
  //     { action: setIsSuccessLastOperation, params: false },
  //     { action: setIsSuccessLastOperation, params: true },
  //     { action: setIsSuccessLastOperation, params: false },
  //     { action: setIsSuccessLastOperation, params: false })
}

// delete deliveryAccount by deliveryAccount id
export function* workerDeleteDeliveryAccount(action: any): any {
  yield sagaUtils.workerWrapper(
    setIsLoading,
    deleteDeliveryAccountFromServer,
    action.payload,
    deleteDeliveryAccount,
    action.payload,
    intl.formatMessage({id: 'SAGAS.DELIVERYACCOUNTS.DELETE.DELIVERYACCOUNT'}),
    'DELETE',
    'direct',
    true,
    '',
    {action: setIsSuccessLastOperation, params: false},
    {action: setIsSuccessLastOperation, params: true},
    {action: setIsSuccessLastOperation, params: false},
    {action: setIsSuccessLastOperation, params: false}
  )
}

// add new deliveryAccount with saving it on server
export function* workerAddDeliveryAccount(action: any): any {
  yield sagaUtils.workerWrapper(
    setIsLoading,
    addDeliveryAccountToServer,
    action.payload,
    addDeliveryAccount,
    null,
    intl.formatMessage({id: 'SAGAS.DELIVERYACCOUNTS.ADD.DELIVERYACCOUNT'}),
    'ADD',
    'data.result',
    true,
    `${intl.formatMessage({id: 'SAGAS.DELIVERYACCOUNTS.DELIVERYACCOUNT'})} ${
      action.payload.name
    } ${intl.formatMessage({id: 'SAGAS.ADD.SUCCESS'})}`,
    {action: setIsSuccessLastOperation, params: false},
    {action: setIsSuccessLastOperation, params: true},
    {action: setIsSuccessLastOperation, params: false},
    {action: setIsSuccessLastOperation, params: false}
  )
}

// add new deliveryAccount to storage
export function* workerAddDeliveryAccountNoApi(action: any): any {
  yield put(addDeliveryAccount(action.payload))
  // yield sagaUtils.workerWrapper(setIsLoading,
  //     null, null,
  //     addDeliveryAccount, action.payload,
  //     intl.formatMessage({ id: "SAGAS.DELIVERYACCOUNTS.ADD.DELIVERYACCOUNT" }), "ADD", "data", true,
  //     `${intl.formatMessage({ id: "SAGAS.DELIVERYACCOUNTS.DELIVERYACCOUNT" })} ${action.payload.name} ${intl.formatMessage({ id: "SAGAS.ADD.SUCCESS" })}`,
  //     { action: setIsSuccessLastOperation, params: false },
  //     { action: setIsSuccessLastOperation, params: true },
  //     { action: setIsSuccessLastOperation, params: false },
  //     { action: setIsSuccessLastOperation, params: false }
  // )
}
