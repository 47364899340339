import axios from 'axios'
import {IUserModel} from '../../modules/auth/models/UserModel'
import {IUserModelResponse} from './types'

const API_URL = `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENDPOINT_PREFIX}`
export const USERS_URL = `${API_URL}v2/users/`

// =================   get customers info =================
// #region
//https://api-dev.shop-delivery.ru/int_api/v2/users/?limit=10&offset=1

// get users info from server
export const getUsersFromServer = () => {
  return axios.get<IUserModel[]>(`${USERS_URL}`)
}

// #endregion

// =================   update user info =================
// #region
//https://api-dev.shop-delivery.ru/int_api/v2/users/1

// update current user info on server
export const updateUserOnServer = (params: IUserModel) => {
  console.log(updateUserOnServer.name, params)
  return axios.put<IUserModel>(`${USERS_URL}${params.id}/`, params)
}

// #endregion

// =================   link user  =================
// #region
//https://api-dev.shop-delivery.ru/int_api/v2/users/linking_user

// update current user info on server
export const linkUserOnServer = (params: string) => {
  console.log(updateUserOnServer.name, params)
  return axios.post<IUserModelResponse>(`${USERS_URL}linking_user/`, {userEmail: params})
}

// #endregion

// =================   unlink user  =================
// #region
//https://api-dev.shop-delivery.ru/int_api/v2/users/unlinking_user

// update current user info on server
export const unlinkUserOnServer = (params: string) => {
  console.log(updateUserOnServer.name, params)
  return axios.post<IUserModelResponse>(`${USERS_URL}unlinking_user/`, {userEmail: params})
}

// #endregion
