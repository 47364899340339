/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC} from 'react'
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom'
import {shallowEqual, useSelector} from 'react-redux'
import {PrivateRoutes} from './PrivateRoutes'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {Logout, AuthPage} from '../modules/auth'
import {IUserModel} from '../modules/auth/models/UserModel'
import {StorageType} from '../../setup'
import {App} from '../App'

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL} = process.env

const AppRoutes: FC = () => {
  const user: IUserModel = useSelector(({auth}: StorageType) => auth.user, shallowEqual)

  const isNumberConfirmed = user?.params.number_confirm

  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />

          {user ? (
            <>
              {isNumberConfirmed ? (
                <>
                  <Route path='/*' element={<PrivateRoutes />} />
                  <Route index element={<Navigate to='/home' />} />
                </>
              ) : (
                <>
                  <Route path='*' element={<Navigate to='/auth/phone-confirm' />} />
                  <Route path='auth/register' element={<Navigate to='/auth/phone-confirm' />} />
                  <Route path='auth/login' element={<Navigate to='/auth/phone-confirm' />} />
                  <Route path='auth/*' element={<AuthPage />} />
                </>
              )}
            </>
          ) : (
            <>
              <Route path='auth/*' element={<AuthPage />} />
              <Route path='*' element={<Navigate to='/auth' />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export {AppRoutes}
