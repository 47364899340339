import {useState, ChangeEvent} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {updateData} from '../../../../../store'

import {Form} from 'react-bootstrap'

import {StorageType} from '../../../../../../setup'

const WBStep1 = () => {
  const data = useSelector((storage: StorageType) => storage.mpWizard.data)

  const [authorizationToken, setAuthorizationToken] = useState(data?.step0?.authorization || '')

  const dispatch = useDispatch()

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setAuthorizationToken(e.target.value)

    dispatch(updateData({key: 'step0', field: 'authorization', value: e.target.value}))
  }
  return (
    <div className='fs-5'>
      <p className='mb-9'>
        Токен авторизации необходим для того, чтобы наш сервис мог взаимодействовать с вашим
        аккаунтом <span className='fw-bold'>Wildberries</span> и выполнять необходимые задачи, такие
        как автоматизация процессов, интеграция с вашим магазином и управление данными.
      </p>
      <Form className='mb-9'>
        <Form.Control
          name='authorization'
          value={authorizationToken}
          onChange={onChange}
          className='bg-secondary'
          placeholder='Токен авторизации'
        />
      </Form>
      <p>
        Чтобы получить токен, необходимо перейти в личный кабинет на портале поставщика{' '}
        <span className='fw-bold'>Wildberries</span>.{' '}
        <span className='fst-italic'>
          Важно сделать это с главного аккаунта в системе, потому что только из него можно
          сгенерировать ключи.
        </span>
      </p>
      <p>
        Наведите в правом меню{' '}
        <a
          href='https://seller.wildberries.ru/about-portal/ru/?redirect_url=https%3A%2F%2Fseller.wildberries.ru%2F'
          target='_blank'
          rel='noopener noreferrer'
        >
          портала поставщика
        </a>{' '}
        на профиль &gt; Настройки &gt; Доступ к API
      </p>
      <p>
        Создайте токен и укажите, к каким функциям <span className='fw-bold'>Wildberries</span> он
        разрешает доступ.
      </p>
      <p className='mb-0'>
        Рекомендуем дать доступ ко всем модулям но если знаете что будете использовать определенный
        функционал, то:
      </p>
      <ul>
        <li>Для управления продажами - контент, маркетплейс, цены, статистика.</li>
        <li>Для коэффициентов приемки - поставки.</li>
        <li>Для отзывов - вопросы и отзывы.</li>
      </ul>
      <p>
        Более подробно можно ознакомиться в{' '}
        <a
          href='https://docs.shop-delivery.ru/personal_account/#_8'
          target='_blank'
          rel='noopener noreferrer'
        >
          Руководстве пользователя
        </a>
        .{' '}
      </p>
    </div>
  )
}

export default WBStep1
