import { createSlice } from "@reduxjs/toolkit"
import { ICustomerCompany, initialCustomerCompanys as initialState, } from "./types"


const customerCompanySlice = createSlice({
    name: "customerCompanySlice",
    initialState,
    reducers: {
        setCustomerCompanys(state, action){
            state.items = [...action.payload]
        },
        addCustomerCompany(state, action){
            console.log('addCustomerCompany', action.payload)
            state.items = [...state.items, action.payload]
        },
        setCustomerCompany(state, action){
            console.log('setCustomerCompany', action.payload)
            const customer_company: ICustomerCompany = action.payload
            state.items = [...state.items.map((cc:ICustomerCompany) => {
                if (cc.id == customer_company.id){
                    cc = customer_company;
                }
                return cc
            })]
        },
        setIsLoading(state, action){
            state.isLoading = action.payload
        },
        deleteCustomerCompany(state, action){
            const customer_company_id: number = action.payload
            state.items = [...state.items.filter((cc:ICustomerCompany) => cc.id != customer_company_id)]
        },
    }
})


export const customerCompanyReducer = customerCompanySlice.reducer
export const { setCustomerCompanys, addCustomerCompany, setCustomerCompany, setIsLoading, deleteCustomerCompany } = customerCompanySlice.actions