import {createSlice} from '@reduxjs/toolkit'
import {initialRegions as initialState} from './types'

const regionsSlice = createSlice({
  name: 'regionsSlice',
  initialState,
  reducers: {
    setRegions(state, action) {
      state.items = action.payload
    },
  },
})

export const regionsReducer = regionsSlice.reducer
export const {setRegions} = regionsSlice.actions
