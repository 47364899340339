import {useIntl} from 'react-intl'
import {NavLink} from 'react-router-dom'
import {KTSVG} from '../../../_metronic/helpers'
import {IUrlParameters} from './UrlFilteredList'
import Loader2 from '../vision/loaders/Loader2'

// const DEFAULT_ITEMS_PER_PAGE = 10
// const DEFAULT_PAGE = 1

interface IUrlPagination {
  isLoading?: boolean
  itemsNumber: number
  itemsPerPageVariants: number[]
  pageButtonNumber: number // количество кнопок для отрисовки в текущем режиме отображения экрана

  filterParams: IUrlParameters
  makeLink: any //(p: any) => ({link: IPaginationLink}) // колбэк для формирования ссылки
}

// pagination component. makeing paging through changing url address. when we select any page, router changes
// url and wrapper of this component changing options, which then sets here through props
const UrlPagination = (props: IUrlPagination) => {
  const intl = useIntl()

  const itemsPerPage = props.filterParams?.itemsPerPage // itemsPerPage ?? DEFAULT_ITEMS_PER_PAGE
  const currentPageIndex = props.filterParams?.pageNumber // currentPage ?? DEFAULT_PAGE
  const lastPageIndex = Math.ceil(props.itemsNumber / itemsPerPage)
  const middlePageIndex = currentPageIndex - Math.trunc(props.pageButtonNumber / 2)
  const startIndex =
    middlePageIndex >= 0
      ? lastPageIndex - middlePageIndex > props.pageButtonNumber
        ? middlePageIndex
        : lastPageIndex - props.pageButtonNumber
      : 0
  const rowFrom = currentPageIndex * itemsPerPage + 1
  const ost = props.itemsNumber - itemsPerPage * currentPageIndex
  const rowTo = rowFrom + (ost >= itemsPerPage ? itemsPerPage : ost) - 1

  // prepearing links for pagination
  const pages = Array.from(new Array(props.pageButtonNumber).keys()).map((pageNum, index) => {
    return props.makeLink({
      pageNumber: index + startIndex,
      itemsPerPage: itemsPerPage,
      title: (index + startIndex).toString(),
      filter: props.filterParams.filter, // ?? "",
      ordering: props.filterParams.ordering, // order ?? "id"
      additionalParameters: props.filterParams.additionalParameters,
      url: props.filterParams.url,
    })
  })

  // prepearing links for changing limit of items
  const volumes = props.itemsPerPageVariants.map((item) => {
    return props.makeLink({
      pageNumber: 0,
      itemsPerPage: item,
      title: item.toString(),
      filter: props.filterParams?.filter, //filter ?? "",
      ordering: props.filterParams?.ordering ?? 'id', // props.order ?? "id"
      additionalParameters: props.filterParams?.additionalParameters,
      url: props.filterParams?.url,
    })
  })

  const first = props.makeLink({
    pageNumber: 0,
    itemsPerPage: itemsPerPage,
    title: intl.formatMessage({id: 'COMPONENTS.LISTS.PAGINATION.FIRST'}),
    filter: props.filterParams?.filter, // ?? "",
    ordering: props.filterParams?.ordering, // order ?? "id"
    additionalParameters: props.filterParams?.additionalParameters,
    url: props.filterParams?.url,
  })

  const last = props.makeLink({
    pageNumber: lastPageIndex - 1,
    itemsPerPage: itemsPerPage,
    title: intl.formatMessage({id: 'COMPONENTS.LISTS.PAGINATION.LAST'}),
    filter: props.filterParams?.filter, // ?? "",
    ordering: props.filterParams?.ordering, // order ?? "id"
    additionalParameters: props.filterParams?.additionalParameters,
    url: props.filterParams?.url,
  })

  return (
    <div className='card-footer'>
      <div className='row'>
        <div className='col-sm-12 col-md-3 d-flex align-items-center justify-content-center justify-content-md-start'>
          <div className='paginator'>
            <ul className='pagination pagination-outline'>
              {volumes?.map((link, index) => {
                return (
                  <li
                    key={index}
                    className={`page-item ${
                      itemsPerPage == link.title.toString() ? ' active' : ''
                    }`}
                    style={{cursor: 'pointer', zIndex: '0'}}
                  >
                    <NavLink
                      className={`page-link border-0 ${
                        itemsPerPage != link.title.toString() ? 'bg-secondary text-primary' : ''
                      }`}
                      to={link.url}
                    >
                      {link.title}
                    </NavLink>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
        <div className='col-sm-12 col-md-3 d-flex align-items-center justify-content-center justify-content-md-start'>
          <span>{`${intl.formatMessage({
            id: 'COMPONENTS.LISTS.PAGINATION.SHOWN',
          })} ${intl.formatMessage({
            id: 'COMPONENTS.LISTS.PAGINATION.FROM',
          })} ${rowFrom} ${intl.formatMessage({
            id: 'COMPONENTS.LISTS.PAGINATION.TO',
          })} ${rowTo} ${intl.formatMessage({id: 'COMPONENTS.LISTS.PAGINATION.TOTAL'})} ${
            props.itemsNumber
          }`}</span>
        </div>
        <div className='col-sm-12 col-md-6 d-flex align-items-center justify-content-center justify-content-md-end'>
          <div className='paginator'>
            {
              <ul role='menubar' className='pagination pagination-outline'>
                <li
                  key={pages.length + 1}
                  className={`page-item`}
                  style={{cursor: 'pointer', zIndex: '0'}}
                >
                  <NavLink
                    className={`page-link bg-secondary text-primary border-0`}
                    to={first.url}
                  >
                    <KTSVG path='/media/icons/duotune/arrows/arr025.svg'></KTSVG>
                  </NavLink>
                </li>
                <li
                  key={pages.length + 2}
                  className={`page-item`}
                  style={{cursor: 'pointer', zIndex: '0'}}
                >
                  <NavLink
                    className={`page-link bg-secondary text-primary border-0`}
                    // to={first.url}>
                    // to={pages ? pages[currentPageIndex - 1]?.url ?? first.url : first.url}
                    to={
                      pages
                        ? pages.find((link) => currentPageIndex - 1 == link.num)?.url ??
                          pages.find((link) => currentPageIndex == link.num)?.url ??
                          '#'
                        : '#'
                    }
                  >
                    <KTSVG path='/media/icons/duotune/arrows/arrow-left.svg'></KTSVG>
                  </NavLink>
                </li>
                {pages?.map((link) => {
                  return (
                    <li
                      key={link.num}
                      className={`page-item ${currentPageIndex == link.num && ' active'}`}
                      style={{cursor: 'pointer', zIndex: '0'}}
                    >
                      <NavLink
                        className={`page-link border-0 ${
                          currentPageIndex != link.num ? 'bg-secondary text-primary' : ''
                        }`}
                        to={link.url}
                      >
                        {(+link.title + 1).toString()}
                      </NavLink>
                    </li>
                  )
                })}
                <li
                  key={pages.length + 3}
                  className={`page-item d-flex flex-row justify-content-between align-items-center`}
                  style={{cursor: 'pointer', zIndex: '0'}}
                >
                  <NavLink
                    className={`page-link bg-secondary text-primary border-0`}
                    to={
                      pages
                        ? pages.find((link) => currentPageIndex + 1 == link.num)?.url ??
                          pages.find((link) => currentPageIndex == link.num)?.url ??
                          '#'
                        : '#'
                    }
                  >
                    <KTSVG path='/media/icons/duotune/arrows/arrow-right.svg'></KTSVG>
                  </NavLink>
                </li>
                <li
                  key={pages.length + 4}
                  className={`page-item`}
                  style={{cursor: 'pointer', zIndex: '0'}}
                >
                  <NavLink className={`page-link bg-secondary text-primary border-0`} to={last.url}>
                    <KTSVG path='/media/icons/duotune/arrows/arr028.svg'></KTSVG>
                  </NavLink>
                </li>
              </ul>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export {UrlPagination}
