import React from 'react'
import {useIntl} from 'react-intl'
import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import {PageTitle} from '../../../_metronic/layout/core'
import MpDocsItemWrapper from '../mpdocs/item/MpDocsItemWrapper'
import MpDocsListWrapper from '../mpdocs/list/MpDocsListWrapper'
import OrdersListWrapper from '../orders/list/OrdersListWrapper'
import OrdersItemWrapper from '../orders/item/OrdersItemWrapper'

type MpDocsPageProps = {
  rootPath: string
}
const MpDocsPage = (props: MpDocsPageProps) => {
  const intl = useIntl()

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route path='list' element={<MpDocsListWrapper />} />
        <Route path='item/*' element={<Outlet />}>
          <Route path=':id' element={<MpDocsItemWrapper />} />
          <Route
            index
            element={
              <PageTitle breadcrumbs={[]}>
                {intl.formatMessage({id: 'COMPONENTS.MPDOCS.ITEM.NOTSELECTED.TITLE'})}
              </PageTitle>
            }
          />
          <Route path='*' element={<Navigate to='/error/404' />} />
        </Route>
        <Route index element={<Navigate to={`${props.rootPath}/list`} />} />
      </Route>
    </Routes>
  )
}

export default MpDocsPage
