import React, { FC } from 'react'
import {useLayout} from '../../core/LayoutProvider'
import {Toolbar1} from './Toolbar1'
import { useAppLayout } from '../../core/AppLayoutProvider'
// import { deserialize } from 'v8'




const Toolbar: FC = (props) => {
  const {config} = useLayout()
  const appLayout = useAppLayout();

  switch (config.toolbar.layout) {
    case 'toolbar1':
      // return <Toolbar1 className='toolbarMain' id='kt_toolbar'>
      return <Toolbar1 className='toolbarMain'>
        {appLayout.toolbar}
        {/* {config.toolbar.components} */}
      </Toolbar1>

    default:
      // return <Toolbar1 className='toolbarMain' id='kt_toolbar' />
      return <Toolbar1 className='toolbarMain' />
  }
}

export {Toolbar}
