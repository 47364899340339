import { call, put, takeEvery } from "redux-saga/effects"
import { fetchOrderStatuses } from "./api";
import { setOrderStatuses } from "./reducers"
import { ASYNC_GET_ORDER_STATUSES } from "./actions"



export function* orderStatusSaga(){
    yield takeEvery(ASYNC_GET_ORDER_STATUSES, workerGetOrderStatuses)
}


export function* workerGetOrderStatuses():any{
    const orderStatusesPromise = yield call(fetchOrderStatuses)
    yield put(setOrderStatuses(orderStatusesPromise.data.results))
}