import { createAction } from "@reduxjs/toolkit";
import { categoryActionTypes as actionTypes, IAllCategoriesProps, IComparisonSaveRequest, IFilteredCategory } from "./types"

export const SET_ROOT_CATEGORIES = actionTypes.SetRootCategories;
export const ASYNC_GET_ROOT_CATEGORIES = actionTypes.GetRootCategories;
export const ASYNC_GET_CATEGORIES_BY_FILTER = actionTypes.GetCategoriesByFilter;
export const ASYNC_GET_ALL_CATEGORIES = actionTypes.GetAsyncAllCategories;
export const RESET_FILTERED_CATEGORIES = actionTypes.ResetFilteredCategories;
export const RESET_ALL_CATEGORIES = actionTypes.ResetAllCategories;
export const ASYNC_GET_COMPARISON = actionTypes.GetComparison;
export const ASYNC_UPDATE_COMPARISON = actionTypes.SetComparison;
export const RESET_COMPARISON = actionTypes.ResetComparison;

export const setRootCategoriesCreator = createAction<string>(SET_ROOT_CATEGORIES);
export const resetFilteredCategoriesCreator = createAction(RESET_FILTERED_CATEGORIES);
export const resetAllCategoriesCreator = createAction(RESET_ALL_CATEGORIES);

export const getAsyncRootCategoriesCreator = createAction(ASYNC_GET_ROOT_CATEGORIES);
export const getAsyncCategoriesByFilterCreator = createAction<string>(ASYNC_GET_CATEGORIES_BY_FILTER);
export const getComparisonCreator = createAction<number>(ASYNC_GET_COMPARISON);
export const saveComparisonCreator = createAction<IComparisonSaveRequest>(ASYNC_UPDATE_COMPARISON);
export const resetComparisonCreator = createAction(RESET_COMPARISON);



export const getAsyncAllCategoriesCreator = createAction<IAllCategoriesProps>(ASYNC_GET_ALL_CATEGORIES);