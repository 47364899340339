import axios from "axios";
import { IWarehouse, IWarehouseListRequestParams, IWarehouseListResponse } from "./types";

const API_URL = `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENDPOINT_PREFIX}`;
export const WAREHOUSES_URL = `${API_URL}v2/warehouses/`

export const getWarehousesFromServer = (params: IWarehouseListRequestParams) => {
    return axios.get<IWarehouseListResponse>(`${WAREHOUSES_URL}?limit=${params.limit ?? 0}&offset=${params.offset ?? 0}`)
}


export const addWarehouseToServer = (params: IWarehouse) => {
    let wh: any = params
    delete wh.id
    delete wh.owner
    return axios.post<IWarehouse>(`${WAREHOUSES_URL}`, wh)
}

export const deleteWarehouseFromServer = (params: number) => {
    return axios.delete(`${WAREHOUSES_URL}${params}/`)
}

export const updateWarehouseOnServer = (params: IWarehouse) => {
    let wh: any = params
    delete wh.owner
    return axios.put<IWarehouse>(`${WAREHOUSES_URL}${params.id}/`, wh)
}