import {createAction} from '@reduxjs/toolkit'
import {
  actionTypes,
  FeedbacksActionTypes,
  IFeedbackRequestParams,
  IFeedbackSendParams,
} from './types'
import {GoodsGroupsActionTypes} from '../goodsGroups/types'
import {FETCH_GOODS_GROUP_BY_ID} from '../goodsGroups/actions'

export const FETCH_FEEDBACKS = FeedbacksActionTypes.FetchFeedbacks
export const FETCH_FETCH_FEEDBACKS_BY_ID = FeedbacksActionTypes.FetchFeedbackById
export const ASYNC_GENERATE_ANSWER = actionTypes.SET_Answer
export const ASYNC_GENERATE_FEEDBACK = FeedbacksActionTypes.SET_Feedback

export const ASYNC_SEND_FEEDBACK = FeedbacksActionTypes.SEND_Feedback

export const fetchFeedbacksAction = createAction(FETCH_FEEDBACKS)

export const fetchFeedbackByIdAction = createAction<number>(FETCH_FETCH_FEEDBACKS_BY_ID)

export const generateAnswerCreator = createAction<IFeedbackRequestParams>(ASYNC_GENERATE_ANSWER)

export const generateFeedbackCreator = createAction<IFeedbackRequestParams>(ASYNC_GENERATE_FEEDBACK)

export const sendFeedbackAction = createAction<IFeedbackSendParams>(ASYNC_SEND_FEEDBACK)
