import {call, put, select, takeEvery} from 'redux-saga/effects'
import {sagaUtils} from '../../utils/dataUtils'
import {
  resetSelectedOrderTrackInfo,
  setCount,
  setIsItemLoading,
  setIsListLoading,
  setIsTrackLoading,
  setOrders,
  setOrderStatus,
  setSelectedOrder,
  setSelectedOrderTrackInfo,
  setSomeOrders,
  setSticker,
  setIsRegistering,
  registerOrder,
} from './reducers'
import {
  ASYNC_DELETE_TRACKNUMBER,
  ASYNC_GET_ORDER,
  ASYNC_GET_ORDERS_MANUAL,
  ASYNC_GET_ORDERS_WITH_FILTER,
  ASYNC_GET_STICKER,
  ASYNC_SET_ORDER_STATUS,
  ASYNC_SET_ORDER_STATUSES,
  ASYNC_SET_TRACKNUMBER,
  SET_ORDERS_REQUEST_PARAMETERS,
  REGISTER_ORDER,
} from './actions'
import {
  getOrderFromServer,
  getOrdersFromServer,
  getOrderStickersOnServer,
  getOrdersWithFilterFromServer,
  resetDeliveryTrackNumberOnServer,
  setDeliveryTrackNumberOnServer,
  setOrderStatusesOnServer,
  setOrderStatusOnServer,
  registerOrderOnServer,
} from './api'
import {intl} from '../../modules/goods/item/validation'
import {setRequestParams} from './reducers'
import {emptyUrlParameters, IUrlParameters} from '../../components/lists/UrlFilteredList'
import {StorageType} from '../../../setup'
import {IOrderStorage} from './types'
import {IOrderStatusState} from '../orderStatuses/types'

export function* orderSaga() {
  yield takeEvery(ASYNC_GET_ORDERS_MANUAL, workerGetOrdersManual)
  yield takeEvery(ASYNC_GET_ORDERS_WITH_FILTER, workerGetOrdersWithFilter)
  yield takeEvery(SET_ORDERS_REQUEST_PARAMETERS, workerSetOrdersRequestParameters)
  yield takeEvery(ASYNC_GET_ORDER, workerGetOrder)
  yield takeEvery(ASYNC_SET_ORDER_STATUS, workerSetOrderStatus)
  yield takeEvery(ASYNC_SET_ORDER_STATUSES, workerSetOrderStatuses)
  yield takeEvery(ASYNC_GET_STICKER, workerGetOrderSticker)

  yield takeEvery(ASYNC_SET_TRACKNUMBER, workerSetTrackNumber)
  yield takeEvery(ASYNC_DELETE_TRACKNUMBER, workerDeleteTrackNumber)

  yield takeEvery(REGISTER_ORDER, workerRegisterOrder)
}

// gets all orders and set it to storage
export function* workerGetOrdersManual(action: any): any {
  yield sagaUtils.workerWrapper(
    setIsListLoading,
    getOrdersFromServer,
    action.payload,
    setOrders,
    null,
    intl.formatMessage({id: 'SAGAS.ORDERS.GET'}),
    'GET',
    'data.results',
    false,
    '',
    undefined,
    {action: setCount, resultType: 'data', resultTypePropertyName: 'count', params: null}
  )
}

// gets order by id and set it to storage
export function* workerGetOrder(action: any): any {
  yield sagaUtils.workerWrapper(
    setIsItemLoading,
    getOrderFromServer,
    action.payload,
    setSelectedOrder,
    null,
    intl.formatMessage({id: 'SAGAS.ORDER.GET'}),
    'GET',
    'data',
    false
  )
}

// gets order by id and set it to storage
export function* workerSetOrderStatus(action: any): any {
  const statusesState: IOrderStatusState = yield select((stor: StorageType) => stor.orderStatuses)

  statusesState &&
    (yield sagaUtils.workerWrapper(
      setIsItemLoading,
      setOrderStatusOnServer,
      action.payload,
      setOrderStatus,
      {status: action.payload, statuses: statusesState.items},
      intl.formatMessage({id: 'SAGAS.ORDERSTATUS.SET'}),
      'SAVE',
      'direct',
      true
    ))
}

// get orders by array of ids and update it statuses in storage
export function* workerSetOrderStatuses(action: any): any {
  const statusesState: IOrderStatusState = yield select((stor: StorageType) => stor.orderStatuses)

  statusesState &&
    (yield sagaUtils.workerWrapper(
      setIsItemLoading,
      setOrderStatusesOnServer,
      action.payload,
      setSomeOrders,
      null,
      intl.formatMessage({id: 'SAGAS.ORDERSTATUSES.SET'}),
      'SAVE',
      'data',
      true
    ))
}

// gets order sticers
export function* workerGetOrderSticker(action: any): any {
  yield sagaUtils.workerWrapper(
    setIsItemLoading,
    getOrderStickersOnServer,
    action.payload,
    setSticker,
    null,
    intl.formatMessage({id: 'SAGAS.ORDERSTICKERS.GET'}),
    'GET',
    'data',
    false
  )
}

// get orders from server, using filters in redux
export function* workerGetOrdersWithFilter(): any {
  const orderState: IOrderStorage = yield select((store: StorageType) => store.orders)
  const params: IUrlParameters = orderState.filter ?? emptyUrlParameters

  yield sagaUtils.workerWrapper(
    setIsListLoading,
    getOrdersWithFilterFromServer,
    params,
    setOrders,
    null,
    intl.formatMessage({id: 'SAGAS.ORDERS.GET'}),
    'GET',
    'data.results',
    false,
    '',
    undefined,
    {action: setCount, resultType: 'data', resultTypePropertyName: 'count', params: null}
  )
}

// get orders from server, using filters in redux
export function* workerSetTrackNumber(action: any): any {
  // const orderState: IOrderStorage = yield select((store: StorageType) => store.orders)
  // const params: IUrlParameters = orderState.filter ?? emptyUrlParameters

  yield sagaUtils.workerWrapper(
    setIsTrackLoading,
    setDeliveryTrackNumberOnServer,
    action.payload,
    setSelectedOrderTrackInfo,
    null,
    intl.formatMessage({id: 'SAGAS.ORDERTRACK.SET'}),
    'SAVE',
    'data.result',
    true
  )
}

export function* workerRegisterOrder(action: any): any {
  yield sagaUtils.workerWrapper(
    setIsRegistering,
    registerOrderOnServer,
    action.payload,
    registerOrder,
    null,
    intl.formatMessage({id: 'SAGAS.ORDERS.REGISTER'}),
    'ADD',
    'data.result',
    true
  )
}

// get orders from server, using filters in redux
export function* workerDeleteTrackNumber(action: any): any {
  // const orderState: IOrderStorage = yield select((store: StorageType) => store.orders)
  // const params: IUrlParameters = orderState.filter ?? emptyUrlParameters

  yield sagaUtils.workerWrapper(
    setIsTrackLoading,
    resetDeliveryTrackNumberOnServer,
    action.payload,
    resetSelectedOrderTrackInfo,
    action.payload,
    intl.formatMessage({id: 'SAGAS.ORDERTRACK.RESET'}),
    'DELETE',
    'data',
    true
  )
}

// set request parameters
export function* workerSetOrdersRequestParameters(action: any): any {
  yield put(setRequestParams(action.payload))
}
