import React, {useEffect} from 'react'
import {useLocation} from 'react-router'
import clsx from 'clsx'
import {useLayout} from '../core'
import {DrawerComponent} from '../../assets/ts/components'

const Content: React.FC = ({children}) => {
  const {classes} = useLayout()
  const location = useLocation()
  console.log(location);
  useEffect(() => {
    DrawerComponent.hideAll()
  }, [location])

  return (
    <div id='kt_content_container' className={location.pathname === '/home' ? "container-fluid" : clsx(classes.contentContainer.join(' '))}>
      {/*Временное решение, обязательно сделать нормально*/}
      {children}
    </div>
  )
}

export {Content}
