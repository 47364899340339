import moment from 'moment'
import {emptyUrlParameters, IUrlParameters} from '../../components/lists/UrlFilteredList'
import {TCheckThreeState} from '../../modules/prices/list/PricesListWrapper'
import {emptyAccount} from '../accounts/types'
import {emptyCustomerCompany} from '../customerCompanys/types'
import {emptyMarketplace} from '../marketplaces/types'
import {dafaultPriceItemId} from './constants'

export const emptyPricesUrlParameters: IUrlParameters = {
  ...emptyUrlParameters,
  additionalParameters: [
    {
      label: 'marketplace',
      value: emptyMarketplace.id,
    },
    {
      label: 'company',
      value: emptyCustomerCompany.id,
    },
    {
      label: 'account',
      value: emptyAccount.id,
    },
    {
      label: 'price_type',
      value: '',
    },
  ],
  url: '/prices/list/',
}

export const UNDEFINED_PRICE_TYPE = -1
export const emptyPriceItem: IPriceItem = {
  id: dafaultPriceItemId,
  created: moment(new Date()).toDate(),
  date_posted: moment(new Date()).toDate(),
  updated_at: moment(new Date()).toDate(),
  marketplace: emptyMarketplace.id,
  posted: false,
  price: '0.0',
  comment: '',
  account: emptyAccount.id,
  company: emptyCustomerCompany.id,
}

export interface IPriceStorage {
  items: IPriceGood[]
  selected: IPriceGood | null
  isLoading: boolean
  count: number | null
  filter: IUrlParameters
}

export interface IPriceRequestParameters {}

export interface IPricesSaveRequestParameters {
  company: number
  sku_customer_uid: string
  marketplace: string
  price: number
  account: number
  comment: string
  price_type: string
  old_price: number
}

export interface IPriceListItem {
  id: number
  account?: number
  marketplace: number
  price: string
  comment?: string
  price_type?: string
  posted: boolean
  created: Date
  date_posted: Date
  updated_at: Date
  sku?: number
  company?: number
  mpcard?: number
}

export interface IPriceItem {
  id: number
  account?: number
  marketplace: number
  price: string
  comment?: string
  price_type?: string
  posted: boolean
  created: Date
  date_posted: Date
  updated_at: Date
  sku?: number
  company?: number
  mpcard?: number
  selected?: boolean
}

export interface IPrices {
  id: number
  price: number
  price_mod: number
  base_price: number
}

export enum PricesMenuItem {
  PriceTypes = 'priceTypes',
  Prices = 'prices',
}

export interface IPostedPriceType {
  sku_customer_uid: string
  price: string
  old_price: string
  price_type: number
}

export interface INextPrice {
  sku_customer_uid: string
  price: string
  old_price: string
  price_type: number
  updated_price?: boolean
  updated_old_price?: boolean
}

export interface IPricesType {
  id: number
  price: number
  price_mod: number
  base_price: number
  price_type: string | undefined
}

export const emptyPrices: IPrices = {
  id: -1,
  price: 0,
  price_mod: 0,
  base_price: 0,
}

export interface IPriceExtended {
  account?: number
  comment: string
  company?: number
  created?: string
  date_posted?: string
  id: number
  marketplace: string // text marketplace
  mpcard?: number
  params?: {
    price_settings?: {
      old_price?: number
    }
  }
  posted?: boolean
  price: number
  price_type?: string
  sku?: number
  updated_at?: string
}

export const emptyPriceExtended: IPriceExtended = {
  comment: '',
  id: -1,
  marketplace: '', // text marketplace,
  price: 0,
  params: {},
}

export interface IPriceGoodAccountPrice {
  // id: number
  marketplace: number
  customer: number
  account: number
  prices: IPrices
  selected: boolean
  new: boolean // when adding new price this flag will set on true
  exist: boolean // means that price is exist on server
  changed: boolean // means that price is chenged on client
}

export const emptyPriceGoodAccountPrice: IPriceGoodAccountPrice = {
  // id: -1,
  marketplace: emptyMarketplace.id,
  customer: emptyCustomerCompany.id,
  account: emptyAccount.id,
  prices: emptyPrices,
  selected: false,
  new: false,
  exist: false,
  changed: false,
}

export interface IPriceGoodTradeOffer {
  id: number
  name: string
  customer_uid: string
  accounts: IPriceGoodAccountPrice[]
  selected?: TCheckThreeState
  prices?: IPricesType[]
}

export const emptyPriceGoodTradeOffer: IPriceGoodTradeOffer = {
  id: -1,
  name: '',
  customer_uid: '',
  // accounts: [emptyPriceGoodAccountPrice],
  accounts: [],
  selected: 'unchecked',
}

export interface IPriceGood {
  id: number
  name: string
  customer_uid: string
  offers: IPriceGoodTradeOffer[]
  selected?: TCheckThreeState
}

export const emptyPriceGood: IPriceGood = {
  id: -1,
  name: '',
  customer_uid: '',
  offers: [],
  selected: 'unchecked',
}

export interface IPriceListResponse {
  count: number
  results: IPriceItem[]
}

export const emptyState: IPriceStorage = {
  items: [emptyPriceGood],
  selected: null,
  isLoading: false,
  filter: emptyPricesUrlParameters,
  count: 0,
}

export interface IUpdatePriceRequest {
  pricesToSave: IPricesSaveRequestParameters[]
  accountsStorageChanged: IPriceGood[]
}

export const priceActionTypes = {
  GetPrices: '[GetPrices] Action',
  UpdatePrice: '[UpdatePrice] Action',
  UpdatePrices: '[UpdatePrices] Action',
  SetFilterPrice: '[SetFilterPrice] Action',
}
