import { createSlice } from "@reduxjs/toolkit"
import { emptyUrlParameters } from "../../components/lists/UrlFilteredList"
import { IGoodTradeOffer } from "../../modules/goods/types"
import { IGoodTradeOfferPrice } from "../goods/api"
import { IMpCardItemPublishParams, IMpCardTemplate, emptyGoodMpCardUniversal, emptyMpCardFilterAdditionalParameters, emptyState as initialState, } from "./types"


const mpcardSlice = createSlice({
    name: "mpcardSlice",
    initialState,
    reducers: {
        setMpCards(state, action) {
            state.items = [...action.payload]
        },
        setMpCardTemplates(state, action) {
            state.templateItems = [...action.payload]
        },
        addMpCardTemplate(state, action) {
            // console.log('addMpCardTemplate',[...state.templateItems, {...action.payload}])
            state.templateItems = [...state.templateItems, { ...action.payload }]
        },
        deleteMpCardTemplate(state, action) {
            // console.log('deleteMpCardTemplate',[...state.templateItems, {...action.payload}])
            const delId: number = action.payload
            state.templateItems = [...state.templateItems.filter(t => t.id != delId)]
        },
        updateMpCardTemplate(state, action) {
            // console.log('deleteMpCardTemplate',[...state.templateItems, {...action.payload}])
            const template: IMpCardTemplate = action.payload
            state.templateItems = [...state.templateItems.map(t => {
                if (t.id == template.id) {
                    return template;
                }
                return t
            })]
        },
        setSelectedMpCard(state, action) {
            state.selected = action.payload
        },
        setSelectedUniversalMpCard(state, action) {
            state.selectedUniversal = action.payload
        },
        setSelectedUniversalMpCardNewSkuData(state, action) {
            state.selectedUniversal_data = action.payload
        },
        setIsLoading(state, action) {
            state.isLoading = action.payload
        },
        setIsUniversalMpCardLoading(state, action) {
            state.isUniversalMpCardLoading = action.payload
        },
        setLastOperationIsSuccess(state, action) {
            state.lastOperationIsSuccess = action.payload
        },
        setisUniversalMpCardSaving(state, action) {
            state.isUniversalMpCardSaving = action.payload
        },
        setTemplatesIsLoading(state, action) {
            state.templateItemsIsLoading = action.payload
        },
        // setUnlinkedMpCards(state, action){
        //     state.unlinkedItems = action.payload
        // },
        // setMatchesItem(state, action){
        //     state.matchesItem = action.payload
        // },
        // setTradeOffers(state, action){
        //     state.tradeOffers = action.payload
        // },
        setCount(state, action) {
            state.count = action.payload
        },
        // setMpCardPrices(state, action){
        //     console.log('setMpCardPrices',action.payload)
        //     const price: IGoodTradeOfferPrice = action.payload

        //     state.items = state.items.map((s: IGoodTradeOffer) => {
        //         let offer = s;
        //         offer.mpcards = offer.mpcards.map(card => {
        //             if (card.id == price.mpcard){
        //                 card.price = {
        //                     comment: price.comment,
        //                     price: price.price,
        //                     marketplace: price.marketplace,
        //                     company: price.company,
        //                     posted: price.posted,
        //                     params: price.params,
        //                 }
        //             }
        //             return card
        //         })
        //         return offer
        //     })
        // },
        setMpCardPrices(state, action) {
            console.log('setMpCardPrices', action.payload)
            const prices: IGoodTradeOfferPrice[] = action.payload

            state.items = state.items.map((s: IGoodTradeOffer) => {
                let offer = s;
                offer.mpcards = offer.mpcards.map(card => {
                    prices.map(price => {
                        if (card.id == price.mpcard) {
                            card.price = {
                                comment: price.comment,
                                price: price.price,
                                marketplace: price.marketplace,
                                company: price.company,
                                posted: price.posted,
                                params: price.params,
                            }
                        }

                    })
                    return card
                })
                return offer
            })
        },
        setRequestParams(state, action) {
            state.filter = action.payload
        },
        resetRequestParams(state) {
            state.filter = {
                ...emptyUrlParameters,
                additionalParameters: emptyMpCardFilterAdditionalParameters
            }
        },
        setMarketplaceParam(state, action) {
            state.filter = {
                ...state.filter,
                additionalParameters: state.filter.additionalParameters?.map(ap => {
                    if (ap.label == "marketplace")
                        return { label: ap.label, value: action.payload }
                    else
                        return ap
                }) ?? emptyMpCardFilterAdditionalParameters
            }
        },
        setAccountParameter(state, action) {
            state.filter = {
                ...state.filter,
                additionalParameters: state.filter.additionalParameters?.map(ap => {
                    if (ap.label == "account")
                        return { label: ap.label, value: action.payload }
                    else
                        return ap
                }) ?? emptyMpCardFilterAdditionalParameters
            }
        },
        setGoodTradeOfferMpCardPublish2(state, action) {
            const { id, isPublishing } = action.payload
            
            state.items = [...(state.items?.map(to => {
                const toMpCards = to.mpcards.map(mc => {
                    if (mc.id == id)
                        return { ...mc, isPublishing: isPublishing }
                    else
                        return mc
                })
                return { ...to, mpcards: toMpCards }
            }) ?? [])]
        },
        setGoodTradeOfferMpCardIsPublished2(state, action) {
            //console.log('Good reducers action.payload', action.payload)
            const { id, is_published } = action.payload

            state.items = [...(state.items?.map(to => {
                const toMpCards = to.mpcards.map(mc => mc.id == id ? { ...mc, is_published } : mc)
                return { ...to, mpcards: toMpCards }
            }) ?? [])]
        },
        setMpCardsIsLoading(state, action) {
            state.isLoading = action.payload
        },
        setMpCardsCount(state, action) {
            state.count = action.payload
        },
        addTradeOfferMpCard(state, action) {
            // const {mpcard, offerid} = action.payload
            const { main, combined } = action.payload
            state.items = state.items.map(offer => {
                if (offer.id == combined) {
                    offer.mpcards = [...offer.mpcards, main]
                }
                return offer
            })
        },
    }
})


export const mpcardReducer = mpcardSlice.reducer
export const { setMpCards, setMpCardsIsLoading, setMpCardsCount, setRequestParams, setSelectedUniversalMpCard,
    setGoodTradeOfferMpCardPublish2, setGoodTradeOfferMpCardIsPublished2,
    setCount,
    setMpCardTemplates, setTemplatesIsLoading, setLastOperationIsSuccess,
    addMpCardTemplate, deleteMpCardTemplate, updateMpCardTemplate, setIsUniversalMpCardLoading, setisUniversalMpCardSaving, setSelectedUniversalMpCardNewSkuData,
    setMarketplaceParam, setIsLoading, setAccountParameter, setSelectedMpCard, setMpCardPrices, addTradeOfferMpCard, resetRequestParams
} = mpcardSlice.actions