import {IUrlParameters} from '../../components/lists/UrlFilteredList'
import axios from 'axios'
import {IMonitoring, INotify, IRules, IWarehouseRule, MonitoringItem} from './types'
import {IAccount, IWBAccount} from '../accounts/types'

const API_URL = `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENDPOINT_PREFIX}`
export const ACCEPTANCE_URL = `${API_URL}v2/warehouse_coefficient/`

export const ACCOUNTS_URL = `${API_URL}v2/accounts/`

const DEFAULT_ACCEPTANCE_OFFSET = 0
export const DEFAULT_ACCEPTANCE_GET_LIMIT = 5

export const fetchWarehouseRates = (params: IUrlParameters) => {
  const offset =
    params.pageNumber !== undefined && params.itemsPerPage !== undefined
      ? params.pageNumber * params.itemsPerPage
      : DEFAULT_ACCEPTANCE_OFFSET

  return axios.get<MonitoringItem>(
    `${ACCEPTANCE_URL}?limit=${
      params.itemsPerPage ?? DEFAULT_ACCEPTANCE_GET_LIMIT
    }&offset=${offset}`
  )
}

export const fetchWarehouseRatesById = (id: number) => {
  return axios.get<IMonitoring>(`${ACCEPTANCE_URL}${id}/`)
}

export const refreshWarehouseRatesById = (id: number) => {
  try {
    const res = axios.get<IMonitoring>(`${ACCEPTANCE_URL}${id}/refresh/`)
    return res
  } catch (error) {
    return null
  }
}
export const updateWarehouseRatesOnServer = (params: IMonitoring) => {
  return axios.put<IMonitoring>(`${ACCEPTANCE_URL}${params.id}/`, params)
}

export const addAcceptanceRatesToServer = (params: IMonitoring) => {
  return axios.post<IMonitoring>(`${ACCEPTANCE_URL}`, params)
}

export const patchWarehouseNotificationOnServer = (params: INotify) => {
  return axios.patch<IMonitoring>(`${ACCEPTANCE_URL}${params.id}/`, params.notify)
}

export const patchWarehouseRulesOnServer = (params: IRules) => {
  return axios.patch<IMonitoring>(`${ACCEPTANCE_URL}${params.id}/`, {rules: params.rules})
}
export const deleteAcceptanceRatesOnServer = (id: number) => {
  return axios.delete(`${ACCEPTANCE_URL}${id}/`)
}

export const getSuppliesFromServer = (accId: string, monitoringId: number) => {
  return axios.get(`${ACCEPTANCE_URL}supplies/${accId}/${monitoringId}/`)
}

export async function addWBAccountToServer(params: IWBAccount) {
  try {
    const res = axios.post<IWBAccount>(ACCOUNTS_URL, params)
    return res
  } catch (error) {
    return null
  }
}
