/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import { propTypes } from 'react-bootstrap/esm/Image'
import { useIntl } from 'react-intl'
import Logo from './Logo'
import css from './SquareIconedTextBlock.module.css'

interface ISquareIconedTextBlock {
  className?: string
}

const SquareIconedTextBlock: FC<ISquareIconedTextBlock> = (props) => {
  const intl = useIntl()

  return (
    <Row>
      <Col>
        {/* begin::Row */}
        <div className={`${css.squareLayout} ${props.className}`}>
          {props.children}
        </div>
        {/* gy-5 g-xl-8 */}
        {/* end::Row */}

      </Col>
    </Row>
    )
}


export default SquareIconedTextBlock
