import {FC} from 'react'

interface IQuickMenuRootProps {
  rightChildren?: any
}

const QuickMenuRoot: FC<IQuickMenuRootProps> = (props) => {
  return (
    <>
      <div className='d-flex flex-row justify-content-start align-items-start w-lg-100'>
        {props.children}
      </div>
      {props.rightChildren && (
        <div className='w-lg-25 d-flex flex-row justify-content-end'>{props.rightChildren}</div>
      )}
    </>
  )
}

export default QuickMenuRoot
