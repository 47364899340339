import {FC, useEffect, useState} from 'react'
import {Col, Row} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import DevidedNumber from '../../../components/vision/info/DevidedNumber'
import {IAccount} from '../../../store/accounts/types'
import {ICustomerCompany} from '../../../store/customerCompanys/types'
import {IMarketplace} from '../../../store/marketplaces/types'
import {IOrderItem, IOrderSku} from '../../../store/orders/types'
import {dateUtils, messageUtils} from '../../../utils/dataUtils'
import ModalWindowExtended from '../../../components/modals/cathegory-select-modal/ModalWindowExtended'
import Select from 'react-select'
import {
  IDeliveryService,
  IDeliveryTrack,
  IDeliveryTrackOrderRequest,
  emptyDeliveryTrack,
} from '../../../store/deliveryServices/types'
import Loader2 from '../../../components/vision/loaders/Loader2'
import {fetchGoodFromOreder} from "../../../store/goods/api";
import css from "./OrderDataItem.module.css";
import {useNavigate} from "react-router-dom";

export const paddingX40Px = {
  paddingLeft: '40px',
  paddingRight: '40px',
}

export const paddingLeft40Px = {
  paddingLeft: '40px',
}

export const border = {
  paddingLeft: '40px',
  paddingRight: '40px',
  borderTop: '1px solid #dee2e6',
  borderBottom: '1px solid #dee2e6',
}

export const padding40Px = {
  paddingLeft: '40px',
  paddingRight: '40px'
}

interface IOrdersData {
  item: IOrderItem
  marketplaces?: IMarketplace[]
  accounts?: IAccount[]
  companys?: ICustomerCompany[]
  deliveryCompanys?: IDeliveryService[]
  isTrackLoading?: boolean
  saveTrackInfo?: (info: IDeliveryTrackOrderRequest) => void
  resetTrackInfo?: (orderId: number) => void
}

const OrderData: FC<IOrdersData> = (props) => {
  const navigation = useNavigate();
  const intl = useIntl()
  const [trackModalShow, setTrackModalShow] = useState<boolean>(false)

  const createdDate = props.item.created ? new Date(props.item.created) : null
  const shippedDate = props.item.shipment_date ? new Date(props.item.shipment_date) : null
  const currentItemMarketplace =
    props.marketplaces?.find(
      (mp) => props.item?.marketplace_id && mp.id === props.item.marketplace_id
    ) ?? undefined

  const [selectedTrack, setSelectedTrack] = useState<IDeliveryTrack>(emptyDeliveryTrack)
  const [tmpSelectedTrack, setTmpSelectedTrack] = useState<string>('')
  const [tmpSelectedDeliveryCompany, setTmpSelectedDeliveryCompany] =
    useState<IDeliveryService | null>(null)

  useEffect(() => {
    if (
      props.item.delivery_service != undefined &&
      props.item.delivery_service != null &&
      props.item.track_number != undefined &&
      props.item.track_number != null
    ) {
      setSelectedTrack({
        company: props.item.delivery_service,
        track: props.item.track_number,
      })
    }
  }, [props.item])

  const setModalShow = (show: boolean) => {
    setTrackModalShow(show)
    setTmpSelectedDeliveryCompany(selectedTrack.company)
    setTmpSelectedTrack(selectedTrack.track)
  }

  const apply = () => {
    props.saveTrackInfo &&
      props.saveTrackInfo({
        company: tmpSelectedDeliveryCompany,
        track: tmpSelectedTrack,
        orderId: props.item.id,
      })
  }

  const cancel = () => {
    setTmpSelectedDeliveryCompany(selectedTrack.company)
    setTmpSelectedTrack(selectedTrack.track)
  }

  const reset = () => {
    props.resetTrackInfo && props.resetTrackInfo(props.item.id)
  }

  const getGood=async (param:number)=>{
    const  good = await fetchGoodFromOreder(param)
    const goodId = good?.data?.results[0]?.id
    goodId && navigation(`/goods/item/${goodId}`)
  }
  return (
    <Row>
      <Col>
        <Row>{props.children}</Row>
        <Row>
          <Col className='d-flex flex-row justify-content-start mt-5'>
            <div className='mx-5 ps-5'>
              <h4>
                {intl.formatMessage({id: 'COMPONENTS.ORDERS.ITEM.COMPANY'})}: {props.item.company}
              </h4>
            </div>
            <div className='ms-5'>
              <h4>
                {intl.formatMessage({id: 'COMPONENTS.ORDERS.ITEM.ACCOUNT'})}:{' '}
                {props.item.account
                  ? props.accounts?.find((a) => a.id == props.item.account)?.name ??
                    props.item.account
                  : intl.formatMessage({id: 'COMPONENTS.ORDERS.LIST.FILTERS.ACCOUNT.NOTSELECTED'})}
              </h4>
            </div>
          </Col>
        </Row>
        <Row className='mt-5'>
          <Col>
            <div className='bg-secondary mb-5 py-5 ' style={border}>
              <div>
                <strong>{intl.formatMessage({id: 'COMPONENTS.ORDERS.ITEM.CREATED'})}:</strong>{' '}
                {createdDate
                  ? `${dateUtils.standartDateString(
                      createdDate,
                      '.'
                    )} в ${dateUtils.standartTimeString(createdDate, ':')}`
                  : intl.formatMessage({id: 'COMPONENTS.ORDERS.LIST.FILTERS.DATERANGE.NOVALUES'})}
              </div>
              <div>
                <strong>{intl.formatMessage({id: 'COMPONENTS.ORDERS.ITEM.SHIP'})}:</strong>{' '}
                {shippedDate
                  ? `${dateUtils.standartDateString(
                      shippedDate,
                      '.'
                    )} в ${dateUtils.standartTimeString(shippedDate, ':')}`
                  : intl.formatMessage({
                      id: 'COMPONENTS.ORDERS.LIST.FILTERS.DATERANGE.NOVALUES',
                    })}{' '}
                {intl.formatMessage({id: 'COMPONENTS.ORDERS.ITEM.BYCOMPANY'})}{' '}
                <strong className='text-dark'>
                  {props.item.delivery_company
                    ? props.item.delivery_company
                    : intl.formatMessage({id: 'STATUS.NODATA'})}
                </strong>
              </div>
              <div>
                <strong>{intl.formatMessage({id: 'COMPONENTS.ORDERS.ITEM.TRACK'})}:</strong>
                {props.isTrackLoading ? (
                  <Loader2 />
                ) : (
                  <>
                    <span>
                      {' '}
                      {selectedTrack?.track?.length
                        ? selectedTrack.track
                        : intl.formatMessage({
                            id: 'COMPONENTS.ORDERS.ITEM.TRACKNUBER.ERRORS.NOTSET',
                          })}{' '}
                      (
                      {selectedTrack?.company && selectedTrack.company.id >= 0
                        ? selectedTrack.company.name
                        : intl.formatMessage({
                            id: 'COMPONENTS.ORDERS.ITEM.TRACKNUBER.ERRORS.COMPANYNOTSELECTED',
                          })}
                      ){' '}
                      {
                        <a href='#' onClick={() => setModalShow(true)}>
                          {intl.formatMessage({
                            id: selectedTrack?.track?.length ? 'ACTION.EDIT' : 'ACTION.SET',
                          })}
                        </a>
                      }{' '}
                    </span>
                    {selectedTrack?.track?.length ? (
                      <span>
                        {' '}
                        <a
                          href='#'
                          className='text-danger'
                          onClick={() =>
                            messageUtils.question(
                              intl.formatMessage({
                                id: 'COMPONENTS.ORDERS.ITEM.TRACKNUBER.DELETE.QUESTION',
                              }),
                              reset
                            )
                          }
                        >
                          &#9747;
                        </a>
                      </span>
                    ) : null}
                  </>
                )}
              </div>
            </div>
            {/* <hr /> */}
            <div className='table-responsive bg-white  mb-5 py-5' style={paddingX40Px}>
              <p>
                <strong>{intl.formatMessage({id: 'COMPONENTS.ORDERS.ITEM.PRODUCTS'})}:</strong>
              </p>
              {props.item.skus ? (
                <table className='table table-row-bordered  table-row-gray-500 align-middle gs-0 gy-4 table-hover"'>
                  <thead>
                    <th className='text-center fw-bolder'>
                      {intl.formatMessage({id: 'COMPONENTS.ORDERS.ITEM.N'})}
                    </th>
                    <th className='text-left fw-bolder w-50'>
                      {intl.formatMessage({id: 'COMPONENTS.ORDERS.ITEM.NAME'})}
                    </th>
                    <th className='text-center fw-bolder'>
                      {intl.formatMessage({id: 'COMPONENTS.ORDERS.ITEM.IMAGE'})}
                    </th>
                    <th className='text-center fw-bolder'>
                      {intl.formatMessage({id: 'COMPONENTS.ORDERS.ITEM.QUANTITY'})}
                    </th>
                    <th className='text-center fw-bolder'>
                      {intl.formatMessage({id: 'COMPONENTS.ORDERS.ITEM.PRICE'})}
                    </th>
                  </thead>
                  <tbody>
                    {props.item.skus.map((sku: IOrderSku, index) => (
                      <>
                        <tr className='fs-6 '>
                          <td className='text-center'>{index + 1}</td>
                          <td className={ `${css.itemName}` } onClick={()=>getGood(+sku.good)}>{sku.sku_name
                          }</td>
                          {sku.images.length > 0 ? (
                            <td className='text-center'>
                              <img
                                key={sku?.images[0].id}
                                src={sku?.images[0].image.full_size}
                                style={{width: '100px', height: '130px'}}
                                className='img-thumbnail '
                                alt={sku?.images[0].name}
                              />
                            </td>
                          ) : (
                            <td className='text-center'>
                              <img
                                key={index}
                                src='../../../assets/img/no-image.png'
                                style={{width: '100px', height: '150px'}}
                                className='img-thumbnail '
                                alt='no-image'
                              />
                            </td>
                          )}
                          <td className='text-center'>{sku.quantity}</td>
                          <td className='text-center'>{sku.price}</td>
                        </tr>
                      </>
                    ))}
                  </tbody>
                </table>
              ) : null}
            </div>
            {/* <hr /> */}
            <div className='bg-secondary py-5' style={border}>
              <p>
                <strong>{intl.formatMessage({id: 'COMPONENTS.ORDERS.ITEM.AMOUNT'})}:</strong>{' '}
                <strong>
                  {' '}
                  <DevidedNumber>{props.item.amount}</DevidedNumber>{' '}
                </strong>
                <strong>
                  {' ' + intl.formatMessage({id: 'COMPONENTS.ORDERS.ITEM.PRICE.MEASURE'})}
                </strong>
              </p>
            </div>
            <ModalWindowExtended
              setShow={(show) => setTrackModalShow(show)}
              applyAction={apply}
              cancelAction={cancel}
              applyText={intl.formatMessage({id: 'ACTION.SET1'})}
              cancelText={intl.formatMessage({id: 'ACTION.CANCEL1'})}
              canApply={tmpSelectedDeliveryCompany != null && tmpSelectedTrack.length > 0}
              show={trackModalShow}
              centered={true}
              title={intl.formatMessage({id: 'COMPONENTS.ORDERS.ITEM.TRACK.SET'})}
            >
              <div>
                <div className='form-control'>
                  <p>
                    {intl.formatMessage({id: 'COMPONENTS.ORDERS.ITEM.TRACKNUBER.COMPANY.TITLE'})}
                  </p>
                  <Select
                    className={`w-100 mb-5`}
                    noOptionsMessage={() =>
                      intl.formatMessage({id: 'COMPONENTS.ORDERS.ITEM.TRACKNUBER.COMPANYS.NODATA'})
                    }
                    loadingMessage={() =>
                      intl.formatMessage({id: 'COMPONENTS.ORDERS.ITEM.TRACKNUBER.COMPANYS.LOADING'})
                    }
                    value={tmpSelectedDeliveryCompany}
                    placeholder='выберите '
                    isClearable={true}
                    options={[...(props.deliveryCompanys ?? [])]}
                    getOptionLabel={(l) => l.name}
                    getOptionValue={(l) => l.id.toString()}
                    onChange={(e) => setTmpSelectedDeliveryCompany(e)}
                  />
                  <p>{intl.formatMessage({id: 'COMPONENTS.ORDERS.ITEM.TRACKNUBER.TRACK.TITLE'})}</p>
                  <input
                    className={`form-control form-control-solid`}
                    value={tmpSelectedTrack}
                    onChange={(e) => setTmpSelectedTrack(e.currentTarget.value)}
                  />
                </div>
              </div>
            </ModalWindowExtended>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default OrderData
