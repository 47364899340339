import React, {FC} from 'react'
import {useIntl} from 'react-intl'
import CardSimple from '../../../components/containers/CardSimple'
import CheckBoxCustom from '../../../components/inputs/CheckBoxCustom'
import OrderingHeader from '../../../components/inputs/OrderingHeader'
import {IUrlParameters} from '../../../components/lists/UrlFilteredList'
import {UrlPagination} from '../../../components/lists/UrlPagination'
import Loader1 from '../../../components/vision/loaders/Loader1'
import {IAccount} from '../../../store/accounts/types'
import {ICustomerCompany} from '../../../store/customerCompanys/types'
import {IMarketplace} from '../../../store/marketplaces/types'
import {DEFAULT_ORDERS_LIMIT} from '../../../store/orders/constants'
import {IOrderItem} from '../../../store/orders/types'
import {createLink} from '../../../utils/dataUtils'
import {currentScreenSize} from '../../../utils/systemUtils'
import {DEFAULT_PAGE} from '../../goods/GoodsList'
import {PAGINATION_VARIANTS} from '../../goods/list/GoodsTable1'
import {DEFAULT_BUTTONS_PAGINATION} from '../../goods/types'
import {paddingLeft40Px} from '../item/OrderData'
import OrdersListItem from '../item/OrdersListItem'

interface IOrderListProps {
  title?: string
  description?: string
  className?: string
  children?: any
  childrenContainerClassName?: string

  items?: IOrderItem[]
  count: number
  isLoading?: boolean

  filterParams: IUrlParameters
  getPage?: () => {}

  itemUrl?: string // url to navigate when click on item
  marketplaces?: IMarketplace[]
  accounts?: IAccount[]
  companys?: ICustomerCompany[]

  setSelectedItem?: (item: IOrderItem, selected: boolean) => void
  setSelectedAllItems?: (selected: boolean) => void

  setQuickView?: (itemId: string) => void
}

export const tableWithStripesStyle: React.CSSProperties = {
  borderCollapse: 'separate',
  borderSpacing: '0 5px',
}

const OrdersList: FC<IOrderListProps> = (props) => {
  const intl = useIntl()

  const limit = props.filterParams.itemsPerPage
  const currentPage =
    props.filterParams.pageNumber > 0 ? props.filterParams.pageNumber : DEFAULT_PAGE
  let maxPaginationButtons = DEFAULT_BUTTONS_PAGINATION[currentScreenSize()]
  const numberOfPages =
    Math.ceil(props.count / (limit ?? DEFAULT_ORDERS_LIMIT)) < maxPaginationButtons
      ? Math.ceil(props.count / (limit ?? DEFAULT_ORDERS_LIMIT))
      : maxPaginationButtons
  const offset = currentPage * limit

  const selectedAll = props.items?.find((i) => !i.selected) ? false : true

  const filterToOrder = (title: string): IUrlParameters => ({
    ...props.filterParams,
    pageNumber: currentPage,
    itemsPerPage: limit,
    title,
  })

  return (
    <CardSimple title={props.title} subTitle={props.description} cardBodyClassIsOff>
      {props.children ?? null}
      <div className='table-responsive mt-4'>
        <table className='table align-middle g-4 table-striped'>
          <thead>
            <tr className='fw-bolder text-muted text-left'>
              <th className='w-50px' style={paddingLeft40Px}>
                <div className='d-flex flex-column justify-content-center align-items-center'>
                  <CheckBoxCustom
                    checked={selectedAll}
                    onChange={() =>
                      props.setSelectedAllItems && props.setSelectedAllItems(!selectedAll)
                    }
                  />
                </div>
              </th>
              <th className='w-150px'>
                <OrderingHeader
                  filterParams={filterToOrder(
                    intl.formatMessage({id: 'COMPONENTS.ORDERS.LIST.FIELDS.ACCOUNT'})
                  )}
                  orderFieldName='number'
                />
              </th>
              <th className='w-100px'>
                {intl.formatMessage({id: 'COMPONENTS.ORDERS.LIST.FIELDS.NUMBER'})}
              </th>

              <th className='w-100px'>{''}</th>
              <th className='w-350px'>
                {intl.formatMessage({id: 'COMPONENTS.ORDERS.LIST.FIELDS.PRODUCTS'})}
              </th>
              <th className='w-150px text-center'>
                {intl.formatMessage({id: 'COMPONENTS.ORDERS.LIST.FIELDS.AMOUNT'})}
              </th>
              <th className='w-100px'>
                <OrderingHeader
                  filterParams={filterToOrder(
                    intl.formatMessage({id: 'COMPONENTS.ORDERS.LIST.FIELDS.CREATED'})
                  )}
                  orderFieldName='created_at'
                />
              </th>
              <th className='w-100px'>
                <OrderingHeader
                  filterParams={filterToOrder(
                    intl.formatMessage({id: 'COMPONENTS.ORDERS.LIST.FIELDS.SHIPDATE'})
                  )}
                  orderFieldName='ship_at'
                />
              </th>
              <th className='w-150px'>
                <OrderingHeader
                  filterParams={filterToOrder(
                    intl.formatMessage({id: 'COMPONENTS.ORDERS.LIST.FIELDS.STATUS'})
                  )}
                  orderFieldName='status'
                />
              </th>
            </tr>
          </thead>
          <tbody>
            {props.isLoading ? (
              <Loader1 />
            ) : (
              props.items?.map((item, index) => {
                return (
                  <OrdersListItem
                    index={offset + index + 1}
                    item={item}
                    selected={item.selected}
                    setSelected={(selected: boolean) =>
                      props.setSelectedItem && props.setSelectedItem(item, selected)
                    }
                    itemUrl={props.itemUrl}
                    accounts={props.accounts}
                    marketplaces={props.marketplaces}
                    companys={props.companys}
                    setQuickView={props.setQuickView}
                  />
                )
              })
            )}
          </tbody>
        </table>
      </div>
      <UrlPagination
        isLoading={props.isLoading ?? false}
        itemsPerPageVariants={PAGINATION_VARIANTS}
        itemsNumber={props.count}
        filterParams={props.filterParams}
        makeLink={createLink.paginationLink}
        pageButtonNumber={numberOfPages}
      />
    </CardSimple>
  )
}

export default OrdersList
