export interface IPvzCdekState {
  items: IPvzCdekState[]
}

export interface IPvzCdek {
  id: string
  label: string
}

export const emptyPvzCdek: IPvzCdek = {
  id: '',
  label: '',
}

export const initialPvzCdekState: IPvzCdekState = {
  items: [],
}

export interface IPvzCdekRequestParams {
  query: string
}

export const pvzCdekActionTypes = {
  GetPvzCdek: '[GetPvzCdek] Action',
}
