import { createSlice } from "@reduxjs/toolkit"
import { IAccountWithCampaign, ICampaign, ICampaignSaveData, initialMarketing as initialState, } from "./types"


const marketingSlice = createSlice({
    name: "marketingSlice",
    initialState,
    reducers: {
        setItems(state, action) {
            state.items = [...action.payload]
        },
        setAccountsWithCampaigns(state, action) {
            state.accountsWithCampaigns = [...(action.payload as IAccountWithCampaign[]).map(awc => ({
                ...awc,
                campaigns: awc.campaigns.map(c => ({
                    ...c,
                    tmpKey: c.tmpKey ?? c.advert_id,
                    budget_add: 0
                }))
            }))]
        },
        deleteCampaign(state, action) {
            state.accountsWithCampaigns = [...state.accountsWithCampaigns.map(a => ({ ...a, campaigns: [...a.campaigns.filter(c => c.advert_id != action.payload)] }))]
        },
        updateCampaign(state, action) {
            console.log()
            const tosave = [...state.accountsWithCampaigns.map(a => (
                {
                    ...a, campaigns: [...a.campaigns.map(c => c.advert_id == action.payload.advert_id
                        ? {
                            ...action.payload,
                            budget_add: 0,
                            tmpKey: action.payload.advert_id
                        }
                        : c)]
                }
            ))]
            state.accountsWithCampaigns = tosave
        },
        pauseResumeCampaign(state, action) {
            state.accountsWithCampaigns = [...state.accountsWithCampaigns.map(a => (
                {
                    // ...a, campaigns: [...a.campaigns.map(c => c.advert_id != action.payload.changedCampaigns[0].advert_id
                    ...a, campaigns: [...a.campaigns.map(c => c.advert_id == action.payload.advert_id
                        ? { ...c, status: action.payload.status }
                        : c)]
                }
            ))]
        },
        addCampaign(state, action) {
            const cmpData: ICampaign = action.payload
            state.accountsWithCampaigns = [...state.accountsWithCampaigns.map(awc => {
                if (awc.id == cmpData.account_id) {
                    return {
                        ...awc,
                        campaigns: [...awc.campaigns, {
                            ...cmpData,
                            tmpKey: cmpData.tmpKey ?? cmpData.advert_id,
                            budget_add: cmpData.budget_add ?? 0
                        }]
                    }
                }
                else {
                    return awc
                }
            })]
        },
        setFilter(state, action) {
            state.filter = action.payload
        },
        setKeywords(state, action) {
            state.items = state.items.map(awc => {
                return {
                    ...awc,
                    objects: [...awc.objects.map(cat => {
                        return {
                            ...cat,
                            goods: cat.goods.map(g => {
                                if (g.nmid == action.payload.combined) {
                                    return {
                                        ...g,
                                        nmid: action.payload.combined,
                                        keyPhrases: (action.payload.main.keywords as string[]).map((s, idx) => ({
                                            name: s,
                                            key: `phrase-${idx}`,
                                            isNew: false,
                                        }))
                                    }
                                }
                                else
                                    return g
                            })
                        }
                    })]
                }
            })
        },
        setIsLoading(state, action) {
            state.isLoading = action.payload
        },
    }
})


export const marketingReducer = marketingSlice.reducer
export const { setItems, setIsLoading, setKeywords, setAccountsWithCampaigns, deleteCampaign, updateCampaign, pauseResumeCampaign,
    setFilter, addCampaign } = marketingSlice.actions