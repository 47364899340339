import {PayloadAction, createSlice} from '@reduxjs/toolkit'
import {IDeliveryAccount, initialDeliveryAccount as initialState} from './types'
import {customDeliveryTimeUtils} from '../../utils/dataUtils'

const transformDeliveryAccount = (da: IDeliveryAccount): IDeliveryAccount => {
  const transformedCustomDeliveryTime =
    customDeliveryTimeUtils.transformCustomDeliveryTimeFromServer(da.custom_delivery_time)

  return {
    ...da,
    params: {...da.params, custom_delivery_time: transformedCustomDeliveryTime},
  }
}

const deliveryAccountSlice = createSlice({
  name: 'deliveryAccountSlice',
  initialState,
  reducers: {
    setDeliveryAccounts: {
      reducer: (state, action: PayloadAction<IDeliveryAccount[]>) => {
        state.items = [...action.payload]
      },

      prepare: (deliveryAccounts: IDeliveryAccount[]) => {
        const transformedDeliveryAccounts = deliveryAccounts.map(transformDeliveryAccount)

        return {payload: transformedDeliveryAccounts}
      },
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload
    },
    setIsSuccessLastOperation(state, action) {
      state.isSuccessLastOperation = action.payload
    },
    addDeliveryAccount: {
      reducer: (state, action: PayloadAction<IDeliveryAccount>) => {
        state.items = [...state.items, action.payload]
      },

      prepare: (acc: IDeliveryAccount) => {
        return {payload: transformDeliveryAccount(acc)}
      },
    },
    setDeliveryAccount: {
      reducer: (state, action: PayloadAction<IDeliveryAccount>) => {
        const newAcc: IDeliveryAccount = action.payload
        state.items = state.items.map((ac: IDeliveryAccount) => (ac.id === newAcc.id ? newAcc : ac))
      },

      prepare: (acc: IDeliveryAccount) => {
        return {payload: transformDeliveryAccount(acc)}
      },
    },
    deleteDeliveryAccount(state, action) {
      const accNum: number = action.payload
      state.items = state.items.filter((a) => a.id !== accNum)
    },
  },
})

export const deliveryAccountReducer = deliveryAccountSlice.reducer
export const {
  setDeliveryAccounts,
  setDeliveryAccount,
  addDeliveryAccount,
  deleteDeliveryAccount,
  setIsSuccessLastOperation,
  setIsLoading,
} = deliveryAccountSlice.actions
