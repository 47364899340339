import {createAction} from '@reduxjs/toolkit'
import {IUserModel} from '../../modules/auth/models/UserModel'
import {userActionTypes as actionTypes} from './types'

export const ASYNC_GET_USERS = actionTypes.GetUsers
export const ASYNC_UPDATE_USER = actionTypes.UpdateUserProfile
export const ASYNC_LINK_USER = actionTypes.LinkUserProfile
export const ASYNC_UNLINK_USER = actionTypes.UnlinkUserProfile

export const getAsyncUsersCreator = createAction(ASYNC_GET_USERS)
export const updateAsyncUserCreator = createAction<IUserModel>(ASYNC_UPDATE_USER)
export const linkAsyncUserCreator = createAction<string>(ASYNC_LINK_USER)
export const unlinkAsyncUserCreator = createAction<string>(ASYNC_UNLINK_USER)
