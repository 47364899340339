import { call, put, select, takeEvery } from "redux-saga/effects"
import { sagaUtils } from "../../utils/dataUtils"
import { intl } from "../../modules/goods/item/validation"
import { StorageType } from "../../../setup"
import { IDashboardStorage, IGraphicWithNumberRequestParams, IStatisticsRequestParams, TDashboardRequestSource, TDashboardRequestType } from "./types"
import { ASYNC_GET_MONEY_ACCOUNT_STATISTICS, ASYNC_GET_MONEY_COMPANY_STATISTICS, ASYNC_GET_MONEY_GRAPHIC, ASYNC_GET_MONEY_MARKETPLACE_STATISTICS, ASYNC_GET_ORDERS_ACCOUNT_STATISTICS, ASYNC_GET_ORDERS_COMPANY_STATISTICS, ASYNC_GET_ORDERS_GRAPHIC, ASYNC_GET_ORDERS_MARKETPLACE_STATISTICS, getAsyncMoneyGraphicCreator, getAsyncOrdersGraphicCreator, getAsyncStatisticsMoneyAccountCreator, getAsyncStatisticsMoneyCompanyCreator, getAsyncStatisticsMoneyMarketplaceCreator, getAsyncStatisticsOrdersAccountCreator, getAsyncStatisticsOrdersCompanyCreator, getAsyncStatisticsOrdersMarketplaceCreator, SET_DATE_RANGE } from "./actions"
import { getGraphicFromServer, getStatFromServer } from "./api"
import { setDateRange, setIsLoading, setMoney, setMoneyAccountIsLoading, setMoneyAccountStatistics, setMoneyCompanyIsLoading, setMoneyCompanyStatistics, setMoneyIsLoading, setMoneyMarketplaceIsLoading, setMoneyMarketplaceStatistics, setOrderAccountIsLoading, setOrderAccountStatistics, setOrderCompanyIsLoading, setOrderCompanyStatistics, setOrderMarketplaceIsLoading, setOrderMarketplaceStatistics, setOrdersIsLoading, setProducts } from "./reducers"



export function* dashboardSaga(){
    yield takeEvery(SET_DATE_RANGE, workerSetDateRange)
    yield takeEvery(ASYNC_GET_MONEY_GRAPHIC, workerGetMoneyGraphic)
    yield takeEvery(ASYNC_GET_ORDERS_GRAPHIC, workerGetOrderGraphic)
    yield takeEvery(ASYNC_GET_MONEY_MARKETPLACE_STATISTICS, () => workerGetStatistics("sales", "marketplace"))
    yield takeEvery(ASYNC_GET_MONEY_ACCOUNT_STATISTICS, () => workerGetStatistics("sales", "account"))
    yield takeEvery(ASYNC_GET_MONEY_COMPANY_STATISTICS, () => workerGetStatistics("sales", "company"))
    yield takeEvery(ASYNC_GET_ORDERS_MARKETPLACE_STATISTICS, () => workerGetStatistics("orders", "marketplace"))
    yield takeEvery(ASYNC_GET_ORDERS_ACCOUNT_STATISTICS, () => workerGetStatistics("orders", "account"))
    yield takeEvery(ASYNC_GET_ORDERS_COMPANY_STATISTICS, () => workerGetStatistics("orders", "company"))    
}

// gets dashboard money stats
export function* workerSetDateRange(action: any):any{
    yield put(setDateRange(action.payload))
    
    
    yield put(getAsyncMoneyGraphicCreator())
    yield put(getAsyncOrdersGraphicCreator())
    yield put(getAsyncStatisticsMoneyMarketplaceCreator())
    yield put(getAsyncStatisticsMoneyAccountCreator())
    yield put(getAsyncStatisticsMoneyCompanyCreator())
    yield put(getAsyncStatisticsOrdersMarketplaceCreator())
    yield put(getAsyncStatisticsOrdersAccountCreator())
    yield put(getAsyncStatisticsOrdersCompanyCreator())
}

// gets dashboard money graphics
export function* workerGetMoneyGraphic():any{
    const dashboardState: IDashboardStorage = yield select((s: StorageType) => s.dashboard)
    const request: IGraphicWithNumberRequestParams = {
        date_from: dashboardState.date_from,
        date_to: dashboardState.date_to,
        type: "sales"
    }
    yield sagaUtils.workerWrapper(setMoneyIsLoading, 
        getGraphicFromServer, request,
        setMoney, null,
        intl.formatMessage({id: "SAGAS.DASHBOARDMONEYGRAPHIC.GET"}), "GET", "data", false)
}

// gets dashboard orders graphics
export function* workerGetOrderGraphic():any{
    const dashboardState: IDashboardStorage = yield select((s: StorageType) => s.dashboard)
    const request: IGraphicWithNumberRequestParams = {
        date_from: dashboardState.date_from,
        date_to: dashboardState.date_to,
        type: "orders"
    }
    yield sagaUtils.workerWrapper(setOrdersIsLoading, 
        getGraphicFromServer, request,
        setProducts, null,
        intl.formatMessage({id: "SAGAS.DASHBOARDORDERGRAPHIC.GET"}), "GET", "data", false)
}


// gets dashboard stats
export function* workerGetStatistics(type: TDashboardRequestType, source: TDashboardRequestSource):any{
    const dashboardState: IDashboardStorage = yield select((s: StorageType) => s.dashboard)
    const request: IStatisticsRequestParams = {
        date_from: dashboardState.date_from,
        date_to: dashboardState.date_to,
        type: type,
        source: source,
    }

    const getReducer = (type: TDashboardRequestType, source: TDashboardRequestSource) => {
        if ((type == "sales") && (source == "marketplace"))
            return {reducer: setMoneyMarketplaceStatistics, loadingSetter: setMoneyMarketplaceIsLoading}
        else if ((type == "sales") && (source == "account"))
            return {reducer: setMoneyAccountStatistics, loadingSetter: setMoneyAccountIsLoading}
        else if ((type == "sales") && (source == "company"))
            return {reducer: setMoneyCompanyStatistics, loadingSetter: setMoneyCompanyIsLoading}
        else if ((type == "orders") && (source == "marketplace"))
            return {reducer: setOrderMarketplaceStatistics, loadingSetter: setOrderMarketplaceIsLoading}
        else if ((type == "orders") && (source == "account"))
            return {reducer: setOrderAccountStatistics, loadingSetter: setOrderAccountIsLoading}
        else if ((type == "orders") && (source == "company"))
            return {reducer: setOrderCompanyStatistics, loadingSetter: setOrderCompanyIsLoading}
    }

    const aReducer: {reducer: any, loadingSetter: any} | undefined = getReducer(type, source)

    yield sagaUtils.workerWrapper(aReducer?.loadingSetter ?? null, 
        getStatFromServer, request,
        aReducer?.reducer ?? null, null,
        intl.formatMessage({id: "SAGAS.DASHBOARDSTATISTICS.GET"}), "GET", "data", false)
}