import moment from "moment"

export interface IDateRangeParameters{
    date_from: Date
    date_to: Date
}

export const defaultDateRangeParameters: IDateRangeParameters = {
    date_from: moment(new Date()).add(-1, "months").toDate(),
    date_to: moment(new Date()).toDate()
}

export interface IGraphicWithNumberRequestParams{
    date_from: Date
    date_to: Date
    type: "orders" | "sales"
}

let dashboardOrderType: "orders" | "sales"
export type TDashboardRequestType = typeof dashboardOrderType
let dashboardOrderSource: "marketplace" | "account" | "company"
export type TDashboardRequestSource = typeof dashboardOrderSource

export interface IStatisticsRequestParams{
    date_from: Date
    date_to: Date
    type: TDashboardRequestType
    source: TDashboardRequestSource
}

export interface IDateRange2{
    from: Date
    to: Date
}


export interface IGraphicWithNumber{
    isLoading: boolean
    total: number
    picks: number[]
    nodata?: boolean
}

export interface IStatisticsItem{
    name: string
    id: number
    count: number
}

export const emptyGraphicWithNumber: IGraphicWithNumber = {
    isLoading: false,
    total: 0,
    picks: []
}

export interface IStatisticsStorage{
    marketplace: {isLoading: boolean, data: IStatisticsItem[]}
    account: {isLoading: boolean, data: IStatisticsItem[]}
    company: {isLoading: boolean, data: IStatisticsItem[]}
}

export const emptyStatisticsStorage: IStatisticsStorage = {
    marketplace: {isLoading: false, data: []},
    account: {isLoading: false, data: []},
    company: {isLoading: false, data: []},
}

export interface IDashboardStorage {
    isLoading: boolean
    date_from: Date
    date_to: Date
    money: IGraphicWithNumber
    products: IGraphicWithNumber

    moneyStatistics: IStatisticsStorage
    orderStatistics: IStatisticsStorage
}

export const emptyDashboardState: IDashboardStorage = {
    isLoading: false,
    money: emptyGraphicWithNumber,
    products: emptyGraphicWithNumber,
    moneyStatistics: emptyStatisticsStorage,
    orderStatistics: emptyStatisticsStorage,
    date_from: defaultDateRangeParameters.date_from,
    date_to: defaultDateRangeParameters.date_to,
}

export const dashboardActionTypes = {
    SetDateRange: '[SetDateRange] Action',
    GetMoneyByDateRange: '[GetMoneyByDateRange] Action',
    GetOrdersByDateRange: '[GetOrdersByDateRange] Action',
    GetMoneyMarketplaceStatisticsByDateRange: '[GetMoneyMarketplaceStatisticsByDateRange] Action',
    GetMoneyAccountStatisticsByDateRange: '[GetMoneyAccountStatisticsByDateRange] Action',
    GetMoneyCompanyStatisticsByDateRange: '[GetMoneyCompanyStatisticsByDateRange] Action',
    GetOrdersMarketplaceStatisticsByDateRange: '[GetOrdersMarketplaceStatisticsByDateRange] Action',
    GetOrdersAccountStatisticsByDateRange: '[GetOrdersAccountStatisticsByDateRange] Action',
    GetOrdersCompanyStatisticsByDateRange: '[GetOrdersCompanyStatisticsByDateRange] Action',
}
