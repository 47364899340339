import { createSlice } from "@reduxjs/toolkit"
import { emptyUrlParameters, IUrlParameters } from "../../components/lists/UrlFilteredList"
import { emptyMpDocItem, emptyState as initialState, IMpDocItem, IMpDocsGetParams,  } from "./types"


const mpdocSlice = createSlice({
    name: "mpdocSlice",
    initialState,
    reducers: {
        setMpDocs(state, action){
            state.items = [...action.payload]
        },
        setSomeMpDocs(state, action){
            const mpdocs: IMpDocItem[] = action.payload
            state.items = [...state.items.map(item => {
                const itemToUpdate = mpdocs.find(o => o.id == item.id)
                return itemToUpdate ? itemToUpdate : item
            })]
        },
        setSelectedMpDoc(state, action){
            state.selected = action.payload
        },
        // setStock(state, action){
        //     console.log('setStock',action.payload)

        //     state.items = [...state.items.map((s: IStockListItem) => {
        //         if (s.id == action.payload.stockId){

        //             if (s.stocks?.length){
        //                 const stItems = s.stocks.map(st => {
        //                     if (st.id == action.payload.id){
        //                         st.quantity = action.payload.quantity
        //                         st.reserved = action.payload.reserved
        //                     }
        //                     return st
        //                 })
        //                 return {...s, stocks: stItems}
        //             }
        //             else {
        //                 return s
        //             }
        //         }
        //         else
        //             return s
        //     })]
        // },
        // addStock(state, action){
        //     console.log('addStock',action.payload)

        //     state.items = [...state.items.map((s: IStockListItem) => {
        //         if (s.id == action.payload.stockId){
        //             const stock:IStockItem = {
        //                 id: action.payload.id,
        //                 quantity: action.payload.quantity,
        //                 reserved: action.payload.reserved,
        //                 sku_customer_uid: action.payload.sku_customer_uid,
        //                 warehouse: action.payload.warehouse,
        //             }
        //             return {...s, stocks: [...s.stocks ?? [], stock]}
        //         }
        //         else
        //             return s
        //     })]
        // },
        // setRequestParams(state, action){
        //     const {limit, page, mpdocing, search} = action.payload
        //     state.itemsPerPage = limit ?? 0
        //     state.currentPage = page ?? 0
        //     state.mpdocing = mpdocing ?? ""
        //     state.search = search ?? ""
        // },
        setRequestParams(state, action){
            const payload: IUrlParameters = action.payload
            state.filter = payload
            // state.itemsPerPage = payload.items ?? emptyUrlParameters.itemsPerPage
            // state.currentPage = payload.page ?? emptyUrlParameters.pageNumber
            // state.mpdocing = payload.mpdoc ?? emptyUrlParameters.mpdocing
            // state.search = payload.search ?? emptyUrlParameters.filter
        },
        setIsLoading(state, action){
            state.isLoading = action.payload
        },
        setCount(state, action){
            state.count = action.payload
        },
    }
})


export const mpdocReducer = mpdocSlice.reducer
export const { setMpDocs, setIsLoading, setCount, setRequestParams, setSelectedMpDoc, setSomeMpDocs,
} = mpdocSlice.actions