import { createSlice } from "@reduxjs/toolkit"
import { initialMarketplaces as initialState, } from "./types"


const marketplaceSlice = createSlice({
    name: "marketplaceSlice",
    initialState,
    reducers: {
        setMarketplaces(state, action){
            state.items = [...action.payload]
        }
    }
})


export const marketplaceReducer = marketplaceSlice.reducer
export const { setMarketplaces } = marketplaceSlice.actions







// const reducer = createReducer(initialAccount, (builder) => {
//     builder
//         .addCase(GET_ACCOUNTS, (s, a): PayloadAction<IAccount> => {
//             return {...s,
//                 accounts: a.payload
//             }
//         })
// })

//export default accountSlice.reducer