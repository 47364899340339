import { createSlice } from "@reduxjs/toolkit"
import { emptyDashboardState as initialState, } from "./types"


const dashboardSlice = createSlice({
    name: "dashboardSlice",
    initialState,
    reducers: {
        setDateRange(state, action) {
            const {date_from, date_to} = action.payload
            state.date_from = date_from
            state.date_to = date_to
        },
        setMoneyMarketplaceStatistics(state, action){
            state.moneyStatistics.marketplace.data = action.payload
        },
        setMoneyAccountStatistics(state, action){
            state.moneyStatistics.account.data = action.payload
        },
        setMoneyCompanyStatistics(state, action){
            state.moneyStatistics.company.data = action.payload
        },
        setOrderMarketplaceStatistics(state, action){
            state.orderStatistics.marketplace.data = action.payload
        },
        setOrderAccountStatistics(state, action){
            state.orderStatistics.account.data = action.payload
        },
        setOrderCompanyStatistics(state, action){
            state.orderStatistics.company.data = action.payload
        },
        setMoney(state, action) {
            const {picks, total, nodata} = action.payload
            state.money.picks = picks
            state.money.total = total
            state.money.nodata = nodata ?? undefined
        },
        setProducts(state, action) {
            const {picks, total, nodata} = action.payload
            state.products.picks = picks
            state.products.total = total
            state.products.nodata = nodata ?? undefined
        },
        setIsLoading(state, action) {
            state.isLoading = action.payload
        },
        setMoneyIsLoading(state, action) {
            state.money.isLoading = action.payload
        },
        setOrdersIsLoading(state, action) {
            state.products.isLoading = action.payload
        },
        setMoneyMarketplaceIsLoading(state, action) {
            state.moneyStatistics.marketplace.isLoading = action.payload
        },
        setMoneyAccountIsLoading(state, action) {
            state.moneyStatistics.account.isLoading = action.payload
        },
        setMoneyCompanyIsLoading(state, action) {
            state.moneyStatistics.company.isLoading = action.payload
        },
        setOrderMarketplaceIsLoading(state, action) {
            state.orderStatistics.marketplace.isLoading = action.payload
        },
        setOrderAccountIsLoading(state, action) {
            state.orderStatistics.account.isLoading = action.payload
        },
        setOrderCompanyIsLoading(state, action) {
            state.orderStatistics.company.isLoading = action.payload
        },
        
    }
})


export const dashboardReducer = dashboardSlice.reducer
export const { setIsLoading, setMoney, setProducts, setDateRange,
    setMoneyMarketplaceStatistics, setMoneyAccountStatistics, setMoneyCompanyStatistics, setOrderMarketplaceStatistics,
 setMoneyIsLoading, setOrdersIsLoading, setMoneyMarketplaceIsLoading, setMoneyAccountIsLoading, setMoneyCompanyIsLoading, setOrderMarketplaceIsLoading, setOrderAccountIsLoading, setOrderCompanyIsLoading,
    setOrderAccountStatistics, setOrderCompanyStatistics, 
} = dashboardSlice.actions