import axios from 'axios'
import {IDeliveryAccount} from './types'
import {objectUtils} from '../../utils/dataUtils'

const API_URL = `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENDPOINT_PREFIX}`
export const DELIVERYACCOUNTS_URL = `${API_URL}v2/delivery_accounts/`

export const fetchDeliveryAccounts = () => {
  return axios.get(DELIVERYACCOUNTS_URL)
}

export const getDeliveryAccountFromServer = (deliveryAccountId: number) => {
  return axios.get(`${DELIVERYACCOUNTS_URL}${deliveryAccountId}`)
}

export const updateDeliveryAccountOnServer = (params: IDeliveryAccount) => {
  let pars: any = objectUtils.deepCopy(params)
  delete pars.id
  return axios.put<IDeliveryAccount>(`${DELIVERYACCOUNTS_URL}${params.id}/update_settings/`, pars)
}

export const deleteDeliveryAccountFromServer = (params: number) => {
  return axios.post(`${DELIVERYACCOUNTS_URL}${params}/delete_delivery_account/`)
}

export const addDeliveryAccountToServer = (params: IDeliveryAccount) => {
  let pars: any = params
  delete pars.id
  return axios.post<IDeliveryAccount>(`${DELIVERYACCOUNTS_URL}create_acc/`, {...pars})
}

// no such function, but maybe in the future
// export const testDeliveryAccountConnection = async (params: ITestFieldsToSend) => {
//     return await axios.post<{ success: boolean }>(`${DELIVERYACCOUNTS_URL}test_account_api/`, {
//         marketplace_id: params.marketplaceId,
//         ...params.connectionParams.reduce((resObj, curItem) => ({ ...resObj, [curItem.parameterName]: curItem.value }), {})
//     })
// }
