import {useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {updateData} from '../../../../../store'

import ReactSelect, {OnChangeValue} from 'react-select'
import {StorageType} from '../../../../../../setup'
import {TCompany} from '../../../../../store/mpWizard/types'

const WBStep3 = () => {
  const data = useSelector((storage: StorageType) => storage.mpWizard.data)

  const [selectedWBCompany, setSelectedWBCompany] = useState<TCompany | null>(
    data?.step2?.selectedWBCompany || null
  )

  const currentStep = useSelector((storage: StorageType) => storage.mpWizard.currentStep)

  const dispatch = useDispatch()

  const companies = data.step1.wbCompanies

  const onChangeCompany = (selected: OnChangeValue<TCompany, false>) => {
    setSelectedWBCompany(selected)

    dispatch(updateData({key: `step${currentStep}`, field: 'selectedWBCompany', value: selected}))
  }

  return (
    <div>
      <p className='mb-5'>
        Пожалуйста, выберите ваш личный кабинет Wildberries из списка ниже, чтобы продолжить.
      </p>
      <ReactSelect
        className='fs-6'
        isSearchable={false}
        onChange={onChangeCompany}
        placeholder='Кабинет'
        getOptionLabel={(w: TCompany) => w.name}
        isClearable
        value={selectedWBCompany ?? null}
        options={companies}
      />
    </div>
  )
}

export default WBStep3
