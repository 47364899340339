import {createSlice} from '@reduxjs/toolkit'
import {emptyState as initialState} from './types'

export const feedbackSlice = createSlice({
  name: 'feedbackSlice',
  initialState,
  reducers: {
    setFeedbacks(state, action) {
      state.items = [...state.items, ...action.payload.results]
      state.count = action.payload.count
      state.next = action.payload.next
      state.previous = action.payload.previous
      state.isFeedbackLoading = false
      state.isLoadingSelect = false
      state.isGenerated = false
      state.isSendLoading = false
    },
    clearFeedbacks(state) {
      state.items = []
      state.count = 0
      state.next = ''
      state.previous = ''
    },
    setFeedbacksLoading: (state, action) => {
      state.isFeedbackLoading = action.payload
    },
    setIsFilteredItemsLoading: (state, action) => {
      state.isFilteredItemsLoading = action.payload
    },
    setAnswer: (state, action) => {
      state.answer = action.payload
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload
    },
    setSelectedFeedbacks(state, action) {
      state.selected = action.payload
    },
    setIsSelectLoading(state, action) {
      state.isLoadingSelect = action.payload
    },
    setGeneratedFeedback: (state, action) => {
      state.generatedFeedback = action.payload
    },
    setIsGenerated(state, action) {
      state.isGenerated = action.payload
    },
    setFeedbacksFilters(state, action) {
      state.filters = action.payload
    },
    setFeedbackSendResult(state, action) {
      state.sendResult = action.payload.success
    },
    setIsSendLoading(state, action) {
      state.isSendLoading = action.payload
    },
    setIsShowFeedbackSettingsModal(state, action) {
      state.isShowFeedbackSettingsModal = action.payload
    },
  },
})

export const {
  clearFeedbacks,
  setFeedbacks,
  setFeedbacksFilters,
  setSelectedFeedbacks,
  setAnswer,
  setGeneratedFeedback,
  setLoading,
  setFeedbacksLoading,
  setIsSelectLoading,
  setIsGenerated,
  setFeedbackSendResult,
  setIsSendLoading,
  setIsShowFeedbackSettingsModal,
} = feedbackSlice.actions
export const feedbackReducer = feedbackSlice.reducer
