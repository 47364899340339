import { addStockToServer, getStocksFromServer, updateStockOnServer } from './api';
import { call, put, select, takeEvery } from "redux-saga/effects"
import { ADD_STOCK_PARAMETERS, ASYNC_GET_STOCKS, ASYNC_UPDATE_STOCK, SET_STOCK_PARAMETERS } from "./actions"
import { addStock, setCount, setIsLoading, setIsSavingStockPriceId, setRequestParams, setStock, setStocks } from './reducers';
import { addPushMessageCreator } from '../system/actions';
import { StorageType } from '../../../setup';
import { DEFAULT_STOCKS_LIMIT, IStockRequestParameters } from './types';
import { sagaUtils } from '../../utils/dataUtils';
import { intl } from '../../modules/goods/item/validation';





export function* stockSaga() {
    yield takeEvery(ASYNC_GET_STOCKS, workerGetStocks)
    yield takeEvery(ASYNC_UPDATE_STOCK, workerSetStock)
    yield takeEvery(SET_STOCK_PARAMETERS, workerSetStockParameters)
    yield takeEvery(ADD_STOCK_PARAMETERS, workerAddStockParameters)
}

// gets all stocks an set it to storage
export function* workerGetStocks(): any {

    const params: IStockRequestParameters = yield select((storage: StorageType) => storage.stocks)
    yield sagaUtils.workerWrapper(setIsLoading,
        getStocksFromServer, {
        limit: params.itemsPerPage ?? DEFAULT_STOCKS_LIMIT,
        offset: (params.currentPage ?? 0) * (params.itemsPerPage ?? DEFAULT_STOCKS_LIMIT),
        ordering: params.ordering ?? "",
        search: params.search ?? "",
        page: params.currentPage ?? 0
    },
        setStocks, null,
        intl.formatMessage({ id: "SAGAS.WAREHOUSES.GET" }), "GET", "data.results", false, "",
        undefined, { action: setCount, resultType: "data", resultTypePropertyName: "count" })


    // yield put(setIsLoading(true))
    // try{
    //     const stockItemsPromise = yield call(() => getStocksFromServer(
    //         {
    //             limit: params.itemsPerPage ?? DEFAULT_STOCKS_LIMIT, 
    //             offset: (params.currentPage ?? 0) * (params.itemsPerPage ?? DEFAULT_STOCKS_LIMIT),
    //             ordering: params.ordering ?? "",
    //             search: params.search ?? "",
    //             page: params.currentPage ?? 0
    //         }))
    //     stockItemsPromise?.data?.results && (yield put(setStocks(stockItemsPromise.data.results)))
    //     stockItemsPromise?.data?.count && (yield put(setCount(stockItemsPromise.data.count)))

    // }
    // catch(error){
    //     yield put(addPushMessageCreator(
    //         {
    //             name: "Не удалось загрузить остатки", 
    //             status: "error", 
    //             details: ["Сервер недоступен"], 
    //             message: "Ошибка",
    //         }))
    // }
    // finally{
    //     yield put(setIsLoading(false))

    // }

}

// gets all stocks an set it to storage
export function* workerSetStockParameters(action: any): any {
    yield put(setRequestParams(action.payload))

}

// update stock in stocks
export function* workerSetStock(action: any): any {

    try {
        action.payload && (yield put(setIsSavingStockPriceId({warehouseId: action.payload.warehouse, stockId: action.payload.stockId})))

        yield sagaUtils.workerWrapperCombinedReducerRequest(null,
            updateStockOnServer, action.payload,
            setStock, null, action.payload.stockId,
            intl.formatMessage({ id: "SAGAS.STOCKS.UPDATE" }), "SAVE", "data", true)
    }
    finally {
        yield put(setIsSavingStockPriceId(null))
    }
}

// add stock to server
export function* workerAddStockParameters(action: any): any {

    try {
        action.payload && (yield put(setIsSavingStockPriceId({warehouseId: action.payload.warehouse, stockId: action.payload.stockId})))

        const serverData = yield sagaUtils.workerWrapper(null,
            addStockToServer, action.payload,
            null, null,
            intl.formatMessage({ id: "SAGAS.STOCKS.ADD" }), "ADD", "data.result", true)

        yield sagaUtils.workerWrapper(null,
            null, null,
            null, null,
            intl.formatMessage({ id: "SAGAS.STOCKS.ADD" }), "ADD", "direct", false, undefined,
            undefined, undefined, undefined, undefined, {
            action: addStock,
            params: { ...serverData[0], stockId: action.payload.stockId }
        })
    }
    finally {
        yield put(setIsSavingStockPriceId(null))
    }

}

