import {select, takeEvery} from 'redux-saga/effects'
import {IUserModel} from '../../modules/auth/models/UserModel'
import {intl} from '../../modules/goods/item/validation'
import {sagaUtils} from '../../utils/dataUtils'
import {StorageType} from './../../../setup/redux/RootReducer'
import {IAuthState, actions} from './../auth/AuthRedux'
import {ASYNC_GET_USERS, ASYNC_LINK_USER, ASYNC_UNLINK_USER, ASYNC_UPDATE_USER} from './actions'
import {getUsersFromServer, linkUserOnServer, unlinkUserOnServer, updateUserOnServer} from './api'
import {addUser, setCount, setIsLoading, setUser, setUsers, unlinkUser} from './reducers'

export function* userSaga() {
  yield takeEvery(ASYNC_GET_USERS, workerGetUsers)
  yield takeEvery(ASYNC_UPDATE_USER, workerUpdateUser)
  yield takeEvery(ASYNC_LINK_USER, workerLinkUser)
  yield takeEvery(ASYNC_UNLINK_USER, workerUnlinkUser)
}

// gets all user and set it to storage
export function* workerGetUsers(): any {
  yield sagaUtils.workerWrapper(
    setIsLoading,
    getUsersFromServer,
    null,
    setUsers,
    null,
    intl.formatMessage({id: 'SAGAS.USERS.GETUSERS'}),
    'GET',
    'data.results',
    false,
    '',
    undefined,
    {action: setCount, resultType: 'data', resultTypePropertyName: 'count', params: null}
  )
}

// update user in storage
export function* workerUpdateUser(action: any): any {
  const authState: IAuthState = yield select((s: StorageType) => s.auth)
  const loggedUserId = authState.user?.id

  yield sagaUtils.workerWrapper(
    setIsLoading,
    updateUserOnServer,
    action.payload,
    setUser,
    null,
    intl.formatMessage({id: 'SAGAS.USERS.SETUSER'}),
    'SAVE',
    'data',
    true,
    '',
    undefined,
    loggedUserId == (action.payload as IUserModel).id
      ? {
          action: actions.updateUserInfo,
          params: action.payload,
        }
      : undefined
  )
}

// link user
export function* workerLinkUser(action: any): any {
  yield sagaUtils.workerWrapper(
    setIsLoading,
    linkUserOnServer,
    action.payload,
    addUser,
    null,
    intl.formatMessage({id: 'SAGAS.USERS.LINKUSER'}),
    'LINK',
    'data.result',
    true,
    ''
  )
}

// unlink user
export function* workerUnlinkUser(action: any): any {
  yield sagaUtils.workerWrapper(
    setIsLoading,
    unlinkUserOnServer,
    action.payload,
    unlinkUser,
    action.payload,
    intl.formatMessage({id: 'SAGAS.USERS.UNLINKUSER'}),
    'UNLINK',
    'data',
    true,
    ''
  )
}
