import {createAction} from '@reduxjs/toolkit'
import {IPriceType, priceTypesActionTypes, IPriceTypeFilters} from './types'

export const GET_ASYNC_PRICE_TYPES = priceTypesActionTypes.GetPriceTypes
export const ADD_ASYNC_PRICE_TYPE = priceTypesActionTypes.AddPriceType
export const UPDATE_ASYNC_PRICE_TYPE = priceTypesActionTypes.UpdatePriceType
export const DELETE_ASYNC_PRICE_TYPE = priceTypesActionTypes.DeletePriceType

export const getAsyncPriceTypesCreator = createAction<IPriceTypeFilters>(GET_ASYNC_PRICE_TYPES)
export const addAsyncPriceTypeCreator = createAction<IPriceType>(ADD_ASYNC_PRICE_TYPE)
export const updateAsyncPriceTypeCreator = createAction<IPriceType>(UPDATE_ASYNC_PRICE_TYPE)
export const deleteAsyncPriceTypeCreator = createAction<number>(DELETE_ASYNC_PRICE_TYPE)
