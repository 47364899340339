import {createAction} from '@reduxjs/toolkit'
import {tgModalActionTypes} from './types'

export const ASYNC_CONNECT_USER_TO_TELEGRAM = tgModalActionTypes.ConnectUserToTelegram
export const GET_INFO_ABOUT_TG_ID = tgModalActionTypes.GetInfoAboutTgId
export const INTERVAL_GET_INFO_ABOUT_TG_ID_TERMINATED =
  tgModalActionTypes.IntervalGetInfoAboutTgIdTerminated

export const connectAsyncUserToTelegramCreator = createAction<number>(
  ASYNC_CONNECT_USER_TO_TELEGRAM
)
export const getInfoAboutTgIdCreator = createAction(GET_INFO_ABOUT_TG_ID)
export const intervalGetInfoAboutTgIdTerminatedCreator = createAction(
  INTERVAL_GET_INFO_ABOUT_TG_ID_TERMINATED
)
