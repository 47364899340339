export const initialDeliveryServices: IDeliveryServiceState = {
    count: 0,
    isLoading: false,
    items: [],
}


export interface IDeliveryService {
    id: number
    name: string
    params?: any,
    code: string
    
}

export interface IDeliveryTrack{
    company: IDeliveryService | null
    track: string
}

export interface IDeliveryTrackOrderRequest{
    orderId: number
    company: IDeliveryService | null
    track: string
}


export const emptyDeliveryService: IDeliveryService = {
    id: -1,
    name: "",
    code: "",
}

export interface IDeliveryServiceState{
    count: number
    isLoading: boolean,
    items: IDeliveryService[]
}


export const emptyDeliveryTrack: IDeliveryTrack = {
    company: emptyDeliveryService,
    track: ""
}


export const deliveryServiceActionTypes = {
    GetDeliveryServices: '[GetDeliveryServices] Action',
    SetDeliveryServices: '[SetDeliveryServices] Action',
  }