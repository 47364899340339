import { FC } from 'react'
import { useIntl } from 'react-intl'
import CardSimple from '../../../components/containers/CardSimple'
import Loader1 from '../../../components/vision/loaders/Loader1'
import { IAccount } from '../../../store/accounts/types'
import { ICustomerCompany } from '../../../store/customerCompanys/types'
import { IDeliveryService, IDeliveryTrackOrderRequest } from '../../../store/deliveryServices/types'
import { IMarketplace } from '../../../store/marketplaces/types'
import { IOrderItem } from '../../../store/orders/types'
import OrderData from './OrderData'

interface IOrdersItem {
    item: IOrderItem
    title?: string
    description?: string
    isLoading?: boolean

    onCloseClick?: any
    marketplaces?: IMarketplace[]
    accounts?: IAccount[]
    companys?: ICustomerCompany[]
    deliveryCompanys?: IDeliveryService[]
    isOrderTrackLoading?: boolean

    saveTrackInfo?: (info: IDeliveryTrackOrderRequest) => void
    resetTrackInfo?: (orderId: number) => void
}


const OrdersItem: FC<IOrdersItem> = (props) => {
    const intl = useIntl()

    return (
        <CardSimple
            headerClassName={`${props.item.is_error ? 'bg-warning' : ''}`}
            title={props.title}
            subTitle={props.description}
            cardBodyClassIsOff
            rightTopElement={props.children ?? null}
        >
            {
                props.isLoading
                    ? <Loader1 label={intl.formatMessage({ id: "COMPONENTS.ORDERS.ITEM.LOADING" })} />
                    : <OrderData
                        saveTrackInfo={props.saveTrackInfo}
                        resetTrackInfo={props.resetTrackInfo}
                        item={props.item}
                        marketplaces={props.marketplaces}
                        accounts={props.accounts}
                        companys={props.companys}
                        deliveryCompanys={props.deliveryCompanys}
                        isTrackLoading={props.isOrderTrackLoading}
                    >
                    </OrderData>
            }
        </CardSimple>
    )
}

export default OrdersItem