import { createAction } from "@reduxjs/toolkit";
import { deliveryAccountActionTypes, IDeliveryAccount } from "./types"

export const SET_DELIVERYACCOUNTS = deliveryAccountActionTypes.SetDeliveryAccounts;
export const ASYNC_GET_DELIVERYACCOUNTS = deliveryAccountActionTypes.GetDeliveryAccounts;
export const ASYNC_ADD_DELIVERYACCOUNT = deliveryAccountActionTypes.AddDeliveryAccount;
export const ASYNC_UPDATE_DELIVERYACCOUNT = deliveryAccountActionTypes.UpdateDeliveryAccount;
export const ASYNC_DELETE_DELIVERYACCOUNT = deliveryAccountActionTypes.DeleteDeliveryAccount;
export const SET_IS_LOADING_DELIVERYACCOUNTS = deliveryAccountActionTypes.SetIsLoadingDeliveryAccounts;
export const ADD_DELIVERYACCOUNT = deliveryAccountActionTypes.AddDeliveryAccountNoApi;
export const SET_DELIVERYACCOUNT = deliveryAccountActionTypes.SetDeliveryAccountNoApi;

export const setDeliveryAccountsCreator = createAction<string>(SET_DELIVERYACCOUNTS);
export const setIsLoadingDeliveryAccountsCreator = createAction<boolean>(SET_IS_LOADING_DELIVERYACCOUNTS);
export const getAsyncDeliveryAccountsCreator = createAction(ASYNC_GET_DELIVERYACCOUNTS);
export const deleteAsyncDeliveryAccountCreator = createAction<number>(ASYNC_DELETE_DELIVERYACCOUNT);

export const addAsyncDeliveryAccountCreator = createAction<IDeliveryAccount>(ASYNC_ADD_DELIVERYACCOUNT);
export const addDeliveryAccountCreator = createAction<IDeliveryAccount>(ADD_DELIVERYACCOUNT);
export const setDeliveryAccountCreator = createAction<IDeliveryAccount>(SET_DELIVERYACCOUNT);
export const updateAsyncDeliveryAccountCreator = createAction<IDeliveryAccount>(ASYNC_UPDATE_DELIVERYACCOUNT);