import React, { Children, useState } from "react"
import { Button, Col, Modal, Row } from "react-bootstrap"
import { KTSVG } from "../../../../_metronic/helpers"
import Loader1 from "../../vision/loaders/Loader1"


export interface IModalWindowExtendedProps {
    title?: string
    children: any

    className?: string

    isLoading?: boolean
    show: boolean
    fullscreen?: true | string
    centered?: boolean
    scrollable?: boolean
    closable?: boolean
    buttonsIsInFooter?: boolean
    showClose?: boolean

    applyAction?: any
    applyShow?: boolean
    applyClassNames?: string
    applyText?: string
    canApply: boolean
    applyStyle?: any
    applyTitle?: string // text in bubble (attribute title)
    applyIsDisabled?: boolean

    cancelShow?: boolean
    cancelAction?: any
    cancelText?: string
    cancelClassNames?: string
    cancelStyle?: any
    cancelTitle?: string // text in bubble (attribute title)
    cancelIsDisabled?: boolean

    headerHide?: boolean  // hide header with title and buttons
    footerHide?: boolean  // hide footer with title and buttons
    bodyClassIsOff?: boolean  // hide body construction
    setShow: (show: boolean, isCancelled?: boolean) => void
    size?: "sm" | "lg" | "xl"

    additionalControlsInRightTopCorner?: any
}

const ModalWindowExtended = (props: IModalWindowExtendedProps) => {


    const handleClose = (cancel: boolean = true) => {
        props.setShow(false, cancel)

        if (cancel)
            props.cancelAction && props.cancelAction() //  props.cancelAction && props.cancelAction
        else
            props.applyAction && props.applyAction() // props.applyAction && props.applyAction


    }

    const renderButtons = () => (
        <Row>
            <Col>
                {
                    (props.cancelShow == undefined || props.cancelShow == true)
                        ? <button
                            type="button"
                            disabled={props.cancelIsDisabled ?? false}
                            className={`btn btn-light ${props.cancelClassNames ?? ""}`}
                            onClick={() => handleClose()}
                            title={props.cancelTitle ?? ""}
                            style={props.cancelStyle}
                        >
                            {props.cancelText ?? "Cancel"}
                        </button>
                        : null
                }
            </Col>
            <Col>
                {/* <NotifiedButton/> */}
                {
                    (props.applyShow == undefined || props.applyShow == true)
                        ? <button
                            type="button"
                            disabled={!props.canApply || (props.applyIsDisabled != undefined ? props.applyIsDisabled : false)}
                            onClick={() => handleClose(false)} // false parameter means call 'props.applyAction'
                            style={props.applyStyle}
                            title={props.applyTitle ?? ""}
                            className={`btn btn-primary ${props.applyClassNames ?? ""}`}>
                            {props.applyText ?? "Ok"}
                        </button>
                        : null
                }
            </Col>
            {
                props.additionalControlsInRightTopCorner
                    ? <Col>
                        {
                            props.additionalControlsInRightTopCorner
                        }
                    </Col>
                    : null
            }
        </Row>
    )

    return (

        <Modal
            scrollable={props.scrollable ?? false}
            fullscreen={props.fullscreen}
            centered={props.centered ?? false}
            onHide={handleClose}
            show={props.show}
            size={props.size ?? "lg"}
            className={props.className}
            backdrop={props.closable ? "static" : false}
        >
            {props?.isLoading ?
                <>
                    {props.headerHide ? null : <Modal.Header></Modal.Header>}
                    <Modal.Body>
                        <Loader1 />
                    </Modal.Body>
                    {props.footerHide ? null : <Modal.Footer></Modal.Footer>}
                </>
                :
                <>
                    {
                        props.headerHide
                            ? null
                            : <Modal.Header>
                                <h5 className="modal-title">{props.title}</h5>
                                {
                                    props.showClose &&
                                    <div
                                        className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                        onClick={() => handleClose()}
                                        aria-label="Close"
                                    >
                                        <KTSVG
                                            path="/media/icons/duotune/arrows/arr061.svg"
                                            className="svg-icon svg-icon-2x"
                                        />
                                    </div>
                                }
                                {!props.buttonsIsInFooter ? renderButtons() : null}
                            </Modal.Header>}
                    {
                        props.bodyClassIsOff
                            ? props.children
                            : <Modal.Body >
                                {props.children}
                            </Modal.Body>
                    }
                    {
                        props.footerHide
                            ? null
                            : <Modal.Footer>
                                {props.buttonsIsInFooter ? renderButtons() : null}
                            </Modal.Footer>
                    }
                </>
            }
        </Modal>
    )
}

export default ModalWindowExtended