import { KTSVG } from "../../../_metronic/helpers"

const arrowUp = '/media/icons/duotune/arrows/arr003.svg'
const arrowDown = '/media/icons/duotune/arrows/arr004.svg'

interface IOrderingArrowProps {
    currentOrderName: string
    orderName: string
}

export const orderingSign = (currentOrder: string) => {
    return currentOrder.includes("-") ? "" : "-"
}

const OrderingArrow = (props: IOrderingArrowProps) => {
    if (props.currentOrderName.includes(props.orderName))
        return <span>{orderingSign(props.currentOrderName)
            ? <KTSVG
                path={arrowDown}
                className='svg-icon-2 svg-icon-primary'
            />
            : <KTSVG
                path={arrowUp}
                className='svg-icon-2 svg-icon-primary'
            />}
        </span>
    else
        return null
}


export default OrderingArrow