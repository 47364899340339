interface ICheckTransactionStatusState {
  status: TransactionStatus
}

export enum TransactionStatus {
  PENDING = 'pending',
  CANCELED = 'canceled',
  PAID = 'paid',
  ERROR = 'error',
}

export const initState: ICheckTransactionStatusState = {
  status: TransactionStatus.PENDING,
}

export interface ICheckTransactionStatusResponse {
  id: number
  customer: number
  amount: string
  issued_date: string
  due_date: string
  status: TransactionStatus
}

export const transactionsActionTypes = {
  CHECK_TRANSACTION_STATUS: '[CheckTransactionStatus] Action',
  STOP_CHECKING_TRANSACTION_STATUS: '[StopCheckingTransactionStatus] Action',
}
