import axios, {AxiosResponse} from 'axios'
import {
  getAdditionalParametersUrl,
  IFilterRequestParams,
  IUrlParameters,
} from '../../components/lists/UrlFilteredList'
import {DEFAULT_PRICES_GET_LIMIT, DEFAULT_PRICES_LIMIT, DEFAULT_PRICES_OFFSET} from './constants'
import {
  IPostedPriceType,
  IPriceExtended,
  IPriceItem,
  IPriceListResponse,
  IPricesSaveRequestParameters,
} from './types'

const API_URL = `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENDPOINT_PREFIX}`
export const PRICES_URL = `${API_URL}v2/prices/`

// =================   get prices =================
// #region
//https://api-dev.shop-delivery.ru/int_api/v2/prices/?limit=10&offset=1

// get all prices from server
export const getPricesFromServer = (params: IUrlParameters) => {
  const offset =
    params.pageNumber != undefined && params.itemsPerPage != undefined
      ? params.pageNumber * params.itemsPerPage
      : DEFAULT_PRICES_OFFSET

  return axios.get<IPriceListResponse>(
    `${PRICES_URL}?limit=${params.itemsPerPage ?? DEFAULT_PRICES_GET_LIMIT}&offset=${offset}${
      params.filter != undefined ? `&search=${params.filter}` : ``
    }${
      params.ordering != undefined ? `&ordering=${params.ordering}` : ``
    }${getAdditionalParametersUrl(params, -1)}`
  )
}

// http://api-dev.shop-delivery.ru/int_api/v2/grouped/?limit=10
// get all prices from server
export const getGroupedPricesFromServer = (params: IUrlParameters) => {
  const offset =
    params.pageNumber != undefined && params.itemsPerPage != undefined
      ? params.pageNumber * params.itemsPerPage
      : DEFAULT_PRICES_OFFSET
  console.log(7777777, params)
  return axios.get<IPriceListResponse>(
    `${API_URL}v2/grouped/?limit=${
      params.itemsPerPage ?? DEFAULT_PRICES_GET_LIMIT
    }&offset=${offset}${params.filter != undefined ? `&search=${params.filter}` : ``}${
      params.ordering != undefined ? `&ordering=${params.ordering}` : ``
    }${
      params.price_type != undefined ? `&price_type=${params.price_type}` : ``
    }${getAdditionalParametersUrl(params, -1)}`
  )
}
// #endregion

// =================   mass save prices =================

// http://api-dev.shop-delivery.ru/int_api/v2/grouped/?limit=10
// save all prices on server
export const saveGroupedPricesToServer = (params: IPostedPriceType[]) => {
  return axios.post(`${API_URL}v2/bulk_price/`, params)
}
// #endregion
