export interface IPriceTypeIF {
  id: string | undefined
  name: string
  code: string
  params:
    | {
        additionalProp1: string
        additionalProp2: string
        additionalProp3: string
      }
    | {}
  customer: number
  description: string
}
export const initialPriceTypeFilters: IPriceTypeFilters = {
  search: '',
  itemsPerPage: 10,
  currentPage: 0,
  ordering: 'id',
}

export const initialPriceTypes: IPriceTypeState = {
  count: 0,
  isLoading: false,
  selected: null,
  priceTypes: [],
  filters: initialPriceTypeFilters,
}

export const emptyPriceType: IPriceType = {
  id: -1,
  code: '',
  name: '',
  params: {},
  customer: -1,
  description: '',
}

export interface IPriceType {
  id: number
  code: string
  name: string
  params: any
  customer: number
  description: string
}

export interface IPriceTypesState {
  priceTypes: IPriceType[]
  isLoading: boolean
}

export interface IPriceTypeState {
  count: number
  selected: IPriceType | null
  isLoading: boolean
  priceTypes: IPriceType[]
  filters: IPriceTypeFilters
}

export interface IPriceTypeFilters {
  search: string
  itemsPerPage: number
  currentPage: number
  ordering: string
}

export interface IPriceTypeListResponse {
  count: number
  next: string
  previous: string
  results: IPriceType[]
}

export const priceTypesActionTypes = {
  GetPriceTypes: '[GetPriceTypes] Action',
  AddPriceType: '[AddPriceType] Action',
  UpdatePriceType: '[UpdatePriceType] Action',
  DeletePriceType: '[DeletePriceType] Action',
}
