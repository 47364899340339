import { FC, createContext, useContext, useState } from "react"


interface IAppLayoutModel {
    toolbar: any
    setToolbars: (toolbars: any) => void
}

const toolbarDefaultValue: any = []

const AppLayoutContext = createContext<IAppLayoutModel>({
    toolbar: toolbarDefaultValue,
    setToolbars: (toolbars: any) => {}
    // config: DefaultLayoutConfig,
    // classes: getEmptyCssClasses(),
    // attributes: getEmptyHTMLAttributes(),
    // cssVariables: getEmptyCSSVariables(),
    // setLayout: (config: LayoutSetup) => {},
})


const AppLayoutProvider: React.FC = ({ children }) => {
    const [toolbar, setToolbar] = useState(toolbarDefaultValue)
    // const [classes, setClasses] = useState(LayoutSetup.classes)
    // const [attributes, setAttributes] = useState(LayoutSetup.attributes)
    // const [cssVariables, setCSSVariables] = useState(LayoutSetup.cssVariables)
    // const setLayout = (_themeConfig: Partial<ILayout>) => {
    const setToolbarControls = (toolbarControls: any) => {
        setToolbar(toolbarControls)
        //   enableSplashScreen()
        //   const bodyClasses = Array.from(document.body.classList)
        //   bodyClasses.forEach((cl) => document.body.classList.remove(cl))
        //   LayoutSetup.updatePartialConfig(_themeConfig)
        //   setConfig(Object.assign({}, LayoutSetup.config))
        //   setClasses(LayoutSetup.classes)
        //   setAttributes(LayoutSetup.attributes)
        //   setCSSVariables(LayoutSetup.cssVariables)
        //   setTimeout(() => {
        //     disableSplashScreen()
        //   }, 500)
    }
    const value: IAppLayoutModel = {
        toolbar: toolbar,
        setToolbars: setToolbarControls
        //   config,
        //   classes,
        //   attributes,
        //   cssVariables,
        //   setLayout,
    }

    // useEffect(() => {
    //   disableSplashScreen()
    // }, [])
    return <AppLayoutContext.Provider value={ value }>{ children }</AppLayoutContext.Provider>
}

export {AppLayoutContext, AppLayoutProvider}

export function useAppLayout() {
  return useContext(AppLayoutContext)
}
