import { takeEvery } from "redux-saga/effects"
import { addWarehouseToServer, deleteWarehouseFromServer, getWarehousesFromServer, updateWarehouseOnServer } from "./api";

import { GET_ASYNC_WAREHOUSES, ADD_ASYNC_WAREHOUSE, UPDATE_ASYNC_WAREHOUSE, DELETE_ASYNC_WAREHOUSE } from "./actions"
import { setWarehouses, addWarehouse, setWarehouse, deleteWarehouse, setIsLoading } from "./reducers";
import { sagaUtils } from "../../utils/dataUtils";
import { intl } from "../../modules/goods/item/validation";



export function* warehouseSaga() {
    yield takeEvery(GET_ASYNC_WAREHOUSES, workerGetWarehouses)
    yield takeEvery(ADD_ASYNC_WAREHOUSE, workerAddWarehouse)
    yield takeEvery(UPDATE_ASYNC_WAREHOUSE, workerUpdateWarehouse)
    yield takeEvery(DELETE_ASYNC_WAREHOUSE, workerDeleteWarehouse)
}

// get all waregouses
export function* workerGetWarehouses(action: any): any {
    yield sagaUtils.workerWrapper(setIsLoading,
        getWarehousesFromServer, action.payload,
        setWarehouses, null,
        intl.formatMessage({ id: "SAGAS.WAREHOUSES.GET" }), "GET", "data.results", false, "")
}

// add warehouse
export function* workerAddWarehouse(action: any): any {
    yield sagaUtils.workerWrapper(setIsLoading,
        addWarehouseToServer, action.payload,
        addWarehouse, null,
        intl.formatMessage({ id: "SAGAS.WAREHOUSES.ADD" }), "ADD", "data", true, "")
}

// save edited warehouse
export function* workerUpdateWarehouse(action: any): any {
    yield sagaUtils.workerWrapper(setIsLoading,
        updateWarehouseOnServer, action.payload,
        setWarehouse, null,
        intl.formatMessage({ id: "SAGAS.WAREHOUSES.SAVE" }), "SAVE", "data", true, "")
}

// delete warehouse by id
export function* workerDeleteWarehouse(action: any): any {
    yield sagaUtils.workerWrapper(setIsLoading,
        deleteWarehouseFromServer, action.payload,
        deleteWarehouse, action.payload,
        intl.formatMessage({ id: "SAGAS.WAREHOUSES.DELETE" }), "DELETE", "direct", true, "")
}

