import { call, put, takeEvery } from "redux-saga/effects"
import { ADD_SYSTEM_PUSHMESSAGE, ADD_SYSTEM_PUSHMESSAGE_GROUP, DEL_SYSTEM_PUSHMESSAGES } from "./actions"
import { addPushMessage, delPushMessageById, addPushMessageGroup } from "./reducers"




export function* systemSaga(){
    yield takeEvery(ADD_SYSTEM_PUSHMESSAGE, workerAddPushMessage)
    yield takeEvery(ADD_SYSTEM_PUSHMESSAGE_GROUP, workerAddPushMessageGroup)
    yield takeEvery(DEL_SYSTEM_PUSHMESSAGES, workerDelPushMessage)
}


export function* workerAddPushMessage(action: any):any{
    try{
        yield put(addPushMessage(action.payload))

    }
    catch(error){
    }
}

export function* workerAddPushMessageGroup(action: any):any{
    try{
        yield put(addPushMessageGroup(action.payload))

    }
    catch(error){
    }
}

export function* workerDelPushMessage(action: any):any{
    try{
        yield put(delPushMessageById(action.payload))

    }
    catch(error){
    }
}
