import {takeEvery, call, delay, put, race, take} from 'redux-saga/effects'
import {
  ASYNC_CONNECT_USER_TO_TELEGRAM,
  connectAsyncUserToTelegramCreator,
  GET_INFO_ABOUT_TG_ID,
  INTERVAL_GET_INFO_ABOUT_TG_ID_TERMINATED,
} from './actions'
import {connectUserToTelegram} from './api'
import {getUserByToken} from '../auth/AuthCRUD'
import {setIsLoading, setBindingInfo, setTgId} from './reducer'

import {sagaUtils} from '../../utils/dataUtils'

export function* thModalSaga() {
  yield takeEvery(ASYNC_CONNECT_USER_TO_TELEGRAM, workerConnectUserToTelegram)
  yield takeEvery(GET_INFO_ABOUT_TG_ID, watchGetInfoAboutTgId)
}

export function* workerConnectUserToTelegram(
  action: ReturnType<typeof connectAsyncUserToTelegramCreator>
) {
  yield sagaUtils.workerWrapper(
    setIsLoading,
    connectUserToTelegram,
    action.payload,
    setBindingInfo,
    null,
    '',
    'GET',
    'data.result',
    false,
    '',
    undefined
  )
}

export function* watchGetInfoAboutTgId() {
  yield race([call(workerGetInfoAboutTgId), take(INTERVAL_GET_INFO_ABOUT_TG_ID_TERMINATED)])
}

export function* workerGetInfoAboutTgId() {
  try {
    while (true) {
      yield call(workerGetUserInfo)
      yield delay(5000)
    }
  } catch (error) {
    console.error(error)

    yield put({type: INTERVAL_GET_INFO_ABOUT_TG_ID_TERMINATED})
  }
}

export function* workerGetUserInfo() {
  try {
    const {data} = yield call(getUserByToken)

    const tgId = data.result.params?.tg_id

    if (tgId) {
      yield put({type: setTgId, payload: tgId})
      yield put({type: INTERVAL_GET_INFO_ABOUT_TG_ID_TERMINATED})
    }
  } catch (error) {
    throw error
  }
}
