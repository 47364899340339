import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {initialNews} from './types'

const newsSlice = createSlice({
  name: 'newsSlice',
  initialState: initialNews,
  reducers: {
    setNewsData(state, action) {
      state.results = action.payload.results
      state.count = action.payload.count
      state.previous = action.payload.previous
      state.results = action.payload.results
      state.isLoading = false
    },

    setIsLoading(state, action) {
      state.isLoading = action.payload
    },
  },
})

export const {setNewsData, setIsLoading} = newsSlice.actions;
export const newsReducer = newsSlice.reducer;