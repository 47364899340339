import { call, put, takeEvery } from "redux-saga/effects"
import { fetchMarketplaces } from "./api";
import { setMarketplaces } from "./reducers"
import { ASYNC_GET_MARKETPLACES } from "./actions"



export function* marketplaceSaga(){
    yield takeEvery(ASYNC_GET_MARKETPLACES, workerGetMarketplaces)
}


export function* workerGetMarketplaces():any{
    //console.log('saga marketplaces workerGetMarketplaces')
    const marketplacesPromise = yield call(fetchMarketplaces)
    yield put(setMarketplaces(marketplacesPromise.data.results))
}