import {FC, useEffect, useState} from 'react'
import {Button, Col} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'
import {useNavigate, useParams} from 'react-router-dom'
import {StorageType} from '../../../../setup'
import ModalInfo from '../../../components/vision/info/ModalInfo'
import Loader1 from '../../../components/vision/loaders/Loader1'
import {IAccountState} from '../../../store/accounts/types'
import {IMarketplaceState} from '../../../store/marketplaces/types'
import {getAsyncMpDocCreator} from '../../../store/mpdocs/actions'
import {emptyMpDocUrlParameters, IMpDocItem, IMpDocStorage} from '../../../store/mpdocs/types'
import {createLink, dateUtils} from '../../../utils/dataUtils'
import {makeLinkAndClick, requestStickers, scanService} from '../list/MpDocsListWrapper'
import MpDocsItem from './MpDocsItem'
import SbxLightButton from '../../../components/buttons/SbxButtons/SbxLightButton'
import {useLocation} from 'react-router'
import * as publicRoutes from '../../../routing/publicRoutes'
import * as routes from '../../../routing/routes'

interface IMpDocsItemWrapperProps {
  id?: string
  closeAction?: any
  forWarehouse?: boolean
}

const MpDocsItemWrapper: FC<IMpDocsItemWrapperProps> = (props) => {
  const location = useLocation()
  const isPublic = location.pathname.startsWith('/public')
  const urlParams = useParams()
  const {id} = props?.id?.length ? props : urlParams
  const dispatch = useDispatch()
  const navigator = useNavigate()
  const intl = useIntl()

  const marketplaceState: IMarketplaceState = useSelector((s: StorageType) => s.marketplaces)
  const accountState: IAccountState = useSelector((s: StorageType) => s.accounts)

  const mpdocsState: IMpDocStorage = useSelector((db: StorageType) => db.mpdocs)
  const item: IMpDocItem | undefined = mpdocsState.selected ?? undefined

  const createdDate = item?.created ? new Date(item.created) : null

  const [showModal, setShowModal] = useState<boolean>(false)
  const [showScanned, setShowScanned] = useState<boolean>(true)

  // when id in url changes
  useEffect(() => {
    // get mpdoc from server by id to storage (mpdocState.selected)
    id != undefined && !Number.isNaN(parseInt(id)) && dispatch(getAsyncMpDocCreator(parseInt(id)))
  }, [id])

  // back to mpdocs list
  const goBack = () => {
    // go to filtered page or on standart mpdoc list route
    const urlParams = {
      ...(mpdocsState?.filter ?? emptyMpDocUrlParameters),
      url: '/' + (isPublic ? publicRoutes.MPDOCS : routes.MPDOCS) + '/list',
    }
    props.closeAction
      ? props.closeAction()
      : navigator(createLink.paginationLink(urlParams).url + '&back=true')
  }

  // call to get sticker from server
  const printBarcode = async () => {
    if (item) {
      setShowModal(true)
      ;(await requestStickers([item.id]))?.click()
      setShowModal(false)
    }
  }

  // call to get sticker from server
  const gotoScanService = (id: string) => {
    makeLinkAndClick(`${scanService}${id}`)
  }

  return item ? (
    <MpDocsItem
      isLoading={mpdocsState.isLoading}
      title={`${intl.formatMessage({id: 'COMPONENTS.MPDOCS.ITEM.TITLE'})} ${item.number} ${
        createdDate ? `от ${dateUtils.standartDateTimeString(createdDate)}` : ``
      }`}
      item={item}
      onCloseClick={goBack}
      accounts={accountState.items}
      isShowScanned={showScanned ?? false}
      marketplaces={marketplaceState.items}
    >
      <Col className='d-flex flex-row justify-content-end align-items-end'>
        <Button onClick={printBarcode} className='fs-8'>
          {intl.formatMessage({id: 'COMPONENTS.MPDOCS.LIST.BUTTONS.PRINT'})}
        </Button>
        <Button onClick={() => gotoScanService(item.number)} className='fs-8 ms-3'>
          {intl.formatMessage({id: 'COMPONENTS.MPDOCS.LIST.BUTTONS.SCAN'})}
        </Button>
        <Button onClick={() => setShowScanned(!showScanned)} className='fs-8 ms-3'>
          {showScanned
            ? intl.formatMessage({id: 'COMPONENTS.MPDOCS.LIST.BUTTONS.SCANNED'})
            : intl.formatMessage({id: 'COMPONENTS.MPDOCS.LIST.BUTTONS.NOTSCANNED'})}
        </Button>
        <SbxLightButton className='fs-8 ms-3' onClick={goBack}>
          {intl.formatMessage({id: props.id ? 'ACTION.CLOSE' : 'ACTION.BACK'})}
        </SbxLightButton>
        <ModalInfo
          title={intl.formatMessage({id: 'COMPONENTS.MPDOCS.LIST.MODALS.PRINT.TITLE'})}
          showModal={showModal}
          setShowModal={setShowModal}
        />
      </Col>
    </MpDocsItem>
  ) : (
    <Loader1 />
  )
}

export default MpDocsItemWrapper
