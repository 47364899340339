import Store from './../../../setup/redux/Store'
import { addPushMessageCreator } from "./actions"
import { IPushMessage } from "./types"


// push messager system
const PushMessager = {
    // add message to queue
    add: (message: IPushMessage) => {
        Store.
            dispatch(addPushMessageCreator(message))
    },
    // add message to queue
    addSuccess: (name: string, message: string, details: any = '', picture: string = '', code: string = '', showSeconds: number = 5,) => {
        const msg: IPushMessage = {
            name,
            details,
            status: "success",
            message,
            showSeconds,
            code,
            picture
        }
        Store.
            dispatch(addPushMessageCreator(msg))
    },
    // add error message to queue
    addError: (name: string, message: string, details: any = '', picture: string = '', code: string = '', showSeconds: number = 5,) => {
        const msg: IPushMessage = {
            name,
            details,
            status: "error",
            message,
            showSeconds,
            code,
            picture
        }
        Store.
            dispatch(addPushMessageCreator(msg))
    },
    // add info message to queue
    addInfo: (name: string, message: string, details: any = '', picture: string = '', code: string = '', showSeconds: number = 5,) => {
        const msg: IPushMessage = {
            name,
            details,
            status: "info",
            message,
            showSeconds,
            code,
            picture
        }
        Store.
            dispatch(addPushMessageCreator(msg))
    },

}

export default PushMessager