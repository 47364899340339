import { FC } from 'react'
import { useIntl } from 'react-intl'
import CardSimple from '../../../components/containers/CardSimple'
import Loader1 from '../../../components/vision/loaders/Loader1'
import { IAccount } from '../../../store/accounts/types'
import { IMarketplace } from '../../../store/marketplaces/types'
import { IMpDocItem } from '../../../store/mpdocs/types'
import MpDocData from './MpDocData'

interface IMpDocsItem {
    item: IMpDocItem
    title?: string
    description?: string
    isLoading?: boolean
    isShowScanned?: boolean

    onCloseClick?: any
    marketplaces?: IMarketplace[]
    accounts?: IAccount[]
}


const MpDocsItem: FC<IMpDocsItem> = (props) => {
    const intl = useIntl()

    return (
        <CardSimple
            headerClassName={``}
            title={props.title}
            subTitle={props.description}
            cardBodyClassIsOff
            rightTopElement={props.children}
        >
            {
                props.isLoading
                    ? <Loader1 label={intl.formatMessage({ id: "COMPONENTS.MPDOCS.ITEM.LOADING" })} />
                    : <MpDocData
                        item={props.item}
                        isShowScanned={props.isShowScanned ?? false}
                        marketplaces={props.marketplaces}
                        accounts={props.accounts}
                    >
                        
                    </MpDocData>
            }
        </CardSimple>
    )
}

export default MpDocsItem