import React, { FC } from 'react'

interface IDevidedNumberProps{
    children: number
    devider?: string
}

export const getDevidedNumberString = (num: number, devider: string): string => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, devider ?? " ")
}

const DevidedNumber:FC<IDevidedNumberProps> = (props) => <>{getDevidedNumberString(props.children, props.devider ?? " ")}</>

export default DevidedNumber