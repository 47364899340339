import {createAction} from '@reduxjs/toolkit'
import {
  priceActionTypes as actionTypes,
  IPriceRequestParameters,
  IUpdatePriceRequest,
  IPostedPriceType,
} from './types'

export const ASYNC_GET_PRICES = actionTypes.GetPrices
export const ASYNC_UPDATE_PRICE = actionTypes.UpdatePrice
export const ASYNC_UPDATE_PRICES = actionTypes.UpdatePrices
export const SET_PRICE_FILTER = actionTypes.SetFilterPrice

export const getAsyncPricesCreator = createAction(ASYNC_GET_PRICES)

export const setPricesFilterCreator = createAction<IPriceRequestParameters>(SET_PRICE_FILTER)
export const savePricesAction = createAction<IPostedPriceType[]>(ASYNC_UPDATE_PRICES)
