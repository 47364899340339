import {call, put, takeEvery} from 'redux-saga/effects'
import {fetchRegions} from './api'
import {setRegions} from './reducer'
import {ASYNC_GET_REGIONS} from './actions'

export function* regionsSaga() {
  yield takeEvery(ASYNC_GET_REGIONS, workerGetRegions)
}

export function* workerGetRegions(): any {
  const regionsPromise = yield call(fetchRegions, {query: ''})
  yield put(setRegions(regionsPromise.data))
}
