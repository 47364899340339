import { createSlice } from "@reduxjs/toolkit"
import { ICompetitorGood, initialCompetitors as initialState, } from "./types"


const competitorSlice = createSlice({
    name: "competitorSlice",
    initialState,
    reducers: {
        setCompetitors(state, action){
            state.competitors = [...action.payload]
        },
        setIsLoading(state, action){
            state.isLoading = action.payload
        },
        setCompetitorGoods(state, action){
            state.competitorGoods = [...action.payload.map((cg: ICompetitorGood) => {
                cg.isLoading = false
                return cg
            })]
        },
        setCompetitorGood(state, action){
            try{
                state.competitorGoods = [...state.competitorGoods.map((cg:ICompetitorGood) => 
                    {
                        cg.isLoading = false  
                        if (cg.id == action.payload.id){
                            action.payload.isLoading = false;
                            return action.payload
                        }
                        else 
                            return cg

                    }
                    )
                ]
            }
            catch(err){
                console.log('setCompetitorGood catch',err)
            }
        },
        setCompetitorGoodIsLoading(state, action){
            try{

                const {id, isLoading} = action.payload
                state.competitorGoods = [...state.competitorGoods.map((cg:ICompetitorGood) => 
                    {

                        if (cg.id == id){
                            cg.isLoading = isLoading
                            return cg
                        }
                        else 
                            return cg

                    }
                    )
                ]
            }
            catch(err){
                console.log('setCompetitorGood catch',err)
            }
        },
        addCompetitorGood(state, action){
            try{
                action.payload.isLoading = false
                state.competitorGoods = [...state.competitorGoods, action.payload]}
            catch(err){
                console.log('addCompetitorGood catch',err)
            }
        },
        delCompetitorGood(state, action){
            try{
                state.competitorGoods = [...state.competitorGoods.filter(cg => cg.id != action.payload)]
            }
            catch(err){
                console.log('delCompetitorGood catch',err)
            }
        }
    }
})


export const competitorReducer = competitorSlice.reducer
export const { setCompetitors, setIsLoading, setCompetitorGoods, addCompetitorGood, delCompetitorGood, setCompetitorGood, setCompetitorGoodIsLoading } = competitorSlice.actions