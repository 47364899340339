import { createAction } from "@reduxjs/toolkit";
import { dashboardActionTypes as actionTypes, IDateRangeParameters, IGraphicWithNumberRequestParams, IStatisticsRequestParams } from "./types";


 export const SET_DATE_RANGE = actionTypes.SetDateRange
 export const ASYNC_GET_MONEY_GRAPHIC = actionTypes.GetMoneyByDateRange
 export const ASYNC_GET_ORDERS_GRAPHIC = actionTypes.GetOrdersByDateRange
 export const ASYNC_GET_MONEY_MARKETPLACE_STATISTICS = actionTypes.GetMoneyMarketplaceStatisticsByDateRange
 export const ASYNC_GET_MONEY_ACCOUNT_STATISTICS = actionTypes.GetMoneyAccountStatisticsByDateRange
 export const ASYNC_GET_MONEY_COMPANY_STATISTICS = actionTypes.GetMoneyCompanyStatisticsByDateRange
 export const ASYNC_GET_ORDERS_MARKETPLACE_STATISTICS = actionTypes.GetOrdersMarketplaceStatisticsByDateRange
 export const ASYNC_GET_ORDERS_ACCOUNT_STATISTICS = actionTypes.GetOrdersAccountStatisticsByDateRange
 export const ASYNC_GET_ORDERS_COMPANY_STATISTICS = actionTypes.GetOrdersCompanyStatisticsByDateRange

export const setDateRangeCreator = createAction<IDateRangeParameters>(SET_DATE_RANGE);
export const getAsyncMoneyGraphicCreator = createAction(ASYNC_GET_MONEY_GRAPHIC);
export const getAsyncOrdersGraphicCreator = createAction(ASYNC_GET_ORDERS_GRAPHIC);
export const getAsyncStatisticsMoneyMarketplaceCreator = createAction(ASYNC_GET_MONEY_MARKETPLACE_STATISTICS);
export const getAsyncStatisticsMoneyAccountCreator = createAction(ASYNC_GET_MONEY_ACCOUNT_STATISTICS);
export const getAsyncStatisticsMoneyCompanyCreator = createAction(ASYNC_GET_MONEY_COMPANY_STATISTICS);
export const getAsyncStatisticsOrdersMarketplaceCreator = createAction(ASYNC_GET_ORDERS_MARKETPLACE_STATISTICS);
export const getAsyncStatisticsOrdersAccountCreator = createAction(ASYNC_GET_ORDERS_ACCOUNT_STATISTICS);
export const getAsyncStatisticsOrdersCompanyCreator = createAction(ASYNC_GET_ORDERS_COMPANY_STATISTICS);



