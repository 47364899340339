import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {emptySubscriptionsState, ISubscription, CategorizedSubscriptionsType} from './types'
import {groupBy} from 'lodash'

const subscriptionsSlice = createSlice({
  name: 'subscriptionsSlice',
  initialState: emptySubscriptionsState,
  reducers: {
    setSubscriptions: {
      reducer(state, action: PayloadAction<CategorizedSubscriptionsType>) {
        state.subscriptions = action.payload
      },
      prepare(subscriptions: ISubscription[]) {
        const transformedSubscriptions = subscriptions.map((s) => ({...s, auto_renewal: true}))

        const categorizedSubscriptions = groupBy(
          transformedSubscriptions,
          'category.code'
        ) as CategorizedSubscriptionsType

        return {payload: categorizedSubscriptions}
      },
    },
    setUserSubscriptions(state, action) {
      state.userSubscriptions = action.payload
    },
    selectSubscription(state, action) {
      state.selectedSubscriptions = [...state.selectedSubscriptions, action.payload]
    },
    unselectSubscription(state, action) {
      state.selectedSubscriptions = state.selectedSubscriptions.filter(
        (s) => s.id !== action.payload
      )
    },
    setAutoReNewSubscription(state, action) {
      state.selectedSubscriptions = state.selectedSubscriptions.map((s) => {
        return s.id === action.payload ? {...s, auto_renewal: !s.auto_renewal} : s
      })
    },
    clearSelectedSubscriptions(state, action) {
      if (action.payload?.exeption) {
        state.selectedSubscriptions = state.selectedSubscriptions.filter(
          (s: ISubscription) => s.name === action.payload.exeption
        )
      } else {
        state.selectedSubscriptions = []
      }
    },
    updateUserSubscriptions(state, action) {
      state.userSubscriptions = [...action.payload, ...state.userSubscriptions]
      state.selectedSubscriptions = []
    },
    setSuccesCreatingPayment(state, action) {
      state.successCreatignPayment = action.payload.success
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload
    },
    setIsUserSubscriptionsLoading(state, action) {
      state.isUserSubscriptionsLoading = action.payload
    },
    setActiveTariff(state, action) {
      state.selectedTariff = action.payload
    },
    setIsCreatingPayment(state, action) {
      state.isCreatingPayment = action.payload
    },
    setPaymentResponse(state, action) {
      state.redirectLink = action.payload.confirmation_url
      state.userSubscriptions = [...state.userSubscriptions, ...action.payload.active_subscriptions]
      state.selectedSubscriptions = []
    },
    setIsTopUpBalanceLoading(state, action) {
      state.isTopupBalanceLoading = action.payload
    },
    setIsTariffChanging(state, action) {
      state.isTariffChanging = action.payload
    },
    setIsRenewing(state, action) {
      state.isRenewing = action.payload
    },
  },
})

export const {
  setSubscriptions,
  setIsLoading,
  selectSubscription,
  unselectSubscription,
  clearSelectedSubscriptions,
  setIsUserSubscriptionsLoading,
  setUserSubscriptions,
  setAutoReNewSubscription,
  setActiveTariff,
  setIsCreatingPayment,
  setPaymentResponse,
  setIsTopUpBalanceLoading,
  updateUserSubscriptions,
  setIsTariffChanging,
  setIsRenewing,
  setSuccesCreatingPayment,
} = subscriptionsSlice.actions

export const subscriptionsReducer = subscriptionsSlice.reducer
