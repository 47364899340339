export const initialImages: IImageState = {
  count: 0,
  next: '',
  previous: '',
  items: [],
}

export interface IImage {
  id: number
  name: string
  image: {
    thumbnail: string
    full_size: string
  }
}

export interface IImageSimple {
  id: number
  name: string
  image: {
    thumbnail: string
    full_size: string
  }
}

export interface IImageState {
  count: number
  next: string
  previous: string
  items: IImage[]
}

export const imageActionTypes = {
  GetImages: '[GetImages] Action',
  SetImages: '[SetImages] Action',
}
