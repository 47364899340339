import {select, takeEvery, takeLatest} from 'redux-saga/effects'
import {StorageType} from '../../../setup'
import {sagaUtils} from '../../utils/dataUtils'
import {intl} from '../../modules/goods/item/validation'
import {IWarehouseRatesStorage} from './types'
import {
  DELETE_ASYNC_ACCEPTANCE_RATES_BY_ID,
  FETCH_ACCEPTANCE_RATES,
  FETCH_ACCEPTANCE_RATES_BY_ID,
  UPDATE_ASYNC_ACCEPTANCE_RATES_BY_ID,
  UPDATE_ASYNC_ACCEPTANCE_RATES_NOTIFICATION_BY_ID,
  UPDATE_ASYNC_ACCEPTANCE_RATES_WAREHOUSE_BY_ID,
  UPDATE_ASYNC_RULES_BY_ID,
} from './actions'
import {
  deleteAcceptanceRatesOnServer,
  fetchWarehouseRates,
  fetchWarehouseRatesById,
  patchWarehouseNotificationOnServer,
  patchWarehouseRulesOnServer,
  refreshWarehouseRatesById,
  updateWarehouseRatesOnServer,
} from './api'
import {
  refreshAcceptanceRate,
  setAcceptanceRate,
  setIsLoading,
  setIsLoadingRules,
  setIsLoadingWarehouses,
  setItemData,
  setSelectedAcceptanceRate,
  updateAcceptanceRate,
} from './reducer'

export function* acceptanceRatesSaga() {
  yield takeLatest(FETCH_ACCEPTANCE_RATES, workerFetchAcceptanceRate)
  yield takeEvery(FETCH_ACCEPTANCE_RATES_BY_ID, workerFetchAcceptanceRateById)
  yield takeEvery(UPDATE_ASYNC_ACCEPTANCE_RATES_BY_ID, workerUpdateAcceptanceRateById)
  yield takeLatest(
    UPDATE_ASYNC_ACCEPTANCE_RATES_WAREHOUSE_BY_ID,
    workerUpdateAcceptanceRatesWarehouseById
  )
  yield takeLatest(DELETE_ASYNC_ACCEPTANCE_RATES_BY_ID, workerDeleteAcceptanceRate)
  yield takeLatest(
    UPDATE_ASYNC_ACCEPTANCE_RATES_NOTIFICATION_BY_ID,
    workerUpdateAcceptanceRateNotifyById
  )
  yield takeLatest(UPDATE_ASYNC_RULES_BY_ID, workerUpdateRulesById)
}

export function* workerFetchAcceptanceRate() {
  const {filters}: IWarehouseRatesStorage = yield select(
    (storage: StorageType) => storage.acceptanceRates
  )
  yield sagaUtils.workerWrapper(
    setIsLoading,
    fetchWarehouseRates,
    filters,
    setAcceptanceRate,
    null,
    intl.formatMessage({id: 'SAGAS.ACCEPTANCE.RATES.GET'}),
    'GET',
    'data',
    false,
    '',
    undefined,
    undefined
  )
}

export function* workerFetchAcceptanceRateById(action: any): any {
  yield sagaUtils.workerWrapper(
    setIsLoading,
    fetchWarehouseRatesById,
    action.payload,
    setSelectedAcceptanceRate,
    null,
    intl.formatMessage({id: 'SAGAS.ACCEPTANCE.RATES.GET'}),
    'GET',
    'data',
    false
  )
}

export function* workerUpdateAcceptanceRateNotifyById(action: any): any {
  yield sagaUtils.workerWrapper(
    null,
    patchWarehouseNotificationOnServer,
    action.payload,
    setItemData,
    null,
    intl.formatMessage({id: 'SAGAS.ACCEPTANCE.RATES.GET'}),
    'GET',
    'data',
    false
  )
}

export function* workerUpdateAcceptanceRateById(action: any): any {
  yield sagaUtils.workerWrapper(
    setIsLoading,
    updateWarehouseRatesOnServer,
    action.payload,
    updateAcceptanceRate,
    action.payload,
    intl.formatMessage({id: 'SAGAS.ACCEPTANCE.RATES.SAVE'}),
    'SAVE',
    'data',
    true,
    ''
  )
}

export function* workerUpdateAcceptanceRatesWarehouseById(action: any): any {
  yield sagaUtils.workerWrapper(
    setIsLoadingWarehouses,
    refreshWarehouseRatesById,
    action.payload,
    refreshAcceptanceRate,
    undefined,
    intl.formatMessage({id: 'SAGAS.ACCEPTANCE.RATES.SAVE'}),
    'GET',
    'data',
    false,
    '',
    undefined,
    undefined
  )
}

export function* workerDeleteAcceptanceRate(action: any): any {
  yield sagaUtils.workerWrapper(
    setIsLoading,
    deleteAcceptanceRatesOnServer,
    action.payload,
    undefined,
    undefined,
    intl.formatMessage({id: 'SAGAS.ACCEPTANCE.RATES.DELETE'}),
    'DELETE',
    'direct',
    true,
    ''
  )
}

export function* workerUpdateRulesById(action: any): any {
  yield sagaUtils.workerWrapper(
    setIsLoadingRules,
    patchWarehouseRulesOnServer,
    action.payload,
    setItemData,
    null,
    intl.formatMessage({id: 'SAGAS.RULES.CHANGE'}),
    'SAVE',
    'data',
    true,
    '',
    undefined,
    undefined
  )
}
