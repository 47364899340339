import { FC } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { IAccount } from '../../../store/accounts/types'
import { IMarketplace } from '../../../store/marketplaces/types'
import { IMpDocItem } from '../../../store/mpdocs/types'
import { dateUtils } from '../../../utils/dataUtils'
import MpDocOrderListItem from './MpDocOrderListItem'
import { border,padding40Px } from '../../orders/item/OrderData'

interface IMpDocsData {
    item: IMpDocItem
    marketplaces?: IMarketplace[]
    accounts?: IAccount[]
    isShowScanned?: boolean

}

const MpDocData: FC<IMpDocsData> = (props) => {
    const intl = useIntl()

    const createdDate = props.item.created ? new Date(props.item.created) : null
    const currentItemMarketplace = props.marketplaces?.find(mp => props.item?.account?.marketplace && mp.id === props.item.account.marketplace) ?? undefined


    return (
        <Row>
            <Col>
                <Row>
                    {props.children}
                </Row>
                <Row>
                    <Col className="d-flex flex-row justify-content-start mt-5">
                        {
                            props.marketplaces && props.item?.account?.marketplace
                                ? <div className='mx-5 ps-5'>
                                    <h4>{intl.formatMessage({ id: "COMPONENTS.MPDOCS.ITEM.MARKETPLACE" })}: {props.marketplaces?.find(m => m.id == props.item.account.marketplace)?.name ?? ""}</h4>
                                </div>
                                : null
                        }
                        <div className='ms-5'>
                            <h4>{intl.formatMessage({ id: "COMPONENTS.MPDOCS.ITEM.ACCOUNT" })}: {props.item.account.name}</h4>
                        </div>
                    </Col>
                </Row>
                <Row className='mt-5'>
                    <Col>
                        <div className='bg-secondary mb-5 py-5' style={border}>
                            <div><strong>{intl.formatMessage({ id: "COMPONENTS.MPDOCS.ITEM.CREATED" })}:</strong> {createdDate ? `${dateUtils.standartDateString(createdDate, ".")} в ${dateUtils.standartTimeString(createdDate, ":")}` : intl.formatMessage({ id: "COMPONENTS.MPDOCS.LIST.FILTERS.DATERANGE.NOVALUES" })}</div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className='table-responsive  mb-5 py-5' style={padding40Px}>
                            <div><strong>{intl.formatMessage({ id: "COMPONENTS.MPDOCS.ITEM.ORDERS" })}:</strong></div>
                            {
                                props.item.orders?.length
                                    ? 
                                        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4' >
                                            <thead>
                                                <th className='text-left fw-bolder w-50px'>{intl.formatMessage({ id: "COMPONENTS.MPDOCS.ITEM.ORDERS.LIST.FIELDS.N" })}</th>
                                                <th className='text-left fw-bolder w-150px'>{intl.formatMessage({ id: "COMPONENTS.MPDOCS.ITEM.ORDERS.LIST.FIELDS.ACT" })}</th>
                                                <th className='text-left fw-bolder w-150px'>{intl.formatMessage({ id: "COMPONENTS.MPDOCS.ITEM.ORDERS.LIST.FIELDS.SCANSTATUS" })}</th>
                                                <th className='text-left fw-bolder w-500px'>{intl.formatMessage({ id: "COMPONENTS.MPDOCS.ITEM.ORDERS.LIST.FIELDS.ITEMS" })}</th>
                                            </thead>
                                            <tbody>
                                                {
                                                    props.item?.orders?.map((order, idx) => <MpDocOrderListItem num={idx + 1} collapsed={props.isShowScanned ?? false} scanned={props.item.params?.payload?.scanned} item={order} />) ?? null
                                                }
                                            </tbody>
                                        </table>

                                    : <p>{intl.formatMessage({ id: "STATUS.NODATA" })}</p>
                            }
                        </div>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default MpDocData