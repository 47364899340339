import {createAction} from '@reduxjs/toolkit'
import { TransactionsActionTypes} from './types'

export const FETCH_TRANSACTIONS = TransactionsActionTypes.FetchTransactions



export const fetchTransactionsAction = createAction(FETCH_TRANSACTIONS)


