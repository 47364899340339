/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import {useDispatch} from 'react-redux'
import {NavLink} from 'react-router-dom'
import * as Yup from 'yup'
import clsx from 'clsx'
import {useFormik} from 'formik'
import * as auth from '../../../store/auth/AuthRedux'
import {login} from '../../../store/auth/AuthCRUD'
import useAbilityContext from '../../../hooks/useAbilityContext'
import {intl} from '../../goods/item/validation'
import {useIntl} from 'react-intl'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email(intl.formatMessage({id: 'AUTH.LOGIN.VALIDATION.INVALID_EMAIL'}))
    .min(3, intl.formatMessage({id: 'AUTH.LOGIN.VALIDATION.MIN3SYMBOLS'}))
    .max(50, intl.formatMessage({id: 'AUTH.LOGIN.VALIDATION.MAX50SYMBOLS'}))
    .required(intl.formatMessage({id: 'AUTH.LOGIN.VALIDATION.EMAIL'})),
  password: Yup.string()
    .min(3, intl.formatMessage({id: 'AUTH.LOGIN.VALIDATION.MIN3SYMBOLS'}))
    .max(50, intl.formatMessage({id: 'AUTH.LOGIN.VALIDATION.MAX50SYMBOLS'}))
    .required(intl.formatMessage({id: 'AUTH.LOGIN.VALIDATION.PASSWORD'})),
})

const initialValues = {
  email: '',
  password: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const intl = useIntl()

  const ability = useAbilityContext()

  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setTimeout(() => {
        login(values.email, values.password)
          .then(({data}) => {
            setLoading(false)
            dispatch(auth.actions.login(data, ability))
          })
          .catch(() => {
            setLoading(false)
            setSubmitting(false)
            setStatus(intl.formatMessage({id: 'AUTH.LOGIN.VALIDATION.LOGINDETAILINCORRECT'}))
          })
      }, 1000)
    },
  })

  return (
    <>
      <form
        className='form w-100'
        onSubmit={formik.handleSubmit}
        noValidate
        id='kt_login_signin_form'
      >
        {formik.status && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>{formik.status}</div>
          </div>
        )}

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <label className='form-label fs-6 fw-bolder text-dark'>
            {intl.formatMessage({id: 'AUTH.LOGIN.CAPTION.EMAIL'})}
          </label>
          <input
            placeholder={intl.formatMessage({id: 'AUTH.LOGIN.CAPTION.EMAIL'})}
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {'is-invalid': formik.touched.email && formik.errors.email},
              {'is-valid': formik.touched.email && !formik.errors.email}
            )}
            type='email'
            name='email'
            autoComplete='off'
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <div className='d-flex justify-content-between mt-n5'>
            <div className='d-flex flex-stack mb-2'>
              {/* begin::Label */}
              <label className='form-label fw-bolder text-dark fs-6 mb-0'>
                {intl.formatMessage({id: 'AUTH.LOGIN.CAPTION.PASSWORD'})}
              </label>
              {/* end::Label */}
            </div>
          </div>
          <input
            type='password'
            autoComplete='off'
            {...formik.getFieldProps('password')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid': formik.touched.password && formik.errors.password,
              },
              {
                'is-valid': formik.touched.password && !formik.errors.password,
              }
            )}
          />
          {formik.touched.password && formik.errors.password && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.password}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Action */}
        <div className='text-center'>
          <button
            type='submit'
            id='kt_sign_in_submit'
            className='btn btn-lg btn-primary w-100 mb-5'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {!loading && (
              <span className='indicator-label'>
                {intl.formatMessage({id: 'AUTH.LOGIN.BUTTONS.LOGIN'})}
              </span>
            )}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                {intl.formatMessage({id: 'AUTH.LOGIN.STATUS.LOGIN'})}...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Action */}
      </form>
      <div className='fv-row mt-10'>
        <div className='d-flex justify-content-between mt-n5'>
          <div className='d-flex flex-stack'>
            {/* begin::Label */}
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>
              {intl.formatMessage({id: 'AUTH.LOGIN.CAPTION.NOACCOUNT.QUESTION'})}
            </label>
            {/* end::Label */}
          </div>
          <div className='d-flex flex-stack'>
            {/* begin::Label */}
            <NavLink to={'/auth/register'}>
              {intl.formatMessage({id: 'AUTH.LOGIN.CAPTION.REGISTER'})}
            </NavLink>
            {/* end::Label */}
          </div>
        </div>
      </div>
    </>
  )
}
