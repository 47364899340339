import {isNullOrUndefined} from 'util'
import {LazyBuilder} from 'yup/lib/Lazy'
import {allMessages} from '../../../../_metronic/i18n/i18nProvider'
import {IAccount} from '../../../store/accounts/types'
import {IGoodAttributeValueResponse, IGoodFieldAttribute} from '../../../store/goods/api'
import {IGoodMpCardUniversalAttribute} from '../../../store/mpcards/types'
import Yup from './../../../../setup/validation/yup' // I think `import * as yup from "yup"` also works (it's from author on smth forum)
import {createIntl, createIntlCache} from 'react-intl'
import {IUniversalDynamicFieldType} from '../../../store/marketplaces/types'
import {IWarehouseResponse} from '../../../store/warehouses/types'

const I18N_CONFIG_KEY = process.env.REACT_APP_I18N_CONFIG_KEY || 'i18nConfig'
const ls: any = localStorage.getItem(I18N_CONFIG_KEY) ?? 'ru'
const cache = createIntlCache()
const messages: any = allMessages
export const intl = createIntl(
  {
    locale: 'ru-RU',
    messages: messages[ls],
  },
  cache
)

const {lazy, object, string, array} = Yup
const NO_VALUE = intl.formatMessage({id: 'VALIDATION.GOODS.NOVALUE'})

Yup.setLocale({
  object: {
    noUnknown: intl.formatMessage({id: 'VALIDATION.GOODS.UNKNOWN'}),
  },
  string: {
    max: intl.formatMessage({id: 'VALIDATION.GOODS.MAXSYMBOLS'}),
  },
  number: {
    max: intl.formatMessage({id: 'VALIDATION.GOODS.MAXVALUE'}),
  },
  mixed: {
    required: NO_VALUE,
    notType: NO_VALUE, // nullable
  },
  array: {
    min: intl.formatMessage({id: 'VALIDATION.GOODS.NOVALUE'}),
  },
})

export const priceSchema = Yup.object().shape({
  current: Yup.number().min(0, intl.formatMessage({id: 'VALIDATION.GOODS.VALUELESSZERO'})),
  old: Yup.number().min(0, intl.formatMessage({id: 'VALIDATION.GOODS.VALUELESSZERO'})),
  premium: Yup.number().min(0, intl.formatMessage({id: 'VALIDATION.GOODS.VALUELESSZERO'})),
})

const StringValue = Yup.string().min(1, NO_VALUE).nullable(false).required(NO_VALUE)

const LazyStringValue = lazy((item: IGoodAttributeValueResponse) => {
  return Yup.object().shape({
    value: StringValue,
  })
})

const BooleanValue = Yup.boolean().required(NO_VALUE)
const LazyBoolValue = lazy((item: IGoodAttributeValueResponse) => {
  return Yup.object().shape({
    value: BooleanValue,
  })
})

const NumberValue = Yup.number()
  .nullable(false)
  .min(0, intl.formatMessage({id: 'VALIDATION.GOODS.LESSZERO'}))
  .required(NO_VALUE)
const LazyNumberValue = lazy((item: IGoodAttributeValueResponse) => {
  return Yup.object().shape({
    value: NumberValue,
  })
})

const ItemsArrayValue = (type: any, field: IGoodFieldAttribute) => {
  if (field.id >= 0)
    return Yup.object().shape({
      items: Yup.array().required(NO_VALUE).nullable(false).min(1, NO_VALUE).of<any>(type),
    })
  else
    return Yup.object().shape({
      items: Yup.array()
        .max(-field.id, intl.formatMessage({id: 'VALIDATION.GOODS.MAXVALUES'}))
        .required(NO_VALUE)
        .nullable(false)
        .min(1, NO_VALUE)
        .of<any>(type),
    })
}

const ItemsObjectValue = (type: any, field: IGoodFieldAttribute) => {
  return Yup.object().shape({
    items: ValuesObject(type),
  })
}

const ValuesObject = (type: any) => {
  return Yup.object().nullable(false).shape({
    value: type,
  })
}

const LazyObject = lazy((field: IGoodFieldAttribute) => {
  // try{
  if (field.is_required) {
    switch (field.type) {
      case 'URL':
      case 'multiline':
      case 'String': {
        if (field.is_collection)
          // if (field.dictionary_id > 0)
          return ItemsArrayValue(LazyStringValue, field)
        else return ItemsObjectValue(StringValue, field)
      }
      case 'Boolean': {
        if (field.is_collection)
          // if (field.dictionary_id > 0)
          return ItemsArrayValue(LazyBoolValue, field)
        else return ItemsObjectValue(BooleanValue, field)
      }
      case 'Decimal':
      case 'Integer': {
        if (field.is_collection)
          // if (field.dictionary_id > 0)
          return ItemsArrayValue(LazyNumberValue, field)
        else return ItemsObjectValue(NumberValue, field)
      }

      default: {
        return Yup.object().nullable()
      }
    }
  } else {
    return Yup.object().nullable()
  }
})

export const stockSchema: any = Yup.object({
  reserved: Yup.number()
    .required(intl.formatMessage({id: 'VALIDATION.GOODS.REQUIRED'}))
    .integer(intl.formatMessage({id: 'VALIDATION.GOODS.INTEGER'}))
    .min(0, intl.formatMessage({id: 'VALIDATION.GOODS.COUNTLESSZERO'}))
    .label('COMPONENTS.GOODS.CARD.TABS.INFORMATION.MASS'),
  quantity: Yup.number()
    .required(intl.formatMessage({id: 'VALIDATION.GOODS.REQUIRED'}))
    .integer(intl.formatMessage({id: 'VALIDATION.GOODS.INTEGER'}))
    .min(0, intl.formatMessage({id: 'VALIDATION.GOODS.COUNTLESSZERO'}))
    .label('COMPONENTS.GOODS.CARD.TABS.INFORMATION.MASS'),
})

export const goodItemSchemas: any = {
  INFORMATION: Yup.object({
    name: Yup.lazy((value: any) => {
      const res = Yup.string()
        .required(intl.formatMessage({id: 'VALIDATION.GOODS.REQUIRED'}))
        .max(300) //.label("COMPONENTS.GOODS.CARD.TABS.INFORMATION.NAME.PLACEHOLDER")
      return res
    }),
    customer_uid: Yup.string().min(1).max(60).required(),
    // category: Yup.object().shape({
    //     id: Yup.number()
    //         .min(0, intl.formatMessage({id: "VALIDATION.GOODS.CATEGORYNOTSELECTED"}))
    //         .required(intl.formatMessage({id: "VALIDATION.GOODS.CATEGORYNOTSELECTED"})),
    // }),
    category: Yup.mixed().test(
      'test-id',
      intl.formatMessage({id: 'VALIDATION.GOODS.CATEGORYNOTSELECTED'}),
      function (value) {
        if (value && value.id >= 0) {
          return true
        } else {
          return false
        }
      }
    ),
    parent: Yup.object().nullable(),
    description: Yup.string()
      .nullable()
      .max(1000, intl.formatMessage({id: 'VALIDATION.GOODS.MAX1000'})),
    isoffer: Yup.boolean()
      .nullable(false)
      .label(intl.formatMessage({id: 'VALIDATION.GOODS.SELECTVALUE'})),
    weight: Yup.number()
      .required(intl.formatMessage({id: 'VALIDATION.GOODS.REQUIRED'}))
      .min(15, intl.formatMessage({id: 'VALIDATION.GOODS.MINMASS15'}))
      .max(300000, intl.formatMessage({id: 'VALIDATION.GOODS.MAXMASS300K'})),
    height: Yup.number()
      .required(intl.formatMessage({id: 'VALIDATION.GOODS.REQUIRED'}))
      .min(10, intl.formatMessage({id: 'VALIDATION.GOODS.MINHEIGHT10MM'}))
      .max(10000, intl.formatMessage({id: 'VALIDATION.GOODS.MAXHEIGHT10M'})),
    width: Yup.number()
      .required(intl.formatMessage({id: 'VALIDATION.GOODS.REQUIRED'}))
      .min(10, intl.formatMessage({id: 'VALIDATION.GOODS.MINWIDTH10MM'}))
      .max(10000, intl.formatMessage({id: 'VALIDATION.GOODS.MAXWIDTH10M'})),
    depth: Yup.number()
      .required(intl.formatMessage({id: 'VALIDATION.GOODS.REQUIRED'}))
      .min(10, intl.formatMessage({id: 'VALIDATION.GOODS.MINLENGTH10MM'}))
      .max(10000, intl.formatMessage({id: 'VALIDATION.GOODS.MAXLENGTH10M'})),
    images: Yup.array(),
  }),
  OZONCARD: Yup.object({
    variableFields: Yup.array().of<any>(LazyObject),
    wbDynamicFields: Yup.array().of<any>(LazyObject),
  }),
  TRADEOFFER: Yup.object({
    prices: Yup.object().nullable(),
  }),
  PRICEMONITORING: Yup.object({
    goodName: Yup.string().required(),
  }),
}

const UniversalAttributeObject = lazy((field: IGoodMpCardUniversalAttribute) => {
  // try{
  if (field && field.required) {
    switch (field.value_type) {
      case 'URL':
      case 'ImageURL':
      case 'multiline':
      case 'String': {
        return (
          Yup.object(
            // return Yup.array(
            {
              value: Yup.array()
                // .min(1, intl.formatMessage({id: "VALIDATION.GOODS.REQUIRED"}))

                // .min(1, "массив пустой!")
                // .length(1, "Не заполнено значение")
                .of(
                  Yup.object({
                    value: Yup.string()
                      .required(intl.formatMessage({id: 'VALIDATION.GOODS.REQUIRED'}))
                      // .required("абизатина")
                      // .length(1, "Заполните значение")
                      .nullable(false),
                  })
                )
                .min(1, intl.formatMessage({id: 'VALIDATION.GOODS.REQUIRED'})),
              // .min(1, "обз")
              // .required("обязательно")
            }
          )
            // .min(0, "заполните значение")
            .nullable(false)
        )
      }
      case 'Boolean': {
        return Yup.object({
          value: Yup.array()
            .min(1, intl.formatMessage({id: 'VALIDATION.GOODS.REQUIRED'}))
            .of(
              Yup.object({
                value: Yup.string()
                  .required(intl.formatMessage({id: 'VALIDATION.GOODS.REQUIRED'}))
                  .nullable(false),
              })
            )
            .min(1, intl.formatMessage({id: 'VALIDATION.GOODS.REQUIRED'})),
        }).nullable(false)
      }
      case 'Decimal':
      case 'Integer': {
        return Yup.object({
          value: Yup.array()
            .min(1, intl.formatMessage({id: 'VALIDATION.GOODS.REQUIRED'}))
            .of(
              Yup.object({
                count: Yup.string()
                  .required(intl.formatMessage({id: 'VALIDATION.GOODS.REQUIRED'}))
                  .nullable(false),
              })
            )
            .min(1),
        }).nullable(false)
      }

      default: {
        return Yup.object().nullable()
      }
    }
  } else {
    return Yup.object().nullable()
  }
})

export const universalMpCardSchema = Yup.object({
  attributes: Yup.array().of<any>(UniversalAttributeObject),
})

export const profileDetailsSchema = Yup.object().shape({
  name: Yup.string().required(
    intl.formatMessage({id: 'ACCOUNT.TABS.SETTINGS.CUSTOMERACCOUNT.VALIDATION.COMPANYNAME'})
  ),
  inn: Yup.number().required(
    intl.formatMessage({id: 'ACCOUNT.TABS.SETTINGS.CUSTOMERACCOUNT.VALIDATION.INN'})
  ),
  kpp: Yup.number().required(
    intl.formatMessage({id: 'ACCOUNT.TABS.SETTINGS.CUSTOMERACCOUNT.VALIDATION.KPP'})
  ),
  account_number: Yup.string().required(
    intl.formatMessage({id: 'ACCOUNT.TABS.SETTINGS.CUSTOMERACCOUNT.VALIDATION.ACCOUNTNUMBER'})
  ),
  address: Yup.string().required(
    intl.formatMessage({id: 'ACCOUNT.TABS.SETTINGS.CUSTOMERACCOUNT.VALIDATION.ADDRESS'})
  ),
  bank_name: Yup.string().required(
    intl.formatMessage({id: 'ACCOUNT.TABS.SETTINGS.CUSTOMERACCOUNT.VALIDATION.BANKNAME'})
  ),
  bik: Yup.string().required(
    intl.formatMessage({id: 'ACCOUNT.TABS.SETTINGS.CUSTOMERACCOUNT.VALIDATION.BIK'})
  ),
  kor_account: Yup.string().required(
    intl.formatMessage({id: 'ACCOUNT.TABS.SETTINGS.CUSTOMERACCOUNT.VALIDATION.KORACCOUNT'})
  ),
  podpisant: Yup.string().required(
    intl.formatMessage({id: 'ACCOUNT.TABS.SETTINGS.CUSTOMERACCOUNT.VALIDATION.PODPISANT'})
  ),
})

export const accountDetailsSchema = Yup.object().shape({
  name: Yup.string().required(
    intl.formatMessage({id: 'ACCOUNT.TABS.SETTINGS.CUSTOMERACCOUNT.ERRORS.ACCOUNTNAME'})
  ),
  company: Yup.object()
    .nullable(false)
    .shape({
      id: Yup.number()
        .required(intl.formatMessage({id: 'ACCOUNT.TABS.SETTINGS.CUSTOMERACCOUNT.ERRORS.CUSTOMER'}))
        .min(0, 'Не выбран аккаунт'),
    }),
  // company: Yup.number().required(intl.formatMessage({ id: "ACCOUNT.TABS.SETTINGS.CUSTOMERACCOUNT.ERRORS.CUSTOMER" }))
})

//   id: number | null
//     name :string
//     description: string
//     value: IGoodMpCardUniversalValue[]
//     dict_link: string //link to dictioanary values (select options). add parameter  '?query=search phrase' to filter dictionary values
//     only_dict: boolean // if true (default), there values only from dictionary (false - you can add your own values)
//     value_type: IGoodFieldAttributeType
//     allow_multiply: boolean // allow multiple value
//     variants?: IGoodMpCardUniversalVariant[] | null
//     required: boolean //is required field
//     read_only: boolean // is readonly field. can't edit it
//     hidden: boolean  // do not show field
//     complex: boolean // if true, then value { value and count will be required}
//     tag: string
//     address: string
//     code: string
// ).shape(
export const accountFieldsSchema = Yup.array(
  Yup.object({
    value: Yup.lazy((field: IUniversalDynamicFieldType) => {
      if (field.required) {
        if (!field.value) {
          return Yup.object().nullable(false)
        }
        switch (field.value_type) {
          case 'ImageURL':
          case 'URL':
          case 'multiline':
          case 'String': {
            if (Array.isArray(field.value)) {
              return Yup.array(
                Yup.object().shape({
                  value: Yup.string().nullable(false),
                })
              )
            } else {
              return Yup.string().nullable(false)
            }
          }
          case 'Decimal':
          case 'Integer': {
            if (Array.isArray(field.value)) {
              return Yup.array(
                Yup.object().shape({
                  value_id: Yup.number().nullable(false),
                })
              )
            } else {
              return Yup.number().nullable(false)
            }
          }
          case 'Boolean':
            return Yup.boolean()
          default:
            return Yup.string()
        }
      } else return Yup.object()
    }),
    // company: Yup.number().required(intl.formatMessage({ id: "ACCOUNT.TABS.SETTINGS.CUSTOMERACCOUNT.ERRORS.CUSTOMER" }))
  })
)

export const validateAccountFields = (fields: IUniversalDynamicFieldType[]) => {
  let errors: any = {}
  fields.forEach((field) => {
    if (field.required) {
      if (!isNullOrUndefined(field.value)) {
        if (Array.isArray(field.value)) {
          switch (field.value_type) {
            case 'URL':
            case 'multiline':
            case 'ImageURL':
            case 'String':
              {
                if (
                  !field.value.length ||
                  !field.value[0] ||
                  !field.value[0].value ||
                  !field.value[0].value.length
                ) {
                  errors[field.code] = `Заполните поле ${field.name}`
                }
              }
              break
            case 'Decimal':
            case 'Integer':
              {
                if (!field.value.length || !field.value[0] || !field.value[0].value_id) {
                  errors[field.code] = `Заполните поле ${field.name}`
                }
              }
              break
            default: {
              if (
                !field.value.length ||
                !field.value[0] ||
                !field.value[0].value ||
                !field.value[0].value.length
              ) {
                errors[field.code] = `Заполните поле ${field.name}`
              }
            }
          }
        } else {
          switch (typeof field.value) {
            case 'boolean':
              {
                if (isNullOrUndefined(field.value)) {
                  errors[field.code] = `Заполните поле ${field.name}`
                }
              }
              break
            case 'string':
              {
                if (!(field.value as string)?.length) {
                  errors[field.code] = `Заполните поле ${field.name}`
                }
              }
              break
            case 'number':
              {
                if (!(field.value as number)?.toString()?.length) {
                  errors[field.code] = `Заполните поле ${field.name}`
                }
              }
              break
          }
        }
      } else {
        errors[field.code] = `Заполните поле ${field.name}`
      }
    }
  })
  // return accountFieldsSchema.validate(fields)
  return errors
}

export const editUserDetailsSchema = Yup.object().shape({
  firstname: Yup.string().required(
    intl.formatMessage({id: 'ACCOUNT.TABS.SETTINGS.USERS.ERRORS.FIRSTNAME'})
  ),
  phone: Yup.string()
    .min(11, intl.formatMessage({id: 'AUTH.LOGIN.VALIDATION.PHONE11SYMBOLS'}))
    .max(13, intl.formatMessage({id: 'AUTH.LOGIN.VALIDATION.MAX13SYMBOLS'}))
    .required(intl.formatMessage({id: 'AUTH.LOGIN.VALIDATION.PHONE11SYMBOLS'}))
    .matches(/^\+?[1-9]\d{1,14}$/, {
      message: intl.formatMessage({id: 'VALIDATION.SETTINGS.PHONE.WRONGFORMAT'}),
    }),
  // Yup.string().required(intl.formatMessage({ id: "ACCOUNT.TABS.SETTINGS.USERS.ERRORS.PHONE" })),
  // firstname: Yup.string().length(0,"строка пустая"),
  // phone: Yup.string().length(0, "ошибка телефона"),
})

export const linkUserDetailsSchema = Yup.object().shape({
  email: Yup.string().required(
    intl.formatMessage({id: 'ACCOUNT.TABS.SETTINGS.USERS.ERRORS.EMAIL'})
  ),
})

export const warehouseDetailsSchema = Yup.object().shape({
  name: Yup.string().required(
    intl.formatMessage({id: 'ACCOUNT.TABS.SETTINGS.WAREHOUSES.VALIDATION.NAME'})
  ),
})

export const tradeOfferSchema = Yup.object().shape({
  name: Yup.string().required(
    intl.formatMessage({id: 'COMPONENTS.GOODS.CARD.TABS.OFFERS.VALIDATION.NAME'})
  ),
})

export const priceTypeDetailsSchema = Yup.object().shape({
  name: Yup.string().required(
    intl.formatMessage({id: 'ACCOUNT.TABS.SETTINGS.PRICETYPES.VALIDATION.NAME'})
  ),
  code: Yup.string().required(
    intl.formatMessage({id: 'ACCOUNT.TABS.SETTINGS.PRICETYPES.VALIDATION.CODE'})
  ),
  description: Yup.string().required(
    intl.formatMessage({id: 'ACCOUNT.TABS.SETTINGS.PRICETYPES.VALIDATION.DESCRIPTION'})
  ),
})

export const goodsGroupDetailsSchema = Yup.object().shape({
  name: Yup.string().required(
    intl.formatMessage({id: 'COMPONENTS.GOODS.CARD.TABS.OFFERS.VALIDATION.NAME'})
  ),
})

export const deliveryAccountDetailSchema = Yup.object().shape({
  name: Yup.string().required(
    intl.formatMessage({id: 'ACCOUNT.TABS.SETTINGS.DELIVERYACCOUNTS.VALIDATION.NAME'})
  ),
  region: Yup.object().shape({
    id: Yup.number().min(
      1,
      intl.formatMessage({id: 'ACCOUNT.TABS.SETTINGS.DELIVERYACCOUNTS.VALIDATION.REGION'})
    ),
  }),
  params: Yup.object().shape({
    client_id: Yup.string().required(
      intl.formatMessage({id: 'ACCOUNT.TABS.SETTINGS.DELIVERYACCOUNTS.VALIDATION.CLIENTID'})
    ),
    client_secret: Yup.string().required(
      intl.formatMessage({id: 'ACCOUNT.TABS.SETTINGS.DELIVERYACCOUNTS.VALIDATION.SECRET'})
    ),
    extra_days_for_pvz: Yup.number()
      .min(
        0,
        intl.formatMessage({
          id: 'ACCOUNT.TABS.SETTINGS.DELIVERYACCOUNTS.VALIDATION.EXTRADAYSFORPVZ',
        })
      )
      .max(
        31,
        intl.formatMessage({
          id: 'ACCOUNT.TABS.SETTINGS.DELIVERYACCOUNTS.VALIDATION.EXTRADAYSFORPVZ',
        })
      ),
    extra_days_for_courier: Yup.number()
      .min(
        0,
        intl.formatMessage({
          id: 'ACCOUNT.TABS.SETTINGS.DELIVERYACCOUNTS.VALIDATION.EXTRADAYSFORCOURIER',
        })
      )
      .max(
        31,
        intl.formatMessage({
          id: 'ACCOUNT.TABS.SETTINGS.DELIVERYACCOUNTS.VALIDATION.EXTRADAYSFORCOURIER',
        })
      ),
    extra_days_after_holidays: Yup.number()
      .required(
        intl.formatMessage({
          id: 'ACCOUNT.TABS.SETTINGS.DELIVERYACCOUNTS.VALIDATION.EXTRADAYSAFTERHOLIDAYS',
        })
      )
      .min(
        0,
        intl.formatMessage({
          id: 'ACCOUNT.TABS.SETTINGS.DELIVERYACCOUNTS.VALIDATION.EXTRADAYSAFTERHOLIDAYS.VALUE',
        })
      )
      .max(
        31,
        intl.formatMessage({
          id: 'ACCOUNT.TABS.SETTINGS.DELIVERYACCOUNTS.VALIDATION.EXTRADAYSAFTERHOLIDAYS.VALUE',
        })
      ),
    custom_delivery_time: Yup.array().of(
      Yup.object().shape({
        region: Yup.object().shape({
          id: Yup.number().min(
            1,
            intl.formatMessage({
              id: 'ACCOUNT.TABS.SETTINGS.DELIVERYACCOUNTS.VALIDATION.CUSTOMDELIVERYTIME.REGION',
            })
          ),
        }),
        dayEnd: Yup.number()
          .min(
            0,
            intl.formatMessage({
              id: 'ACCOUNT.TABS.SETTINGS.DELIVERYACCOUNTS.VALIDATION.CUSTOMDELIVERYTIME.DAYS.VALUE',
            })
          )
          .max(
            31,
            intl.formatMessage({
              id: 'ACCOUNT.TABS.SETTINGS.DELIVERYACCOUNTS.VALIDATION.CUSTOMDELIVERYTIME.DAYS.VALUE',
            })
          )
          .required(
            intl.formatMessage({
              id: 'ACCOUNT.TABS.SETTINGS.DELIVERYACCOUNTS.VALIDATION.CUSTOMDELIVERYTIME.DAYS',
            })
          ),
        dayStart: Yup.number()
          .min(
            0,
            intl.formatMessage({
              id: 'ACCOUNT.TABS.SETTINGS.DELIVERYACCOUNTS.VALIDATION.CUSTOMDELIVERYTIME.DAYS.VALUE',
            })
          )
          .max(
            31,
            intl.formatMessage({
              id: 'ACCOUNT.TABS.SETTINGS.DELIVERYACCOUNTS.VALIDATION.CUSTOMDELIVERYTIME.DAYS.VALUE',
            })
          )
          .required(
            intl.formatMessage({
              id: 'ACCOUNT.TABS.SETTINGS.DELIVERYACCOUNTS.VALIDATION.CUSTOMDELIVERYTIME.DAYS',
            })
          ),
      })
    ),
  }),
})

export const generateAnswerShema = Yup.object().shape({
  good_name: Yup.string().required(intl.formatMessage({id: 'ANSWERGENERATE.VALIDATION.GOODNAME'})),
  rating: Yup.string().required(intl.formatMessage({id: 'ANSWERGENERATE.VALIDATION.RATING'})),
})

export const feedbackSettingsShema = Yup.object().shape({
  ai_text: Yup.string().required(intl.formatMessage({id: 'ANSWERGENERATE.VALIDATION.NEURAL'})),
})

export const AcceptanceRatesSettingsShema = Yup.object().shape({
  name: Yup.string().required(intl.formatMessage({id: 'ANSWERGENERATE.VALIDATION'})),
  max_coefficient_box: Yup.number()
    .integer(intl.formatMessage({id: 'ACCEPTANCE.RATES.COEFICIENTS.VALIDATION'}))
    .min(0, intl.formatMessage({id: 'ACCEPTANCE.RATES.COEFICIENTS.VALIDATION'})),
  max_coefficient_monopallet: Yup.number()
    .integer(intl.formatMessage({id: 'ACCEPTANCE.RATES.COEFICIENTS.VALIDATION'}))
    .min(0, intl.formatMessage({id: 'ACCEPTANCE.RATES.COEFICIENTS.VALIDATION'})),
  max_coefficient_supersafe: Yup.number()
    .integer(intl.formatMessage({id: 'ACCEPTANCE.RATES.COEFICIENTS.VALIDATION'}))
    .min(0, intl.formatMessage({id: 'ACCEPTANCE.RATES.COEFICIENTS.VALIDATION'})),
})

export const suppliesShema = Yup.object().shape({
  supply_id: Yup.object()
    .shape({
      supplyId: Yup.number().required(intl.formatMessage({id: 'EMPTY.FIELDS.VALIDATION'})),
    })
    .test('unique-supply-id', 'Поставка уже используется', function (value) {
      return value.message !== 'OK' ? false : true
    }),
  account: Yup.object().shape({
    name: Yup.string().required(intl.formatMessage({id: 'EMPTY.FIELDS.VALIDATION'})),
  }),
  max_rate: Yup.number().required(intl.formatMessage({id: 'EMPTY.FIELDS.VALIDATION'})),
  planing_supply_dates: Yup.array().min(
    1,
    intl.formatMessage({id: 'ACCEPTANCE.RATES.SUPPLYDATE.VALIDATION'})
  ),
})

export const newAccountShema = Yup.object().shape({
  accountName: Yup.string().max(50, intl.formatMessage({id: 'ACCOUNT.VALIDATION.FIELDS.LENGTH'})),
  authToken: Yup.string().max(50, intl.formatMessage({id: 'ACCOUNT.VALIDATION.FIELDS.LENGTH'})),
  vendorCode: Yup.string().max(50, intl.formatMessage({id: 'ACCOUNT.VALIDATION.FIELDS.LENGTH'})),
  validationСode: Yup.string().max(
    50,
    intl.formatMessage({id: 'ACCOUNT.VALIDATION.FIELDS.LENGTH'})
  ),
})

export const GoodGroupShema = Yup.object().shape({
  name: Yup.string().required(intl.formatMessage({id: 'VALIDATION.GOODS.GROUPS.NONAME'})),
  params: Yup.object().shape({
    price: Yup.object().shape({
      gt: Yup.number().required(intl.formatMessage({id: 'EMPTY.FIELDS.VALIDATION'})),
      lt: Yup.number().required(intl.formatMessage({id: 'EMPTY.FIELDS.VALIDATION'})),
    }),
    stock: Yup.object().shape({
      gt: Yup.number().required(intl.formatMessage({id: 'EMPTY.FIELDS.VALIDATION'})),
      lt: Yup.number().required(intl.formatMessage({id: 'EMPTY.FIELDS.VALIDATION'})),
    }),
    skus_customer_uid: Yup.string().matches(/^(\d+(?:-\d+)?,)*(\d+(?:-\d+)?)?$/, {
      message: 'Неверный формат артикула',
    }),
    goods_customer_uid: Yup.array().of(
      Yup.string().matches(/^(\d+(?:-\d+)?,)*(\d+(?:-\d+)?)?$/, {
        message: 'Неверный формат артикула',
      })
    ),
    excluded: Yup.object().shape({
      skus_customer_uid: Yup.string().matches(/^(\d+(?:-\d+)?,)*(\d+(?:-\d+)?)?$/, {
        message: 'Неверный формат артикула',
      }),
      goods_customer_uid: Yup.array().of(
        Yup.string().matches(/^(\d+(?:-\d+)?,)*(\d+(?:-\d+)?)?$/, {
          message: 'Неверный формат артикула',
        })
      ),
    }),
  }),
})
