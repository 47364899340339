import axios from 'axios'
import {IFilterParameters} from '../../components/lists/Filter'
import {IUrlParameters} from '../../components/lists/UrlFilteredList'
import {DEFAULT_LIMIT, DEFAULT_PAGE} from '../../modules/goods/GoodsList'
import {PagedData} from '../types'
import {IUniversalCategoriesParams} from '../categories/types'
import {IGoodTradeOffer} from '../../modules/goods/types'
import {
  IGoodMpCardUniversal,
  IGoodMpCardUniversalRequest,
  IMPCardExtended,
  IMpCardItemPublishParams,
  IMpCardItemRequestParams,
  IMpCardListRequestParams,
  IMpCardListResponse,
  IMpCardMatchResponse,
  IMpCardMatchSaveRequest,
  IMpCardTemplate,
  IMpCardTemplateCreateParams,
  IMpCardTemplateGetParams,
  IMpCardUniversalAttributeValue,
  INewMpCardParams,
} from './types'

const API_URL = `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENDPOINT_PREFIX}`
const API_URL_ROOT = `${process.env.REACT_APP_API_URL}`
export const OFFERS_URL = `${API_URL}v2/offers/`
export const OFFERMPCARDS_URL = `${API_URL}v2/offers/mpcards/`
export const MPCARDS_TEMPLATES_URL = `${API_URL}v2/mpcards_templates/`
export const AUTOCOMPLETE_URL = `${API_URL}v2/`
export const MPCARDS_URL = `${API_URL}v2/mpcards/`
export const GOODS_URL = `${API_URL}v2/goods/`

// =================   get mpcards =================
// #region
// https://api-dev.shop-delivery.ru/int_api/v2/offers/mpcards/

// get all stocks from server
export const getMpCardsFromServer = (params: IMpCardListRequestParams) => {
  return axios.get<IMpCardListResponse>(
    `${OFFERMPCARDS_URL}?limit=${params.limit ?? 0}&offset=${params.offset ?? 0}&ordering=${
      params.ordering ?? ''
    }&search=${params.search ?? ''}${params.account ? '&account=' + params.account : ''}${
      params.company ? '&company=' + params.company : ''
    }${params.good_name ? '&good_name=' + params.good_name : ''}${
      params.good_id ? '&good_id=' + params.good_id : ''
    }${params.good_uid ? '&good_uid=' + params.good_uid : ''}${
      params.marketplace ? '&marketplace=' + params.marketplace : ''
    }${params.mpcard_id ? '&mpcard_id=' + params.mpcard_id : ''}${
      params.name ? '&name=' + params.name : ''
    }${params.sku_id ? '&sku_id=' + params.sku_id : ''}`
  )
}
export const getMpCardsFromServer2 = (params: IUrlParameters) => {
  const accountId = params.additionalParameters?.find((ap) => ap.label == 'account')?.value ?? -1
  const marketplaceId =
    params.additionalParameters?.find((ap) => ap.label == 'marketplace')?.value ?? -1
  return axios.get<IMpCardListResponse>(
    `${OFFERMPCARDS_URL}?limit=${params.itemsPerPage ?? 0}&offset=${
      params.itemsPerPage * params.pageNumber ?? 0
    }&ordering=${params.ordering ?? ''}&search=${params.filter ?? ''}${
      accountId != -1 ? '&account=' + accountId : ''
    }${marketplaceId != -1 ? '&marketplace=' + marketplaceId : ''}`
  )
}

// #endregion

// =================   get offers mpcard =================
// #region
// https://api-dev.shop-delivery.ru/int_api/v2/offers/mpcards/34853/

// get all stocks from server
export const getOfferMpCardFromServer = (params: IMpCardItemRequestParams) => {
  return axios.get<IGoodTradeOffer>(`${OFFERMPCARDS_URL}${params.id}`)
}

// #endregion

// =================   get mpcard =================
// #region
// https://api-dev.shop-delivery.ru/int_api/v2/mpcards/34853/

// get mpcard from server
export const getMpCardFromServer = (params: IMpCardItemRequestParams) => {
  return axios.get<IMPCardExtended>(`${MPCARDS_URL}${params.id}`)
}

// #endregion

// =================   publish mpcard =================
// #region
// https://api-dev.shop-delivery.ru/int_api/v2/mpcards/publish_mpcard/

// publish mpcard
export const publishMpCardOnMarketplace = (params: IMpCardItemPublishParams) => {
  return axios.post(`${MPCARDS_URL}publish_mpcard/`, {mpcard_id: params.id, action: params.action})
}

// #endregion

// =================   get universal mpcard =================
// #region
// https://api-dev.shop-delivery.ru/int_api/v2/mpcards/34853/get_universal_card/

// get universal mpcard from server
export const getOfferUniversalMpCardFromServer = (params: number) => {
  return axios.get<IGoodMpCardUniversal>(
    `${MPCARDS_URL}${params}/get_universal_card/?with_offers=true`
  )
}

// #endregion

// =================   set universal mpcard =================
// #region
// https://api-dev.shop-delivery.ru/int_api/v2/mpcards/34853/set_universal_card/

// set universal mpcard to server
export const setOfferUniversalMpCardToServer = (params: IGoodMpCardUniversalRequest) => {
  const data: IGoodMpCardUniversal = {
    account_id: params.card.account_id,
    id: params.card.id,
    attributes: params.card.attributes,
    has_offers: params.card.has_offers,
    images: params.card.images,
    variants: params.card.variants,
    category: params.card.category,
    marketplace: params.card.marketplace,
    show_attributes_in_variants: params.card.show_attributes_in_variants,
  }
  return axios.post<IGoodMpCardUniversal>(`${MPCARDS_URL}${params.id}/post_universal_card/`, data)
  // return params.card
}

// #endregion

// =================   create universal mpcard =================
// #region
// https://api-dev.shop-delivery.ru/int_api/v2/mpcards/save_universal_cards/

// create universal mpcard on server
export const saveUniversalMpCardToServer = (params: INewMpCardParams) => {
  // let parmeters: INewMpCardParams = params

  // delete parmeters.payload.id
  // const data: INewMpCardParams = {
  const data = {
    account_id: params.account_id,
    good_id: params.good_id,
    sku_id: params.sku_id,
    category_id: params.payload.category?.id ?? -1,
    payload: {
      account_id: params.payload.account_id,
      attributes: params.payload.attributes,
      has_offers: params.payload.has_offers,
      images: params.payload.images,
      variants: params.payload.variants,
      category: params.payload.category,
      marketplace: params.payload.marketplace,
      show_attributes_in_variants: params.payload.show_attributes_in_variants,
    },
    params: {},
  }
  return axios.post<IGoodMpCardUniversal>(`${MPCARDS_URL}save_universal_card/`, data)
}

// #endregion

// =================   get universal mpcard templates =================
// #region
// https://api-dev.shop-delivery.ru/int_api/v2/mpcards_templates/

// get universal mpcard templates from server
export const getUniversalMpCardTemplatesFromServer = (params: IMpCardTemplateGetParams | null) => {
  return axios.get<IMpCardTemplate[]>(
    `${MPCARDS_TEMPLATES_URL}${
      params
        ? `?${params.limit ? `limit=${params.limit}` : 'limit=10'}${
          params.good ? `&good=${params.good}` : ''
        }${params.marketplace ? `&marketplace=${params.marketplace}` : ''}${
          params.offset ? `&offset=${params.offset}` : ''
        }${params.ordering ? `&ordering=${params.ordering}` : ''}${
          params.search ? `&search=${params.search}` : ''
        }`
        : ''
    }`
  )
}

// #endregion

// =================   create universal mpcard template =================
// #region
// https://api-dev.shop-delivery.ru/int_api/v2/mpcards_templates/create_template/

// create universal mpcard template in server
export const createUniversalMpCardTemplateInServer = (params: IMpCardTemplateCreateParams) => {
  return axios.post(`${MPCARDS_TEMPLATES_URL}create_template/`, params)
}

// #endregion

// =================   delete universal mpcard template =================
// #region
// https://api-dev.shop-delivery.ru/int_api/v2/mpcards_templates/34853/delete_template/

// delete universal mpcard template from server
export const deleteUniversalMpCardTemplateInServer = (params: number) => {
  return axios.delete(`${MPCARDS_TEMPLATES_URL}${params}/delete_template/`)
}

// #endregion

// =================   update universal mpcard template =================
// #region
// https://api-dev.shop-delivery.ru/int_api/v2/mpcards_templates/34853/update_template/

// update universal mpcard template on server
export const updateUniversalMpCardTemplateInServer = (params: IMpCardTemplate) => {
  const updateParams: IMpCardTemplateCreateParams = {
    good_id: params.good.id,
    marketplace_id: params.marketplace,
    name: params.name,
    params: params.params,
    payload: params.payload,
  }
  return axios.put(`${MPCARDS_TEMPLATES_URL}${params.id}/update_template/`, updateParams)
}

// #endregion

// =================   get mpcard select field options =================
// #region
// https://api-dev.shop-delivery.ru/int_api/v2/mpcards/34853/get_universal_card/

/// isRootUrl. if ==true, then use only root site name (without path)
export const getMpCardUniversalAttributeValues = (path: string, isRootUrl?: boolean) => {
  return path?.length && path != 'null' && path != 'undefined'
    ? axios.get<IMpCardUniversalAttributeValue[]>(
      `${isRootUrl ? API_URL_ROOT : AUTOCOMPLETE_URL}${path}`
    )
    : {data: []}
}

// generator
export function* getMpCardUniversalAttributeValuesGenerator(
  path: string,
  isRootUrl?: boolean
): Generator {
  const response = yield axios.get<IMpCardUniversalAttributeValue[]>(
    `${isRootUrl ? API_URL_ROOT : AUTOCOMPLETE_URL}${path}`
  )
  return response
}
// #endregion

// =================   get universal mpcard select field options =================
// #region
// http://api-dev.shop-delivery.ru/int_api/v2/mpcards/new_universal_card/?marketplace=1&mp_category=9113

export const getUniversalMpCardUniversalAttributeValues = (params: IUniversalCategoriesParams) => {
  return axios.get<IGoodMpCardUniversal>(
    `${MPCARDS_URL}new_universal_card/?marketplace=${params.marketplaceId}&mp_category=${
      params.categoryId
    }${params.sku_id != undefined ? `&sku_id=${params.sku_id}` : ``}`
  )
}
// #endregion

// =================   get unlinked mpcards =================
// #region
// https://api-dev.shop-delivery.ru/int_api/v2/mpcards/?unlinked=true

// get unlinked mpcards
// unlinnkedOff - if true, then request all mpcards (not only unlinked)
export const getUnlinkedMpCards = (params?: {
  marketplace?: string
  perPage?: number
  startFrom?: number
  filterPhrase?: string
  account?: number
  unlinkedOff?: boolean
}) => {
  const withUnlinked = params && params.unlinkedOff ? false : true
  return axios.get<{count: number; results: IMPCardExtended[]}>(
    `${MPCARDS_URL}?unlinked=${withUnlinked}${
      params && params?.marketplace != undefined ? '&marketplace=' + params.marketplace : ''
    }${params && params.account != undefined ? '&account=' + params.account : ''}${
      params?.filterPhrase ? `&search=${params?.filterPhrase}` : ''
    }${params?.startFrom ? `&offset=${params?.startFrom}` : ''}${
      params?.perPage ? `&limit=${params?.perPage}` : ''
    }`
  )
}

// #endregion

// =================   get unlinked mpcard info (matches) =================
// #region
// http://api-dev.shop-delivery.ru/int_api/v2/mpcards/{id}/matches/

// get mpcard matches
export const getMpCardMatches = (mpcardId: number) => {
  return axios.get<IMpCardMatchResponse>(`${MPCARDS_URL}${mpcardId}/matches/`)
}

// #endregion

// =================   save unlinked mpcard info (matches) =================
// #region
// http://api-dev.shop-delivery.ru/int_api/v2/mpcards/{id}/matches/

// save mpcard matches
export const saveMpCardMatches = (matchData: IMpCardMatchSaveRequest) => {
  return axios.post<IMpCardMatchSaveRequest>(
    `${AUTOCOMPLETE_URL}matches/post_mpcard_match/`,
    matchData
  )
}

// #endregion

// =================   get trade offers =================
// #region
// https://api-dev.shop-delivery.ru/int_api/v2/offers/?search=%D0%B0%D0%BA%D0%BA%D1%83%D0%BC%D1%83%D0%BB%D1%8F&limit=10

// TODO: it may be danger:
// get mpcard matches (if params.items == -1 => exclude this parameter from request and get all records)
export const getTradeOffers = (params: IFilterParameters) => {
  const page = params.pageNumber ?? DEFAULT_PAGE
  const items = params.itemsPerPage ?? DEFAULT_LIMIT
  const offset = page * items
  return axios.get<PagedData<IGoodTradeOffer>>(
    `${OFFERS_URL}?offset=${offset}${params.itemsPerPage != -1 ? '&limit=' + items : ''}&ordering=${
      params.ordering
    }&search=${params.filter}`
  )
}

export const getTradeOffersWithoutPagination = (query: string) => {
  return axios
    .get<PagedData<IGoodTradeOffer>>(`${OFFERS_URL}?search=${query}`)
    .then((res) => res.data?.results ?? [])
    .catch(() => [])
}
// #endregion