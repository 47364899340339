import { createAction } from "@reduxjs/toolkit";
import { IUrlParameters } from "../../components/lists/UrlFilteredList";
import { IMpDocBarcodeRequest, IMpDocListRequest, IMpDocsGetParams, mpdocActionTypes as actionTypes } from "./types";


export const ASYNC_GET_MPDOC = actionTypes.GetMpDoc
export const ASYNC_GET_STICKER = actionTypes.GetSticker
export const ASYNC_GET_STICKERS = actionTypes.GetStickers
export const ASYNC_GET_MPDOCS_WITH_FILTER = actionTypes.GetMpDocsWithFilter
export const SET_MPDOCS_REQUEST_PARAMETERS = actionTypes.SetRequestParameters

export const getAsyncMpDocsWithFilterCreator = createAction(ASYNC_GET_MPDOCS_WITH_FILTER);
export const getAsyncMpDocCreator = createAction<number>(ASYNC_GET_MPDOC);
// export const getAsyncMpDocStickerCreator = createAction<IMpDocBarcodeRequest>(ASYNC_GET_STICKER);
export const setMpDocsRequestParams = createAction<IUrlParameters>(SET_MPDOCS_REQUEST_PARAMETERS);


