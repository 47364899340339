import { ITEMS_PER_PAGE_DEFAULT } from "../goods/types"


export const DEFAULT_AUTOREFRESH_INTERVAL = 5000

export const emptyAutoRefresh: IAutoRefresh = {
    enabled: false,
    interval: DEFAULT_AUTOREFRESH_INTERVAL,
    workData: null,
    isWorking: false
}


export const emptyState: ITaskState = {
    items: [],
    selected: null,
    isLoading: false,
    count: 0,
    currentPage: 0,
    itemsPerPage: ITEMS_PER_PAGE_DEFAULT,
    formats: [],
    autoRefresh: {
        enabled: true,
        interval: DEFAULT_AUTOREFRESH_INTERVAL,
        workData: null,
        isWorking: false,
    }
}

export interface IImportXlsFormat {
    format: string
    name: string
    description: string
    file_example: string
    params: any
}

export interface ICommonListParams {
    limit: number
    offset: number
}

export const standartCommonListParams: ICommonListParams = {
    limit: 10,
    offset: 0
}

export interface IUserTaskResponse {
    count: number,
    results: IUserTask[]
}

export const emptyUserTask: IUserTask = {
    id: -1,
    finished: null,
    state: "",
    result: null,
    uuid: "",
    name: "",
    created: null,
    user: -1
}

export interface IUserTask {
    id: number
    finished: Date | string | null
    result: { errors: string[] | null } | null
    state: TTaskStateType
    uuid: string
    name: string
    created: Date | string | null
    user: number
}

export interface ITask {
    task_id: string
    description: string
}

let TaskTypeEnum: "" | "prices" | "cards"
export type TTaskType = typeof TaskTypeEnum

let StateTypeEnum:
    "FAILURE" |     // Завершено с ошибкой
    "PENDING" |     // Создано
    "RECEIVED" |   // Готово к работе
    "RETRY" |      // Повторный запуск
    "REVOKED" |    // - Аннулировано
    "STARTED" |    // - Запущено
    "SUCCESS" |    // Выполнено успешно
    ""
export type TTaskStateType = typeof StateTypeEnum

export interface IImportMpTaskRequest {
    object: TTaskType
    account_id: number
}

export interface IImportXlsTaskRequest {
    format: string
    fileData: any
}

export interface ITaskResponse {
    success: boolean
    errors?: string[]
    result?: ITask | null
}

export interface ITaskState {
    items: IUserTask[]
    count: number
    isLoading: boolean
    selected: ITask | null
    itemsPerPage?: number
    currentPage?: number
    formats: IImportXlsFormat[]
    autoRefresh: IAutoRefresh
}

export interface IAutoRefresh {
    enabled: boolean
    interval: number
    workData: any
    isWorking: boolean
}



export const taskActionTypes = {
    GetTasks: '[GetTasks] Action',
    SetRequestParams: '[SetRequestParams] Action',
    GetTask: '[GetTask] Action',
    AddImportMpTask: '[AddImportMpTask] Action',
    AddImportXlsTask: '[AddImportXlsTask] Action',
    GetImportXlsFormat: '[GetImportXlsFormat] Action',

    SetTimer: '[SetTimer] Action',
    SetRefreshData: '[SetRefreshData] Action', // tasks, which aren't in state 'success' or 'failure'
    CheckRefreshData: '[CheckRefreshData] Action', // 
    SetRefreshIsWorking: '[SetRefreshIsWorking] Action', // 
}