import {FC, useState, useRef} from 'react'
import {useSelector, useDispatch, shallowEqual} from 'react-redux'
import {useIntl} from 'react-intl'
import clsx from 'clsx'
import {autocompleteCompany} from '../../../../store/customerCompanys/api'

import ReactSelect from 'react-select'
import {Button} from 'react-bootstrap'
import {SelectInput} from '../../../../components/inputs/SelectInput'
import LegalEntityInfo from './LegalEntityInfo/LegalEntityInfo'

import {StorageType} from '../../../../../setup'
import {ICustomerCompanyState, ICustomerCompany} from '../../../../store/customerCompanys/types'
import {TCompany} from '../../../../store/mpWizard/types'

import styles from './LinkingToLegalEntity.module.scss'

interface ILinkingToLegalEntityProps {
  customerCompany?: TCompany[]
  onSelectCustomerCompany: (cc: TCompany | null) => void
  addCustomerCompanyHandler: (cc: TCompany) => void
}

const LinkingToLegalEntity: FC<ILinkingToLegalEntityProps> = ({
  customerCompany,
  onSelectCustomerCompany,
  addCustomerCompanyHandler,
}) => {
  const [selectedCustomerCompany, setSelectedCustomerCompany] = useState<TCompany | null>(null)
  const [autocompleteCustomerCompany, setAutocompleteCustomerCompany] = useState<TCompany[]>(
    customerCompany || []
  )

  const blocks = useRef<HTMLDivElement[]>([])

  const {items: customerCompanys, isLoading: isLoadingCustomerCompanys}: ICustomerCompanyState =
    useSelector((s: StorageType) => s.customerCompanys, shallowEqual)

  const isAddingCustomerCompany: boolean = useSelector((s: StorageType) => s.mpWizard.isLoading)

  const customerId: number = useSelector((s: StorageType) => s.auth.user.customer.id)

  const intl = useIntl()

  const onSetCustomerCompany = (cc: TCompany | null) => {
    blocks.current[1].classList.remove(styles.active)
    blocks.current[0].classList.add(styles.active)

    onSelectCustomerCompany(cc)
  }

  const onAddCustomerCompany = (cc: TCompany | null) => {
    if (!cc) return

    blocks.current[0].classList.remove(styles.active)
    blocks.current[1].classList.add(styles.active)

    addCustomerCompanyHandler({...cc, customer: customerId})
  }

  return (
    <div className='text-gray-700'>
      <div className='d-flex align-items-center justify-content-around px-8'>
        <h6 className='m-0 text-gray-700'> Выбрать юр. лицо</h6>
        <span className='fs-5 ms-11'>или</span>
        <h6 className='m-0 text-gray-700'>Создать новое юр. лицо</h6>
      </div>
      <div className='d-flex align-items-start'>
        <div className='border-end pe-1 w-50'>
          <div
            ref={(el) => {
              if (el) {
                blocks.current[0] = el
              }
            }}
            className={clsx('p-8 rounded', styles.customerWrapper)}
          >
            <div className='mb-9 border-bottom border-gray-400' style={{paddingBottom: '20px'}}>
              <div
                className='d-flex align-items-center fs-5 form-label mb-3 text-gray-700'
                style={{fontWeight: 'bold'}}
              >
                Выберите организацию:
              </div>
              <ReactSelect
                isSearchable={false}
                className=' my-1'
                isMulti={false}
                isLoading={isLoadingCustomerCompanys}
                onChange={(selected: any) => {
                  setSelectedCustomerCompany(selected ? selected.value : null)
                }}
                placeholder={intl.formatMessage({
                  id: 'COMPONENTS.ORDERS.LIST.FILTERS.CUSTOMER.PLACEHOLDER',
                })}
                loadingMessage={() =>
                  intl.formatMessage({id: 'COMPONENTS.ORDERS.LIST.FILTERS.ALL.LOADING'})
                }
                noOptionsMessage={() =>
                  intl.formatMessage({id: 'COMPONENTS.ORDERS.LIST.FILTERS.CUSTOMER.NOVALUES'})
                }
                getOptionLabel={(w: any) => w.label}
                isClearable={true}
                options={customerCompanys?.map((mp) => ({label: mp.name, value: mp})) ?? []}
              />
            </div>
            <LegalEntityInfo customerCompany={selectedCustomerCompany} />
            <Button
              disabled={!selectedCustomerCompany || isLoadingCustomerCompanys}
              className='px-20 mx-auto d-block'
              onClick={() => onSetCustomerCompany(selectedCustomerCompany)}
            >
              Выбрать
            </Button>
          </div>
        </div>
        <div className='w-50 ps-1'>
          <div
            ref={(el) => {
              if (el) {
                blocks.current[1] = el
              }
            }}
            className={clsx('p-8 rounded', styles.customerWrapper)}
          >
            <div className='mb-9'>
              <SelectInput<{id: number; name: string}, ICustomerCompany>
                selected={(autocompleteCustomerCompany ?? []).map(
                  (x) => ({id: x, name: x.name} as unknown as ICustomerCompany)
                )}
                multiple={false}
                labelKey={'name'}
                fetchData={autocompleteCompany}
                title='Введите наименование организации:'
                setValue={(nextValue) => {
                  setAutocompleteCustomerCompany(nextValue as TCompany[])
                }}
              />
            </div>
            <LegalEntityInfo customerCompany={autocompleteCustomerCompany[0]} />
            <Button
              disabled={!autocompleteCustomerCompany[0] || isAddingCustomerCompany}
              className='px-20 mx-auto d-block'
              onClick={() => onAddCustomerCompany(autocompleteCustomerCompany[0])}
            >
              Создать
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LinkingToLegalEntity
