export const initialRegions: IRegionsState = {
  items: [],
}

export interface IRegion {
  id: number
  label: string
}

export const emptyRegion: IRegion = {
  id: -1,
  label: '',
}

export interface IRegionsState {
  items: IRegion[]
}

export interface IRegionRequestParams {
  query: string
}

export const regionActionTypes = {
  GetRegions: '[GetRegions] Action',
}
