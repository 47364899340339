import {createSlice} from '@reduxjs/toolkit'
import {emptyState as initialState, MonitoringItem} from './types'

const acceptanceRatesSlice = createSlice({
  name: 'acceptanceRatesSlice',
  initialState,
  reducers: {
    setAcceptanceRate(state, action) {
      state.items = action.payload.results
      state.count = action.payload.count
      state.next = action.payload.next
      state.previous = action.payload.previous
      state.isLoading = false
      state.isLoadingWarehouses = false
      state.isLoadingRules = false
      state.notify = action.payload.notify
      state.rules = action.payload.rules
    },

    setIsLoading(state, action) {
      state.isLoading = action.payload
    },
    setSelectedRule(state, action) {
      state.selectedRule = action.payload
    },
    setIsLoadingWarehouses(state, action) {
      state.isLoadingWarehouses = action.payload
    },
    setIsLoadingRules(state, action) {
      state.isLoadingRules = action.payload
    },

    setSelectedAcceptanceRate(state, action) {
      state.selected = action.payload
    },
    setItemData(state, action) {
      state.items = state.items?.map((wr: MonitoringItem) =>
        wr.id == action.payload.id ? action.payload : wr
      )
    },
    setIsShowAddAccountModal(state, action) {
      state.isShowAddAccountModal = action.payload
    },
    setAcceptanceRateFilters(state, action) {
      state.filters = action.payload
    },
    updateAcceptanceRate(state, action) {
      const updatedAcceptanceRate: MonitoringItem = action.payload

      state.items = state.items?.map((wr: MonitoringItem) =>
        wr.id == updatedAcceptanceRate.id ? action.payload : wr
      )
    },

    refreshAcceptanceRate(state, action) {
      const updatedAcceptanceRate: MonitoringItem = action.payload.result
      state.items = state.items?.map((wr: MonitoringItem) =>
        wr.id == updatedAcceptanceRate.id ? updatedAcceptanceRate : wr
      )
    },
  },
})

export const {
  setAcceptanceRate,
  setIsLoading,
  setIsLoadingWarehouses,
  setIsLoadingRules,
  setSelectedAcceptanceRate,
  setSelectedRule,
  setAcceptanceRateFilters,
  updateAcceptanceRate,
  refreshAcceptanceRate,
  setItemData,
  setIsShowAddAccountModal,
} = acceptanceRatesSlice.actions

export const acceptanceRatesReducer = acceptanceRatesSlice.reducer
