import React, { FC } from 'react'
import { removeProps } from 'react-select/dist/declarations/src/utils'
import ModalWindowExtended from '../../modals/cathegory-select-modal/ModalWindowExtended'
import Loader1 from '../loaders/Loader1'

interface IModalInfoProps{
    title: string
    showModal: boolean
    setShowModal: (show: boolean) => void
}

const ModalInfo:FC<IModalInfoProps> = (props) => {
  return (
    <ModalWindowExtended centered={true} show={props.showModal} setShow={props.setShowModal} canApply={false} closable={false} applyShow={false} cancelShow={false} >
        <Loader1 label={props.title} />
        {props.children}
    </ModalWindowExtended>
  )
}

export default ModalInfo