import {ChangeEvent} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {updateData} from '../../../../../store'

import CheckBoxCustom from '../../../../../components/inputs/CheckBoxCustom'

import {StorageType} from '../../../../../../setup'
import {TYMAccount} from '../../../../../store/mpWizard/types'

const YMStep2 = () => {
  const data = useSelector((storage: StorageType) => storage.mpWizard.data)
  const ymAccounts: TYMAccount[] = data?.step0?.ymAccounts
  const selectedYMAccounts: TYMAccount[] = data?.step1?.selectedYMAccounts || []

  const dispatch = useDispatch()

  const onChange = (acc: TYMAccount) => (e: ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked

    if (checked) {
      dispatch(
        updateData({key: 'step1', field: 'selectedYMAccounts', value: [...selectedYMAccounts, acc]})
      )
    } else {
      dispatch(
        updateData({
          key: 'step1',
          field: 'selectedYMAccounts',
          value:
            selectedYMAccounts.length > 1
              ? selectedYMAccounts.filter((item) => item.id !== acc.id)
              : null,
        })
      )
    }
  }
  return (
    <div className='fs-5'>
      <p className='mb-8'>
        Пожалуйста, выберите кабинеты, которые вы хотите импортировать в наш сервис. Это позволит
        нам настроить автоматизацию и управление для аккаунта, обеспечивая удобство и эффективность
        работы.
      </p>
      <ul className='list-unstyled'>
        {ymAccounts ? (
          <>
            {ymAccounts.map((account) => (
              <li key={account.id} className='mb-5'>
                <CheckBoxCustom
                  className='me-5'
                  id={account.id.toString()}
                  defaultChecked={selectedYMAccounts.some(
                    (acc: TYMAccount) => acc.id === account.id
                  )}
                  onChange={onChange(account)}
                />
                <label htmlFor={account.id.toString()} className='form-check-label text-end ms-5'>
                  {account.business.name} ({account.domain})
                </label>
              </li>
            ))}
          </>
        ) : (
          <p>Аккаунты не найдены</p>
        )}
      </ul>
    </div>
  )
}

export default YMStep2
