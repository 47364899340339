import { createAction } from "@reduxjs/toolkit";
import { ICompetitorRequestParams, ICompetitorWithIdRequestParams } from "./api";
import { competitorActionTypes } from "./types"


export const ASYNC_GET_COMPETITORS = competitorActionTypes.GetCompetitors;
export const ASYNC_GET_COMPETITOR_GOODS = competitorActionTypes.GetCompetitorGoods;
export const ASYNC_ADD_COMPETITOR_GOOD = competitorActionTypes.AddCompetitorGood;
export const ASYNC_DEL_COMPETITOR_GOOD = competitorActionTypes.DelCompetitorGood;
export const ASYNC_EDIT_COMPETITOR_GOOD = competitorActionTypes.EditCompetitorGood;
export const ASYNC_FORCE_UPDATE_COMPETITOR_GOOD = competitorActionTypes.ForceUpdateCompetitorGood;
export const ASYNC_CHECK_COMPETITOR_GOOD_URL = competitorActionTypes.CheckCompetitorGoodsUrl;

export const getAsyncCompetitorsCreator = createAction(ASYNC_GET_COMPETITORS)
export const getAsyncCompetitorGoodsCreator = createAction<number>(ASYNC_GET_COMPETITOR_GOODS)
export const addAsyncCompetitorGoodCreator = createAction<ICompetitorRequestParams>(ASYNC_ADD_COMPETITOR_GOOD)
export const delAsyncCompetitorGoodCreator = createAction<number>(ASYNC_DEL_COMPETITOR_GOOD)
export const editAsyncCompetitorGoodCreator = createAction<ICompetitorWithIdRequestParams>(ASYNC_EDIT_COMPETITOR_GOOD)
export const forceUpdateAsyncCompetitorGoodCreator = createAction<number>(ASYNC_FORCE_UPDATE_COMPETITOR_GOOD)
export const checkUrlAsyncCompetitorGoodCreator = createAction<string>(ASYNC_CHECK_COMPETITOR_GOOD_URL)