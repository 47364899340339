import {FC, useMemo} from 'react'

import {Button, Col, Row} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import QuickMenuRoot from '../goods/QuickMenuRoot'
import ActiveButton from '../../components/buttons/ActiveButton'
import {useNavigate} from 'react-router-dom'

const OrdersMenu = (props: {isActive: 'orders' | 'documents'; backPath?: string}) => {
  const intl = useIntl()
  const navigator = useNavigate()

  return (
    <QuickMenuRoot>
      <ActiveButton
        isActive={props.isActive == 'orders'}
        className='me-5 fs-7'
        onClick={() => navigator('/orders/orders/list')}
      >
        {intl.formatMessage({id: 'COMPONENTS.ORDERS.MENU.ORDERS.TITLE'})}
      </ActiveButton>
      <ActiveButton
        isActive={props.isActive == 'documents'}
        className='me-5 fs-7'
        onClick={() => navigator('/orders/mpdocs/list')}
      >
        {intl.formatMessage({id: 'COMPONENTS.ORDERS.MENU.DOCUMENTS.TITLE'})}
      </ActiveButton>
    </QuickMenuRoot>
  )
}

export default OrdersMenu
