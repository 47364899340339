import { emptyMarketplace } from './../marketplaces/types';
import { DEFAULT_GOOD_ITEM_ID, IGoodTradeOffer } from "../../modules/goods/types"
import { EMPTY_CATEGORY, ICategory } from "../categories/types"
import { IImage } from "../images/types"
import { IMarketplace } from "../marketplaces/types"
import { emptyGoodMpCardUniversal, IGoodMpCardUniversal, IGoodMpCardUniversalAttribute } from "../mpcards/types"
import { CardDataAddin, IGoodAttributeValueResponse, IGoodCardOzonSaveAttribute, IGoodFieldAttribute } from "./api"
import { initialGood } from './reducers';

export const ITEMS_PER_PAGE_DEFAULT = 10
export const CURRENT_PAGE_DEFAULT = 0
export const ORDERING_DEFAULT = "id" // "customer_uid"/"-customer_uid", "name"/"-name", "id"/"-id"
export const SEARCH_DEFAULT = ""



export interface IGoodParent{
    id: number
    name: string
}


export interface IGood {
    id: number
    name: string
    customer_uid: string
    category: ICategory[]
    is_offer: boolean
    offers?: number[]
    params?: IGoodExtended | null
    images: IImage[]
    parent?: IGoodParent | null
}

export interface IGoodSave {
    id: number
    name: string
    customer_uid: string
    category: number[]
    is_offer: boolean
    offers?: number[]
    params?: IGoodExtended | null
    images?: number[]
    parent?: number | null
}

export interface IGoodState{
    count: number
    next: string
    previous: string
    itemsPerPage: number
    currentPage: number
    ordering: string
    search: string
    isLoading: boolean
    isTradeOffersLoading: boolean
    items: IGood[]
    selected: IGood
    tradeOffers?: IGoodTradeOffer[] | []
    redirectToId?: number
}


interface IWhData{
    depth?: number
    width?: number
    height?: number
    weight?: number
    volume_weight?: number
}

export interface IOzonCard{
    data:
    {
        brand:
        {
            id: number
            text: string
            selected_text: string
        }
        attributes: IGoodCardOzonSaveAttribute[]
        category_id: number
    }
}

export interface IWBCard{
    data:
    {
        attributes: {
            addin: CardDataAddin[]
            countryProduction: string
            object: string
            supplierVendorCode: string
        }
    }
    upload:
    {}
}

export interface IGoodExtendedCardData{
    ozon: IGoodMpCardUniversal
    wildberries: IGoodMpCardUniversal
    // ozon: IOzonCard
    // wildberries: IWBCard 
    //universal?: IGoodMpCardUniversal[]
}

export interface IGoodExtended{
    wh_data: IWhData
    card_data: IGoodExtendedCardData | undefined
    description: string
    need_update?: boolean
}

export interface IGoodResponse {
    success?: boolean
    result: IGood
}



export interface ISelectedMarketplaceCategory {
    marketplace: IMarketplace
    category: ICategory
  }
  
export interface ISelectedMarketplaceCategoryCard {
    marketplace: IMarketplace
    category: ICategory
    card?: IGoodMpCardUniversal
    showOnlyRequired: boolean
    isValid?: boolean
  }
  
export const emptySelectedMarketplaceCategoryCard: ISelectedMarketplaceCategoryCard = {
    marketplace: emptyMarketplace,
    category: EMPTY_CATEGORY,
    card: emptyGoodMpCardUniversal,
    showOnlyRequired: true,
    isValid: true
  }

  export interface IGoodItemForm {
    id: number
    category: ICategory
    customer_uid: string
    images: IImage[],
    is_offer: boolean
    name: string
    offers: number[] | null
    parent?: IGoodParent | null
    depth: number
    height: number
    weight: number
    width: number
    card_data?: IGoodExtendedCardData | null
    description?: string

    // universalFields?: IGoodMpCardUniversalAttribute[] // universal card attributes
    // universalFields?: IGoodMpCardUniversal[] // universal card attributes
    universalFields?: ISelectedMarketplaceCategoryCard[] // universal card attributes
    // categories?: ICategory[] // categories for universal card attributes
    //categories?: ISelectedMarketplaceCategory[] // categories for universal card attributes

    variableFields?: IGoodFieldAttribute[]
    ozonCardData?: object

    wbDynamicFields?: IGoodFieldAttribute[]
    wbCardData?: object

    tradeOffers?: IGoodTradeOffer[] | [] | null  // original data from server
    tradeOffersEdit?: IGoodTradeOffer[] | [] | null  // temporary data for editing

    ozonDynamicTradeOfferFields?: IGoodFieldAttribute[] // dynamic copy of variableFields without values (only fields from server). needs for trade offers

    // here we save the source of ozon fieldlist (not for data filling)
    ozonDynamicTradeOfferFieldsEdit?: IGoodFieldAttribute[] // dynamic copy of variableFields without values (only fields from server). needs for edit copy of trade offers

    //wbDynamicTradeOfferFields?: IGoodFieldAttribute[] // dynamic copy of wbDynamicFields without values (only fields from server). needs for trade offers
    //wbDynamicTradeOfferFieldsEdit?: IGoodFieldAttribute[] // dynamic copy of wbDynamicFields without values (only fields from server). needs for edit copy of trade offers

    // here we save values of wb nomenklature addins (colors, collection) list
    wbDynamicTradeOfferAddinsFieldsEdit?: IGoodFieldAttribute[] // dynamic copy of  nomenclature addins without values (only fields from server). needs for edit copy of trade offers
    wbDynamicTradeOfferAddinsFields?: IGoodFieldAttribute[] // dynamic nomenclature addins without values (only fields from server). needs for trade offers
    //wbDynamicTradeOfferVariationsFields?: IGoodFieldAttribute[] // dynamic  nomenclature variations without values (only fields from server). needs for trade offers

    // here we save variations template (sizes)
    wbDynamicTradeOfferVariationsTemplate?: IGoodFieldAttribute[] // dynamic  nomenclature variations without values (only fields from server). it's template. it's not for edit! needs for trade offers

    // here we saveing values if wb nomenklature variations list
    wbDynamicTradeOfferVariationsFieldsEdit?: IGoodFieldAttribute[][] // dynamic copy of nomenclature variations without values (only fields from server). needs for edit copy of trade offers
}

let MpType: "wb_card" | "ozon_card" | "ali_card" | "sb_card" | "ym_card"
export type TMpType = typeof MpType

export interface IGoodCardSave{
    payload: IGoodMpCardUniversal
    object: TMpType
}


export interface IGoodCardsSaveRequest{
    goodId:number
    cards: IGoodCardSave[]
}

export interface IGoodAndCardsSaveRequest{
    good: IGoodItemForm
    cards: IGoodCardSave[]
}



export interface IGoodsGetParams{
    page?: number
    items?: number
    order?: string
    search?: string
    
    objectId?: number // for marketing
}

export interface IGoodGetParams{
    id: number
    expand?: "parent" | "offers"
}



export const EMPTY_GOOD_ITEM_FORM: IGoodItemForm = {
    card_data: null,
    category: EMPTY_CATEGORY,
    customer_uid: "",
    depth: 1,
    height: 1,
    id: 0,
    is_offer: false,
    name: "",
    images: [],
    offers: null,
    parent: null,
    weight: 0,
    width: 0,
    description: "",
    universalFields: [],   // maybe set by emptyGoodMpCardUniversalAttribute
    //categories: [],
    variableFields: [],
    wbDynamicFields: [],
    tradeOffers: [],
    tradeOffersEdit: [],
}

export interface IGoodsWithFilterRequestParams{
    filter: string
    objectId?: number
    accountId?: number
}


export interface IGoodShort {
    id: number
    name: string
    customer_uid: string
    // owner: number
}


export const EMPTY_GOOD = initialGood

export const goodActionTypes = {
    GetGoods: '[GetGoods] Action',
    GetGoodsWithFilter: '[GetGoodsWithFilter] Action',
    //GetGoodTrageOffers: '[GetGoodTrageOffers] Action',
    GetGood: '[GetGood] Action',
    AddGood: '[AddGood] Action',
    UpdateGood: '[UpdateGood] Action',
    UpdateGoodCards: '[UpdateGoodCards] Action',
    UpdateGoodAndCards: '[UpdateGoodAndCards] Action',
    UpdateGoodNotCards: '[UpdateGoodNotCards] Action',
    GetGoodTradeOffer: '[GetGoodTradeOffer] Action',
    GetGoodTradeOfferFiltered: '[GetGoodTradeOfferFiltered] Action',
    AddGoodTradeOffer: '[AddGoodTradeOffer] Action',
    UpdateGoodTradeOffer: '[UpdateGoodTradeOffer] Action',
    DeleteGoodTradeOffer: '[DeleteGoodTradeOffer] Action',
    SetGoods: '[SetGoods] Action',
    ClearSelectedGood: '[ClearSelectedGood] Action',
    SetOzonCard: '[SetOzonCard] Action',
    SetWbCard: '[SetWbCard] Action',
    SetIsLoading: '[SetIsLoading] Action',
    SetGoodsRequestParams: '[SetGoodsRequestParams] Action',
    CopyGoodCard: '[CopyGoodCard] Action',
}