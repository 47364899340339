import React, { FC } from 'react'

interface IWidgetDropdownProps{
    title?: string
}

const WidgetDropdown: FC<IWidgetDropdownProps> = (props) => {
  return (
    <div className='menu menu-sub menu-sub-dropdown w-250px w-md-300px' data-kt-menu='true'>
      <div className='px-7 py-5'>
        <div className='fs-5 text-dark fw-bolder'>{props.title ?? ""}</div>
      </div>

      <div className='separator border-gray-200'></div>

      <div className='px-7 py-5'>
        {props.children ?? null}

        {/* <div className='d-flex justify-content-end'>
          <button
            type='reset'
            className='btn btn-sm btn-white btn-active-light-primary me-2'
            data-kt-menu-dismiss='true'
          >
            Reset
          </button>

          <button type='submit' className='btn btn-sm btn-primary' data-kt-menu-dismiss='true'>
            Apply
          </button>
        </div> */}
      </div>
    </div>
  )
}

export default WidgetDropdown