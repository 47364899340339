import {useState, ChangeEvent, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {updateData} from '../../../../../store'

import {Form} from 'react-bootstrap'

import {StorageType} from '../../../../../../setup'

type TTokens = {
  apiId: string
  apiKey: string
}

const OzonStep1 = () => {
  const data = useSelector((storage: StorageType) => storage.mpWizard.data)

  const initDataTokens = {
    apiId: '',
    apiKey: '',
  }

  const [tokens, setTokens] = useState<TTokens>(data?.step0 || initDataTokens)

  const dispatch = useDispatch()

  useEffect(() => {
    if (!data?.step0) {
      dispatch(updateData({key: 'step0', field: 'apiId', value: ''}))
      dispatch(updateData({key: 'step0', field: 'apiKey', value: ''}))
    }
  }, [])

  const updateTokens = (e: ChangeEvent<HTMLInputElement>) => {
    const {value, name} = e.target

    dispatch(updateData({key: 'step0', field: name, value: value}))

    setTokens((prev) => ({...prev, [name]: value}))
  }
  return (
    <div className='fs-5'>
      <p className='mb-7'>
        Токен авторизации необходим для того, чтобы наш сервис мог взаимодействовать с вашим
        аккаунтом OZON и выполнять необходимые задачи, такие как автоматизация процессов, интеграция
        с вашим магазином и управление данными.
      </p>

      <Form className='mb-9'>
        <Form.Label className='text-gray-700' htmlFor='apiId'>
          API ID:
        </Form.Label>
        <Form.Control
          name='apiId'
          id='apiId'
          value={tokens.apiId}
          onChange={updateTokens}
          className='bg-secondary mb-6'
        />

        <Form.Label className='text-gray-700' htmlFor='apiKey'>
          API KEY:
        </Form.Label>
        <Form.Control
          id='apiKey'
          name='apiKey'
          value={tokens.apiKey}
          onChange={updateTokens}
          className='bg-secondary'
        />
      </Form>
      <p>Чтобы получить API KEY, необходимо перейти в личный кабинет на портале поставщика OZON.</p>
      <p>
        Кабинет поставщика &gt; API ключи &gt; Seller API &gt; сгенерировать ключ &gt; в разделе
        «Тип токена» ввести название токена &gt; Выбрать тип Администратор &gt; Создать ключ.
      </p>
      <p>API ID - Client ID в личном кабинете пользователя</p>
      <p>
        Более подробно можно ознакомиться в{' '}
        <a
          href='https://docs.shop-delivery.ru/personal_account/#_8'
          target='_blank'
          rel='noopener noreferrer'
        >
          Руководстве пользователя
        </a>
        .
      </p>
    </div>
  )
}

export default OzonStep1
