import {createSlice} from '@reduxjs/toolkit'
import {IPriceType, initialPriceTypes as initialState} from './types'

const priceTypeSlice = createSlice({
  name: 'priceTypeSlice',
  initialState,
  reducers: {
    setPriceTypes(state, action) {
      const {results, count} = action.payload
      state.priceTypes = results
      state.count = count
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload
    },
    addPriceType(state, action) {
      state.priceTypes.push(action.payload)
    },
    deletePriceType(state, action) {
      const pTId: number = action.payload

      state.priceTypes = state.priceTypes.filter((pT: IPriceType) => pT.id !== pTId)
    },
    updatePriceType(state, action) {
      const updatedPriceType: IPriceType = action.payload

      state.priceTypes = state.priceTypes.map((pT: IPriceType) =>
        pT.id === updatedPriceType.id ? action.payload : pT
      )
    },
    setRequestParams(state, action) {
      state.filters = action.payload
    },
  },
})

export const {
  setPriceTypes,
  setIsLoading,
  addPriceType,
  deletePriceType,
  updatePriceType,
  setRequestParams,
} = priceTypeSlice.actions
export const priceTypesReducer = priceTypeSlice.reducer
