/* eslint-disable react-hooks/exhaustive-deps */
import {useState, useEffect, FC} from 'react'
import {useIntl} from 'react-intl'
import {useNavigate} from 'react-router-dom'
import {KTSVG, useDebounce} from '../../../_metronic/helpers'
import {createLink} from '../../utils/dataUtils'
import {DEFAULT_PAGE} from '../../modules/goods/GoodsList'
import {IUrlParameters} from './UrlFilteredList'
import {SEARCH_DEBOUNCE_TIMEOUT} from '../../utils/constants'

interface IUrlFilterProps {
  urlParams: IUrlParameters
  onSearch?: (value?: any) => void
}

// attention! encodeURIComponent(searchTerm) - encodes the search parameter. to read from it you need to decodeURIComponent()!
const Filter: FC<IUrlFilterProps> = ({urlParams, onSearch}) => {
  const [searchTerm, setSearchTerm] = useState<string>(urlParams.filter)
  const [init, setInit] = useState<boolean>(false)

  const intl = useIntl()
  const navigate = useNavigate()

  const getSearchPath = () => {
    return createLink.paginationLink({
      pageNumber: DEFAULT_PAGE,
      itemsPerPage: urlParams.itemsPerPage,
      title: intl.formatMessage({id: 'COMPONENTS.TABLES.GOODS.LIST.FILTER.TITLE'}),
      ordering: urlParams.ordering,
      filter: urlParams.useURLEncoding ? encodeURIComponent(searchTerm) : searchTerm,
      additionalParameters: urlParams.additionalParameters
        ? [...urlParams.additionalParameters]
        : [],
      url: urlParams.url,
    })
  }

  const debouncedSearchTerm = useDebounce(searchTerm, SEARCH_DEBOUNCE_TIMEOUT)
  useEffect(
    () => {
      if (debouncedSearchTerm !== undefined && searchTerm !== undefined && init) {
        if (onSearch) {
          onSearch(debouncedSearchTerm)
        }

        navigate(getSearchPath().url)
      }
    },
    [debouncedSearchTerm] // Only call effect if debounced search term changes
    // More details about useDebounce: https://usehooks.com/useDebounce/
  )

  useEffect(() => {
    setSearchTerm(urlParams.filter)
  }, [urlParams.filter])

  /// set init flag. need because of auto navigate with empty params when component inits
  useEffect(() => {
    setInit(true)
  })

  return (
    <div className='card-title'>
      {/* begin::Search */}
      <div className='d-flex align-items-center position-relative my-1'>
        <KTSVG
          path='/media/icons/duotune/general/gen021.svg'
          className='svg-icon-1 position-absolute  end-0 me-6 cursor-pointer'
          onClick={() =>
            urlParams.onSearchButtonClick && urlParams.onSearchButtonClick(urlParams.filter)
          }
        />
        <input
          type='text'
          data-kt-user-table-filter='search'
          className='form-control form-control-solid ps-4 fs-8'
          placeholder={
            urlParams.placeholder
              ? urlParams.placeholder
              : intl.formatMessage({id: 'COMPONENTS.TABLES.GOODS.LIST.FILTER.PLACEHOLDER'})
          }
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      {/* end::Search */}
    </div>
  )
}

export default Filter
