import axios from "axios";
import { DEFAULT_LIMIT, DEFAULT_PAGE } from "../../modules/goods/GoodsList";
import { IGoodsGetParams } from "../goods/types";
import { IImportMpTaskRequest, IImportXlsFormat, IImportXlsTaskRequest, ITaskResponse, IUserTask, IUserTaskResponse } from "./types";


const API_URL = `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENDPOINT_PREFIX}`;
export const TASKS_URL = `${API_URL}v2/tasks/`
export const USER_TASKS_URL = `${API_URL}v2/users_tasks/`
export const IMPORTS_URL = `${API_URL}v2/imports/`


// =================   get tasks =================
// #region
//https://api-dev.shop-delivery.ru/int_api/v2/tasks/


// get all tasks from server
export const getTasksFromServer = (params: IGoodsGetParams = { page: DEFAULT_PAGE, items: DEFAULT_LIMIT }) => {
    const page = params.page ?? DEFAULT_PAGE
    const items = params.items ?? DEFAULT_LIMIT
    const offset = page * items

    return axios.get<IUserTaskResponse>(`${USER_TASKS_URL}?limit=${items}&offset=${offset}`)
}

// =================   get task =================
// #region
//https://api-dev.shop-delivery.ru/int_api/v2/tasks/5


// get task from server by id
export const getTaskFromServer = (params: number) => {
    return axios.get<IUserTask>(`${USER_TASKS_URL}${params}`)
}

// =================   new task import from mp =================
// #region
//https://api-dev.shop-delivery.ru/int_api/v2/imports/mp/


// add new import mp task to server
export const addTaskImportMpToServer = (params: IImportMpTaskRequest) => {
    return axios.post<ITaskResponse>(`${IMPORTS_URL}mp`, params)
}

// =================   new task import rom xlsx =================
// #region
//https://api-dev.shop-delivery.ru/int_api/v2/imports/xlsx/


// add new import xls task to server
export const addTaskImportXlsToServer = (params: IImportXlsTaskRequest) => {

    const formData = new FormData()
    formData.append("file", params.fileData);
    formData.append("format", params.format);

    const options: any = {
        method: 'POST',
        url: `${IMPORTS_URL}xlsx`,
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        data: formData
    };

    return axios.request(options)
}

// =================   xls imports formats =================
// #region
//https://api-dev.shop-delivery.ru/int_api/v2/imports/mp/


// get import xls formats from server
export const getTaskImportXlsFormatsFromServer = () => {
    return axios.get<IImportXlsFormat[]>(`${IMPORTS_URL}xlsx/formats`)
}
