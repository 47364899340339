import {useState, useEffect} from 'react'
import {useDispatch, useSelector, shallowEqual} from 'react-redux'
import {updateAsyncUserCreator} from '../../../../store/users/actions'

import PhoneInput from 'react-phone-input-2'
import {Button} from 'react-bootstrap'

import {IUserState} from '../../../../store/users/types'
import {IUserModel} from '../../models/UserModel'
import {StorageType} from '../../../../../setup'

const ChangeNumber = () => {
  const [newPhone, setNewPhone] = useState('')

  const user: IUserModel = useSelector((storage: StorageType) => storage.auth.user, shallowEqual)
  const {isLoading: isUpdating, isUpdatingSuccess}: IUserState = useSelector(
    (storage: StorageType) => storage.users,
    shallowEqual
  )

  const dispatch = useDispatch()

  useEffect(() => {
    if (isUpdatingSuccess) {
      window.location.reload()
    }
  }, [isUpdatingSuccess])

  const onSubmitNumber = (number: string) => () => {
    console.log('number', number)
    const updateUser = {...user, phone: number}

    dispatch(updateAsyncUserCreator(updateUser))
  }

  return (
    <div>
      <h2 className='fw-normal mb-4 text-center'>Введите новый номер телефона</h2>
      <PhoneInput
        country='ru'
        inputClass='form-control form-control-lg form-control-solid w-100'
        onChange={(value) => {
          setNewPhone(value)
        }}
        regions={['ex-ussr']}
        isValid={(value) => {
          if (value.length < 11) {
            return 'Минимум 11 символов (включая знак +)'
          } else {
            return true
          }
        }}
      />
      <Button
        className='mt-5 w-100'
        disabled={newPhone.length < 11 || isUpdating}
        onClick={onSubmitNumber(newPhone)}
      >
        {isUpdating ? 'Идет обновление...' : 'Сохранить'}
      </Button>
    </div>
  )
}

export default ChangeNumber
