import { createSlice } from "@reduxjs/toolkit"
import { DEFAULT_GOOD_ITEM_ID } from "../../modules/goods/types"
// import { emptyGoods as initialState, IGoodState, } from "./types"
import { IGood, IGoodState, } from "./types"
import { IMpCardItemPublishParams } from "../mpcards/types"

const ITEMS_PER_PAGE_DEFAULT = 10
const CURRENT_PAGE_DEFAULT = 0
const ORDERING_DEFAULT = "id" // "customer_uid"/"-customer_uid", "name"/"-name", "id"/"-id"
const SEARCH_DEFAULT = ""

//! replaced here because of the error 'Cannot access 'emptyGoods' before initialization' (it was in types earlier)
export const initialGood: IGood = {
    id: DEFAULT_GOOD_ITEM_ID,
    name: "",
    customer_uid: "",
    category: [],
    is_offer: false,
    offers: [],
    params: null,
    images: [],
    parent: null,
}

export const emptyGoods: IGoodState = {
    count: 0,
    next: "",
    previous: "",
    itemsPerPage: ITEMS_PER_PAGE_DEFAULT,
    currentPage: CURRENT_PAGE_DEFAULT,
    ordering: ORDERING_DEFAULT,
    search: SEARCH_DEFAULT,
    isLoading: false,
    isTradeOffersLoading: false,
    items: [],
    tradeOffers: [],
    selected: initialGood
}

// let emp: IGoodState = {
//     count: 0,
//     next: "",
//     previous: "",
//     itemsPerPage: 0,
//     currentPage: 0,
//     ordering: "",
//     search: "",
//     isLoading: false,
//     items: [],
//     tradeOffers: [],
//     selected: {
//         id: 0,
//         name: "",
//         customer_uid: "",
//         category: [],
//         is_offer: false,
//         offers: [],
//         params: null,
//         images: [],
//         parent: null,
//     }
// }

let initialState = emptyGoods

const goodSlice = createSlice({
    name: "goodSlice",
    // initialState,
    initialState,
    reducers: {
        setGoods(state, action) {
            state.items = [...action.payload]
        },
        addGood(state, action) {
            //console.log('reducers addGood', action.payload)
            state.selected = action.payload
            state.items = [...state.items, action.payload]
            state.redirectToId = action.payload.id
        },
        setCount(state, action) {
            state.count = action.payload
        },
        setRequestParams(state, action) {
            const { items, page, order, search } = action.payload
            state.itemsPerPage = items
            state.currentPage = page
            state.ordering = order
            state.search = search
        },
        setIsLoading(state, action) {
            state.isLoading = action.payload
        },
        setIsTradeOffersLoading(state, action) {
            state.isTradeOffersLoading = action.payload
        },
        setSelectedGood(state, action) {
            //console.log('goods reducers setSelectedGood',action.payload)
            state.selected = action.payload
            state.redirectToId = undefined
            state.tradeOffers = []
        },
        setGoodTradeOffers(state, action) {
            state.tradeOffers = [...action.payload]
        },
        addGoodTradeOffer(state, action) {
            state.tradeOffers = [...(state.tradeOffers ?? []), action.payload]
        },
        setGoodTradeOffer(state, action) {
            //console.log('Good reducers action.payload', action.payload)
            state.tradeOffers = [...(state.tradeOffers?.map(to => {
                return to.id == action.payload.id ? action.payload : to
            }) ?? [])]
        },
        setGoodTradeOfferMpCardPublish(state, action) {
            //console.log('Good reducers action.payload', action.payload)
            const { id, isPublishing } = action.payload

            state.tradeOffers = [...(state.tradeOffers?.map(to => {
                const toMpCards = to.mpcards.map(mc => {
                    if (mc.id == id)
                        return { ...mc, isPublishing: isPublishing }
                    else
                        return mc
                })
                return { ...to, mpcards: toMpCards }
            }) ?? [])]
        },
        setGoodTradeOfferMpCardIsPublished(state, action) {
            //console.log('Good reducers action.payload', action.payload)
            const { id, is_published } = action.payload

            state.tradeOffers = [...(state.tradeOffers?.map(to => {
                const toMpCards = to.mpcards.map(mc => mc.id == id ? { ...mc, is_published } : mc)
                return { ...to, mpcards: toMpCards }
            }) ?? [])]
        },
        deleteGoodTradeOffer(state, action) {
            state.tradeOffers = [...(state.tradeOffers?.filter(to => to.id != action.payload.offer_id) ?? [])]
        },
        setOzonCard(state, action) {
            if (state.selected?.params?.card_data?.ozon) {
                state.selected.params.card_data.ozon = action.payload
            };
        },
        setWbCard(state, action) {
            if (state.selected.params?.card_data?.wildberries) {
                state.selected.params.card_data.wildberries = action.payload
            }
        },
    }
})


export const goodReducer = goodSlice.reducer
export const { setGoods, setCount, setRequestParams, setSelectedGood, setIsLoading, deleteGoodTradeOffer,
    setOzonCard, setWbCard, addGood, addGoodTradeOffer, setGoodTradeOffer, setGoodTradeOffers,
    setIsTradeOffersLoading, setGoodTradeOfferMpCardPublish, setGoodTradeOfferMpCardIsPublished,
} = goodSlice.actions