import { FC, useEffect, useState } from 'react'
import { IMpDocShortOrder, IScannedData } from '../../../store/mpdocs/types'
import CollapsedButton from '../../../components/buttons/CollapsedButton'
import UncollapsedButton from '../../../components/buttons/UncollapsedButton'
import { dateUtils } from '../../../utils/dataUtils'
import { useIntl } from 'react-intl'

interface IMpDocOrderListItemProps {
    num: number
    item: IMpDocShortOrder
    collapsed?: boolean
    scanned?: IScannedData[]
}

const MpDocOrderListItem: FC<IMpDocOrderListItemProps> = (props) => {
    const intl = useIntl()
    const [collapsed, setCollapsed] = useState<boolean>(props.collapsed ?? true)

    useEffect(() => { props.collapsed != undefined && setCollapsed(props.collapsed) }, [props.collapsed])

    const scannedItems = props.scanned ? props.scanned.filter(s => s.order_number == props.item.number) : []
    const tdStyle={verticalAlign: 'top',paddingLeft:0}

    return (<tr>
        <td style={tdStyle}>
            <div className='d-flex flex-column justify-content-start align-items-start w-50px'>{props.num}</div>
        </td>
        <td style={tdStyle}>
            <div className='d-flex flex-column justify-content-start align-items-start w-150px'>{props.item.number}</div>
        </td>
        <td style={tdStyle}>
            {
                scannedItems && scannedItems.length > 0
                    ? <div className='d-flex flex-column align-items-start justify-content-start'>
                        <div className='d-flex flex-row align-items-center justify-content-start'>
                            <div className="badge bg-success text-white fs-8">{intl.formatMessage({ id: "COMPONENTS.MPDOCS.ITEM.ORDERS.LIST.FIELDS.SCANNED" })}</div>
                            {collapsed 
                                ? <UncollapsedButton offStyle offBtnStyle className='mx-2 cursor-pointer' onClick={() => setCollapsed(false)} /> 
                                : <CollapsedButton  offStyle offBtnStyle className='mx-2 cursor-pointer' onClick={() => setCollapsed(true)} />}
                        </div>
                        <div className="d-flex flex-column justify-content-start align-items-start">
                            {collapsed
                                ? null
                                : <ol>
                                    {
                                        scannedItems.map(si => {
                                            const output = dateUtils.standartDateTimeString(new Date(si.date), '.', ':', ` ${intl.formatMessage({ id: "COMPONENTS.ITEMS.PRICEMONITORING.CAPTION.IN" })} `)
                                            return <li className="fs-8 mt-1">{output}</li>
                                        })
                                    }
                                </ol>
                            }
                        </div>
                    </div>
                    : <div className="badge bg-warning text-white fs-8">{intl.formatMessage({ id: "COMPONENTS.MPDOCS.ITEM.ORDERS.LIST.FIELDS.NOTSCANNED" })}</div>
            }
        </td>
        <td style={tdStyle}>{props.item?.skus?.map(sku => <div className='d-flex flex-column justify-content-start align-items-start'>{sku.name} - {sku.price} {intl.formatMessage({id: "COMPONENTS.PRICES.FORM.PRICEMASSCHANGE.FIELDS.PRICE.CURRENCY.RUB"})}.</div>) ?? null}</td>

    </tr>

    )
}

export default MpDocOrderListItem