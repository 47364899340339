import { takeEvery } from "redux-saga/effects"
import { fetchCustomerCompanys, updateCustomerCompanyOnServer, addCustomerCompanyToServer, deleteCustomerCompanyOnServer } from "./api";
import { addCustomerCompany, setCustomerCompanys, setCustomerCompany, deleteCustomerCompany, setIsLoading } from "./reducers"
import { ASYNC_ADD_CUSTOMER_COMPANY, ASYNC_DELETE_CUSTOMER_COMPANY, ASYNC_GET_CUSTOMER_COMPANYS, ASYNC_UPDATE_CUSTOMER_COMPANY } from "./actions"
import { sagaUtils } from "../../utils/dataUtils";
import { intl } from "../../modules/goods/item/validation";



export function* customerCompanySaga(){
    yield takeEvery(ASYNC_GET_CUSTOMER_COMPANYS, workerGetCustomerCompanys)
    yield takeEvery(ASYNC_UPDATE_CUSTOMER_COMPANY, workerUpdateCustomerCompanys)
    yield takeEvery(ASYNC_ADD_CUSTOMER_COMPANY, workerAddCustomerCompanys)
    yield takeEvery(ASYNC_DELETE_CUSTOMER_COMPANY, workerDeleteCustomerCompanys)
}

// get all customer companies
export function* workerGetCustomerCompanys():any{
    yield sagaUtils.workerWrapper(setIsLoading, fetchCustomerCompanys, null, setCustomerCompanys, null, 
        intl.formatMessage({id: "SAGAS.CUSTOMERCOMPANYS.GET"}), "GET", "data.results", false)
}

// save customer company
export function* workerUpdateCustomerCompanys(action: any):any{
    yield sagaUtils.workerWrapper(setIsLoading, updateCustomerCompanyOnServer, action.payload, setCustomerCompany, null, 
        intl.formatMessage({id: "SAGAS.CUSTOMERCOMPANYS.UPDATE.SAVECUSTOMERCOMPANY"}), "SAVE", "data", true)
}

// add new customer company
export function* workerAddCustomerCompanys(action: any):any{
    yield sagaUtils.workerWrapper(setIsLoading, addCustomerCompanyToServer, action.payload, addCustomerCompany, null, 
        intl.formatMessage({id: "SAGAS.CUSTOMERCOMPANYS.UPDATE.SAVECUSTOMERCOMPANY"}), "SAVE", "data", true)
}

// delete customer company
export function* workerDeleteCustomerCompanys(action: any):any{
    yield sagaUtils.workerWrapper(setIsLoading, deleteCustomerCompanyOnServer, action.payload, deleteCustomerCompany, action.payload, 
        intl.formatMessage({id: "SAGAS.CUSTOMERCOMPANYS.DELETE"}), "DELETE", "direct", true, action.payload?.name ?? "")
}