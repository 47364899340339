import { createAction } from "@reduxjs/toolkit";
import { IWarehouse, IWarehouseListRequestParams, warehouseActionTypes } from "./types"

export const GET_ASYNC_WAREHOUSES = warehouseActionTypes.GetWarehouses;
export const ADD_ASYNC_WAREHOUSE = warehouseActionTypes.AddWarehouse;
export const UPDATE_ASYNC_WAREHOUSE = warehouseActionTypes.UpdateWarehouse;
export const DELETE_ASYNC_WAREHOUSE = warehouseActionTypes.DeleteWarehouse;

export const getAsyncWarehousesCreator = createAction<IWarehouseListRequestParams>(GET_ASYNC_WAREHOUSES);
export const addAsyncWarehouseCreator = createAction<IWarehouse>(ADD_ASYNC_WAREHOUSE);
export const updateAsyncWarehouseCreator = createAction<IWarehouse>(UPDATE_ASYNC_WAREHOUSE);
export const deleteAsyncWarehouseCreator = createAction<number>(DELETE_ASYNC_WAREHOUSE);