import React, { FC } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { KTSVG } from "../../../../_metronic/helpers"

import css from './Logo.module.css'

interface ILogoProps{
    className?: string
}

const Logo: React.FC<ILogoProps> = (props) => {

    return (
        <Container className={`${props.className}`}>
            <Row className={`w-200px h-200px`}>
                <Col className={`${css.layout}`}>
                    <KTSVG path="/media/custom/Shape.svg" disableSvgIconClass={true} svgClassName={`${css.topImage}`}></KTSVG>
                    <KTSVG path="/media/custom/Path.svg" disableSvgIconClass={true} svgClassName={`${css.bottomImage}`}></KTSVG>
                </Col>
            </Row>
        </Container>
    )
}

export default Logo