import {FC} from 'react'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import 'bootstrap-daterangepicker/daterangepicker.css'
import classes from './DateRangePicker.module.scss'

interface ILocaleProps {
  // direction: any,
  format?: any
  // separator: string,
  applyLabel: string
  cancelLabel: string
  // weekLabel: string,
  // customRangeLabel: string,
  // daysOfWeek: any,
  // monthNames: any,
  // firstDay: any
}

const emptyLocaleProps: ILocaleProps = {
  applyLabel: 'Apply',
  cancelLabel: 'Cancel',
  format: 'MM.DD.YYYY',
}

interface IDateRangePickerBootstrapProps {
  locale?: ILocaleProps // locale object like in bootstrap-daterangepicker
  disabled?: boolean
  placeholder?: string
  className?: string
  initDateFrom: Date | null
  initDateTo: Date | null
  onApply: any
  onCancel?: any
  onShow?: any
  onCallback?: any
  onEvent?: any
  onHide?: any
  onHideCalendar?: any
  onShowCalendar?: any
  withTime?: boolean
}

const DateRangePickerBootstrap: FC<IDateRangePickerBootstrapProps> = (props) => {
  return (
    <div className={`${props.className ? props.className : ''} ${classes.DateRangePicker}`}>
      {props.disabled ? (
        <div>{props.placeholder ? props.placeholder : ''}</div>
      ) : (
        <DateRangePicker
          initialSettings={{
            timePicker: props.withTime,
            timePicker24Hour: props.withTime,
            startDate: props.initDateFrom,
            endDate: props.initDateTo,
            locale: props.locale ?? emptyLocaleProps,
          }}
          onApply={props.onApply}
          onCancel={props.onCancel}
          onShow={props.onShow}
          onCallback={props.onCallback}
          onEvent={props.onEvent}
          onHide={props.onHide}
          onHideCalendar={props.onHideCalendar}
          onShowCalendar={props.onShowCalendar}
        >
          {props.children}
        </DateRangePicker>
      )}
    </div>
  )
}

export default DateRangePickerBootstrap
