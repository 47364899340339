import {Button, ButtonProps} from 'react-bootstrap'
import SbxLightButton from './SbxButtons/SbxLightButton'

interface IActiveButton extends ButtonProps {
  isActive: boolean
}

const ActiveButton = (props: IActiveButton) => {
  return props.isActive ? <Button {...props} /> : <SbxLightButton {...props} />
}

export default ActiveButton
