/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react'
import ApexCharts, { ApexOptions } from 'apexcharts'
import { getCSSVariableValue } from '../../../assets/ts/_utils'
import { IStatisticsItem } from '../../../../app/store/dashboard/types'
import Loader2 from '../../../../app/components/vision/loaders/Loader2'
import { Col, Row } from 'react-bootstrap'
import { KTSVG } from '../../../helpers'
import WidgetDropdown from '../../../../app/pages/dashboard/components/Menu/Dropdown/WidgetDropdown'
import 'moment/locale/ru'
import fr from 'apexcharts/dist/locales/fr.json'
import { objectUtils } from '../../../../app/utils/dataUtils'
const defaultLang = 'fr'


type Props = {
  className: string
  chartColor: string
  chartHeight: string
  isLoading?: boolean

  from: Date
  to: Date
  widgetControls?: any
  widgetTitle?: string
  title?: string
  subTitle?: string
  items?: IStatisticsItem[] // list of additional data between title and graphic

  lang: string
  legendName: string
}

const DashboardGraphicList: React.FC<Props> = (props) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const [locales, setLocales] = useState<{locales: any, defaultLocale: string} | null>(null)


  useEffect(() => {
    if (!chartRef.current) {
      return
    }

    const importLocals = async (lang: string) => {
      const imports = await import(`apexcharts/dist/locales/${lang}.json`)
      setLocales({locales: [imports], defaultLocale: lang})
    } 

    if (!locales)
      importLocals(props.lang)

    const chart = new ApexCharts(chartRef.current, chartOptions(props.chartColor, props.chartHeight, props.items ?? [], props.from, props.to, props.legendName, locales?.locales ? locales.locales : undefined , locales?.locales ? props.lang : undefined))
    if (chart) {
      chart.render()
    }

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.items, props.isLoading]) //chartRef

  return (
    <div className={`card ${props.className ? props.className : ''}`}>
      <div className='card-header p-1 border-0 py-5'>
        <Row className='w-100'>
          <Col xxl={props.widgetControls ? 11 : 12} 
            xl={props.widgetControls ? 11 : 12} 
            lg={props.widgetControls ? 10 : 12} 
            md={props.widgetControls ? 9 : 12} 
            sm={props.widgetControls ? 9 : 12} 
            xs={props.widgetControls ? 8 : 12}>
            <div className='d-flex flex-stack flex-wrap flex-grow-1 px-9 pt-9 pb-3'>
              <div className='me-2'>
                <span className='fw-bolder text-gray-800 d-block fs-3'>
                  {
                    props.isLoading
                      ? <Loader2 widthPX='30px' label='' />
                      : props.title ?? ""
                  }
                </span>

                <span className='text-gray-400 fw-bold'>
                  {
                    props.isLoading
                      ? <Loader2 widthPX='30px' label='' />
                      : props.subTitle ?? ""
                  }
                </span>
              </div>
            </div>
          </Col>
          {
            props.widgetControls
            ?  <Col xxl={1} xl={1} lg={2} md={3} sm={3} xs={4} className="d-flex flex-row justify-content-end align-items-center">
              <div className='card-toolbar w-100'>
                <button
                  type='button'
                  className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                  data-kt-menu-trigger='click'
                  data-kt-menu-placement='bottom-end'
                  data-kt-menu-flip='top-end'
                >
                  <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
                </button>
                {
                  props.widgetControls
                    ?
                    <WidgetDropdown title={props.widgetTitle ?? ''}>
                      {props.widgetControls}
                    </WidgetDropdown>
                    : null
                }
              </div>
            </Col>
            : null
          }
        </Row>
      </div>
      <div className='card-body p-3 d-flex justify-content-between flex-column overflow-hidden'>
        <Row>
          <Col>
            {
              props.isLoading
                ? <Loader2 widthPX='30px' label='' />
                : <div ref={chartRef} className='mixed-widget-10-chart'></div>
            }
          </Col>
        </Row>

      </div>
    </div>
  )
}

const chartOptions = (chartColor: string, chartHeight: string, items: IStatisticsItem[], from: Date, to: Date, legendName: string, locales?: ApexLocale[], defaultLocale?: string): ApexOptions => {
  let itms: IStatisticsItem[] = objectUtils.deepCopy(items).sort((a: IStatisticsItem,b: IStatisticsItem) => b.count - a.count)
  // const labelColor = getCSSVariableValue('--bs-gray-500')
  // const borderColor = getCSSVariableValue('--bs-gray-200')
  // const secondaryColor = getCSSVariableValue('--bs-gray-300')
  // const baseColor = getCSSVariableValue('--bs-' + chartColor)

  return {
    chart: {
      type: 'bar',
      redrawOnParentResize: false,
      redrawOnWindowResize: false,
      
      height: chartHeight,
      locales: locales ?? [fr],
      defaultLocale: defaultLocale ?? defaultLang,
    },
    xaxis: {
      categories: itms.map(i => i.name)
    },
    series: [{
      name: legendName,
      data: itms.map(i => i.count)
    }],
    plotOptions: {
      bar: {
        barHeight: '100%',
        distributed: true,
        horizontal: true,
        dataLabels: { 
          position: 'bottom'
        },
      }
    },
    dataLabels: {
      enabled: true,
      offsetX: 5,
      style: {
        colors: ['#333'],

        fontSize: '10px'
    },
    },
  }
}

export { DashboardGraphicList }
