import { ICustomer } from "../../modules/auth/models/UserModel"


export const initialState: ICustomerState = {
    count: 0,
    selected: null,
    items: [],
    isLoading: false,
}

export interface ICustomersGetResponse{
    count: number
    results: ICustomer[]
}


export interface ICustomerState{
    count: number
    selected: ICustomer | null
    items: ICustomer[]
    isLoading: boolean
}

export const customerActionTypes = {
    GetCustomers: '[GetCustomers] Action',
    UpdateCustomer: '[UpdateCustomer] Action',
    AddCustomer: '[AddCustomer] Action',
}