import {IDeliveryService} from '../deliveryServices/types'
import {IImageSimple} from '../images/types'
import {IOrderStatus} from '../orderStatuses/types'
import {IUrlParameters} from './../../components/lists/UrlFilteredList'
import {DEFAULT_ORDERS_LIMIT} from './constants'

export const emptyOrderUrlParameters = {
  filter: '',
  itemsPerPage: DEFAULT_ORDERS_LIMIT,
  ordering: '',
  pageNumber: 0,
  url: '/orders/orders/list/',
}

export const emptyState: IOrderStorage = {
  items: [],
  selected: null,
  isListLoading: false,
  isItemLoading: false,
  isTrackLoading: false,
  isRegistering: false,
  filter: emptyOrderUrlParameters,
  count: 0,
}

export interface IOrderStorage {
  items: IOrderItem[]
  selected: IOrderItem | null
  sticker?: any // pdf
  isListLoading: boolean
  isItemLoading: boolean
  isTrackLoading: boolean
  isRegistering: boolean
  filter?: IUrlParameters
  count: number | null
}

export interface IOrderItem {
  id: number
  number: string
  skus: IOrderSku[]
  shipment_date: Date
  delivery_company: string
  delivery_service?: IDeliveryService | null
  track_number?: string | null
  status: number
  status_name: string
  status_code: string
  company: string
  account: number
  created: Date
  params: any
  marketplace: string
  marketplace_id: number
  is_error: boolean
  amount: number
  printable: boolean
  selected?: boolean // my parameter to using it in list
}

export const emptyOrderItem: IOrderItem = {
  id: 0,
  number: '',
  skus: [],
  shipment_date: new Date(),
  delivery_company: '',
  status: 0,
  status_name: '',
  status_code: '',
  company: '',
  account: 0,
  created: new Date(),
  params: null,
  marketplace: '',
  marketplace_id: 0,
  is_error: false,
  amount: 0,
  printable: false,
}

export interface IOrderSku {
  id: number
  sku: string
  sku_name: string
  images: IImageSimple[]
  good: string
  quantity: number
  price: number
  canceled: boolean
  params: any
  order: number
  true_sign: string
}

export interface IOrderListResponse {
  count: number
  results: IOrderItem[]
}

export interface IOrdersGetParams {
  page?: number
  items?: number
  order?: string
  search?: string
  marketplace?: string
  account?: string
  customer?: string
}

export interface IOrderListRequest {
  company?: number
  created_after?: string
  created_before?: string
  expand?: string // List["account"]
  limit?: number
  marketplace?: number
  number?: number
  numbers?: string
  offset?: number
  ordering?: string
  search?: string
  ship_at_after?: string
  ship_at_before?: string
  status?: number
}

export interface IOrderChangeStatusRequest {
  id: number
  status: IOrderStatus
}

export interface IOrderChangeStatusesRequest {
  orders: number[]
  status: IOrderStatus
}

export interface IOrderBarcodeRequest {
  orderIds: number[]
  marketplace_order?: {
    order_id: string
    marketplace: string
  }
}

export interface IOrderRegisterRequest {
  account_id: number
  order_id: number
}

export const orderActionTypes = {
  GetOrdersManual: '[GetOrdersManual] Action',
  GetOrdersWithFilter: '[GetOrdersWithFilter] Action',
  SetRequestParameters: '[SetRequestParameters] Action',
  GetOrder: '[GetOrder] Action',
  SetOrderStatus: '[SetOrderStatus] Action',
  SetOrderStatuses: '[SetOrderStatuses] Action',
  GetSticker: '[GetSticker] Action',
  SetTrackNumber: '[SetTrackNumber] Action',
  DeleteTrackNumber: '[DeleteTrackNumber] Action',
  RegisterOrder: '[RegisterOrder] Action',
}
