/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import { useSelector } from 'react-redux'
import ReactSelect from 'react-select'
import { StorageType } from '../../../setup'

//import { useDispatch } from 'react-redux'
import {PageTitle} from '../../../_metronic/layout/core'
import {
  MixedWidget2,
  MixedWidget10,
  MixedWidget11,
  ListsWidget2,
  ListsWidget3,
  ListsWidget4,
  ListsWidget5,
  ListsWidget6,
  TablesWidget5,
  TablesWidget10,
  
  MixedWidget8,
  StatsWidget6,
} from '../../../_metronic/partials/widgets'
import { IAccount } from '../../store/accounts/types'
import { ICustomerCompany } from '../../store/customerCompanys/types'
import { IGood } from '../../store/goods/types'
import { IWarehouse } from '../../store/warehouses/types'
import DashboardPage, { IControlValue } from './DashboardPage'



const DashboardWrapper: FC = () => {

  const warehouses: IWarehouse[] = useSelector((state: StorageType) => state.warehouses.items)
  const warehousesisLoading: IWarehouse[] = useSelector((state: StorageType) => state.warehouses.isLoading)
  const accounts: IAccount[] = useSelector((state: StorageType) => state.accounts.items)
  const accountsisLoading: IAccount[] = useSelector((state: StorageType) => state.accounts.isLoading)
  const customers: ICustomerCompany[] = useSelector((state: StorageType) => state.customerCompanys.items)
  const customersisLoading: ICustomerCompany[] = useSelector((state: StorageType) => state.customerCompanys.isLoading)
  const goods: IGood[] = useSelector((state: StorageType) => state.goods.items)
  const goodsisLoading: IGood[] = useSelector((state: StorageType) => state.goods.isLoading)

  const [emptyDictionaries, setEmptyDictionaries] = useState<IControlValue[]>()

  useEffect(() => {
    let eDicts: IControlValue[] = []
    if (!warehousesisLoading && !accountsisLoading && !customersisLoading && !goodsisLoading)
    {
      if(!warehouses?.length)
        eDicts.push({dictionary: "Warehouses", path: "/account/settings", message: "Добавить склады"})
      if(!accounts?.length)
        eDicts.push({dictionary: "Accounts", path: "/account/settings", message: "Добавить аккаунты"})
      if(!customers?.length)
        eDicts.push({dictionary: "CustomerCompanys", path: "/account/settings", message: "Добавить юр. лица"})
      if(!goods?.length)
        eDicts.push({dictionary: "Goods", path: "/goods/list", message: "Добавить товары"})
    }
    setEmptyDictionaries(eDicts)
  }, [warehouses,accounts, customers, goods, warehousesisLoading,accountsisLoading, customersisLoading, goodsisLoading,])

  useEffect(() => {

  }, [])
  const intl = useIntl()

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage emptyItems={emptyDictionaries ?? []} />

      
      {/* This widget must be here (it may be empty, but it must be. I don't know why is it so...). If we delete it, we have got a problem with tabs in TabGoodCard.tsx (mayybe anything else. tester found only that problem) (selector is breaks) */}
      <MixedWidget2 chartColor='red' chartHeight='100' strokeColor='white' className='' />
    </>
  )
}

export {DashboardWrapper}
