import { createSlice } from "@reduxjs/toolkit"
import { initialCategories as initialState, } from "./types"


const categorySlice = createSlice({
    name: "categorySlice",
    initialState,
    reducers: {
        setComparison(state, action){
            state.comparison = action.payload
        },
        setRootCategories(state, action){
            state.items = [...action.payload]
        },
        setSubCategories(state, action){
            state.selected = [...action.payload]
        },
        setDestCategories(state, action){
            state.selected2 = [...action.payload]
        },
        setFilteredCategories(state, action){
            state.filtered = [...action.payload]
        },
        setIsLoading(state, action){
            state.isLoading = action.payload
        },
    }
})


export const categoryReducer = categorySlice.reducer
export const { setComparison, setRootCategories, setIsLoading, setSubCategories, setFilteredCategories, setDestCategories,  } = categorySlice.actions