import {createSlice} from '@reduxjs/toolkit'
import {emptyUrlParameters, IUrlParameters} from '../../components/lists/UrlFilteredList'
import {emptyOrderStatus, IOrderStatus} from '../orderStatuses/types'
import {
  emptyOrderItem,
  emptyState as initialState,
  IOrderChangeStatusRequest,
  IOrderItem,
  IOrdersGetParams,
} from './types'
import {emptyDeliveryService} from '../deliveryServices/types'

const orderSlice = createSlice({
  name: 'orderSlice',
  initialState,
  reducers: {
    setOrders(state, action) {
      state.items = [...action.payload]
    },
    setSomeOrders(state, action) {
      const orders: IOrderItem[] = action.payload
      state.items = [
        ...state.items.map((item) => {
          const itemToUpdate = orders.find((o) => o.id == item.id)
          return itemToUpdate ? itemToUpdate : item
        }),
      ]
    },
    setSelectedOrder(state, action) {
      state.selected = action.payload
    },
    setSelectedOrderTrackInfo(state, action) {
      const {delivery_service, track_number} = action.payload
      if (state.selected) {
        state.selected = {...state.selected, delivery_service, track_number}
      }
    },
    resetSelectedOrderTrackInfo(state, action) {
      const orderId = action.payload
      if (state.selected) {
        state.selected = {
          ...state.selected,
          delivery_service: emptyDeliveryService,
          track_number: '',
        } //
      }
    },
    setSticker(state, action) {
      state.sticker = action.payload
    },
    registerOrder(state, action) {
      if (state.selected) {
        state.selected = action.payload
      }
    },
    // setStock(state, action){
    //     console.log('setStock',action.payload)

    //     state.items = [...state.items.map((s: IStockListItem) => {
    //         if (s.id == action.payload.stockId){

    //             if (s.stocks?.length){
    //                 const stItems = s.stocks.map(st => {
    //                     if (st.id == action.payload.id){
    //                         st.quantity = action.payload.quantity
    //                         st.reserved = action.payload.reserved
    //                     }
    //                     return st
    //                 })
    //                 return {...s, stocks: stItems}
    //             }
    //             else {
    //                 return s
    //             }
    //         }
    //         else
    //             return s
    //     })]
    // },
    // addStock(state, action){
    //     console.log('addStock',action.payload)

    //     state.items = [...state.items.map((s: IStockListItem) => {
    //         if (s.id == action.payload.stockId){
    //             const stock:IStockItem = {
    //                 id: action.payload.id,
    //                 quantity: action.payload.quantity,
    //                 reserved: action.payload.reserved,
    //                 sku_customer_uid: action.payload.sku_customer_uid,
    //                 warehouse: action.payload.warehouse,
    //             }
    //             return {...s, stocks: [...s.stocks ?? [], stock]}
    //         }
    //         else
    //             return s
    //     })]
    // },
    // setRequestParams(state, action){
    //     const {limit, page, ordering, search} = action.payload
    //     state.itemsPerPage = limit ?? 0
    //     state.currentPage = page ?? 0
    //     state.ordering = ordering ?? ""
    //     state.search = search ?? ""
    // },
    setOrderStatus(state, action) {
      const {status, statuses}: {status: IOrderChangeStatusRequest; statuses: IOrderStatus[]} =
        action.payload
      const res_status =
        statuses.find((os: IOrderStatus) => os.code === status.status.code) ?? emptyOrderStatus

      state.selected = {
        ...(state.selected ?? emptyOrderItem),
        status: res_status.id,
        status_code: res_status.code,
        status_name: res_status.name,
      }
    },
    setRequestParams(state, action) {
      const payload: IUrlParameters = action.payload
      state.filter = payload
      // state.itemsPerPage = payload.items ?? emptyUrlParameters.itemsPerPage
      // state.currentPage = payload.page ?? emptyUrlParameters.pageNumber
      // state.ordering = payload.order ?? emptyUrlParameters.ordering
      // state.search = payload.search ?? emptyUrlParameters.filter
    },
    setIsListLoading(state, action) {
      state.isListLoading = action.payload
    },
    setIsRegistering(state, action) {
      state.isRegistering = action.payload
    },
    setIsItemLoading(state, action) {
      state.isItemLoading = action.payload
    },
    setIsTrackLoading(state, action) {
      state.isTrackLoading = action.payload
    },
    setCount(state, action) {
      state.count = action.payload
    },
  },
})

export const orderReducer = orderSlice.reducer
export const {
  setOrders,
  setIsListLoading,
  setIsItemLoading,
  setCount,
  setRequestParams,
  setSelectedOrder,
  setOrderStatus,
  setSticker,
  setSomeOrders,
  setSelectedOrderTrackInfo,
  resetSelectedOrderTrackInfo,
  setIsTrackLoading,
  setIsRegistering,
  registerOrder,
} = orderSlice.actions
